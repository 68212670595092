import React from 'react'
import SignInDetail from '../components/login/SignInDetail'

export default function Login() {
  return (
    <div>
        <SignInDetail />
    </div>
  )
}
