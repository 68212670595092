import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Link,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Badge,
  Button,
  Image,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import {
  FiHome,
  FiMenu,
  FiBell,
  FiChevronDown,
  FiUsers,
  FiUser,
  FiSettings,
  FiSearch,
  FiUserCheck,
  FiUserPlus,

} from 'react-icons/fi';
import {BiLogOut} from 'react-icons/bi'
import {GrGroup} from 'react-icons/gr'
import {TbReport} from 'react-icons/tb'
import logo from './logo/onemoney.png'
import logo01 from '../../images/netlogo.png'
import logo02 from '../../images/netmoney.jpg'
import netmoney from '../../images/netone.png'
import {
    Outlet, useNavigate,useLocation
  } from "react-router-dom";
import axios from '../../api/axios';
import { MdBuild, MdMyLocation, MdShop } from 'react-icons/md';
import { BsBuilding } from 'react-icons/bs';
import { FaMoneyCheck, FaRegFileExcel } from 'react-icons/fa';
import jwt from 'jwt-decode';


const GETSHOPS_URL = '/employee/shop/v1/get-shops'
const GETREGION_URL = '/employee/region/v1/get-regions';


const LinkItems = [
  { name: 'Home', icon: FiHome,to:'/admin/exec-dashboard', roles: ["EXECUTIVE"] },
  { name: 'Home', icon: FiHome,to:'/admin/home-dashboard', roles: ["ADMIN"] },
  { name: 'Home', icon: FiHome,to:'/admin/super-home', roles: ["SUPER_ADMIN"] },
  { name: 'Home', icon: FiHome,to:"/admin/operator", roles: ["OPERATOR"] },
  { name: 'Home', icon: FiHome,to:"/admin/operator", roles: ["CLERK"] },
  { name: 'Home', icon: FiHome,to:"/admin/operator", roles: ["BRAND_AMBASSADOR"] },
  { name: 'Home', icon: FiHome,to:"/admin/operator", roles: ["SUPPORT"] },
  { name: 'Home', icon: FiHome,to:'/admin/approver-dashboard', roles: ["APPROVER"] },

  {name: 'Sim Bulk Reg', icon: FaRegFileExcel,to:'/admin/add-bulk-registrations', roles: ["ADMIN"] },
  {name: 'Sim Bulk Reg', icon: FaRegFileExcel,to:'/admin/add-bulk-registrations', roles: ["CLERK"] },
  {name: 'Sim Bulk Reg', icon: FaRegFileExcel,to: '/admin/add-bulk-registrations', roles: ["SUPER_ADMIN"] },
  


  // { name: 'Manage Customers', icon: FiUsers,to:"/admin/manage-customer",roles: ["EXECUTIVE"] },
  // { name: 'Manage Customers', icon: FiUsers,to:"/admin/manage-customer",roles: ["ADMIN"] },
  // { name: 'Manage Customers', icon: FiUsers,to:"/admin/manage-customer",roles: ["SUPER_ADMIN"] },
  { name: 'Search Customers', icon: FiSearch,to:"/admin/search-customer",roles: ["SUPER_ADMIN"] },
  { name: 'Search Customers', icon: FiSearch,to:"/admin/search-customer",roles: ["ADMIN"] },
  { name: 'Search Customers', icon: FiSearch,to:"/admin/search-customer",roles: ["EXECUTIVE"] },
  { name: 'Search Customers', icon: FiSearch,to:"/admin/approver-search",roles: ["APPROVER"] },


  
  // { name: 'Manage Users', icon: FiUser,to:"/admin/manage-user", roles: ["HOD"], },
  { name: 'Manage Users', icon: FiUser,to:"/admin/users", roles: ["ADMIN"]},
  { name: 'Manage Users', icon: FiUser,to:"/admin/users", roles: ["SUPER_ADMIN"],},
  { name: 'Users', icon: FiUser,to:"/admin/users", roles: ["EXECUTIVE"]},

  // { name: 'Departments', icon: GrGroup,to:"/admin/manage-department", roles: ["SUPER_ADMIN"],},

  {name: 'Reports', icon: TbReport, to: "/admin/view-report", roles: ["SUPER_ADMIN"],},
  {name: 'Reports', icon: TbReport, to: "/admin/view-report", roles: ["ADMIN"],},
  {name: 'Reports', icon: TbReport, to: "/admin/view-report", roles: ["EXECUTIVE"],},

  // {name: 'BA Registration', icon: FiUserPlus, to: "/admin-user/ba.registrations", roles: ["SUPER_ADMIN"],},

  {name: 'Approval Reports', icon: TbReport, to: "/admin/view-approval-reports", roles: ["ADMIN"],},
  {name: 'Approval Reports', icon: TbReport, to: "/admin/view-approval-reports", roles: ["CLERK"],},
  {name: 'Approval Reports', icon: TbReport, to: "/admin/view-approval-reports", roles: ["SUPER_ADMIN"],},



  {name: 'BA Commission', icon: FaMoneyCheck, to: "/admin/report_ba", roles: ["SUPER_ADMIN"],},
  {name: 'BA Commission', icon: FaMoneyCheck, to: "/admin/report_ba", roles: ["ADMIN"],},



  // {  name: 'Configurations', icon: FiSettings ,to:"/admin/config", roles: ["ADMIN"],},
  {  name: 'Mobile Config', icon: FiSettings ,to:"/admin/config", roles: ["SUPER_ADMIN"],},
  {  name: 'Mobile Config', icon: FiSettings ,to:"/admin/config", roles: ["SUPPORT"],},

  {  name: 'System Config', icon: FiSettings ,to:"/admin/system-config", roles: ["SUPER_ADMIN"],},
  // {  name: 'System Config', icon: FiSettings ,to:"/admin/system-config", roles: ["ADMIN"],},
  
  


];

export default function SidebarNavbar({children}) {
  const location = useLocation();
const path = location.pathname;
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent
       path={path}
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
      {/* <Statistics/> */}
        <Outlet/>
        {/* {children} */}
      </Box>
    </Box>
  );
}


const SidebarContent = ({ onClose, path, ...rest }) => {

  let user = localStorage.getItem('user')
  user = JSON.parse(user);
console.log(user)
const userRoles = user?.roles[0].name


const filteredSidebarItems = LinkItems.filter(item => userRoles === item.roles[0]);
//  console.log(filteredSidebarItems)

//  const filterSidebar = filteredSidebarItems.filter(itek => title === itek.title)

const [loading, setLoading] = useState(false)
const [shops, setShops] = useState([]);
const [region, setRegion] = useState([]);
const getAllShops = async () => {
  setLoading(true);
const token = localStorage.getItem("token");
try {
  const response = await axios.get(`${GETSHOPS_URL}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })

  if(response.status === 200) {
    setShops(response.data.body)
    setLoading(false);
  }
} catch (error) {

}
}

// GET REGION
const getAllRegions = async () => {
    setLoading(true);
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(`${GETREGION_URL}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })

    if(response.status === 200) {
      setRegion(response.data.body)
      setLoading(false);
    }
  } catch (error) {

  }
}

useEffect(() => {
    getAllRegions();
  
  }, [])
  

useEffect(() => {
  getAllShops();

}, [])


let shopes;
     shops?.forEach((item) => {
       if (user.shopId === item.id) {
         shopes = item;
         
       }
     })

     let regiones;
     region?.forEach((item) => {
       if (user.regionId === item.id) {
         regiones = item;
        
       }
     })


     const [open,setOpen] = useState(false)
  const cancelRef = React.useRef()
  const navigate = useNavigate();
  


  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}>
      <Flex h="20" alignItems="center" mx="15" justifyContent="space-between">
       <HStack spacing={2}>


  <Image
  borderRadius='full'
  boxSize='75px'
  src={logo02}
  alt='logo'
/>

  {shopes?.name ? (<Text  fontSize="sm" fontFamily="monospace" fontStyle={'oblique'}><BsBuilding />{shopes.name} </Text>) : (<Text  fontSize="sm" fontFamily="monospace" fontStyle={'oblique'}>{regiones?.name} </Text>)}


         
            
          
       </HStack>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {filteredSidebarItems.map((link) => (
        <NavItem path={path} key={link.name} to={link.to} icon={link.icon}>
          {link.name}
        </NavItem>
      ))}


    </Box>
  );
};

const NavItem = ({ path, icon, children,to, ...rest }) => {
  return (
    <Link href={to} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>

      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        bg={path === to ? '#F68C1E' : 'white'}
        color={path === to ? 'white' : 'black'}
        _hover={{
          bg: '#F68C1E',
          color: 'white',
        }}
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

// interface MobileProps extends FlexProps {
//   onOpen: () => void;
// }
const MobileNav = ({ onOpen, ...rest }) => {
  const navigate = useNavigate();
  let user = localStorage.getItem('user')
  user = JSON.parse(user);
  
const [loading, setLoading] = useState(false)
const [shops, setShops] = useState([]);
const [region, setRegion] = useState([]);
const getAllShops = async () => {
  setLoading(true);
const token = localStorage.getItem("token");
try {
  const response = await axios.get(`${GETSHOPS_URL}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })

  if(response.status === 200) {
    setShops(response.data.body)
    setLoading(false);
  }
} catch (error) {

}
}

// GET REGION
const getAllRegions = async () => {
    setLoading(true);
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(`${GETREGION_URL}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })

    if(response.status === 200) {
      setRegion(response.data.body)
      setLoading(false);
    }
  } catch (error) {

  }
}


useEffect(() => {
    getAllRegions();
  
  }, [])
  

useEffect(() => {
  getAllShops();

}, [])


let shopes;
     shops?.forEach((item) => {
       if (user.shopId === item.id) {
         shopes = item;
         
       }
     })

     let regiones;
     region?.forEach((item) => {
       if (user.regionId === item.id) {
         regiones = item;
        
       }
     })

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}>
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />
      {shopes?.name ? (<Text  display={{ base: 'flex', md: 'none' }}  fontSize="xl" fontFamily="monospace" fontStyle={'oblique'}><BsBuilding />{shopes.name} </Text>) : (<Text  display={{ base: 'flex', md: 'none' }} fontSize="xl" fontFamily="monospace" fontStyle={'oblique'}><MdMyLocation/> {regiones?.name} </Text>)}

      {/* <Text
        display={{ base: 'flex', md: 'none' }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold">
        Logo
      </Text> */}

      <HStack spacing={{ base: '0', md: '6' }}>
        <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<FiBell />}
        />
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}>
              <HStack>
                <Avatar
                  size={'sm'}
                  // src={
                  //   'https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                  // }
                  name={user?.firstName }
                
                />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="2px"
                  ml="2">
                  <Text fontSize="sm">{user?.firstName} {user?.lastName}</Text>
                  {/* <Text fontSize="xs" color="gray.600">
                    {user.title}
                  </Text> */}
                  <Text fontSize="xs" color="gray.600" textTransform={'lowercase'} >
              
                    
                    {user?.title} - {user?.roles[0].name === 'SUPER_ADMIN' ? (<Badge variant='solid' colorScheme='cyan'>Super Admin</Badge>)
                    :user?.roles[0].name === 'ADMIN' ? (<Badge variant={'solid'} colorScheme={'yellow'} size={'xs'}>ADMIN</Badge>)
                    : user?.roles[0].name === 'CLERK' ? (<Badge variant={'solid'} colorScheme={'orange'} size={'xs'}>Clerk</Badge>)
                    : user?.roles[0].name === 'SUPPORT' ? (<Badge variant={'solid'} colorScheme={'purple'} size={'xs'}>Support</Badge>)
                    : user?.roles[0].name === 'OPERATOR' ? (<Badge variant={'solid'} colorScheme={'red'} size={'xs'}>Operator</Badge>)
                    : user?.roles[0].name === 'BRAND_AMBASSADOR' ? (<Badge variant={'solid'} colorScheme={'red'} size={'xs'}>BA</Badge>)
                    : user?.roles[0].name === 'BRAND_AMBASSADOR' ? (<Badge variant={'solid'} colorScheme={'linkedin'} size={'xs'}>Executive</Badge>)
                    : user?.roles[0].name === 'APPROVER' ? (<Badge variant={'solid'} bg={'yellow.400'}  size={'xs'}>Approver</Badge>)

                    : <Button colorScheme='orange'  onClick={() => { 
                      localStorage.clear(); 
                      navigate("/")
                    }}>Sign in
                      </Button>}
                     
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}>
              <MenuItem icon={<FiUser/>}
              onClick={()=>navigate(`/admin/profile/${user.id}`)}>Profile</MenuItem>
              {/* <MenuItem>Settings</MenuItem>
              <MenuItem>Billing</MenuItem> */}
              <MenuDivider />
              <MenuItem icon={<BiLogOut/>}
              onClick={() => {
                localStorage.clear();
                navigate("/")
              }}
              >Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};