import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  HStack,
  Avatar,
  AvatarBadge,
  IconButton,
  Center,
  Box,
  Text,
  VStack,
  Card,
  CardBody,
  StackDivider,
  Container,
  useToast,
  Tag,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Select,
  Textarea,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  InputRightElement,

  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Portal,
  Wrap,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { FiChevronDown, FiUser } from 'react-icons/fi';
import { MdUpdate } from 'react-icons/md';
import {CgProfile} from 'react-icons/cg'
import { useLocation, useNavigate } from 'react-router-dom';
// import { SmallCloseIcon } from '@chakra-ui/icons';
import axios from '../../api/axios';

const GETPROFILEMP_URL = 'employee/profile/v1/get-employee/'
const UPDATEPROFILE_URL = 'employee/profile/v1/update-employee/'
const CHANGEPASS_URL = 'employee/auth/v1/change-password/'
const UPDATEAVAILABLE_URL = 'employee/profile/v1/update-employee-availability'



export default function UserProfileEdit() {

  const [profile, setProfile] = useState('');
  const [grade, setGrade] = useState("");
  const [loading, setLoading] = useState(false);
  const [availability, setAvailability] = useState('');

  const toast = useToast();
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const firstField = React.useRef()
  const { isOpen: isCPOpen, onOpen: onCPOpen, onClose: onCPClose } = useDisclosure();
  const cancelRef = React.useRef()

  const { isOpen: isAVOpen, onOpen: onAVOpen, onClose: onAVClose } = useDisclosure();
  const cancelAV = React.useRef()

  const [oldshow, setOldShow] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [showmatchpxd, setShowMatchPxd] = React.useState(false);
  const handleClick = () => setShow(!show);
  const handleOClick = () => setOldShow(!oldshow);
  const handleTclick = () => setShowMatchPxd(!showmatchpxd);
  const [oldpassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('')
  const [confpassword, setConfPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [oldpasswordError, setOldPasswordError] = useState('')
  const [confirmPassError, setConfirmPassError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')


  
  const getEmployee = async () => {

    const token = localStorage.getItem("token");
    let user = localStorage.getItem('user')
    user = JSON.parse(user);
    try {
      const response = await axios.get(GETPROFILEMP_URL + `${user.id}`, {

        headers: {
          'Authorization': `Bearer ${token}`
        }
      })

      if (response.status === 200) {
        setProfile(response.data.body);

      }
      else {

        toast({
          title: 'Failed',
          description: response.data.message,
          status: 'error',
          duration: 3000,

        });
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        toast({
          title: "Error",
          description: `Server responded with ${error.response.status}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });

      } else if (error.request) {
        // The request was made but no response was received
        toast({
          title: "Network Error",
          description: "Please Check your Internet Connection",
          status: "error",
          duration: 8000,
          isClosable: true,
        });

      } else {
        // Something happened in setting up the request that triggered an Error
        toast({
          title: "Network Error",
          description: `Error`,
          status: "error",
          duration: 8000,
          isClosable: true,
        });

      }
    }
  }

  useEffect(() => {

    getEmployee();
  }, []);

  const inititate = {
    ecNumber: profile.ecNumber,
    firstName: profile.firstName,
    lastName: profile.lastName,
    personalPhoneNumber: profile.personalPhoneNumber,
    personalEmail: profile.personalEmail,
    workPhoneNumber: profile.employeeAccount?.workPhoneNumber,
    workEmail: profile.employeeAccount?.workEmail,
    address: profile.address,
  }
  const [employee, setEmployee] = useState(inititate)
    const {ecNumber, firstName, lastName, personalPhoneNumber, personalEmail, workPhoneNumber, workEmail, address } = employee

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setEmployee({ ...employee, [name]: value });
    };
  const handlesubmit = () => {

    setLoading(true);
    let user = localStorage.getItem('user')
    user = JSON.parse(user);
    const token = localStorage.getItem("token");
    axios.put(UPDATEPROFILE_URL, {
      id: user.id,
      ecNumber:employee.ecNumber,
      firstName: employee.firstName,
      lastName: employee.lastName,
      personalPhoneNumber:employee.personalPhoneNumber,
      personalEmail: employee.personalEmail,
      workPhoneNumber: employee.workPhoneNumber,
      workEmail: employee.workEmail,
      grade,
      address:employee.address,


    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }

    })


      .then(response => {
        if (response.data.success) {
          getEmployee();
          onClose();
          setLoading(false)
          toast({
            title: 'User created',
            description: response.data.message,
            status: 'success',
            duration: 6000,

          });
        }

        else {
          setLoading(false)
          toast({
            title: 'Failed',
            description: response.data.message,
            status: 'error',
            duration: 3000,

          });
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast({
            title: "Error",
            description: `Server responded with ${error.response.status}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          setLoading(false)
        } else if (error.request) {
          // The request was made but no response was received
          toast({
            title: "Network Error",
            description: "Please Check your Internet Connection",
            status: "error",
            duration: 8000,
            isClosable: true,
          });
          setLoading(false)
        } else {
          // Something happened in setting up the request that triggered an Error
          toast({
            title: "Network Error",
            description: `Error: ${error.message}`,
            status: "error",
            duration: 8000,
            isClosable: true,
          });

        }
      })
  }

  const handleChangepass = () => {
    setLoading(true);
    // let user = localStorage.getItem('user')
    // user = JSON.parse(user);
    const token = localStorage.getItem("token");
    axios.post(CHANGEPASS_URL, {
      oldPassword: oldpassword,
      newPassword: password


    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }

    })


      .then(response => {
        if (response.data.success) {
          getEmployee();
          onCPClose();
          setLoading(false)
          toast({
            title: 'Message',
            description: response.data.message,
            status: 'success',
            duration: 6000,

          });
        }

        else {
          setLoading(false)
          toast({
            title: 'Password Failed',
            description: response.data.message,
            status: 'error',
            duration: 3000,

          });
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast({
            title: "Error",
            description: `Server responded with ${error.response.status}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          setLoading(false)
        } else if (error.request) {
          // The request was made but no response was received
          toast({
            title: "Network Error",
            description: "Please Check your Internet Connection",
            status: "error",
            duration: 8000,
            isClosable: true,
          });
          setLoading(false)
        } else {
          // Something happened in setting up the request that triggered an Error
          toast({
            title: "Network Error",
            description: `Error: ${error.message}`,
            status: "error",
            duration: 8000,
            isClosable: true,
          });

        }
      })
  }

  const handleapprove = (status) => {
    // e.preventDefault();
    setLoading(true)
    let user = localStorage.getItem('user')
    user = JSON.parse(user);
    const token = localStorage.getItem("token");
    axios.put(UPDATEAVAILABLE_URL, {
      id: user.id,
      availability: status

    }, {
      headers: {
        "Content-type": "application/json",
        'Authorization': `Bearer ${token}`
      }
    }

    ).then(response => {
      if (response.data.success) {
        let user = localStorage.getItem('user')
        user = JSON.parse(user);
        console.log(user.roles[0].name)
        if (user.roles[0].name === 'EXECUTIVE') {
          navigate('/admin/exec-dashboard');
        }else if (user.roles[0].name === 'ADMIN'){
          navigate('/admin/home-dashboard')
        }else if (user.roles[0].name === 'SUPER_ADMIN'){
          navigate('/admin/super-home')
        }else{
          navigate('/admin/operator')
        }
        
        toast({
          title: 'Success.',
          description: 'Status Updated',
          status: 'success',
          duration: 6000,

        });
        setLoading(false)
      }

      else {
        setLoading(false)
        toast({
          title: 'Failed',
          description: 'Failed to change',
          status: 'error',
          duration: 6000,

        });
      }
    })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast({
            title: "Error",
            description: `Server responded with ${error.response.status}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          setLoading(false)
        } else if (error.request) {
          // The request was made but no response was received
          toast({
            title: "Network Error",
            description: "Please Check your Internet Connection",
            status: "error",
            duration: 8000,
            isClosable: true,
          });
          setLoading(false)
        } else {
          // Something happened in setting up the request that triggered an Error
          toast({
            title: "Network Error",
            description: `Error: ${error.message}`,
            status: "error",
            duration: 8000,
            isClosable: true,
          });

        }
      })
  }

  const validate = () => {
    if (!oldpassword) {
      setOldPasswordError('password is required')
      return false;
    }
    if (!password) {
      setPasswordError('password is required')
      return false;
    }


    if (!confpassword) {
      setConfirmPassError('confirm password is required')
      return false;
    }

    if (password !== confpassword) {
      setErrorMessage('Password and Confirm password do not match');
      return false;
    }

    return true

  }
  return (
    <>
      <Flex
        p={2}
        minH={'100vh'}
        flexDirection={'row'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}>
        <Box
          spacing={4}
          w={'full'}
          maxW={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          rounded={'xl'}
          boxShadow={'lg'}
          p={6}
          my={12}>
          <Heading mb={4} lineHeight={1.1} fontSize={{ base: 'xl', sm: '2xl' }}>
            Profile Edit
          </Heading>
          <Stack divider={<StackDivider />} spacing='4'>
          <FormControl id="userName">

            <Stack direction={['column', 'row']} spacing={6} align={'center'} justify={'center'}>
              <HStack>
                <Avatar
                  size={'2xl'}
                  // src={
                  //   'https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                  // }

                  name={profile.firstName === 'null' ? '' : profile.firstName}
                />

              </HStack>
            </Stack>
            
            <Flex mt={2} alignContent={'center'} justifyContent={'center'} >
              <Heading size='xs'>
                Status:
              </Heading>
              <Popover>
                <PopoverTrigger>

                  {profile.availability === 'PRESENT' ? (<Button ml={2} colorScheme='green' size='xs'>Present</Button>) : profile.availability === 'ABSENT' ? (<Button ml={2} colorScheme='red' size='xs'>Absent</Button>) : <Button ml={2} colorScheme='orange' size='xs'>OnLeave</Button>}
                </PopoverTrigger>
                <Portal>
                  <PopoverContent >
                    <PopoverArrow />
                    <PopoverHeader>Change Status</PopoverHeader>
                    <PopoverCloseButton />
                    <PopoverBody>
                      <Wrap spacing={2} direction='row' align='center'>

                        <Button
                          onClick={() => handleapprove('PRESENT')}
                          onChange={(e) => setAvailability('PRESENT')}
                          colorScheme='green'>Present</Button>
                        <Button
                          onClick={() => handleapprove('LEAVE')}
                          onChange={(e) => setAvailability('LEAVE')}
                          colorScheme='orange'>OnLeave</Button>
                        <Button
                          onClick={() => handleapprove('ABSENT')}
                          onChange={(e) => setAvailability('ABSENT')}
                          colorScheme='red'>Absent</Button>
                      </Wrap>
                    </PopoverBody>

                  </PopoverContent>
                </Portal>
              </Popover>
            </Flex>
          </FormControl>

         
          <Heading  mb={4} mt={4} lineHeight={1.1} textColor={'orange.400'} fontSize={{ base: 'lg', sm: 'lg' }} >
            Personal Profile 
          </Heading>

            <HStack spacing={28}>

              <Box>
              
                <Heading size='xs'>
                  First Name:
                </Heading>
                <Text pt='2' fontSize='sm'>
                {profile.firstName}
                </Text>
              </Box>
              <Box>
                <Heading size='xs' >
                  Last Name:
                </Heading>
                <Text pt='2' fontSize='sm'>
                  {profile.lastName}
                </Text>
              </Box>

            </HStack>
            <HStack spacing={20}>

              <Box>
                <Heading size='xs' >
                  Personal Number:
                </Heading>
                <Text pt='2' fontSize='sm'>
                  {profile.personalPhoneNumber == null ? <Text color={'gray.400'}>Not Available</Text> : profile.personalPhoneNumber}
                </Text>
              </Box>
              <Box>
                <Heading size='xs'>
                  Personal Email:
                </Heading>
                <Text pt='2' fontSize='sm'>
                  {profile.personalEmail == null ? <Text color={'gray.400'}>Not Available</Text> : profile.personalEmail}
                </Text>
              </Box>

            </HStack>
            <Box>
              <Heading size='xs'>
                Adress:
              </Heading>
              <Text pt='2' fontSize='sm'>
                {profile.address == null ? <Text color={'gray.400'}>Not Available</Text> : profile.address}
              </Text>
            </Box>
            <Heading mb={4} mt={4} lineHeight={1.1} textColor={'orange.400'} fontSize={{ base: 'xl', sm: 'xl' }}>
              Work Profile
            </Heading>
            <HStack spacing={20}>

              <Box>
                <Heading size='xs' >
                  Work Phone:
                </Heading>
                <Text pt='2' fontSize='sm'>
                  {profile.employeeAccount?.workPhoneNumber == null ? <Text color={'gray.400'}>Not Available</Text> : profile.employeeAccount.workPhoneNumber}
                </Text>
              </Box>
              <Box>
                <Heading size='xs'>
                  Work Email:
                </Heading>
                <Text pt='2' fontSize='sm'>
                  {profile.employeeAccount?.workEmail == null ? <Text color={'gray.400'}>Not Available</Text> : profile.employeeAccount.workEmail}
                </Text>
              </Box>

            </HStack>
            <HStack spacing={20}>

              <Box>
                <Heading size='xs' >
                  EC Number:
                </Heading>
                <Text pt='2' fontSize='sm'>
                  {profile.ecNumber == null ? <Text color={'gray.400'}>Not Available</Text> : profile.ecNumber}
                </Text>
              </Box>
              <Box>
                <Heading size='xs'>
                  Work Zone:
                </Heading>
                <Text pt='2' fontSize='sm'>
                  {profile.employeeZone == null ? <Text color={'gray.400'}>Not Available</Text> : profile.employeeZone}
                </Text>
              </Box>

            </HStack>
            <Box>
              <Heading size='xs' >
                Grade:
              </Heading>
              <Text pt='2' fontSize='sm'>
                {profile.grade == null ? <Text color={'gray.400'}>Not Available</Text> : profile.grade}
              </Text>
            </Box>
          </Stack>

          <Stack mt={2} align={'center'} justify={'center'} spacing={6} direction={['row']}>
            <Button
              onClick={onOpen}
              rightIcon={<MdUpdate/>}
              bg={'orange.400'}
              color={'white'}
              w="150px"
              _hover={{
                bg: 'blue.500',
              }}>
              Update Profile
            </Button>
            <Button
              onClick={onCPOpen}
              mt={8}
              bg={'blue.400'}
              color={'white'}
              w="150px"
              _hover={{
                bg: 'teal.500',
              }}>
              Change Password
            </Button>
          </Stack>
          <Drawer
            isOpen={isOpen}
            placement='right'
            initialFocusRef={firstField}
            onClose={onClose}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader borderBottomWidth='1px'>
                Update Profile
              </DrawerHeader>

              <DrawerBody>
                <Stack spacing='24px'>
                  <Box>
                    <FormLabel htmlFor='username'>EC Number</FormLabel>
                    <Input
                      ref={firstField}
                      type='text'
                      id='ecNumber'
                      name='ecNumber'
                      placeholder='ec number'
                      value={employee.ecNumber}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box>

                    <FormControl>
                      <FormLabel htmlFor='firstName'>First name</FormLabel>
                      <Input
                        marginBottom={2}
                        id='firstName'
                        placeholder='First name'
                        type='text'
                        name='firstName'
                        value={employee.firstName}
                        onChange={handleInputChange}
                      />

                    </FormControl>
                  </Box>
                  <Box>

                    <FormControl>
                      <FormLabel htmlFor='lastName'>Last name</FormLabel>
                      <Input
                        marginBottom={2}
                        id='lastName'
                        placeholder='Last name'
                        type='text'
                        name='lastName'
                        value={employee.lastName}
                        onChange={handleInputChange}

                      />

                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl mt={2}>
                      <FormLabel>Grade</FormLabel>
                      <Select placeholder='Select Grade' w={'180px'} onChange={(e) => setGrade(e.target.value)} >
                        <option value='6'>Grade6</option>
                        <option value='7'>Grade7</option>
                        <option value='8'>Grade8</option>
                        <option value='9'>Grade9</option>
                        <option value='10'>Grade10</option>
                        <option value='11'>Grade11</option>
                        <option value='12'>Grade12</option>
                        <option value='13'>Grade13</option>
                        <option value='14'>Grade14</option>
                        <option value='15'>Grade15</option>
                        <option value='16'>Grade16</option>
                        <option value='17'>Grade17</option>
                        <option value='18'>Grade18</option>
                        <option value='19'>Grade19</option>
                        <option value='20'>Grade20</option>
                        <option value='21'>Grade21</option>
                        <option value='22'>Grade22</option>
                        <option value='23'>Grade23</option>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl mt={2}>
                      <FormLabel htmlFor='phoneNumber'>Personal Number</FormLabel>
                      <InputGroup>
                        <InputLeftAddon children='+263' />
                        <Input id='personalPhoneNumber'
                          type='number'
                          placeholder='712000000'
                          _placeholder={{ color: 'gray.500' }}
                          name='personalPhoneNumber'
                          value={employee.personalPhoneNumber}
                          onChange={handleInputChange}

                        />
                      </InputGroup>
                    </FormControl>

                  </Box>
                  <Box>

                    <FormControl>
                      <FormLabel mt={2} htmlFor='email'>Personal Email: </FormLabel>
                      <Input

                        id='personalEmail'
                        placeholder='example@email.com'
                        name='personalEmail'
                        type='email'
                        value={employee.personalEmail}
                        onChange={handleInputChange}


                      />

                    </FormControl>

                  </Box>
                  <Box>
                    <FormControl mt={2}>
                      <FormLabel htmlFor='phoneNumber'>Work Number</FormLabel>
                      <InputGroup>
                        <InputLeftAddon children='+263' />
                        <Input id='workPhoneNumber'
                          type='number'
                          placeholder='712000000'
                          _placeholder={{ color: 'gray.500' }}
                          name='workPhoneNumber'
                          value={employee.workPhoneNumber}
                          onChange={handleInputChange}

                        />
                      </InputGroup>
                    </FormControl>

                  </Box>
                  <Box>

                    <FormControl>
                      <FormLabel mt={2} htmlFor='email'>Work Email: </FormLabel>
                      <Input

                        id='workEmail'
                        placeholder='example@email.com'
                        name='workEmail'
                        type='email'
                        value={employee.workEmail}
                        onChange={handleInputChange}


                      />

                    </FormControl>

                  </Box>
                  <Box>
                    <FormLabel htmlFor='desc'>Address</FormLabel>
                    <Textarea
                      name='address'
                      value={employee.address}
                      onChange={handleInputChange}
                      placeholder='Here is a sample placeholder'
                      size='sm'
                    />
                  </Box>
                </Stack>
              </DrawerBody>

              <DrawerFooter borderTopWidth='1px'>
                <Button variant='outline' mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button onClick={handlesubmit} colorScheme='orange'>Update</Button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>

          <AlertDialog
            isOpen={isCPOpen}
            leastDestructiveRef={cancelRef}
            onClose={onCPClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                  Change Password
                </AlertDialogHeader>

                <AlertDialogBody>
                  <Stack
                    spacing={4}
                    w={'full'}
                    maxW={'md'}
                    bg={useColorModeValue('white', 'gray.700')}
                    rounded={'xl'}
                    boxShadow={'lg'}
                    p={6}
                    my={12}>
                    {/* <FormHelperText>Token expires at: {}</FormHelperText> */}
                    <FormControl isRequired>
                      <FormLabel htmlFor="oldpassword"> Old Password</FormLabel>
                      <InputGroup size="md">
                        <Input
                          pr="4.5rem"
                          type={oldshow ? 'text' : 'password'}
                          placeholder="Enter password"
                          name='oldpassword'
                          value={oldpassword}
                          onFocus={() => { setOldPasswordError('') }} borderColor={oldpasswordError ? 'red.400' : 'gray.300'}
                          onChange={(e) => setOldPassword(e.target.value)}
                        />
                        <InputRightElement width="4.5rem">
                          <Button h="1.75rem" size="sm" onClick={handleOClick}>
                            {oldshow ? 'Hide' : 'Show'}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                      <Text color={'red'}>{oldpasswordError}</Text>
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel htmlFor="password"> New Password</FormLabel>
                      <InputGroup size="md">
                        <Input
                          pr="4.5rem"
                          type={show ? 'text' : 'password'}
                          placeholder="Enter password"
                          name='password'
                          value={password}
                          onFocus={() => { setPasswordError('') }} borderColor={passwordError ? 'red.400' : 'gray.300'}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <InputRightElement width="4.5rem">
                          <Button h="1.75rem" size="sm" onClick={handleClick}>
                            {show ? 'Hide' : 'Show'}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                      <Text color={'red'}>{passwordError}</Text>
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel htmlFor="password">Confirm Password</FormLabel>
                      <InputGroup size="md">
                        <Input
                          pr="4.5rem"
                          type={showmatchpxd ? 'text' : 'password'}
                          placeholder="Enter password"
                          name='confpassword'
                          value={confpassword}
                          onFocus={() => { setConfirmPassError('') }} borderColor={confirmPassError ? 'red.400' : 'gray.300'}
                          onChange={(e) => setConfPassword(e.target.value)}
                        />
                        <InputRightElement width="4.5rem">
                          <Button h="1.75rem" size="sm" onClick={handleTclick}>
                            {showmatchpxd ? 'Hide' : 'Show'}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                      <Text color={'red'}>{confirmPassError}</Text>
                    </FormControl>
                    {
                      errorMessage ? <Text color={'red'}>{errorMessage}</Text> : <></>
                    }
                  </Stack>
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onCPClose}>
                    Cancel
                  </Button>
                  <Button
                    colorScheme='green'
                    onClick={handleChangepass} ml={3}
                    isLoading={loading}
                    loadingText='Loading'
                    spinnerPlacement='start'
                    bg={'orange.400'}
                    color={'white'}
                    _hover={{
                      bg: 'blue.500',
                    }}
                  >
                    Update
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </Box>

      </Flex>
    </>
  );
}