import React, { useState, useEffect } from 'react'
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Stack,
    Box,
    chakra,
    Wrap,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
    Flex,
    Text,
    Center,
    useDisclosure,
    Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  Select,
  useToast,
  InputGroup,
  InputLeftElement,
  Spinner,
  } from '@chakra-ui/react'
  import {MdOutlineSendToMobile} from 'react-icons/md'
  import { useNavigate } from 'react-router-dom'
  import axios from '../../api/axios'

  const ADDLIMIT_URL = 'mobile-api/config/limit'
  const GETLIMITS_URL = 'mobile-api/config/limits'
  const DELETETLIMIT_URL = 'mobile-api/config/limit/'
  const GETCURRENCY_URL = 'mobile-api/config/currencies'

function TransLimit() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const initialRef = React.useRef(null);

    const toast = useToast();
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [minimum , setMin] = useState('')
    const [maximum, setMax] = useState('')

    const [ loading, setLoading] = useState(false);
    const [nameError, setNameError] = useState('')
    const [minerror, setMinError] = useState('')
    const [maxerror, setMaxError] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const handlesubmit = () => {
        setErrorMessage('')
    const res = validate();
        if (!res) {
      toast({
        title: 'Failed',
        description: "Form not complete",
        status: 'error',
        duration: 3000,

      });
      return;
    }
        setLoading(true)
        const token = localStorage.getItem("token");
        axios.post(ADDLIMIT_URL, {
          name,
          minimum,
          maximum,
          currencyId
          
        },{
          headers: { 
            "Content-type": "application/json",
            'Authorization': `Bearer ${token}`
          }
          
        })
        .then(response => {
          
            if (response.data.success) {
                navigate('/admin/config')
                getLimits();
                onClose();
                toast({
                  title: 'Success',
                  description: response.data.message,
                  status: 'success',
                  duration: 6000,
                  
                });
                setLoading(false)
              }
            
            else {
              setLoading(false)
              toast({
                title: 'Failed',
                description: response.message,
                status: 'error',
                duration: 3000,
               
              });
            }
        })
        .catch(error => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              toast({
                title: "Error",
                description: `Server responded with ${error.response.status}`,
                status: "error",
                duration: 5000,
                isClosable: true,
              });
              setLoading(false)
            } else if (error.request) {
              // The request was made but no response was received
              toast({
                title: "Network Error",
                description: "No response from server",
                status: "error",
                duration: 8000,
                isClosable: true,
              });
              setLoading(false)
            } else {
              // Something happened in setting up the request that triggered an Error
              toast({
                title: "Network Error",
                description: `Error: ${error.message}`,
                status: "error",
                duration: 8000,
                isClosable: true,
              });
             
            }
          })
    }
    useEffect(() => {

    }, [loading])

    const validate =() => {
        if (!name) {
            setNameError('Enter currency name')
            return false;
          }
          if (!minimum) {
            setMinError('Enter minimum amount')
            return false;
          }
          if (!maximum) {
            setMaxError('Enter maximum amount')
            return false;
          }
          if (parseInt(minimum) > parseInt(maximum)) {
            console.log(parseInt(minimum) > parseInt(maximum))
            setMinError('Minimum must be lower than maximum');
            return false;
          }
          
          return true;
    }

    const [selectCurrency, setSelectCurrency] = useState([])
    const [currencyId, setCurrencyId] = useState('')
    const [limit, setLimit] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [totalPages, setTotalPages] = useState(1);

    const getCurrencies = async () => {
      setLoading(true);
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`${GETCURRENCY_URL}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
  
        if(response.status === 200) {
          setSelectCurrency(response.data.body)
          setLoading(false);
        }
      } catch (error) {
 
      }
    }

    useEffect(() => {
       
        getCurrencies();
      
      }, [])
      
   
    const getLimits = async () => {
        setLoading(true)
        const token = localStorage.getItem("token");
        try {
            const response = await axios.get(`${GETLIMITS_URL}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
            })
    
            if(response.status === 200) {
            setLimit(response.data.body)
           
            setLoading(false);
            }
        } catch (error) {
        
        }
        }

    useEffect(() => {
      
        getLimits();
      
      }, [])
      
      const handleDelete = async (id) => {
        setLoading(true)
        const token = localStorage.getItem("token");
        try {
          
          const response = await axios.delete(DELETETLIMIT_URL + `${id}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          if(response.data.success) {
            getLimits();
            toast({
              title: 'Success',
              description: "Deleted",
              status: 'success',
              duration: 6000,
              
            });
            setLoading(false)
          
          }else{
            toast({
              title: 'Failed',
              description: "Failed to Delete",
              status: 'error',
              duration: 6000,
              
            });
            setLoading(false)
          }
        } catch (error) {
          
        }
      };
      

      useEffect(() => {
        setTotalPages(Math.ceil(limit.length / itemsPerPage));
      }, [limit]);
      
      const paginateData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return limit.slice(startIndex, endIndex);
      };
      
      const handlePageChange = (page) => {
        setCurrentPage(page);
      }
      
      const paginatedData = paginateData(limit, currentPage, itemsPerPage);



  return (
    <Box maxW="4xl" >
      <Stack direction='row' spacing={4}>
  <Button onClick={onOpen}  leftIcon={<MdOutlineSendToMobile/>} colorScheme='orange' variant='solid'>
    Add Limit
  </Button>
  <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Limit</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Name:</FormLabel>
              <Input type='text'  placeholder=' Name' onChange={(e) => setName(e.target.value)}  onFocus={()=> {setNameError('')}} borderColor={nameError ? 'red.400' : 'gray.300'} />
              <Text color={'red'}>{nameError}</Text>
            </FormControl>
            <FormControl mt={2}>
          <FormLabel>Currency</FormLabel>
               <Select 
                      _hover={{
                        borderRadius: 'orange',
                      }} 
                      placeholder='Select Currency' name='currencyId' onChange={(e)=> setCurrencyId(e.target.value)}>
                 {selectCurrency.length === 0 && <Spinner />}
              {selectCurrency.map((currency) => (
                <option key={currency.id} value={currency.id}>
                  {currency.name}
                </option>
              ))} 
              </Select>
          </FormControl>

            <FormControl mt={4}>
              <FormLabel>Minimum Limit:</FormLabel>
              <InputGroup>
              <InputLeftElement
                pointerEvents='none'
                color='gray.300'
                fontSize='1.2em'
                children='$'
              />
              <Input 
              type={'number'}
              step="0.01"
              min="0"
               placeholder='minimum' 
               onChange={(e) => setMin(e.target.value)}  
               onFocus={()=> {setMinError('')}} borderColor={minerror ? 'red.400' : 'gray.300'} />
              <Text color={'red'}>{minerror}</Text>
              </InputGroup>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Maximum Limit:</FormLabel>
              <InputGroup>
              <InputLeftElement
                pointerEvents='none'
                color='gray.300'
                fontSize='1.2em'
                children='$'
              />
              <Input type={'number'} placeholder='maximum' onChange={(e) => setMax(e.target.value)}  onFocus={()=> {setMaxError('')}} borderColor={maxerror ? 'red.400' : 'gray.300'} />
              <Text color={'red'}>{maxerror}</Text>
              </InputGroup>
              {
              errorMessage ? <Text color={'red'}>{errorMessage}</Text> : <></>
            }
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button 
             onClick={handlesubmit} 
             isLoading={loading}
             loadingText='Loading'
             spinnerPlacement='start'
            colorScheme='blue' mr={3}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  </Stack>
    <Stack p="3" bg='white' boxShadow="lg" m="4" borderRadius="sm" width={'1100px'}>
    <TableContainer>
  <Center>

  <Table variant='simple' >
    <TableCaption> 
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
    
    <Button
    colorScheme={'orange'}
    m={1}
    key={page}
    onClick={() => handlePageChange(page)}
    color={page === currentPage ? 'black' : 'white'}
    cursor="pointer"

    >
            {page}
          </Button>
        ))}
        </TableCaption>
    <Thead>
      <Tr>
        <Th>#</Th>
        <Th>Name</Th>
        <Th>Currency</Th>
        <Th>Minimum Limit</Th>
        <Th>Maximum Limit</Th>
        <Th>Action</Th>
      </Tr>
    </Thead>
    <Tbody>
    {paginatedData.map((data,index) =>{

      
const ZigMin = data?.minimum?.toLocaleString('en-US', {
  style: 'currency',
  currency: 'USD', // You can change the currency code as needed
});
const ZigMax = data?.maximum?.toLocaleString('en-US', {
  style: 'currency',
  currency: 'USD', // You can change the currency code as needed
});
      return(
      <Tr key={index}>
      <Td>{index +1}</Td>
      <Td>{data.name == null ? '': data.name}</Td>
      <Td>{data.currency?.name == null ? '': data.currency.name}</Td>
      <Td>{ZigMin == null ? '': ZigMin }</Td>
      <Td>{ZigMax == null ? (<Text fontSize={'3xs'}>No text</Text>) : ZigMax }</Td>
      <Td>
        <Wrap spacing={2} direction='row' align='center'>
          {/* <Button colorScheme='gray' size='xs'>View</Button> */}
          <Button onClick={() => handleDelete(data.id)}  colorScheme='red' size='xs'>Delete</Button>
        </Wrap>
      </Td>
    </Tr>
    )
  })}
    </Tbody>
    

    <Tfoot>
  
    </Tfoot>
    
  </Table>
  </Center>
  <Flex justifyContent={'flex-end'} mr={10} >

 
        </Flex>
</TableContainer>

    </Stack>
    </Box>
  )
}

export default TransLimit