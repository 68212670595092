import React, { useState, useEffect } from 'react'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Container,
  Box,
  RadioGroup,
  HStack,
  Radio,
  Text,
  Heading,
  Center,
  Stack,
  useToast,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Flex,
  InputGroup,
  InputLeftAddon,
  Button,
  Select,
  Spinner,
} from '@chakra-ui/react'
import axios from '../../api/axios';
import { BsChevronRight, BsPerson } from 'react-icons/bs';
import { NavLink, useNavigate } from 'react-router-dom'
import { MdSend } from 'react-icons/md';

const CREATEEMP_URL = 'auth/employee/create-employee'
const NEWCREATE_EMPL_URL = 'employee/profile/v1/create-employee'
const GETSHOPS_URL = '/employee/shop/v1/get-shops'
const GETREGION_URL = '/employee/region/v1/get-regions';
const GETZONE_URL = '/employee/zone/v1/get-zones'


const employee = {
  firstName: "",
  lastName: "",
  workPhoneNumber: "",
  workEmail: "",
  title: "",
  // availability: "",
  // employeeZone: "",


}

export default function UserForm() {
  const [state, setState] = useState(employee);

  const { firstName, lastName, workPhoneNumber, workEmail, title } = state

  const [regionId, setRegionId] = useState('')
    const [shopId, setShopId] = useState('')
    const [zoneId, setZoneId] = useState('')
    const [selectzone, setSelectZone] = useState([]);
    const [selectregion, setSelectRegion] = useState([]);
    const [selectshop, setSelectShop] = useState([])


  const [availability, setAvailability] = useState('')
  const [employeeZone, setEmployeeZone] = useState('')
  const [employeeError, setEmployeeError] = useState('')
  const [availabilityError, setAvailabilityError] = useState('')
  const [phoneError, setPhoneError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [fnameError, setFNameError] = useState('')
  const [lnameError, setLNameError] = useState('')
  const [roleerror, setRoleError] = useState('')
  const [titleerror, setTitleError] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios.get(GETSHOPS_URL, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((response) => {
      setSelectShop(response.data.body);
    });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios.get(GETZONE_URL, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((response) => {
      setSelectZone(response.data.body);
    });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios.get(GETREGION_URL, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((response) => {
      setSelectRegion(response.data.body);

    });
  }, []);

  const handleemployee = () => {
    // e.preventDefault();
    setErrorMessage('')
    const res = validate();
    if (!res) {
      // console.log('validation failed')
      toast({
        title: 'Failed',
        description: "Please enter details",
        status: 'error',
        duration: 3000,

      });
      return;
    }
    const newData = {
      option: selectedOption,
    };
    // setRoles([...roles, selectedOption]);
    setLoading(true);
    const token = localStorage.getItem("token");
    axios.post(NEWCREATE_EMPL_URL, {
      firstName,
      lastName,
      workPhoneNumber,
      workEmail,
      availability:'PRESENT',
      employeeZone: 'DEFAULT ZONE',
      zoneId,
      shopId,
      regionId,
      title,
      roles: [selectedOption],

    }, {
      headers: {
        "Content-type": "application/json",
        'Authorization': `Bearer ${token}`
      }

    })


      .then(response => {
        if (response.data.success) {
          navigate('/admin/users')
          setLoading(false)
          toast({
            title: 'User created',
            description: response.data.message,
            status: 'success',
            duration: 9000,

          });
        }

        else {
          setLoading(false)
          toast({
            title: 'Failed',
            description: response.data.message,
            status: 'error',
            duration: 3000,

          });
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast({
            title: "Error",
            description: `Server responded with ${error.response.status}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          setLoading(false)
        } else if (error.request) {
          // The request was made but no response was received
          toast({
            title: "Network Error",
            description: "Please Check your Internet Connection",
            status: "error",
            duration: 8000,
            isClosable: true,
          });
          setLoading(false)
        } else {
          // Something happened in setting up the request that triggered an Error
          toast({
            title: "Network Error",
            description: `Error: ${error.message}`,
            status: "error",
            duration: 8000,
            isClosable: true,
          });

        }
      })
  }


  useEffect(() => {

  }, [loading])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
console.log(title)
  const [roles, setRoles] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");

  const options = [
    // { label: 'Operator', value: "OPERATOR" },
    { label: 'Clerk', value: "CLERK",roles: ["ADMIN"] },
    { label: 'Clerk', value: "CLERK",roles: ["SUPER_ADMIN"] },
    { label: 'Admin', value: "ADMIN",roles: ["ADMIN"] },
    { label: 'Admin', value: "ADMIN",roles: ["SUPER_ADMIN"] },
    { label: 'Approver', value: "APPROVER",roles: ["SUPER_ADMIN"] },
    { label: 'Approver', value: "APPROVER",roles: ["ADMIN"] },


    // { label: 'Support', value: "SUPPORT",roles: ["ADMIN"] },
    // { label: 'Support', value: "SUPPORT",roles: ["SUPER_ADMIN"] },
    { label: 'Super Admin', value: "SUPER_ADMIN",roles: ["SUPER_ADMIN"] },
    { label: 'Executive', value: "EXECUTIVE",roles: ["SUPER_ADMIN"] },
  ];
  const titles = [
    {label: 'Approver', value: "APPROVER", roles: ["ADMIN"] },
    { label: 'Key Account Rep', value: "KEY ACCOUNT REP",roles: ["ADMIN"] },
     { label: 'Customer Care Rep', value: "CUSTOMER CARE REP",roles: ["ADMIN"] },
    { label: 'Cashier', value: "CASHIER",roles: ["ADMIN"] },
    { label: 'Call Center', value: "CALL CENTER",roles: ["ADMIN"] },
    { label: 'Graduate Trainee', value: "GRADUATE TRAINEE",roles: ["ADMIN"] },
    {label: 'CareDesk Personnel', value: "CAREDESK PERSONNEL", roles: ["ADMIN"] },
    {label: 'MFS Customer Experience Agent', value: "MFS CUSTOMER EXPERIENCE AGENT", roles: ["ADMIN"] },
    {label: 'Regional Administrator', value: "REGIONAL ADMINISTRATOR", roles: ["ADMIN"] },





   
    { label: 'Super Admin', value: "SUPER ADMIN",roles: ["SUPER_ADMIN"] },
    { label: 'Executive', value: "EXECUTIVE",roles: ["SUPER_ADMIN"] },
    {label: 'Approver', value: "APPROVER", roles:  ["SUPER_ADMIN"] },
    { label: 'Zone Commander', value: "ZONE COMMANDER",roles: ["SUPER_ADMIN"] },
    { label: 'Zone Admin', value: "ZONE ADMIN ",roles: ["SUPER_ADMIN"] },
    { label: 'National sales admin', value: "NATIONAL SALES ADMIN",roles: ["SUPER_ADMIN"] },
    { label: 'Shop Supervisor', value: "SHOP SUPERVISOR",roles: ["SUPER_ADMIN"] },
    { label: 'Regional Sales Manager', value: "REGIONAL SALES MANAGER",roles: ["SUPER_ADMIN"] },
    { label: 'Key Account Rep', value: "KEY ACCOUNT REP",roles: ["SUPER_ADMIN"] },
    { label: 'Business development Officer', value: "BUSINESS DEVELOPMENT OFFICER ",roles: ["SUPER_ADMIN"] },
    { label: 'Customer Care Rep', value: "CUSTOMER CARE REP",roles: ["SUPER_ADMIN"] },
    { label: 'Cashier', value: "CASHIER",roles: ["SUPER_ADMIN"] },
    { label: 'Call Center', value: "CALL CENTER",roles: ["SUPER_ADMIN"] },
    { label: 'Graduate Trainee', value: "GRADUATE TRAINEE",roles: ["SUPER_ADMIN"] },
    {label: 'CareDesk Personnel', value: "CAREDESK PERSONNEL", roles: ["SUPER_ADMIN"] },
    // {label: 'Employee', value: "EMPLOYEE", roles: ["SUPER_ADMIN"] },
    {label: 'MFS Customer Experience Agent', value: "MFS CUSTOMER EXPERIENCE AGENT", roles: ["SUPER_ADMIN"] },
    {label: 'Reporting Analyst', value: "REPORTING ANALYST", roles: ["SUPER_ADMIN"] },
    {label: 'Regional Administrator', value: "REGIONAL ADMINISTRATOR", roles: ["SUPER_ADMIN"] },



   
  ];

  let user = localStorage.getItem('user')
  user = JSON.parse(user);

const userRoles = user.roles[0].name
console.log(userRoles);

const filteredOptions = options.filter(item => userRoles === item.roles[0]);
console.log(filteredOptions)

const filteredTitles = titles.filter(itek => userRoles === itek.roles[0])

  const validate = () => {
    const EMAIL_REGEX = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
    const PHONE_REGEX = /^(712980)/;

    if (!firstName) {
      setFNameError('First name is required');
      return false;
    }

    if (!lastName) {
      setLNameError('Lastname is required');
      return false;
    }
    if (!workEmail) {
      setEmailError('email is required');
      return false;
    }

    if (!EMAIL_REGEX.test(workEmail)) {
      setEmailError('email not valid');
      return false;
    }
    if (workPhoneNumber.length < 9) {
      setPhoneError('phone number invalid');
      return false;
    }
    if (workPhoneNumber.length > 10) {
      setPhoneError('phone number invalid');
      return false;
    }
    if (!PHONE_REGEX.test(workPhoneNumber)) {
      setPhoneError('please enter staff (980) netone number');
      return false;
    }

    if (!roles) {
      setRoleError('Please select roles');
      return false;
    }
   
  

    return true

  }

  return (
    <Container maxW={'7xl'}>
      <Flex justifyContent="flex-end">
        <Breadcrumb fontSize={'xs'} fontWeight={'semibold'} spacing='8px' separator={<BsChevronRight color='gray.500' />}>
          <BreadcrumbItem>
            <NavLink to={'/admin/operator'}>
              <BreadcrumbLink >  Home</BreadcrumbLink>
            </NavLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <NavLink to={'#'}>
              <BreadcrumbLink >  Manage Customer</BreadcrumbLink>
            </NavLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href='#'>Create Account</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>
      <Center>

        <Box
          //  maxW='sm' borderWidth='2px' borderRadius='lg'
          borderRadius='lg' shadow='md' borderWidth='2px' bg='white'
          style={{
            width: "50%",
            justifyContent: "space-around",
            boxShadow: 50,
            marginTop: "20px",
            marginBottom: "20px",
            // display: "flex",
            //     flexDirection: "column",
            //     justifyContent: "space-evenly",

          }}

        >


          <Stack spacing={1} marginLeft='10' marginRight='10' marginBottom='2'  >
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: '1xl', sm: '2xl', lg: '3xl' }}
              marginBottom='2'
              marginTop='5'
            >
              <Text as={'span'}>
                {' '}
                <Text
                  as={'span'}
                  color="black"
                  position={'relative'}
                >
                  Create
                  <Text as={'span'} position={'relative'}>
                    {' '}User-Account
                  </Text>
                </Text>
              </Text>
            </Heading>
            <HStack mb={4} spacing={4}>

            <FormControl isRequired>
              <FormLabel htmlFor='firstName'>First name</FormLabel>
              <Input
                id='firstName'
                placeholder='First name'
                type='text'
                name='firstName'
                value={firstName}
                onChange={handleInputChange}
                onFocus={() => { setFNameError('') }} borderColor={fnameError ? 'red.400' : 'gray.300'}
              />
              <Text color={'red'}>{fnameError}</Text>
            </FormControl>

            <FormControl isRequired>
              <FormLabel htmlFor='lastName'>Last name</FormLabel>
              <Input
                id='lastName'
                placeholder='Last name'
                type='text'
                name='lastName'
                value={lastName}
                onFocus={() => { setLNameError('') }} borderColor={lnameError ? 'red.400' : 'gray.300'}
                onChange={handleInputChange}
                
                />
              <Text color={'red'}>{lnameError}</Text>
            </FormControl>
                </HStack>
              <HStack spacing={4}>

            <FormControl mt={2} isRequired>
              <FormLabel htmlFor='phoneNumber'>Mobile Number</FormLabel>
              <InputGroup>
                <InputLeftAddon children='+263' />
                <Input id='workPhoneNumber'
                  type='number'
                  placeholder='712000000'
                  _placeholder={{ color: 'gray.500' }}
                  name='workPhoneNumber'
                  value={workPhoneNumber}
                  onFocus={() => { setPhoneError('') }} borderColor={phoneError ? 'red.400' : 'gray.300'}
                  onChange={handleInputChange}

                  />
              </InputGroup>
              <Text color={'red'}>{phoneError}</Text>
            </FormControl>

            <FormControl isRequired>
              <FormLabel mt={2} htmlFor='email'>Work Email: </FormLabel>
              <Input

                id='email'
                placeholder='example@email.com'
                name='workEmail'
                type='email'
                value={workEmail}
                onChange={handleInputChange}
                onFocus={() => { setEmailError('') }} borderColor={emailError ? 'red.400' : 'gray.300'}
              />
              <Text color={'red'}>{emailError}</Text>
            </FormControl>
                  </HStack>
                  <FormControl >
              <FormLabel mt={2} htmlFor='title'>Designation: </FormLabel>
                <Select 
                    maxW={'260px'}
                   _hover={{
                     borderRadius: 'orange',
                   }} 
                   placeholder='Select Title' name='title'  onChange={handleInputChange}>
          
           {filteredTitles.map((option) => (
             <option key={option.id} value={option.value}>
               {option.label}
             </option>
           ))}
             
           </Select>
            </FormControl>
            <HStack spacing={4}>


{user.title == "SHOP SUPERVISOR" &&
<>

<FormControl mt={2}>
<FormLabel>Shop</FormLabel>
<Select 
          _hover={{
            borderRadius: 'orange',
          }} 
          placeholder='Select Shop' name='regionId' onChange={(e)=> setShopId(e.target.value)}>
  {selectshop?.length === 0 && <Spinner />}
  {selectshop?.map((shopes) => (
    <option key={shopes.id} value={shopes.id}>
      {shopes.name}
    </option>
  ))}
    
  </Select>
  </FormControl>
  </>
}

  {user.title == "REGIONAL SALES MANAGER"  &&

<FormControl mt={2}>
<FormLabel>Region</FormLabel>
<Select 
          _hover={{
            borderRadius: 'orange',
          }} 
          placeholder='Select Region' width={'260px'} name='regionId' onChange={(e)=> setRegionId(e.target.value)}>
  {selectregion?.length === 0 && <Spinner />}
  {selectregion?.map((option) => (
    <option key={option.id} value={option.id}>
      {option.name}
    </option>
  ))}
    
  </Select>
  </FormControl> 
}   

{user.title == "KEY ACCOUNT REP " && 
<FormControl mt={2}>
<FormLabel>Region</FormLabel>
<Select 
          _hover={{
            borderRadius: 'orange',
          }} 
          placeholder='Select Region'width={'260px'} name='regionId' onChange={(e)=> setRegionId(e.target.value)}>
  {selectregion?.length === 0 && <Spinner />}
  {selectregion?.map((option) => (
    <option key={option.id} value={option.id}>
      {option.name}
    </option>
  ))}
    
  </Select>
  </FormControl> 

}

{userRoles == "SUPER_ADMIN" && <>

{state.title === "APPROVER" && 
<>

  <FormControl mt={2}>
<FormLabel>Zone</FormLabel>
<Select 
          _hover={{
            borderRadius: 'orange',
          }} 
          placeholder='Select Zone' name='zoneId' onChange={(e)=> setZoneId(e.target.value)}>
  {selectzone?.length === 0 && <Spinner />}
  {selectzone?.map((zones) => (
    <option key={zones.id} value={zones.id}>
      {zones.name}
    </option>
  ))}
    
  </Select>
  </FormControl>  
</>}
{state.title === "CALL CENTER" && 
<>

  <FormControl mt={2}>
<FormLabel>Shop</FormLabel>
<Select 
          _hover={{
            borderRadius: 'orange',
          }} 
          placeholder='Select Shop' name='regionId' onChange={(e)=> setShopId(e.target.value)}>
  {selectshop?.length === 0 && <Spinner />}
  {selectshop?.map((shopes) => (
    <option key={shopes.id} value={shopes.id}>
      {shopes.name}
    </option>
  ))}
    
  </Select>
  </FormControl>  
</>}

{state.title === "GRADUATE TRAINEE" && 
<>
{/* <FormControl mt={2}>

<FormLabel>Region</FormLabel>
<Select 
          _hover={{
            borderRadius: 'orange',
          }} 
          placeholder='Select Region' name='regionId' onChange={(e)=> setRegionId(e.target.value)}>
  {selectregion?.length === 0 && <Spinner />}
  {selectregion?.map((option) => (
    <option key={option.id} value={option.id}>
      {option.name}
    </option>
  ))}
    
  </Select>
  </FormControl> */}

  <FormControl mt={2}>
<FormLabel>Shop</FormLabel>
<Select 
          _hover={{
            borderRadius: 'orange',
          }} 
          placeholder='Select Shop' name='regionId' onChange={(e)=> setShopId(e.target.value)}>
  {selectshop?.length === 0 && <Spinner />}
  {selectshop?.map((shopes) => (
    <option key={shopes.id} value={shopes.id}>
      {shopes.name}
    </option>
  ))}
    
  </Select>
  </FormControl>  
</>}

{state.title === "CAREDESK PERSONNEL" && 
<>
{/* <FormControl mt={2}>

<FormLabel>Region</FormLabel>
<Select 
          _hover={{
            borderRadius: 'orange',
          }} 
          placeholder='Select Region' name='regionId' onChange={(e)=> setRegionId(e.target.value)}>
  {selectregion?.length === 0 && <Spinner />}
  {selectregion?.map((option) => (
    <option key={option.id} value={option.id}>
      {option.name}
    </option>
  ))}
    
  </Select>
  </FormControl> */}

  <FormControl mt={2}>
<FormLabel>Shop</FormLabel>
<Select 
          _hover={{
            borderRadius: 'orange',
          }} 
          placeholder='Select Shop' name='regionId' onChange={(e)=> setShopId(e.target.value)}>
  {selectshop?.length === 0 && <Spinner />}
  {selectshop?.map((shopes) => (
    <option key={shopes.id} value={shopes.id}>
      {shopes.name}
    </option>
  ))}
    
  </Select>
  </FormControl>  
</>}

{state.title === "CASHIER" && 
<>
{/* <FormControl mt={2}>

<FormLabel>Region</FormLabel>
<Select 
          _hover={{
            borderRadius: 'orange',
          }} 
          placeholder='Select Region' name='regionId' onChange={(e)=> setRegionId(e.target.value)}>
  {selectregion?.length === 0 && <Spinner />}
  {selectregion?.map((option) => (
    <option key={option.id} value={option.id}>
      {option.name}
    </option>
  ))}
    
  </Select>
  </FormControl> */}

  <FormControl mt={2}>
<FormLabel>Shop</FormLabel>
<Select 
          _hover={{
            borderRadius: 'orange',
          }} 
          placeholder='Select Shop' name='regionId' onChange={(e)=> setShopId(e.target.value)}>
  {selectshop?.length === 0 && <Spinner />}
  {selectshop?.map((shopes) => (
    <option key={shopes.id} value={shopes.id}>
      {shopes.name}
    </option>
  ))}
    
  </Select>
  </FormControl>  
</>}
{state.title === "CUSTOMER CARE REP" && 
<>
{/* <FormControl mt={2}>

<FormLabel>Region</FormLabel>
<Select 
          _hover={{
            borderRadius: 'orange',
          }} 
          placeholder='Select Region' name='regionId' onChange={(e)=> setRegionId(e.target.value)}>
  {selectregion?.length === 0 && <Spinner />}
  {selectregion?.map((option) => (
    <option key={option.id} value={option.id}>
      {option.name}
    </option>
  ))}
    
  </Select>
  </FormControl> */}

  <FormControl mt={2}>
<FormLabel>Shop</FormLabel>
<Select 
          _hover={{
            borderRadius: 'orange',
          }} 
          placeholder='Select Shop' name='regionId' onChange={(e)=> setShopId(e.target.value)}>
  {selectshop?.length === 0 && <Spinner />}
  {selectshop?.map((shopes) => (
    <option key={shopes.id} value={shopes.id}>
      {shopes.name}
    </option>
  ))}
    
  </Select>
  </FormControl>  
</>}

{state.title === "BUSINESS DEVELOPMENT OFFICER" && 
<>
<FormControl mt={2}>

<FormLabel>Region</FormLabel>
<Select 
          _hover={{
            borderRadius: 'orange',
          }} 
          placeholder='Select Region' name='regionId' onChange={(e)=> setRegionId(e.target.value)}>
  {selectregion?.length === 0 && <Spinner />}
  {selectregion?.map((option) => (
    <option key={option.id} value={option.id}>
      {option.name}
    </option>
  ))}
    
  </Select>
  </FormControl>

  <FormControl mt={2}>
<FormLabel>Shop</FormLabel>
<Select 
          _hover={{
            borderRadius: 'orange',
          }} 
          placeholder='Select Shop' name='regionId' onChange={(e)=> setShopId(e.target.value)}>
  {selectshop?.length === 0 && <Spinner />}
  {selectshop?.map((shopes) => (
    <option key={shopes.id} value={shopes.id}>
      {shopes.name}
    </option>
  ))}
    
  </Select>
  </FormControl>  
</>}

{state.title === "KEY ACCOUNT REP" && 
<>
<FormControl mt={2}>

<FormLabel>Region</FormLabel>
<Select 
          _hover={{
            borderRadius: 'orange',
          }} 
          placeholder='Select Region' name='regionId' onChange={(e)=> setRegionId(e.target.value)}>
  {selectregion?.length === 0 && <Spinner />}
  {selectregion?.map((option) => (
    <option key={option.id} value={option.id}>
      {option.name}
    </option>
  ))}
    
  </Select>
  </FormControl>

  {/* <FormControl mt={2}>
<FormLabel>Shop</FormLabel>
<Select 
          _hover={{
            borderRadius: 'orange',
          }} 
          placeholder='Select Shop' name='regionId' onChange={(e)=> setShopId(e.target.value)}>
  {selectshop?.length === 0 && <Spinner />}
  {selectshop?.map((shopes) => (
    <option key={shopes.id} value={shopes.id}>
      {shopes.name}
    </option>
  ))}
    
  </Select>
  </FormControl>   */}
</>}
{state.title === "REGIONAL SALES MANAGER" && 
<>
<FormControl mt={2}>

<FormLabel>Region</FormLabel>
<Select 
          _hover={{
            borderRadius: 'orange',
          }} 
          placeholder='Select Region' name='regionId' onChange={(e)=> setRegionId(e.target.value)}>
  {selectregion?.length === 0 && <Spinner />}
  {selectregion?.map((option) => (
    <option key={option.id} value={option.id}>
      {option.name}
    </option>
  ))}
    
  </Select>
  </FormControl>

  <FormControl mt={2}>
<FormLabel>Shop</FormLabel>
<Select 
          _hover={{
            borderRadius: 'orange',
          }} 
          placeholder='Select Shop' name='regionId' onChange={(e)=> setShopId(e.target.value)}>
  {selectshop?.length === 0 && <Spinner />}
  {selectshop?.map((shopes) => (
    <option key={shopes.id} value={shopes.id}>
      {shopes.name}
    </option>
  ))}
    
  </Select>
  </FormControl>  
</>}

{state.title === "SHOP SUPERVISOR" && 
<>
{/* <FormControl mt={2}>

<FormLabel>Region</FormLabel>
<Select 
          _hover={{
            borderRadius: 'orange',
          }} 
          placeholder='Select Region' name='regionId' onChange={(e)=> setRegionId(e.target.value)}>
  {selectregion?.length === 0 && <Spinner />}
  {selectregion?.map((option) => (
    <option key={option.id} value={option.id}>
      {option.name}
    </option>
  ))}
    
  </Select>
  </FormControl> */}

  <FormControl mt={2}>
<FormLabel>Shop</FormLabel>
<Select 
          _hover={{
            borderRadius: 'orange',
          }} 
          placeholder='Select Shop' name='regionId' onChange={(e)=> setShopId(e.target.value)}>
  {selectshop?.length === 0 && <Spinner />}
  {selectshop?.map((shopes) => (
    <option key={shopes.id} value={shopes.id}>
      {shopes.name}
    </option>
  ))}
    
  </Select>
  </FormControl>  
</>}

  </>}     
            </HStack>


            <FormControl as='fieldset'>
              <FormLabel as='legend' marginTop='10px' marginBottom={2}>Roles</FormLabel>
              <RadioGroup onChange={(e) => setSelectedOption(e)} value={selectedOption} onFocus={() => { setRoleError('') }} borderColor={roleerror ? 'red.400' : 'gray.300'} >
                <HStack spacing='24px' >
                  {filteredOptions.map((option) => (
                    <Radio key={option.value} value={option.value} name='roles'>
                      {option.label}
                    </Radio>
                  ))}
                </HStack>
              </RadioGroup>
              <FormHelperText>Select user Role</FormHelperText>
              <Text color={'red'}>{roleerror}</Text>
            </FormControl>
            {/* <HStack >

             
              <FormControl mt={2}>
                <FormLabel>Availability</FormLabel>
                <Select placeholder='Select option' w={'180px'} onChange={(e) => setAvailability(e.target.value)} >
                  <option value='PRESENT' >Present</option>
                  <option value='ABSENT'>Absent</option>
                  <option value='LEAVE'>Leave</option>
                </Select>
              </FormControl>
            </HStack> */}
            {
              errorMessage ? <Text color={'red'}>{errorMessage}</Text> : <></>
            }

          </Stack>
          <Button 
            onClick={handleemployee}
            isLoading={loading}
            loadingText='Loading'
            spinnerPlacement='end'
            borderRadius='md' bg='orange.400' color='white'
            px={4} h={8} marginTop='4' marginBottom='5' marginLeft='9'
            rightIcon={<MdSend/>}
          >
            Submit
          </Button>
        </Box>
      </Center>

    </Container>
  )
}
