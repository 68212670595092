import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, Badge, Heading, Input, Stack, Text, Wrap, useDisclosure, useToast } from "@chakra-ui/react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Box, Button,chakra } from "@chakra-ui/react";
import { read, utils, writeFile } from 'xlsx'
import { BiExport } from 'react-icons/bi'
import { Tag } from 'primereact/tag';

import axios from "../../api/axios";
import { useNavigate } from "react-router-dom";
import { MdDelete, MdPreview } from "react-icons/md";
import { FiUserPlus } from "react-icons/fi";
import { classNames } from "primereact/utils";
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { MultiSelect } from 'primereact/multiselect';



const GETB_A_URL = 'employee/brand-ambassador/v1/get-brand-ambassador-by-key-account-rep-account'
const GETB_A_BYSHOP_URL = '/employee/brand-ambassador/v1/get-brand-ambassador-by-shop'
const GETUSERID_URL = '/employee/profile/v1/get-employee/'
const GETALL_BA_URL = '/employee/brand-ambassador/v1/get-all-brand-ambassadors'
const EMPLOYEE_ACC_URL = '/employee/profile/v1/get-employees?availability=PRESENT'




export default function ExternalNew() {
  const [products, setProducts] = useState([]);
  const [getEmployee, setGetemployee] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [open,setOpen] = useState(false)
  const [currentData,setCurrentData] = useState()
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef()

    let emp = localStorage.getItem('user')
    emp = JSON.parse(emp);


    const handleExport = () => {
      const headings = [[ "FirstName","LastName","Mobile Number","Email","Address","National ID", "Created By","Status","Created At"]];
      const wb = utils.book_new();
      const ws = utils.table_to_sheet(document.getElementById('emp'),{raw: true});
      utils.sheet_add_aoa(ws, headings);
   //    utils.sheet_add_json(ws, searchresults, { origin: "A2", skipHeader: true});
      utils.book_append_sheet(wb, ws, "BA_Report");
      writeFile(wb, "BA_Report.xlsx")
       };

  

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    firstName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    lastName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    address: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    nationalIdNumber: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    createdBy: { value: null, matchMode: FilterMatchMode.IN },
    "employeeAccount.workEmail": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    createdAt: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS}] },

   "employeeAccount.accountVerified": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "employeeAccount.workPhoneNumber": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  /* Fetching Data From Server */
  const getUsers = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
     if(emp?.title === "KEY ACCOUNT REP"){
  
         const response = await axios.get(`${GETB_A_URL}?keyAccountRepAccountId=${emp?.jti}`, {
           headers: {
             'Authorization': `Bearer ${token}`
           }
         })
    
         if(response.status === 200) {
           setGetemployee(response.data.body)
           console.log(response.data.body)
           setLoading(false);
         }
     }
     if(emp?.title === "SHOP SUPERVISOR"|| emp?.title === 'CAREDESK PERSONNEL'){
      const res = await axios.get(`${GETB_A_BYSHOP_URL}?shopId=${emp.shopId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
    console.log(res.body)
        if(res.status === 200) {
          setGetemployee(res.data.body)
          setLoading(false);
        }
     }
     
     if(emp?.title === 'SUPER ADMIN' || emp?.title === 'NATIONAL SALES ADMIN') {
      
            const response = await axios.get(GETALL_BA_URL, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            })
        
            if (response.status === 200) {
              setGetemployee(response.data.body)
              // console.log(response.data.body)
              setLoading(false);
            }
     }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        toast({
          title: "Error",
          description: `Server responded with ${error.message}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
       
      } else if (error.request) {
        // The request was made but no response was received
        toast({
          title: "Network Error",
          description: "Please Check your Internet Connection",
          status: "error",
          duration: 8000,
          isClosable: true,
        
        });
     
      } else {
        // Something happened in setting up the request that triggered an Error
        toast({
          title: "Error",
          description: `Error:`,
          status: "error",
          duration: 8000,
          isClosable: true,
        });
       
      }
    }
  }
  
  
  useEffect(() => {
  
    getUsers();
  
  }, [])

  // const handleDelete = async (data) => {
  //   setLoading(true)
  //   const token = localStorage.getItem("token");
  //   try {
      
  //     const response = await axios.delete(DELETEUSER_URL + `${data.id}`, {
  //       headers: {
  //         'Authorization': `Bearer ${token}`
  //       }
  //     });
  //     if(response.data.success) {
  //       getUsers();
  //       onClose();
  //       toast({
  //         title: 'Success',
  //         description: "Deleted",
  //         status: 'success',
  //         duration: 6000,
          
  //       });
  //       setLoading(false)
      
  //     }else{
  //       toast({
  //         title: 'Failed',
  //         description: "Failed to Delete",
  //         status: 'error',
  //         duration: 6000,
          
  //       });
  //       setLoading(false)
  //     }
  //     setOpen(false)
  //   } catch (error) {
      
  //   }
  // };

  // const date = new Date(dateOfBirth);
  // const formattedDate = date.toLocaleDateString();

  const formatDate = (value) => {
    const date = new Date(value)
    return date.toLocaleDateString( 'en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })
};

const [accountId, setAccountId] = useState([]);

// const rep = getEmployee.map(repK => repK.createdBy )
// console.log(rep)
  
const getCreateby = async () => {
  setLoading(true);
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(EMPLOYEE_ACC_URL , {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })

    if (response.status === 200) {
      setAccountId(response.data.body)
       console.log(response.data.body)
      setLoading(false);
    }
  } catch (error) {

  }
}


useEffect(() => {
  getCreateby();

}, [])

const AgentCreatedByBodyTemplate = (customer) => {
  return <Text>{getAgentById(customer.createdBy)}</Text>;
};

function getAgentById(id){
 let Agent = accountId?.find((item) => id === item.employeeAccount.id);
if(Agent){
return `${Agent.firstName} ${Agent.lastName}`
}else{
  return ""
}
}

  /* End Of Fetching Data From Server */

  /* Table Header */
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <Input
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.createdAt);
  
  };

  const verifiedBodyTemplate = (rowData) => {
    // return <i className={classNames('pi', { 'text-green-500 pi-check-circle': rowData.employeeAccount.accountVerified, 'text-red-500 pi-times-circle': !rowData.employeeAccount.accountVerified })}></i>;
    return  rowData.employeeAccount.accountVerified === true ? (<Badge  textTransform={'capitalize'} bg={'green.400'} color={'white'} rounded={'md'} boxShadow={'sm'} >
    Active
  </Badge>): (
                    <Badge textTransform={'capitalize'} bg={'red.400'} color={'white'} rounded={'md'} boxShadow={'sm'} >
                    Deactivated
                  </Badge>
                  )

};

const verifiedFilterTemplate = (options) => {
  console.log(options)
  return (
      <div className="flex align-items-center gap-2">
          <label htmlFor="verified-filter" className="font-bold">
              {/* Verified */}
          </label>
          <TriStateCheckbox inputId="verified-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
      </div>
  );
};


const representativeRowFilterTemplate = (options) => {
  return (
      <MultiSelect
          value={options.value}
          options={getAgentById}
          itemTemplate={AgentCreatedByBodyTemplate}
          onChange={(e) => options.filterApplyCallback(e.value)}
          optionLabel="firstName"
          placeholder="Any"
          className="p-column-filter"
          maxSelectedLabels={1}
          style={{ minWidth: '14rem' }}
      />
  );
};


const actionBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            <Wrap spacing={2} direction='row' align='center'>
            <Button colorScheme='gray' size='xs' leftIcon={<MdPreview />} onClick={() => navigate(`/admin/view-user`,{
                state: rowData
              })} >View</Button>
            {/* <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeleteProduct(rowData)} /> */}
            {/* <Button onClick={()=>{
            setCurrentData(rowData)
            setOpen(true)}}   colorScheme='red' size='xs' leftIcon={<MdDelete />}>Delete</Button>  */}
               </Wrap>
        </React.Fragment>
    );
};




  const header = renderHeader();
  /* End of Table Header */

 

  return (
    <>
          <Stack direction='row' spacing={4}>
      {/* <AlertDialog
        isOpen={open}
        leastDestructiveRef={cancelRef}
        onClose={()=>{setOpen(false)}}
      >

          <AlertDialogContent>
            <AlertDialogHeader bg={'yellow.200'} fontSize='lg' fontWeight='bold'>
              <Text>
                Confirm 
              </Text>
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete <Heading size={'xs'}>{`${currentData?.firstName} ${currentData?.lastName}`}'s account?
                </Heading> You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={()=>{setOpen(false)}}>
                Cancel
              </Button>
              <Button onClick={() => handleDelete(currentData)} colorScheme='red'  ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>

      </AlertDialog> */}
  {/* <Button onClick={()=>navigate("/admin/create-user")} leftIcon={<FiUserPlus />} colorScheme='orange' variant='solid'>
    Create user
  </Button> */}
  </Stack>

 
    <Button
               leftIcon={<FiUserPlus />}
               onClick={()=>navigate("/admin/create-extuser")}
                bg={'black'}
                color={'white'}
                _hover={{
                    bg: 'orange',
                    color: 'white',
                }}
               
                mb={2}
                variant='solid'
                w={'250px'}>
                    Add BrandAmbassador
                </Button>
                <Button
                leftIcon={<BiExport/>}
                isLoading={loading}
                loadingText='Loading'
                spinnerPlacement='end'
                onClick={handleExport}
                bg={'blue'}
                color={'white'}
                _hover={{
                    bg: 'orange.500',
                }}
                 mt={-2}
                ml={2}
                variant='solid'
                w={'150px'}>
                    Export to Excel
                </Button>
    <Box
    mt={4}
    boxShadow={'lg'}
    rounded={'md'}
    >


      
    
      <DataTable
      id="emp"
        value={getEmployee}
        header={header}
        scrollable 
        scrollHeight="600px"
        paginator
        rows={100}
        dataKey="id"
        tableStyle={{ minWidth: "50rem" }}
        filters={filters}
        showGridlines
        filterDisplay="row"
        loading={loading}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        globalFilterFields={[
          "firstName",
          "lastName",
          "employeeAccount.workPhoneNumber",
          "employeeAccount.workEmail",
          "address",
          "nationalIdNumber",
          "createdAt",
          "employeeAccount?.accountVerified"
       
        ]}
        emptyMessage="No employees found.">
        <Column
          field="firstName"
          header="First Name"
          sortable
          filter
          filterPlaceholder="Search by first name"
          style={{ minWidth: "12rem" }}></Column>
        <Column
          field="lastName"
          header="Last Name"
          sortable
          filter
          filterField="lastName"
          filterPlaceholder="Search by last name"
          style={{ minWidth: "12rem" }}></Column>

<Column
          field="employeeAccount.workPhoneNumber"
          header="Mobile Number"
          sortable
          filter
          filterField="employeeAccount.workPhoneNumber"
          filterPlaceholder="Search by number"
          style={{ minWidth: "12rem" }}></Column>

<Column
          field="employeeAccount.workEmail"
          header="Email"
          sortable
          filter
          filterField="employeeAccount.Email"
          filterPlaceholder="Search by email"
          style={{ minWidth: "12rem" }}></Column>

<Column
          field="address"
          header="Address"
          sortable
          filter
          filterField="address"
          filterPlaceholder="Search by address"
          style={{ minWidth: "12rem" }}></Column>

          
<Column
          field="nationalIdNumber"
          header="nationalIdNumber"
          sortable
          filter
          filterField="nationalIdNumber"
          filterPlaceholder="Search by nationalIdNumber"
          style={{ minWidth: "12rem" }}></Column>
<Column 
          field="createdBy" 
           body={AgentCreatedByBodyTemplate} 
          header="Created by"
          // sortable
          // filter
          // filterField="createdBy" 
          // filterPlaceholder="Search by name"
          style={{ minWidth: "18rem" }}
          // showFilterMenu={false} 
          // filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '14rem' }}
                    // body={representativeBodyTemplate} 
                    // filter filterElement={representativeRowFilterTemplate}
          
          ></Column>


        <Column field="employeeAccount.accountVerified" header="Status" dataType="boolean"  style={{ minWidth: '6rem' }} body={verifiedBodyTemplate} filter filterElement={verifiedFilterTemplate} />
        <Column
          field="createdAt"
          header="Created At"
          body={dateBodyTemplate}
          style={{ minWidth: "8rem" }}></Column>
        <Column header="Action" body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem'  }}></Column>

          
         
      </DataTable>
      </Box>
    </>
  );
}