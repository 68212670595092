import React, { useState, useEffect } from 'react'
import {
     Box,
     Button,
     Flex,
     FormControl,
     FormLabel,
     Input,
     InputGroup,
     InputLeftElement,
     Stack,
     Card, 
     CardHeader, 
     CardBody, 
     CardFooter,
     Heading,
     Text,
     StackDivider,
     useColorModeValue,
     HStack,
     Avatar,
     IconButton,
     Image,
     Wrap,
     Tag,
     Table,
     Thead,
     Tbody,
     Tfoot,
     Tr,
     Th,
     Td,
     TableCaption,
     TableContainer,
     Spinner,
     Center,
     useToast,
     Accordion,
     AccordionItem,
     AccordionButton,
     AccordionPanel,
     AccordionIcon,
     Select,
     chakra,
     Tooltip,
    } from '@chakra-ui/react'
import { FiBook, FiBookOpen, FiPause, FiPhoneCall, FiSearch, FiUser, FiUserPlus } from 'react-icons/fi'
import { Form, useNavigate } from 'react-router-dom'
import { MdEdit, MdPreview } from 'react-icons/md'
import {CiWallet} from 'react-icons/ci';
import {GiWallet} from 'react-icons/gi';
import axios from '../../api/axios';
import { FaUserEdit } from 'react-icons/fa'
import { BsFillWalletFill, BsSimFill } from 'react-icons/bs'


const SEARCH_URL = '/onboarding/operator/search-customer'
const SEARCH_TELCO_URL = '/onboarding/telco/operator/search-prepaid-subscriber/'

function SearchCustomer() {
    const [searchvalue, setSearchValue] = useState("");
    const [searchresults, setSearchResults] = useState([])
    const [phoneNumber, setPhoneNumber] = useState('');
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [nationalIdNumber, setNationalIDNumber] = useState("");

    const toast = useToast();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [selectedOption, setSelectedOption] = useState("");

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
      }
      const handleSearchInputChange = (e) => {
        setSearchValue(e.target.value);
      };
    const handlesubmit = () => {
        // e.preventDefault();
        setLoading(true)
        const token = localStorage.getItem("token");
        axios.get(`${SEARCH_URL}/${selectedOption}?searchValue=${searchvalue}`,
        {
    
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        }
        )
          .then(res => {
    
            if (res.data.success) {
                setSearchResults(res.data.body);
            console.log(res.data.body)
              setLoading(false)
            } else {
              toast({
                title: 'Failed',
                description: res.data.message,
                status: 'error',
                duration: 3000,
    
              });
              setLoading(false)
            }
          })
          .catch(error => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              toast({
                title: "Error",
                description: `Server responded with ${error.response.status}`,
                status: "error",
                duration: 5000,
                isClosable: true,
              });
              setLoading(false)
            } else if (error.request) {
              // The request was made but no response was received
              toast({
                title: "Network Error",
                description: "Please Check your Internet Connection",
                status: "error",
                duration: 8000,
                isClosable: true,
              });
              setLoading(false)
            } else {
              // Something happened in setting up the request that triggered an Error
              toast({
                title: "Network Error",
                description: `Error: ${error.message}`,
                status: "error",
                duration: 8000,
                isClosable: true,
              });
    
            }
          })
      }

      const handleTelco = () => {
        // e.preventDefault();
        setLoading(true)
        const token = localStorage.getItem("token");
        axios.get(`${SEARCH_TELCO_URL}/${selectedOption}?searchValue=${searchvalue}`,
        {
    
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        }
        )
          .then(res => {
    
            if (res.data.success) {
                setSearchResults(res.data.body);
            
              setLoading(false)
            } else {
              toast({
                title: 'Failed',
                description: res.data.message,
                status: 'error',
                duration: 3000,
    
              });
              setLoading(false)
            }
          })
          .catch(error => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              toast({
                title: "Error",
                description: `Server responded with ${error.response.status}`,
                status: "error",
                duration: 5000,
                isClosable: true,
              });
              setLoading(false)
            } else if (error.request) {
              // The request was made but no response was received
              toast({
                title: "Network Error",
                description: "Please Check your Internet Connection",
                status: "error",
                duration: 8000,
                isClosable: true,
              });
              setLoading(false)
            } else {
              // Something happened in setting up the request that triggered an Error
              toast({
                title: "Network Error",
                description: `Error: ${error.message}`,
                status: "error",
                duration: 8000,
                isClosable: true,
              });
    
            }
          })
      }

      const options = [
        // { label: 'Operator', value: "OPERATOR" },
        { label: 'first Name', value: "firstName"},
        { label: 'Last Name', value: "lastName"},
        { label: 'Phone Number', value: "phoneNumber"},
        { label: 'National ID', value: "nationalIdNumber"}
      ]
      const [showOnemoney, setShowOneMoney] = useState(false)
    const [showZsmart, setShowZsmart] = useState(false);

    const [selectCategorySearch, setSelectCategorySearch] = useState('')

    const category = [
      // { label: 'Operator', value: "OPERATOR" },
      { label: 'ZSmart', value: "zsmart"},
      { label: 'OneMoney', value: "onemoney"},
    ]
    useEffect(() => {
      if(selectCategorySearch === 'onemoney') {
        setShowOneMoney(true)
      } else {
        setShowOneMoney(false)
      }
    }, [selectCategorySearch])

    useEffect(() => {
      if(selectCategorySearch === 'zsmart') {
        setShowZsmart(true)
      } else {
        setShowZsmart(false)
      }
    }, [selectCategorySearch])

    let user = localStorage.getItem('user')
    user = JSON.parse(user);

  return (
    <Box maxW="8xl"  mx={'2'} pt={1} px={{ base: 2, sm: 12, md: 17 }}>
    {/* <Heading size={'lg'}>Search Customers</Heading> */}
    <chakra.h1
          textAlign={'left'}
          fontSize={'2xl'}
          py={5}
          fontWeight={'bold'}>
      Search Customers
        </chakra.h1>
    <Center mt={4}>

    <Heading mr={2} mt={'2'} color={'teal'} size={'sm'}>Search Customers In:</Heading>
<Flex justifyContent={'space-between'} >
    <HStack ml={2} mt={3} direction='row' spacing={-4}>
    <FormControl>
                    {/* <FormLabel>Search Customers Using</FormLabel>  */}
                    <Select placeholder='Select category' w={'160px'} bg={'white'} onChange={(e) => setSelectCategorySearch(e.target.value)} >
                        {category.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </Select>
        </FormControl>
        </HStack>
</Flex>
        {showOnemoney && <>
        
            <Flex justifyContent={'space-between'} >
                <HStack ml={2} mt={3} direction='row' spacing={-4}>
                    <FormControl>
                                {/* <FormLabel>Search Customers Using</FormLabel>  */}
                                <Select placeholder='Select option to search' w={'245px'} bg={'white'} onChange={(e) => setSelectedOption(e.target.value)} >
                                    {options.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </Select>
                    </FormControl>
                        <FormControl >
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents={'none'}
                                        children={<FiSearch color='gray.300' />}
                                        />
                                    <Input type={'text'} value={searchvalue} onChange={handleSearchInputChange} width='auto' placeholder='Search.. ' bg={'white'} />
                                </InputGroup>
                        </FormControl>
                            
                            <Button
                            leftIcon={<FiSearch/>}
                                isLoading={loading}
                                loadingText='Loading'
                                spinnerPlacement='end'
                                onClick={handlesubmit}
                                bg={'teal'}
                                color={'white'}
                                _hover={{
                                    bg: 'orange.500',
                                }}
                                ml={3}
                                variant='solid'
                                w={'245px'}>
                                Search
                            </Button>
              
                            
                </HStack>
            </Flex>
            </> }

        { showZsmart &&
         <>
           <Flex justifyContent={'space-between'} >
                <HStack ml={2} mt={3} direction='row' spacing={-4}>
                    <FormControl>
                                {/* <FormLabel>Search Customers Using</FormLabel>  */}
                                <Select placeholder='Select option to search' w={'245px'} bg={'white'} onChange={(e) => setSelectedOption(e.target.value)} >
                                    {options.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </Select>
                    </FormControl>
                        <FormControl >
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents={'none'}
                                        children={<FiSearch color='gray.300' />}
                                        />
                                    <Input type={'text'} value={searchvalue} onChange={handleSearchInputChange} width='auto' placeholder='Search.. ' bg={'white'} />
                                </InputGroup>
                        </FormControl>
                            
                            <Button
                            leftIcon={<FiSearch/>}
                                isLoading={loading}
                                loadingText='Loading'
                                spinnerPlacement='end'
                                onClick={handleTelco}
                                bg={'telegram.300'}
                                color={'white'}
                                _hover={{
                                    bg: 'orange.500',
                                }}
                                ml={3}
                                variant='solid'
                                w={'245px'}>
                                Search
                            </Button>
              
                            
                </HStack>
            </Flex>

        </>}
                        </Center>
{user?.title === 'NATIONAL SALES ADMIN' || 
user?.title === 'REGIONAL SALES MANAGER' || 
user?.title === 'ZONE COMMANDER' ||
 user?.title === 'ZONE ADMIN' || 
 user?.title === 'KEY ACCOUNT REP' ? ( 
<></>
):(
<>
<Center>
                <Flex justifyContent={'flex-start'} mt={10} >

  
            <Stack ml={2} spacing={4}>
            <Button onClick={() => navigate('/admin/new-customer-sim')} leftIcon={<BsSimFill />} colorScheme='orange' variant='solid'>
                     Sim Registration
                </Button>
            </Stack>
            <Stack ml={2} spacing={4}>
            <Button onClick={() => navigate('/admin/sim&wallet-registration')} leftIcon={<BsSimFill />} bgColor={'black'} color={'orange'} variant='solid'>
                     Sim & Wallet Registration
                </Button>
            </Stack>
            <Stack ml={4} spacing={4}>
            <Button  onClick={()=>navigate("/admin/create-form")} leftIcon={<GiWallet />} colorScheme='orange' variant='solid'>
                    Wallet Registration
                </Button>
            </Stack>
            {/* <Stack ml={4} spacing={4}>
              <Tooltip label="Not Available" placement='auto-end'>

            <Button isDisabled onClick={()=>navigate("/admin/bulk-form")} leftIcon={<BsSimFill />} colorScheme='blue' variant='solid'>
                   Sim Bulk Registration
                </Button>
              </Tooltip>
            </Stack> */}
                </Flex>
            </Center>
</>
)}
        
      <Stack p="3" bg='white' boxShadow="lg" m="2" borderRadius="sm" mt={'8'}  maxWidth={'1400px'}>

      
<TableContainer>

{searchresults.length > 0 ? (
<Center>
<Table variant='simple'>
<TableCaption><Heading size='sm'>Search Results</Heading></TableCaption>

<Thead>
<Tr>
 <Th>#</Th>
 <Th>First Name</Th>
 <Th>Last name</Th>
 <Th>Phone</Th>
 <Th>National ID</Th>
 {showOnemoney && <>
 
 <Th>Status</Th>
 </>}
 <Th>Action</Th>
</Tr>
</Thead>



<Tbody>
{searchresults.map((data,index)=>{
        return (
          <Tr key={index}>
          <Td>{index +1}</Td>
          <Td>{data?.firstName }</Td>
          <Td>{data?.lastName ? data.lastName : data.surNames}</Td>
          <Td>{data?.user?.phoneNumber ? data.user.phoneNumber : data?.msisdn}</Td>
        <Td>{data?.nationalIdNumber ? data.nationalIdNumber : data.documentNbr}</Td>
        {showOnemoney && <>
          <Td >
          { data?.verificationStatus === 'APPROVED' ? (<Button colorScheme='green' size='xs'>Approved</Button>) : data?.verificationStatus ==='DECLINED' ? (<Button colorScheme='red' size='xs'>Declined</Button>):<Button colorScheme='orange' size='xs'>{data?.verificationStatus}</Button>}
          </Td>
        </>}
          
  
   <Accordion allowToggle>
   <AccordionItem>
   <Td>
   
   <AccordionButton>
     <Wrap spacing={2} direction='row' align='center'>
      {showOnemoney && <>
      
     <Button onClick={() => navigate(`/admin/viewcustomer`,{
       state:data
      })} colorScheme='gray' size='md' leftIcon={<MdPreview/>}>View</Button>
      </>}
      {showZsmart && <>
        <Button onClick={() => navigate(`/admin/viewtelco_cust`,{
       state:data
      })} colorScheme='gray' size='md' leftIcon={<MdPreview/>}>View</Button>
      </>}

       {/* <Button onClick={() => navigate(`/admin/edit-customer`,{
              state:data
            })} colorScheme='yellow' size='md' leftIcon={<FaUserEdit/>}>Edit</Button> */}
     </Wrap>
     <AccordionIcon ml={4} />
     </AccordionButton>
           <AccordionPanel>
  <Button isDisabled onClick={() => navigate(`/admin/junior-wallet`,{
              state:data
            })} mt={2}  colorScheme='cyan' size='xs'>Add Junior wallet</Button>
 </AccordionPanel>
 
   </Td>
   </AccordionItem>
       </Accordion>
 </Tr>
     )}) }        
</Tbody>

      

<Tfoot>
</Tfoot>

</Table>

</Center>
 ): (
    <Table>
        <TableCaption><Text>No Data to Display</Text></TableCaption>
  <Tr>
  </Tr>
    
    </Table>
  )}
</TableContainer>

</Stack>
      
      </Box>
  )
}

export default SearchCustomer

