
// import './App.css';
import {
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Badge,
  InputGroup,
  InputLeftElement,
  Input,
  FormControl,
  Spinner,
  Button,
  Text,
  Alert,
  AlertIcon,
  AlertDescription
} from '@chakra-ui/react'

import Dashboard from './pages/Dashboard';
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  Link,
} from "react-router-dom";
import Home from './pages/Home';
import Customers from './pages/Customers';
import EmployeeUser from './pages/EmployeeUser';
import Login from './pages/Login';
// import FormCustomer from './pages/FormCustomer';
import CustomerForm from './components/form/CustomerForm';
import UserForm from './components/form/UserForm';
import SigningUp from './components/SignUp/SigningUp';
import CustomerPendingList from './components/form/CustomerPendingList';
import SetPassword from './pages/SetPassword';
// import Verification from './pages/Verification';
import ManageApproval from './components/list/ManageApproval';
import Operator from './components/stats/Operator';
import ViewCustomer from './components/list/ViewCustomer';
import EditCustomer from './components/form/EditCustomer';
import ViewUser from './components/list/ViewUser';
import Config from './components/configurations/Config';
import PasswordSet from './components/login/PasswordSet';
import UserProfileEdit from './components/settings/UserProfileEdit';
import JuniorForm from './components/form/JuniorForm';
import ForgotPassword from './components/login/ForgotPassword';
import EmailSuccess from './components/login/EmailSuccess';
import EmailVerify from './components/login/EmailVerify';
import HomeDash from './components/stats/HomeDash';
import Executive from './components/stats/Executive';
import OperatorFormCust from './components/Shortcut/OperatorFormCust';
import OperatorViewCust from './components/Shortcut/OperatorViewCust';
import SearchCustomer from './components/list/SearchCustomer';
import OperatorSearch from './components/Shortcut/OperatorSearch';
import OperatorEditCustomer from './components/Shortcut/OperatorEditCustomer';
import SimReg from './components/Shortcut/SimReg';
import SimRegistration from './components/form/SimRegistration';
import JuniorFormOperator from './components/Shortcut/JuniorFormOperator';
import Department from './components/department/Department';
import CreateDepartment from './components/department/CreateDepartment';
import CustomersReport from './components/reporting/CustomersReport';
import SystemConfig from './components/configurations/systemConfig/SystemConfig';
import UserConfig from './components/list/users/UserConfig';
import UserExtForm from './components/form/UserExtForm';
import EditProvince from './components/configurations/systemConfig/EditProvince';
import EditZone from './components/configurations/systemConfig/EditZone';
import EditShop from './components/configurations/systemConfig/EditShop';
import SuperData from './components/stats/SuperData';
import ViewTelcoCustomer from './components/list/ViewTelcoCustomer';
import OperatorTelcoView from './components/Shortcut/OperatorTelcoView';
import React,{ useEffect, useState } from 'react';
import {useAuth } from './context/AuthProvider';
import jwt from 'jwt-decode';
import BAReport from './components/reporting/BAReport';
import External from './components/list/External';
import BulkRegistration from './components/form/BulkRegistration';
import UserManage from './components/list/UserManage';
import Approver from './components/stats/Approver';
import ApproverSearchCustomer from './components/Shortcut/ApproverSearchCustomer';
import ApprovalReport from './components/reporting/ApprovalReport';
import AdminConfig from './components/list/CustomerConfig/AdminConfig';
import SingleApprovalReport from './components/reporting/SingleApprovalReport';
import NewApprovalReport from './components/reporting/NewApprovalReport';
import TokenExpirationHandler from './components/login/TokenExpirationHandler';
import BulkSimForm from './components/form/Bulk/BulkSimForm';
import SimWalletRegistration from './components/Shortcut/SimWalletRegistration';
import SimWalletAdmin from './components/form/SimWalletAdmin';


function App() {
  const [open,setOpen] = useState(false)
  const cancelRef = React.useRef()

  

//   useEffect (()=>{
//   const interval = setInterval( async()=>{
//     // console.log("checking...")
//     // const tken = localStorage.getItem("token");
//     // if (!tken) {
//     //   setOpen(true)

//     //   clear(); 
//     //   return;
//     // }
//     const valid = await isAuthenticated()
   
//     if (!valid) {
//       setOpen(true)
//       clear();
//       return;
     
//     }

//   }, 5000);

//   const clear = ()=> {
//     // localStorage.clear();
//     clearInterval(interval);
//   }

//   // Clean up the interval on component unmount
//   return () => {
   
//     clearInterval(interval);
   
//   };
// }, []);

  return (
    <div className="App">

<AlertDialog
        isOpen={open}
        closeOnOverlayClick={false}
        onClose={()=>{setOpen(false)}}
      >
        <AlertDialogOverlay
        bg={'blackAlpha.300'}
        backdropFilter={'blur(10px) hue-rotate(90deg)'}
        >
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            <Alert status='warning'>
           <AlertIcon />
           Attention:
            </Alert>
            </AlertDialogHeader>

            <AlertDialogBody>
            <Text>

           Your session has expired. Please log in again to continue
            </Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={()=>window.location.href = "/"}  colorScheme='yellow'  ml={3}>
               Login
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
          </AlertDialogOverlay>
      </AlertDialog>

      {/* <Dashboard /> */}
      <BrowserRouter>
      <TokenExpirationHandler />
      <Routes>
        <Route path="admin" element={<Dashboard/>}>
          <Route path="/admin/home" element={<Home/>} />
          <Route path='/admin/manage-customer' element={<Customers/>} />
          <Route path='/admin/manage-user' element={<EmployeeUser/>} />
          <Route path ='/admin/create-form' element={<CustomerForm/> } />
          <Route path = '/admin/create-cust' element={<OperatorFormCust/>} />
          <Route path='/admin/search-profile' element={<OperatorSearch/>} />
          <Route path='/admin/sim-wallet-registration' element={<SimWalletRegistration />} />
          <Route path='/admin/sim&wallet-registration' element={<SimWalletAdmin />} />


          <Route path='/admin/bulk-form' element={<BulkRegistration />} />
          <Route path='/admin/junior-form-reg' element={<JuniorFormOperator/>}/>
          <Route path='/admin/junior-wallet' element={<JuniorForm/>} />

          <Route path = '/admin/create-user' element={<UserForm/> } />
          <Route path = '/admin/create-extuser' element={<UserExtForm /> } />
          <Route path = '/admin/view-user' element={<ViewUser/>} />
          <Route path='/admin/external-users' element={<External />} />

          <Route path='/admin/pending-customers' element={<CustomerPendingList/>}/>
          <Route path='/admin/manage-approval' element={<ManageApproval/>} />
{/* landing pages */}
          <Route path='/admin/operator' element={<Operator/>} />
          <Route path = '/admin/home-dashboard' element={<HomeDash />} />
          <Route path = '/admin/exec-dashboard' element={<Executive/>}/>
          <Route path='/admin/super-home' element = {<SuperData /> } />

          <Route path='/admin/viewcustomer' element={<ViewCustomer/>} />
          <Route path='/admin/viewtelco_cust' element={<ViewTelcoCustomer />} />
          <Route path = '/admin/customer-profile' element = {<OperatorViewCust />} />
          <Route path='/admin/cust-profile' element = { <OperatorTelcoView />} />
          <Route path='/admin/edit-customer' element={<EditCustomer/>} />
          <Route path='/admin/operator-edit-customer' element={<OperatorEditCustomer />} />


          <Route path='/admin/profile/:userId' element={<UserProfileEdit/>}/>
          <Route path ='/admin/config' element={<Config/>}/>
          <Route path = '/admin/search-customer' element={<SearchCustomer/>} />
          <Route path = '/admin/new-customer' element={<SimReg />}/>
          <Route path ='/admin/new-customer-sim' element={<SimRegistration />} />
          <Route path = '/admin/manage-department' element={<Department/>}/>
          <Route path = '/admin/add-depart' element = {<CreateDepartment/>} />
          <Route path = "/admin/view-report" element = {<CustomersReport />} />
          <Route path='/admin/report_ba' element = {<BAReport />} />
          {/* <Route path='/admin/view-approval-reports' element={<ApprovalReport />} /> */}
          <Route path='/admin/view-approval-reports' element={<NewApprovalReport />} />
          <Route path='/admin/add-bulk-registrations' element={<BulkSimForm />} />

          <Route path='/admin/declined-customers' element={<AdminConfig />} />
          <Route path='/admin/view-declined-reports' element={<SingleApprovalReport />} />

    {/* system config */}
          <Route path='/admin/system-config' element = { <SystemConfig /> } />
          <Route path='/admin/system-config-editprov' element = {<EditProvince />} />
          <Route path='/admin/system-config-editzone' element = {<EditZone />} />
          <Route path='/admin/system-config-editshop' element = {<EditShop />} />
          <Route path = '/admin/users' element = {<UserConfig />} />
          <Route path = '/admin/user-manage' element = {<UserManage />} />
          <Route path='/admin/approver-dashboard' element={<Approver />} />
          <Route path='/admin/approver-search' element={<ApproverSearchCustomer />} />


        </Route>
        <Route path="admin-user" element={<Dashboard/>}>
        <Route path='/admin-user/ba.registrations' element={<Approver />} />
</Route>
        <Route exact path='/' element={<Login/> } />
        {/* <Route path='/signingup' element={<SigningUp/> } /> */}
        <Route path='/set-password' element={<PasswordSet/>} />
        <Route path = '/forgot-password' element={<ForgotPassword/>}/>
        <Route path ='/email-success/:workemail' element={<EmailSuccess/>} />
        <Route path = '/verify-email' element={<EmailVerify/>}/>
        {/* <Route path='/verify-email' element={<} */}

       
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
