import {
    Box,
    chakra,
    Flex,
    SimpleGrid,
    Stat,
    StatLabel,
    StatNumber,
    useColorModeValue,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
    useToast,
    StatHelpText,
    StatArrow,
    StatGroup,
    Stack,
    Heading,
    Spinner,
    Text,
    Badge,
    Button,

} from '@chakra-ui/react';
import { BsChevronRight, BsPerson } from 'react-icons/bs';
import { FiUsers } from 'react-icons/fi';
import CustomerPendingList from '../form/CustomerPendingList';
import axios from '../../api/axios';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom'
import { FaUserCheck, FaUserClock, FaUserMinus, FaUserPlus, FaUsers } from 'react-icons/fa';
import netone from '../../images/netmoney.jpg'
import oneMoney from '../../images/c2.png'
import { GiShop } from 'react-icons/gi';
import { BiExport } from 'react-icons/bi';
import { read, utils, writeFile } from 'xlsx'


const GETSTATS_URL = '/onboarding/operator/dashboard';
const GETALLSTATS_URL = '/onboarding/operator/get-statistics?resultType=STATISTICS'
const GETSHOPS_URL = '/employee/shop/v1/get-shops'
const GETTELCO_URL = '/onboarding/telco/operator/get-statistics?resultType=STATISTICS'


function StatsCard(props) {

    const { title, stat, icon } = props;
    return (
        <Stat
            px={{ base: 2, md: 2 }}
            py={'5'}
            shadow={'xl'}
            border={'1px solid'}
            bgColor={useColorModeValue('white')}
            borderColor={useColorModeValue('white', 'white')}
            rounded={'lg'}>

            <Flex justifyContent={'space-between'}>
                <Box pl={{ base: 2, md: 5 }}>
                    <StatLabel fontSize={'sm'} fontWeight={'medium'} isTruncated>
                        Total Registered at<Badge ml='1' variant={'solid'} colorScheme='orange' fontSize='0.7em'>{title} </Badge>
                    </StatLabel>
                    <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
                        {stat}
                    </StatNumber>
                </Box>
                <Box
                    my={'auto'}
                    color={useColorModeValue('white', 'white')}
                    alignContent={'center'}>
                    {icon}
                </Box>

            </Flex>
        </Stat>
    );
}
function ShopStats() {
    const [loading, setLoading] = useState(false);
    const [statistics, setStatistics] = useState({});
    const [stats, setStats] = useState({})
    const [telco, setTelco] = useState({})
    const toast = useToast();

    const getStats = async () => {
        const token = localStorage.getItem("token");
        try {
            const response = await axios.get(`${GETSTATS_URL}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            if (response.data.success === true) {
                setStatistics(response.data.body)
            }
        } catch (error) {
            console.log(error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                toast({
                    title: "Error",
                    description: `Server responded with ${error.message}`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });

            } else if (error.request) {
                // The request was made but no response was received
                toast({
                    title: "Network Error",
                    description: "Please Check your Internet Connection",
                    status: "error",
                    duration: 8000,
                    isClosable: true,
                });

            } else {
                // Something happened in setting up the request that triggered an Error
                toast({
                    title: "Error",
                    description: `Error:`,
                    status: "error",
                    duration: 8000,
                    isClosable: true,
                });

            }
        }
    }

    useEffect(() => {
        getStats();

    }, [])

    const getAllWeeks = async () => {
        const token = localStorage.getItem("token");
        try {
            const response = await axios.get(`${GETALLSTATS_URL}&statisticsType=SHOP_GROUP&dashboardType=LANDING`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            if (response.data.success === true) {
                setStats(response.data.body.shopStatistics)
                setLoading(false)
            }
        } catch (error) {
            console.log(error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // toast({
                //     title: "Error",
                //     description: `Server responded with ${error.message}`,
                //     status: "error",
                //     duration: 5000,
                //     isClosable: true,
                // });

            } else if (error.request) {
                // The request was made but no response was received
                toast({
                    title: "Network Error",
                    description: "Please Check your Internet Connection",
                    status: "error",
                    duration: 8000,
                    isClosable: true,
                });

            } else {
                // Something happened in setting up the request that triggered an Error
                toast({
                    title: "Error",
                    description: `Error:`,
                    status: "error",
                    duration: 8000,
                    isClosable: true,
                });

            }
        }
    }

    useEffect(() => {
        getAllWeeks();

    }, [])

    const getAllTelco = async () => {
        const token = localStorage.getItem("token");
        try {
            const response = await axios.get(`${GETTELCO_URL}&statisticsType=SHOP_GROUP&dashboardType=LANDING`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            if (response.data.success === true) {
                setTelco(response.data.body.shopStatistics)
                setLoading(false)
                // console.log(response.data.body.zonalStatistics)
            }
        } catch (error) {
            console.log(error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // toast({
                //     title: "Error",
                //     description: `Server responded with ${error.data.message}`,
                //     status: "error",
                //     duration: 5000,
                //     isClosable: true,
                // });

            } else if (error.request) {
                // The request was made but no response was received
                toast({
                    title: "Network Error",
                    description: "Please Check your Internet Connection",
                    status: "error",
                    duration: 8000,
                    isClosable: true,
                });

            } else {
                // Something happened in setting up the request that triggered an Error
                toast({
                    title: "Error",
                    description: `Error:`,
                    status: "error",
                    duration: 8000,
                    isClosable: true,
                });

            }
        }
    }

    useEffect(() => {
        getAllTelco();

    }, [])

    // const [shop, setShop] = useState([]);
    // const getAllShops = async () => {
    //     setLoading(true);
    //     const token = localStorage.getItem("token");
    //     try {
    //         const response = await axios.get(`${GETSHOPS_URL}`, {
    //             headers: {
    //                 'Authorization': `Bearer ${token}`
    //             }
    //         })

    //         if (response.status === 200) {
    //             setShop(response.data.body)
    //             setLoading(false);
    //         }
    //     } catch (error) {

    //     }
    // }


    // useEffect(() => {
    //     getAllShops();

    // }, [])


    let user = localStorage.getItem('user')
    user = JSON.parse(user);

    const currentHour = new Date().getHours();
    let greeting;

    if (currentHour < 12) {
        greeting = 'Good morning';
    } else if (currentHour < 18) {
        greeting = 'Good afternoon';
    } else {
        greeting = 'Good evening';
    }
    // const regId = Object.values(stats)
    // console.log(regId)
    // const zonId = Object.entries(stats)
    // console.log(zonId)

    // let regiones;
    // console.log(zonId)
    // zone.forEach((item) => {
    //   if (zonId[0] === item.id) {
    //     regiones = item;
    //     console.log(regiones);

    //   }
    // })
    const handleExport = () => {
        const headings = [["#", "ShopName", "Statistics"]];
        const wb = utils.book_new();
        const ws = utils.table_to_sheet(document.getElementById('card'),{raw: true});
        utils.sheet_add_aoa(ws, headings);
     //    utils.sheet_add_json(ws, searchresults, { origin: "A2", skipHeader: true});
        utils.book_append_sheet(wb, ws, "ShopStats_Report");
        writeFile(wb, "ShopStats_Report.xlsx")
         };




    return (
        <>
            <Box maxW="full" pt={1} px={{ base: 2, sm: 12, md: 12 }}>
                <Breadcrumb spacing='8px' separator={<BsChevronRight color='gray.500' />}>
                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink href='#'></BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
                {/* <chakra.h1
            textAlign={'left'}
            fontSize={'1xl'}
            
            fontWeight={'bold'}>
         {greeting}, {user.firstName} {user.lastName}
          </chakra.h1> */}


                <Stack
                    spacing={4}
                    minW={'10vw'}
                    bg={useColorModeValue('gray.100', 'gray.800')}
                    rounded={'xl'}
                    boxShadow={'sm'}
                    p={4}
                    my={8}>
                    

                    <Heading fontSize={{ base: '1xl', sm: 'md' }}>
                        OneMoney Statistics By Shop
                    </Heading>
                    {/* <Stack direction={['column','row']}>
                    <Button
                leftIcon={<BiExport/>}
                onClick={() => navigate('/admin/declined-customers')}
                bg={'white'}
                color={'orange'}
                _hover={{
                    bg: 'black',
                    color: 'white',
                }}
             
                variant='solid'
                w={'150px'}>
                    Preview to Excel
                </Button>
                    </Stack> */}
                   {loading ? (
      <Flex>
      <Spinner
      thickness='4px'
      speed='0.85s'
      emptyColor='gray.200'
      color='orange.300'
      size='lg'
/>
      </Flex>
    ):(
                        <SimpleGrid mb={2} columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 4 }} >
                            {/* {stats.length === 0 &&
                <Spinner
                  thickness='4px'
                  speed='0.95s'
                  emptyColor='gray.200'
                  color='orange.500'
                  size='md'
                />} */}

                            {


                                Object.entries(stats)?.map(([key, value]) => {
                                    // console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                                   
                                    return (
                                        <StatsCard
                                            // logo={<Image src={oneMoney} borderRadius='full'
                                            // boxSize='50px' />}
                                            id='card'
                                            title={key}
                                            stat={value}
                                            icon={<GiShop color='orange' size={'1.3em'} />}

                                        />
                                    )
                                })}

                        </SimpleGrid>
                    )}
                </Stack>

                <Stack
                    spacing={4}

                    minW={'10vw'}
                    bg={useColorModeValue('gray.100', 'gray.800')}
                    rounded={'xl'}
                    boxShadow={'sm'}
                    p={4}
                    my={8}>

                    <Heading lineHeight={1.1} fontSize={{ base: '1xl', sm: 'md' }}>
                        Telco Statistics By Shop
                    </Heading>
                    {loading ? (
                        <Flex>
                            <Spinner
                                thickness='4px'
                                speed='0.85s'
                                emptyColor='gray.200'
                                color='orange.300'
                                size='md'
                            />
                        </Flex>
                    ) : (
                        <SimpleGrid mb={2} columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 5 }} >
                            {
                                Object.entries(telco)?.map(([key, value]) => {
                                    // console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
                                   

                                    return (
                                        <StatsCard
                                            title={key}
                                            stat={value}
                                            icon={<GiShop color='orange' size={'1.3em'} />}

                                        />
                                    )
                                })}


                        </SimpleGrid>
                    )}
                </Stack>

            </Box>

        </>
    );
}

export default ShopStats