import React, { useState, useEffect, useRef } from 'react'
import {
     Box,
     Button,
     Flex,
     FormControl,
     FormLabel,
     Input,
     InputGroup,
     InputLeftElement,
     Stack,
     Card, 
     CardHeader, 
     CardBody, 
     CardFooter,
     Heading,
     Text,
     StackDivider,
     useColorModeValue,
     HStack,
     Avatar,
     IconButton,
     Image,
     Wrap,
     Tag,
     Table,
     Thead,
     Tbody,
     Tfoot,
     Tr,
     Th,
     Td,
     TableCaption,
     TableContainer,
     Spinner,
     Center,
     useToast,
     Accordion,
     AccordionItem,
     AccordionButton,
     AccordionPanel,
     AccordionIcon,
     Select,
     chakra,
     Link,
     Stat,
     StatNumber,
    } from '@chakra-ui/react'
import { FiBook, FiBookOpen, FiPause, FiPhoneCall, FiSearch, FiUser, FiUserPlus } from 'react-icons/fi'
import { Form, useNavigate } from 'react-router-dom'
import { MdEdit, MdPreview } from 'react-icons/md'
import {CiWallet} from 'react-icons/ci';
import {GiWallet} from 'react-icons/gi';
import axios from '../../api/axios';
import { FaUserEdit } from 'react-icons/fa'
import { BsFillWalletFill, BsSimFill } from 'react-icons/bs'
// import { useDownloadExcel } from 'react-export-table-to-excel'
import { read, utils, writeFile } from 'xlsx'
import { BiExport } from 'react-icons/bi'
import moment from 'moment'
import { RiExternalLinkFill } from 'react-icons/ri'

const GETBA_COMMISSION = '/onboarding/operator/get-brand-ambassadors-commissions'

function BAReport() {
    const [searchvalue, setSearchValue] = useState("");
    const [searchresults, setSearchResults] = useState([])
    const [loading, setLoading] = useState(false);
    const toast = useToast();

    const handleSearchInputChange = (e) => {
        setSearchValue(e.target.value);
      };
    const handlesubmit = () => {
        // e.preventDefault();
        setLoading(true)
        const token = localStorage.getItem("token");
        axios.get(`${GETBA_COMMISSION}?date=${formattedDate}`,
        {
    
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        }
        )
          .then(res => {
    
            if (res.data.success) {
                setSearchResults(res.data.body);
                console.log(res.data.body)
              setLoading(false)
            } else {
              toast({
                title: 'Failed',
                description: res.data.message,
                status: 'error',
                duration: 3000,
    
              });
              setLoading(false)
            }
          })
          .catch(error => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              toast({
                title: "Error",
                description: `Server responded with ${error.response.status}`,
                status: "error",
                duration: 5000,
                isClosable: true,
              });
              setLoading(false)
            } else if (error.request) {
              // The request was made but no response was received
              toast({
                title: "Network Error",
                description: "Please Check your Internet Connection",
                status: "error",
                duration: 8000,
                isClosable: true,
              });
              setLoading(false)
            } else {
              // Something happened in setting up the request that triggered an Error
              toast({
                title: "Network Error",
                description: `Error: ${error.message}`,
                status: "error",
                duration: 8000,
                isClosable: true,
              });
    
            }
          })
      }
      const handleExport = () => {
        const headings = [[ "#","Date","FullName", "NationalID","Phone Number", "CommissionBalance($)"]];
        const wb = utils.book_new();
        const ws = utils.table_to_sheet(document.getElementById('customers'));
        utils.sheet_add_aoa(ws, headings);
     //    utils.sheet_add_json(ws, searchresults, { origin: "A2", skipHeader: true});
        utils.book_append_sheet(wb, ws, "BrandAmbassador_Report");
        writeFile(wb, "BrandAmbassador_Report.xlsx")
         };

         const dateString =  searchvalue;
  const formattedDate = moment(dateString).format('MMMM YYYY').toUpperCase();
  
  console.log(formattedDate)

  return (
    <Box maxW="60xl"  mx={'2'} pt={1} px={{ base: 2, sm: 12, md: 17 }}>
        <chakra.h1
          textAlign={'left'}
          fontSize={'2xl'}
          py={5}
          fontWeight={'bold'}>
      Commissions
        </chakra.h1>

        <Flex justifyContent={'center'} >
    <HStack ml={2} mt={3} spacing={2}>
    <Heading color={'teal'} size={'sm'}>Select Month:</Heading>
<Box>

    <FormControl>
                {/* <FormLabel>Date:</FormLabel> */}
                <Input
                  placeholder="Select Date and Time"
                  size="md"
                  bg={'white'}
                  w={'240px'}
                  name='searchvalue'
                  type="month"
                  onChange={handleSearchInputChange}
                  />
              </FormControl>
              </Box>
              <Button
                leftIcon={<FiSearch/>}
                isLoading={loading}
                loadingText='Loading'
                spinnerPlacement='end'
                onClick={handlesubmit}
                bg={'teal'}
                color={'white'}
                    _hover={{
                        bg: 'orange.500',
                    }}
                    mt={8}
                    variant='solid'
                    w={'180px'}>
                    Search
                </Button>
        </HStack>
        </Flex>

<Button
                leftIcon={<BiExport/>}
                isLoading={loading}
                loadingText='Loading'
                spinnerPlacement='end'
                onClick={handleExport}
                bg={'blue'}
                color={'white'}
                _hover={{
                    bg: 'orange.500',
                }}
                mt={2}
                ml={2}
                variant='solid'
                w={'150px'}>
                    Export to Excel
                </Button>

<TableContainer maxW={'100vw'} overflowX={'scroll'} overflowY={'scroll'} maxH={'80vh'} bg={'white'} rounded={'lg'} mt={8} p={3}>

{searchresults?.length > 0 ? (
    


<Table id='customers'  variant='simple'>

                {/* <ExportToExcel data ={searchresults} fileName={'statistics_data'} /> */}
<TableCaption><Heading size='sm'>Search Results: {formattedDate}</Heading></TableCaption>

<Thead>
<Tr>
 <Th>#</Th>
 <Th>Date</Th>
 <Th>Full Name</Th>
 <Th>National ID Number</Th>
 <Th>Phone Number</Th>
 <Th>Commission Balance($)</Th>
</Tr>
</Thead>

<Tbody>
{searchresults?.map((data,index)=>{
    let calculations;
    calculations = data.commissionsBalance / 100;
     
        return (
           
          <Tr key={index}>
          <Td>{index +1}</Td>
          <Td>{data.date}</Td>
          <Td>{`${data.firstName} ${data.lastName}` }</Td>
        <Td>{data.nationalIdNumber}</Td>
        <Td>{data.phoneNumber}</Td>
        <Td>
            <Stat>
                <StatNumber>$ {calculations}</StatNumber>
            </Stat>
           </Td>

         </Tr>
     )}) }        
</Tbody>

      

<Tfoot>
</Tfoot>

</Table>


 ): (
    <Table>
        <TableCaption><Text>No Data to Display</Text></TableCaption>
  <Tr>
  </Tr>
    
    </Table>
  )}
</TableContainer>

    </Box>
    )
}

export default BAReport