import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, Flex, Heading, Input, Stack, Text, Wrap, useDisclosure, useToast } from "@chakra-ui/react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Box, Button,chakra } from "@chakra-ui/react";
import { read, utils, writeFile } from 'xlsx'
import { BiExport } from 'react-icons/bi'
import { Tag } from 'primereact/tag';
import { useNavigate } from 'react-router-dom'
import axios from "../../api/axios";
import {  MdCheckCircle, MdPreview, MdReport } from "react-icons/md";
import { FiPhone, FiUser, FiUserPlus } from "react-icons/fi";
import { FaIdCard, FaRegImage } from 'react-icons/fa';
import img from '../../images/noimage1.png'
import {  baseURL } from '../../api/baseUrl'
import { approveImageURL } from '../../api/imgUrl';
import { BsFillLaptopFill, BsPhoneFill } from 'react-icons/bs';
import moment from 'moment'
import Config from '../list/CustomerConfig/Config';

const GET_CUSTOMER_URL = "/onboarding/operator/customer/declined";
const EMPLOYEE_ACC_URL = '/employee/profile/v1/get-employee-by-account-id/'
const BRAND_ACC_URL = '/employee/brand-ambassador/v1/get-all-brand-ambassadors'
const BRANDS_ACC_URL = '/employee/brand-ambassador/v1/get-brand-ambassador-by-account-id'
const CUSTOMER_APPROVAL_URL ='/onboarding/operator/customer/approve'
const CUSTOMER_DECLINE_URL = '/onboarding/operator/customer/decline'
const EDITDECLINE_CUSTOMER_URL = '/onboarding/operator/customer/edit-and-approve'
const GETUSERS_URL = '/employee/profile/v1/get-employees?availability=PRESENT'

function NewApprovalReport() {
    const [searchresults, setSearchResults] = useState([])
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const [phoneNumber, setPhoneNumber] = useState("");
    const navigate = useNavigate();

  
    const handleExport = () => {
    const headings = [["#","FirstName", "LastName", "National Id Number","PhoneNumber","Gender","Approval Status","Created Date", "Created Time", "Created By",  "Declined By","Declined Date","Declined Time", "Reasons"]];
    const wb = utils.book_new();
    const ws = utils.table_to_sheet(document.getElementById('id'),{raw: true});
    utils.sheet_add_aoa(ws, headings);
 //    utils.sheet_add_json(ws, searchresults, { origin: "A2", skipHeader: true});
    utils.book_append_sheet(wb, ws, "Declined_Approval_Report");
    writeFile(wb, "Declined_Approval_Report.xlsx")
     };

     const [customers, setCustomers] = useState([]);
     const getAllCustomers = async () => {
        const token = localStorage.getItem("token");
        try {
          setLoading(true);
          const response = await axios.get(`${GET_CUSTOMER_URL}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          if (response.status === 200) {
            setCustomers(response.data.body);
             console.log(response.data.body);
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
        }
      };
      useEffect(() => {
        getAllCustomers();
      }, []);

      const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        firstName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        lastName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        phoneNumber: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        nationalIdNumber: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        gender: { value: null, matchMode: FilterMatchMode.STARTS_WITH },

        approvalStatus: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        createdAt: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        createdBy: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        declinedBy: { value: null, matchMode: FilterMatchMode.STARTS_WITH },





      });
    
    

     const [updatedId, setUpdatedID] = useState([]);
  const getAllEmployees = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(GETUSERS_URL, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })

      if (response.status === 200) {
        setUpdatedID(response.data.body)
        // console.log(response.data.body)
        setLoading(false);
      }
    } catch (error) {

    }
  }
  useEffect(() => {
    getAllEmployees();
  }, []);

  const [ambassadorId, setAmbassadorID] = useState([]);


  const getBrandAmbassadors = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(BRAND_ACC_URL, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })

      if (response.status === 200) {
        setAmbassadorID(response.data.body)
        setLoading(false);
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    getBrandAmbassadors()

  }, [])

  const reasonsBodyTemplate = (customer) => {
    return <Text>{customer.declineReasons.join(", ")}</Text>;
};


  const formatDate = (value) => {
    const date = new Date(value)
    if(date){
        return date.toLocaleDateString( 'en-US', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
          })        
        }else{
          return ""
        }
  
};

const formatTime = (value) => {
  const date = new Date(value)
  if(date){
      return date.toLocaleTimeString( 'en-US', {
         
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        })        
      }else{
        return ""
      }

};

const createdAtBodyTemplate = (rowData) => {
    return formatDate(rowData.createdAt ? rowData.createdAt : '');
  
  };
  const createdTimeBodyTemplate = (rowData) => {
    return formatTime(rowData.createdAt ? rowData.createdAt : '');
  
  };

  const updatedAtBodyTemplate = (rowData) => {
    return formatDate(rowData.updatedAt ? rowData.updatedAt : '');
  
  };

  const updatedTimeBodyTemplate = (rowData) => {
    return formatTime(rowData.updatedAt ? rowData.updatedAt : '');
  
  };
  

  const AgentCreatedByBodyTemplate = (customer) => {
    return <Text>{getAgentById(customer.createdBy)}</Text>;
};

  function getAgentById(id){
   const Agent = ambassadorId?.find((item) => id === item.employeeAccount.id);
  if(Agent){
  return `${Agent.firstName} ${Agent.lastName}`
  }else{
    return ""
  }
  }

  const EmployeeByBodyTemplate = (customer) => {
    return <Text>{getEmployeeById(customer.declinedBy)}</Text>;
};

  function getEmployeeById(id){
   const Agent = updatedId?.find((item) => id === item.employeeAccount.id);
  if(Agent){
    return `${Agent.firstName} ${Agent.lastName}`
  }else{
    return "OneMoney Declined"
  }
  }

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <Input
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };


  const statusBodyTemplate = (customers) => {
    return <Tag value={customers.approvalStatus} severity={getSeverity(customers)}></Tag>;
};

  const getSeverity = (customers) => {
    switch (customers.approvalStatus) {
        case 'APPROVED':
            return 'success';

        case 'PENDING':
            return 'warning';

        case 'DECLINED':
            return 'danger';

        default:
            return null;
    }
};


   
let user = localStorage.getItem('user')
user = JSON.parse(user);

const header = renderHeader();
/* End of Table Header */


  return (
    <>
   <chakra.h1
          textAlign={'left'}
          fontSize={'2xl'}
          py={5}
          fontWeight={'bold'}>
          Approval 
        </chakra.h1>
    <Box
    mt={4}
    boxShadow={'lg'}
    rounded={'md'}
    >

{user?.roles[0].name === 'CLERK' || user?.title === 'KEY ACCOUNT REP' ?(
<>
</>
     ):(

<Stack mb={4} direction={['column','row']}>

        <Button
                leftIcon={<BiExport/>}
                onClick={handleExport}
                bg={'white'}
                color={'orange'}
                _hover={{
                    bg: 'black',
                    color: 'white',
                }}
             
                variant='solid'
                w={'150px'}>
                    Export to Excel
                </Button>

                <Button
                leftIcon={<BiExport/>}
                onClick={() => navigate('/admin/declined-customers')}
                bg={'black'}
                color={'orange'}
                _hover={{
                    bg: 'orange',
                    color: 'white',
                }}
               
                variant='solid'
                mb={2}
                w={'250px'}>
                    View Approval Customers
                </Button>
                    </Stack>
     )}
      {user?.title === 'KEY ACCOUNT REP' ? (
<>
<Button
                leftIcon={<MdReport />}
                onClick={() => navigate('/admin/view-declined-reports')}
                bg={'black'}
                color={'orange'}
                _hover={{
                    bg: 'orange',
                    color: 'white',
                }}
               mt={2}
                variant='solid'
                w={'150px'}>
                    View Report
                </Button>

    <Flex justify={'center'}>


      <Heading mt={2} mb={2} size={'md'} color={'orange.400'}>Please verify customer KYC</Heading>
    </Flex>

      <Config />
</>
):(
    <>
   
      <DataTable
      id="id"
        value={customers}
        scrollable scrollHeight="600px"
        header={header}
        paginator
        rows={100}
        dataKey="id"
        tableStyle={{ minWidth: "50rem" }}
        filters={filters}
        sortField="createdAt" 
        sortOrder={-1}
        showGridlines
        filterDisplay="row"
        loading={loading}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        globalFilterFields={[
          "firstName",
          "lastName",
          "phoneNumber",
          "nationalIdNumber",
       
        ]}
        emptyMessage="No customers found.">
         <Column
          header="#"
           headerStyle={{ width: '3rem' }} 
           body={(data, options) => options.rowIndex + 1}></Column>
        <Column
          field="firstName"
          header="First Name"
          sortable
          filter
          filterPlaceholder="Search"
          style={{ minWidth: "12rem" }}></Column>
        <Column
          field="lastName"
          header="Last Name"
          sortable
          filter
          filterField="lastName"
          filterPlaceholder="Search"
          style={{ minWidth: "12rem" }}></Column>

<Column
          field="nationalIdNumber"
          header="National ID"
          sortable
          filter
          filterField="nationalIdNumber"
          filterPlaceholder="Search"
          style={{ minWidth: "8rem" }}></Column>
          
          <Column
          field="phoneNumber"
          header="Phone Number"
          filterPlaceholder="Search by number"
          style={{ minWidth: "12rem" }}></Column>

        <Column
          field="gender"
          header="Gender"
          style={{ minWidth: "2rem" }}></Column>

        <Column 
        header="approvalStatus" 
        body={statusBodyTemplate} 
        style={{ minWidth: '8rem'  }}></Column>

        <Column 
        field="createdAt" 
        body={createdAtBodyTemplate}
        header="Created At" 
         sortable style={{ minWidth: '12rem'  }}></Column>

        <Column 
        field="createdAt"
         body={createdTimeBodyTemplate} 
         header="Created Time"  
         sortable style={{ minWidth: '12rem'  }}></Column>

        <Column 
        field="createdBy" 
        body={AgentCreatedByBodyTemplate} 
        header="Created by" 
        style={{ minWidth: '12rem'  }}></Column>

            <Column 
            field="declinedBy" 
            body={EmployeeByBodyTemplate} 
            header="Declined by" 
            style={{ minWidth: '12rem'  }}></Column>

            <Column 
            field="declinedAt" 
            body={updatedAtBodyTemplate} 
            header="Declined At"  
            style={{ minWidth: '12rem'  }}></Column>

            <Column 
            field="declinedAt" 
            body={updatedTimeBodyTemplate} 
            header="Declined Time"  
            style={{ minWidth: '12rem'  }}></Column>

            <Column 
            field="declineReasons" 
            body={reasonsBodyTemplate} 
            header="Declined Reasons" 
            style={{ minWidth: '248rem'  }}></Column>    
         
      </DataTable>
</>
)}
      </Box>
      </>
  )
}

export default NewApprovalReport