import React from 'react'
import { Tabs,
    TabList, 
    TabPanels, 
    Tab, 
    TabPanel, 
   Box,
   Breadcrumb,
   BreadcrumbItem,
   BreadcrumbLink,
   BreadcrumbSeparator,
   Flex,
   chakra,
   TabIndicator,

   } from '@chakra-ui/react'
   import { BsChevronRight, BsPerson } from 'react-icons/bs';
   import { NavLink, useNavigate } from 'react-router-dom'
import Shops from './Shops'
import Province from './Province'
import Zone from './Zone'
import Role from './Role';
import Region from './Region';

function SystemConfig() {
  return (
    <Box maxW="7xl" mx={'auto'} pt={1} px={{ base: 2, sm: 12, md: 17 }}>
    <Flex justifyContent="flex-end" mb={2}>
   <Breadcrumb fontSize={'xs'} fontWeight={'semibold'}  spacing='8px' separator={<BsChevronRight color='gray.500' />}>
   <BreadcrumbItem>
   <NavLink to={'/admin/operator'}>
<BreadcrumbLink >  Home</BreadcrumbLink>
</NavLink>
</BreadcrumbItem>
<BreadcrumbItem isCurrentPage>
<BreadcrumbLink href='#'>System Config</BreadcrumbLink>
</BreadcrumbItem>
</Breadcrumb>
</Flex>
<chakra.h1
    textAlign={'left'}
    fontSize={'2xl'}
    py={2}
    fontWeight={'bold'}>
System Configurations
  </chakra.h1>
    <Box bg={'white'}  pt={1} px={{ base: 2, sm: 8, md: 10 }}>
    <Tabs position="relative" variant="unstyled">
    <TabList>
      <Tab>Manage Province</Tab>
      <Tab>Manage Zones</Tab>
      <Tab>Manage Shops</Tab>
      <Tab>Manage Regions</Tab>
    </TabList>
    <TabIndicator
      mt="-1.5px"
      height="2px"
      bg="blue.500"
      borderRadius="1px"
    />
    <TabPanels>
      <TabPanel>
        <Province/>
      </TabPanel>
      <TabPanel>
        <Zone/>
      </TabPanel>
      <TabPanel>
        <Shops/>
      </TabPanel>
      <TabPanel>
        <Region />
      </TabPanel>
    </TabPanels>
  </Tabs>

    </Box>
</Box>
  )
}

export default SystemConfig