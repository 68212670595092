import {
    Container,
    Flex,
    Heading,
    Stack,
    Box,
    Card,
    CardBody,
    StackDivider,
    Text,
    HStack,
    Image,
    Button,
    Link,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
    Center,
    AspectRatio,
    Badge,
  } from '@chakra-ui/react'
  import React,{useState, useEffect} from 'react'
  import { BsChevronRight, BsFillLaptopFill, BsPerson, BsPhoneFill } from 'react-icons/bs';
  import { useLocation, useNavigate } from 'react-router'
  import { baseURL } from '../../api/baseUrl'
  import id2 from '../../images/id2.jpeg'
  import { NavLink } from 'react-router-dom';
  import img from '../../images/noimage1.png'
  import { FaRegImage, FaUserEdit } from 'react-icons/fa';
  import { MdArrowBack } from 'react-icons/md';
  import axios from '../../api/axios';
  import onemoney from '../../images/netmoney.jpg'

  const EMPLOYEE_ACC_URL = '/employee/profile/v1/get-employee-by-account-id/'
  const BRAND_ACC_URL = '/employee/brand-ambassador/v1/get-brand-ambassador-by-account-id'

  
  function OperatorTelcoView() {
  
  
    const navigate = useNavigate();
    const location = useLocation()
    const [details, setDetails] = useState(location.state)
    if (location.state == null) {
      //return to previous page
    }

      // console.log(details)
      const [accountId, setAccountId] = useState("");
      const [loading, setLoading] = useState(false);
      const [approverId, setApproverId] = useState("");
      const [ambassId, setAmbassID] = useState("");

    
      const getEmployee = async () => {
        setLoading(true);
        const token = localStorage.getItem("token");
        try {
          const response = await axios.get(EMPLOYEE_ACC_URL + `${details.createdBy}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
    
          if (response.status === 200) {
            setAccountId(response.data.body)
            // console.log(response.data.body)
            setLoading(false);
          }
        } catch (error) {
    
        }
      }
    
    
      useEffect(() => {
        getEmployee();
    
      }, [])

      
  const getApprover = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(EMPLOYEE_ACC_URL + `${details.approvedBy}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })

      if (response.status === 200) {
        setApproverId(response.data.body)
        // console.log(response.data.body)
        setLoading(false);
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    getApprover();

  }, [])

  const getAmbassadors = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${BRAND_ACC_URL}?accountId=${details.createdBy}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
  
      if (response.status === 200) {
        setAmbassID(response.data.body)
        // console.log(response.data.body)
        setLoading(false);
      }
    } catch (error) {
  
    }
  }
  
  useEffect(() => {
    getAmbassadors();
  }, [])
  
  
    const formatDOB = (dob) => {
      const year = dob?.slice(0, 4);
      const month = dob?.slice(4, 6);
      const day = dob?.slice(6, 8);
      
      const date = new Date(`${year}-${month}-${day}`);
      const formattedDate = date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    
      return formattedDate;
    };
    const dateOfBirth = details.dateOfBirth; // Replace with your input number
    const formattedDOB = formatDOB(dateOfBirth);
  
    const mydate = new Date(details.createdAt); // created at date format

    const formattedDate = mydate.toLocaleDateString();
    const formattedTime = mydate.toLocaleTimeString();
   
    return (
      <Container maxW={'full'} >
        <Flex justifyContent="flex-end">
          <Breadcrumb fontSize={'xs'} fontWeight={'semibold'} spacing='8px' separator={<BsChevronRight color='gray.500' />}>
            <BreadcrumbItem>
              <NavLink to={'/admin/operator'}>
                <BreadcrumbLink >  Home</BreadcrumbLink>
              </NavLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink href='#'>View Customer</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Flex>
        <Stack minH={'40vh'} direction={{ base: 'column', md: 'row' }}>
          <Flex p={2} flex={2}>
  
            <Box
              bg={'white'}
              borderRadius='lg'
              p={{ sm: 2, md: 2, lg: 8 }}
              m={2}
              width="100%"
            >
                 <Flex alignContent={'center'} justifyContent={'center'}>

                <Image 
                mt={'-5'}
                borderRadius='full'
                boxSize='120px'
                src={onemoney}

                alt='logo'


                />
                </Flex>
              <Heading size='md' textAlign={'center'}>Telco Customer Profile</Heading>
              <Box
                borderRadius={'lg'}
                p={2}
                m={2}
                minW={'200px'}
              >
  
                <Card >
                  <CardBody>
                    <Stack divider={<StackDivider />} spacing='4'>
  
                      <HStack spacing={16}>
  
                        <Box>
  
                          <Heading size='xs' textTransform='capitalize'>
                            First Name:
                          </Heading>
                          <Text pt='2' fontSize='sm'>
                            {details.firstName}
                          </Text>
                        </Box>
                        {/* <Box>
                          <Heading size='xs' textTransform='capitalize'>
                            Middle Name:
                          </Heading>
                          <Text pt='2' fontSize='sm'>
                            {details.middleName == null ? <Text color={'gray.400'}>Not Available</Text> : details.middleName}
                          </Text>
                        </Box> */}
                         <Box>
                          <Heading size='xs' textTransform='capitalize'>
                            Last Name:
                          </Heading>
                          <Text pt='2' fontSize='sm'>
                            {details.surNames == null  ? <Text color={'gray.400'} >Not Available</Text> : details.surNames }
                          </Text>
                        </Box>
  
                      </HStack>
                      <HStack spacing={16}>
                       
  
                        <Box>
                          <Heading size='xs' textTransform='capitalize'>
                            Phone Number
                          </Heading>
                          <Text pt='2' fontSize='sm'>
                            {details?.msisdn == null ? <Text color={'gray.400'} >Not Available</Text> : details.msisdn}
                          </Text>
                        </Box>
                      </HStack>
                      <Box>
                        <Heading size='xs' textTransform='capitalize'>
                          National ID Number:
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          {details.documentNbr == null ? <Text color={'gray.400'} >Not Available</Text> : details.documentNbr}
                        </Text>
                      </Box>
                      <Box>
                        <Heading size='xs' textTransform='capitalize'>
                          Address
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          {details.address == null ? <Text color={'gray.400'} >Not Available</Text> : details.address}
                        </Text>
                      </Box>
  
                      <HStack spacing={16}>
  
                        <Box>
                          <Heading size='xs' textTransform='capitalize'>
                            Date of Birth
                          </Heading>
                          <Text pt='2' fontSize='sm'>
                            {/* {formattedDOB} */}
                            {details?.birthday == null ? <Text color={'gray.400'} >Not Available</Text> : details.birthday}
                          </Text>
                        </Box>
                        <Box>
                        <Heading size='xs' textTransform='capitalize'>
                          Gender
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          {details.gender == null ? <Text color={'gray.400'}>Not Available</Text> : details.gender}
                        </Text>
                      </Box>
                      </HStack>
  
                     
                      <HStack spacing={16}>
  
                        <Box>
                          <Heading size='xs' textTransform='capitalize'>
                            City
                          </Heading>
                          <Text pt='2' fontSize='sm'>
                            {details.city == null ? <Text color={'gray.400'}>Not Available</Text> : details.city}
                          </Text>
                        </Box>
                        <Box>
                          <Heading size='xs' textTransform='capitalize'>
                            Province
                          </Heading>
                          <Text pt='2' fontSize='sm'>
                            {/* {details.user?.email} */}
                            {details.province == null ? <Text color={'gray.400'}>Not Available</Text> : details.province}
  
                          </Text>
                        </Box>
                      </HStack>
                      <Box>
                          <Heading size='xs' textTransform='capitalize'>
                            Country
                          </Heading>
                          <Text pt='2' fontSize='sm'>
                            {/* {details.user?.email} */}
                            {details.country == null ? <Text color={'gray.400'}>Not Available</Text> : details.country}
  
                          </Text>
                        </Box>
                      <Box>
                        <Heading size='xs' textTransform='capitalize'>
                          Signed Up Through
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          {/* {details.user?.signupPlatform} */}
                          {details.user?.signupPlatform === 'WEB_PORTAL' ? (<Button leftIcon={<BsFillLaptopFill />} colorScheme='linkedin' size='xs'>Web Portal</Button>) : details.user?.signupPlatform === 'MOBILE_PORTAL' ? (<Button leftIcon={<BsPhoneFill />} colorScheme='purple' size='xs'>Mobile Portal</Button>) : details.user?.signupPlatform === 'MOBILE-APP' ? (<Button leftIcon={<BsPhoneFill />} colorScheme='purple' size='xs'>Mobile App</Button>) : <Button leftIcon={<BsFillLaptopFill />} colorScheme='linkedin' size='xs'>Web Portal</Button>}
                        </Text>
                      </Box>
                      <Box>
                      <Heading size={'xs'} textTransform={'capitalize'}>
                        Created by:
                      </Heading>
                      <Text pt='2' fontSize='sm'>
                      {accountId?.firstName + accountId?.lastName ? `${accountId?.firstName} ${accountId?.lastName}`: ambassId?.firstName + ambassId?.lastName ? `${ambassId?.firstName} ${ambassId?.lastName}` :<Badge variant={'solid'} colorScheme='gray' size={'xs'}></Badge>} 

                      </Text>
                      {accountId?.employeeAccount?.roles[0].name === 'SUPER_ADMIN' ? (<Badge variant='solid' colorScheme='cyan'>Super Admin</Badge>) : accountId?.employeeAccount?.roles[0].name === 'ADMIN' ? (<Badge variant={'solid'} colorScheme={'yellow'} size={'xs'}>ADMIN</Badge>) : accountId?.employeeAccount?.roles[0].name === 'CLERK' ? (<Badge variant={'solid'} colorScheme={'orange'} size={'xs'}>Clerk</Badge>) : accountId?.employeeAccount?.roles[0].name === 'SUPPORT' ? (<Badge variant={'solid'} colorScheme={'purple'} size={'xs'}>Support</Badge>) : accountId?.employeeAccount?.roles[0].name === 'OPERATOR' ? (<Badge variant={'solid'} colorScheme={'red'} size={'xs'}>Operator</Badge>) : ambassId?.employeeAccount?.roles[0].name === 'BRAND_AMBASSADOR' ? (<Badge variant={'solid'} colorScheme={'linkedin'} size={'xs'}>Brand Ambassador</Badge>) : accountId?.employeeAccount?.roles[0].name === 'EXECUTIVE' ? (<Badge variant={'solid'} colorScheme={'linkedin'} size={'xs'}>Executive</Badge>) : details.user?.signupPlatform === 'MOBILE-APP' ? (<Badge variant={'solid'} colorScheme='purple' size={'xs'}>Self-Registration</Badge>): <Badge variant={'solid'} colorScheme='gray' size={'xs'}>Not Available</Badge>}
                    </Box>

                    {approverId && <>
                        
                       
                        <Box >
                          <Heading size={'xs'} textTransform={'capitalize'}>
                            Approved by:
                          </Heading>
                          <Text pt='2' fontSize='sm'>
                            {`${approverId?.firstName}  ${approverId?.lastName}` } 
    
                          </Text>
                              {approverId?.employeeAccount?.roles[0].name === 'APPROVER' ? (<Badge variant='solid' colorScheme='orange'>Approver</Badge>):<Badge variant={'solid'} colorScheme='gray' size={'xs'}>Not Available</Badge>}
                        </Box>
                        </>}
                      <Box >
                      <Heading size={'xs'} textTransform={'capitalize'}>
                        Created at:
                      </Heading>
                      <Badge variant='solid' colorScheme='gray'>{ formattedDate} : {formattedTime}</Badge>
                    </Box>
                    </Stack>
                  </CardBody>
                </Card>
  
              </Box>
            </Box>
          </Flex>
          <Flex p={2} flex={2}>
  
  
            <Box
              bg={'white'}
              borderRadius='lg'
              p={{ sm: 2, md: 2, lg: 8 }}
              m={2}
              width="100%"
            >
  
              <Heading size='md' m={4} textAlign='center'>Photo National ID</Heading>
  
              <Center>
                {details.nationalIdPictureUrl === null ? (
  
  
  
                  <Image
                    boxSize='25%'
                    width={'25%'}
                    objectFit='cover'
                    src={img}
                    rounded={'md'}
                    alt='image'
  
                  />
                ) :
                  (
                    <Image
                    boxSize='400px'
                    width={'95%'}
                      objectFit='cover'
                      src={baseURL + 'onboarding/customer/view-picture' + details.nationalIdPictureUrl}
                      rounded={'md'}
                      alt='image'
  
                    />
  
                  )}
              </Center>
              <Box m={2}>
              <Center>
                {details.nationalIdPictureUrl== null ? '' : (
              <a target={'_blank'} href={baseURL + 'onboarding/customer/view-picture' + details.nationalIdPictureUrl}><Button leftIcon={<FaRegImage />} colorScheme='orange'>View Image</Button></a>
  
                )}
              </Center>
              </Box>
           
  
             
              <Box mt={8}>
              {/* <Stack direction='column' spacing={4} align='center'm={4}>
                  <Button onClick={() => navigate(`/admin/operator-edit-customer`,{ state:details
                 })}colorScheme='yellow' variant='solid' leftIcon={<FaUserEdit/>}>
                  Edit
                  </Button>
              </Stack>   */}
                <Stack direction='column' spacing={4} align='center' m={4}>
                  <Button onClick={() => navigate('/admin/operator')} colorScheme='red' variant='solid' leftIcon={<MdArrowBack/>}>
                    Back
                  </Button>
                </Stack>
              </Box>
            </Box>
  
          </Flex>
        </Stack>
  
      </Container>
    )
  }
  
  export default OperatorTelcoView