import React, { useState, useEffect } from 'react'
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Stack,
    Box,
    chakra,
    Wrap,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
    Flex,
    Text,
    Center,
    useDisclosure,
    Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  ButtonGroup,
  Container,
  Heading,
  FormHelperText,
  HStack
  } from '@chakra-ui/react'
  import { FiUserPlus } from 'react-icons/fi'
  import { BsBank, BsChevronRight, BsPerson } from 'react-icons/bs';
  import { NavLink, useLocation, useNavigate } from 'react-router-dom'
  import axios from '../../../api/axios';
import { MdArrowBack, MdUpdate } from 'react-icons/md';


  const EDITPROVINCE_URL = '/employee/province/v1/update-province'

function EditProvince() {
    
    const location = useLocation()
  const [details, setDetails] = useState(location.state)
  if (location.state == null) {
    //return to previous page
  }
    const [editname, setEditName] = useState("");
    const toast = useToast();
    const navigate = useNavigate();

    const [ loading, setLoading] = useState(false);

    const handleedit = () => {
        //     setErrorMessage('')
        // const res = validate();
        //     if (!res) {
        //   console.log('validation failed')
        //   toast({
        //     title: 'Failed',
        //     description: "Form not complete",
        //     status: 'error',
        //     duration: 3000,
    
        //   });
        //   return;
        // }
            setLoading(true)
            const token = localStorage.getItem("token");
            axios.put(EDITPROVINCE_URL, {
                id:details.id,
                name: editname,
              
            },{
              headers: { 
                "Content-type": "application/json",
                'Authorization': `Bearer ${token}`
              }
              
            })
            .then(response => {
                // console.log(response)
                if (response.data.success) {
                    navigate('/admin/system-config')
                    toast({
                      title: 'Success',
                      description: response.data.message,
                      status: 'success',
                      duration: 6000,
                      
                    });
                    setLoading(false)
                  }
                
                else {
                  setLoading(false)
                  toast({
                    title: 'Failed',
                    description: response.message,
                    status: 'error',
                    duration: 3000,
                   
                  });
                }
            })
            .catch(error => {
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  toast({
                    title: "Error",
                    description: `Server responded with ${error.response.status}`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                  });
                  setLoading(false)
                } else if (error.request) {
                  // The request was made but no response was received
                  toast({
                    title: "Network Error",
                    description: "No response from server",
                    status: "error",
                    duration: 8000,
                    isClosable: true,
                  });
                  setLoading(false)
                } else {
                  // Something happened in setting up the request that triggered an Error
                  toast({
                    title: "Network Error",
                    description: `Error: ${error.message}`,
                    status: "error",
                    duration: 8000,
                    isClosable: true,
                  });
                 
                }
              })
        }
    
        useEffect(() => {
    
        }, [loading])
  return (
    <>
    <Flex justifyContent="flex-end">
    <Breadcrumb fontSize={'xs'} fontWeight={'semibold'}  spacing='8px' separator={<BsChevronRight color='gray.500' />}>
    <BreadcrumbItem>
    <NavLink to={'/admin/operator'}>
 <BreadcrumbLink >  Home</BreadcrumbLink>
</NavLink>
</BreadcrumbItem>
<BreadcrumbItem isCurrentPage>
<BreadcrumbLink href='#'>Manage Customer</BreadcrumbLink>
</BreadcrumbItem>
</Breadcrumb>
</Flex>
    <Container maxW={'7xl'}>
        
        <Center>
         <Box
            
         minH={'28vh'}
          borderRadius='lg' shadow='md' borderWidth='2px' bg='white'
          style={{
            width: "50%",
            justifyContent: "space-around",
            boxShadow: 50,
            marginTop: "200px",
          
           
          }}

        >

          
          <Stack spacing={5} marginLeft='10' marginRight='10' marginBottom='5'  >
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: '1xl', sm: '2xl', lg: '2xl' }}
              marginBottom='2'
              marginTop='5'
            >
              <Text as={'span'}>
                {' '}
                <Text
                  as={'span'}
                  color="black"
                  position={'relative'}
                >
                    Edit
                  <Text as={'span'} position={'relative'}>
                    {' '}Province
                  </Text>
                </Text>
              </Text>
            </Heading>
            <Text mt={2} fontStyle={'oblique'}  textAlign={'center'}>You're about to edit {details.name} Province ?</Text>
            
            <FormControl isRequired>
        
              <FormLabel htmlFor='editname'>Province name</FormLabel>
              <Input
                id='editname'
                placeholder='Province name'
                type='text'
                name='editname'
                value={editname}
                onChange={(e)=> setEditName(e.target.value)}
              />
            </FormControl>
         
         </Stack>
         <HStack mt={4} spacing={4} align={'center'} justify={'center'}>

            <Button 
            onClick={handleedit}
            isLoading={loading}
            loadingText='Loading'
            spinnerPlacement='end'
            borderRadius='md' bg='orange.400' color='white'
            w={'120px'}
            leftIcon={<MdUpdate/>}
            >
            Submit
            </Button>
            
            <Button
              leftIcon={<MdArrowBack/>}
              onClick={()=> navigate('/admin/system-config')}
              bg={'red'}
              color={'white'}
              w="120px"
              _hover={{
                bg: 'blue.500',
              }}
              
              >
                Back
              </Button>
              </HStack>
         </Box>
         </Center>
    </Container>
    </>
  )
}

export default EditProvince