import React from 'react'
import Config from './Config'
import { Box,chakra } from '@chakra-ui/react'

function AdminConfig() {
  return (
    <Box mt={8} >
         <chakra.h1
          textAlign={'left'}
          fontSize={'2xl'}
          py={5}
          fontWeight={'bold'}>
        BA registrations
        </chakra.h1>
        <Config />
    </Box>
  )
}

export default AdminConfig