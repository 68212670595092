import React, { useState, useEffect } from 'react'
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Stack,
    Box,
    chakra,
    Wrap,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
    Flex,
    Text,
    Center,
    useDisclosure,
    Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  useToast,
  } from '@chakra-ui/react'
  import { FiUserPlus } from 'react-icons/fi'
  import { BsBank, BsChevronRight, BsPerson } from 'react-icons/bs';
  import { NavLink, useNavigate } from 'react-router-dom'
  import axios from '../../api/axios'

  const BUNDLETYPE_URL = 'mobile-api/config/bundle-type/'
  const GETBUNDLETYPE_URL = 'mobile-api/config/bundle-types'
  const DELETEBUNDLETYPE_URL = 'mobile-api/config/bundle-type/'

function BundleType() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const initialRef = React.useRef(null);

    const toast = useToast();
    const navigate = useNavigate();

    const [bundleTypeName, setBundleTypeName] = useState('');

    const [ loading, setLoading] = useState(false);
    const [nameError, setNameError] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const handlesubmit = () => {
        setErrorMessage('')
    const res = validate();
        if (!res) {
      // console.log('validation failed')
      toast({
        title: 'Failed',
        description: "Form not complete",
        status: 'error',
        duration: 3000,

      });
      return;
    }
        setLoading(true)
        const token = localStorage.getItem("token");
        axios.post(BUNDLETYPE_URL + `${bundleTypeName}`, {
            bundleTypeName,
          
        },{
          headers: { 
            "Content-type": "application/json",
            'Authorization': `Bearer ${token}`
          }
          
        })
        .then(response => {
          
            if (response.data.success) {
                navigate('/admin/config')
                getBundleType();
                onClose();
                toast({
                  title: 'Success',
                  description: response.data.message,
                  status: 'success',
                  duration: 6000,
                  
                });
                setLoading(false)
              }
            
            else {
              setLoading(false)
              toast({
                title: 'Failed',
                description: response.message,
                status: 'error',
                duration: 3000,
               
              });
            }
        })
        .catch(error => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              toast({
                title: "Error",
                description: `Server responded with ${error.response.status}`,
                status: "error",
                duration: 5000,
                isClosable: true,
              });
              setLoading(false)
            } else if (error.request) {
              // The request was made but no response was received
              toast({
                title: "Network Error",
                description: "No response from server",
                status: "error",
                duration: 8000,
                isClosable: true,
              });
              setLoading(false)
            } else {
              // Something happened in setting up the request that triggered an Error
              toast({
                title: "Network Error",
                description: `Error: ${error.message}`,
                status: "error",
                duration: 8000,
                isClosable: true,
              });
             
            }
          })
    }
    useEffect(() => {

    }, [loading])

    const validate =() => {
        if (!bundleT) {
            setNameError('Enter currency name')
            return false;
          }
          return true;
    }

    const [bundleT, setBundleT] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [totalPages, setTotalPages] = useState(1);

    const getBundleType = async () => {
        setLoading(true);
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`${GETBUNDLETYPE_URL}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
  
        if(response.status === 200) {
          setBundleT(response.data.body)
          setLoading(false);
        }
      } catch (error) {
      
      }
    }
  

    useEffect(() => {
        getBundleType();
      
      }, [])
      
      const handleDelete = async (id) => {
        setLoading(true)
        const token = localStorage.getItem("token");
        try {
          
          const response = await axios.delete(DELETEBUNDLETYPE_URL + `${id}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          if(response.data.success) {
            getBundleType()
            toast({
              title: 'Success',
              description: "Deleted",
              status: 'success',
              duration: 6000,
              
            });
            setLoading(false)
          
          }else{
            toast({
              title: 'Failed',
              description: "Failed to Delete",
              status: 'error',
              duration: 6000,
              
            });
            setLoading(false)
          }
        } catch (error) {
          
        }
      };
  
      useEffect(() => {
        setTotalPages(Math.ceil(bundleT.length / itemsPerPage));
      }, [bundleT]);
      
      const paginateData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return bundleT.slice(startIndex, endIndex);
      };
      
      const handlePageChange = (page) => {
        setCurrentPage(page);
      }
      
      const paginatedData = paginateData(bundleT, currentPage, itemsPerPage);


  return (
    <Box maxW="4xl" >
       {/* <chakra.h1
        textAlign={'left'}
        fontSize={'1xl'}
        py={5}
        fontWeight={'bold'}>
   Bank List
      </chakra.h1> */}
      <Stack direction='row' spacing={4}>
  <Button onClick={onOpen}  leftIcon={<BsBank/>} colorScheme='orange' variant='solid'>
    Add Bundle Type
  </Button>
  <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create BundleType:</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>BundleType name:</FormLabel>
              <Input type='text'  placeholder='BundleType Name' onChange={(e) => setBundleTypeName(e.target.value)}  onFocus={()=> {setNameError('')}} borderColor={nameError ? 'red.400' : 'gray.300'} />
              <Text color={'red'}>{nameError}</Text>
            </FormControl>
            {
              errorMessage ? <Text color={'red'}>{errorMessage}</Text> : <></>
            }
          </ModalBody>

          <ModalFooter>
            <Button 
           onClick={handlesubmit} 
           isLoading={loading}
           loadingText='Loading'
           spinnerPlacement='start'
           colorScheme='orange' mr={3}>
             Save
           </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  </Stack>
    <Stack p="3" bg='white' boxShadow="lg" m="4" borderRadius="sm">
    <TableContainer>
  <Center>

  <Table variant='simple' >
    <TableCaption> 
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
    
    <Button
    colorScheme={'orange'}
    m={1}
    key={page}
    onClick={() => handlePageChange(page)}
    color={page === currentPage ? 'black' : 'white'}
    cursor="pointer"

    >
            {page}
          </Button>
        ))}
        </TableCaption>
    <Thead>
      <Tr>
        <Th>#</Th>
        <Th>Bundle Type</Th>
        <Th>Action</Th>
      </Tr>
    </Thead>
    <Tbody>
    {paginatedData.map((data,index) =>{
      return(
      <Tr key={index}>
      <Td>{index +1}</Td>
      <Td>{data.name == null ? '': data.name}</Td>
      <Td>
        <Wrap spacing={2} direction='row' align='center'>
          {/* <Button  colorScheme='gray' size='xs'>View</Button> */}
          <Button onClick={() => handleDelete(data.id)}  colorScheme='red' size='xs'>Delete</Button>
        </Wrap>
      </Td>
    </Tr>
    )
  })}
    </Tbody>
    

    <Tfoot>
  
    </Tfoot>
    
  </Table>
  </Center>
  <Flex justifyContent={'flex-end'} mr={10} >

 
        </Flex>
</TableContainer>

    </Stack>
    </Box>
  )
}

export default BundleType