import {
  Container,
  Flex,
  Heading,
  Stack,
  Box,
  VStack,
  FormControl,
  Input,
  FormLabel,
  Card,
  CardBody,
  CardHeader,
  StackDivider,
  Text,
  HStack,
  Textarea,
  Image,
  Button,
  useToast,
  Center,
  Select,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Badge,

} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useState } from 'react'
import axios from '../../api/axios'
import { baseURL } from '../../api/baseUrl'
import { Link,NavLink } from 'react-router-dom'
import { BsChevronRight, BsPerson, BsFillLaptopFill, BsPhoneFill } from 'react-icons/bs';
import img from '../../images/noimage1.png'
import oneMoney from '../../images/c2.png'


const VERIFYCUST_URL = '/onboarding/operator/verify-customer-profile';

function ManageApproval() {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState('')
  const [declined, setDeclined] = useState('DECLINED')
  const [reason, setReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [showReasonInput, setShowReasonInput] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  
  const {isOpen, onOpen, onClose} = useDisclosure()
  const cancelRef = React.useRef();

  const { isOpen: isDELOpen, onOpen: onDELOpen, onClose: onDELClose } = useDisclosure();
  const cancelDEL = React.useRef()

  const location = useLocation()
  const [details, setDetails] = useState(location.state)
  const [errorMessage, setErrorMessage] = useState('')


  useEffect(() => {
    if(reason === 'other') {
      setShowReasonInput(true)
    } else {
      setShowReasonInput(false)
    }
  }, [reason])

  useEffect(() => {

  }, [loading])

  if (location.state == null) {
    //return to previous page
  }

  const handleapprove = (status) => {
    // e.preventDefault();
    setErrorMessage('')

    const res = validate();
    if (!res) {
      console.log(`validation failed`)
      toast({
        title: 'Failed',
        description: `Check for errors`,
        status: 'error',
        duration: 3000,

      });
      return;
    }
    setLoading(true)
    const token = localStorage.getItem("token");
    axios.post(VERIFYCUST_URL, {
      profileId: details.id,
      reason: reason === 'other' ? otherReason : reason,
      verificationStatus: status

    }, {
      headers: {
        "Content-type": "application/json",
        'Authorization': `Bearer ${token}`
      }
    }

    ).then(response => {
        if (response.data.success) {
          let user = localStorage.getItem('user')
          user = JSON.parse(user);
          console.log(user.roles[0].name)
          if (user.roles[0].name === 'EXECUTIVE') {
            navigate('/admin/exec-dashboard');
          }else if (user.roles[0].name === 'ADMIN'){
            navigate('/admin/home-dashboard')
          }else if (user.roles[0].name === 'SUPER_ADMIN'){
            navigate('/admin/super-home')
          }else{
            navigate('/admin/operator')
          }
          toast({
            title: 'Success.',
            description: 'Processed successfully',
            status: 'success',
            duration: 6000,
            
          });
          setLoading(false)
        }

        else {
          setLoading(false)
          toast({
            title: 'Failed',
            description: 'Something went wrong',
            status: 'error',
            duration: 6000,

          });
        }
      })
      .catch(error => {

        if (error.response.status === 400) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast({
            title: "OneMoney Verification Failed",
            description: ` ${error.response.data.body.message}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          setLoading(false)
        } else if (error.request) {
          // The request was made but no response was received
          toast({
            title: "Network Error",
            description: "Please Check your Internet Connection",
            status: "error",
            duration: 8000,
            isClosable: true,
          });
          setLoading(false)
        } else {
          // Something happened in setting up the request that triggered an Error
          toast({
            title: "Network Error",
            description: `Error: ${error.message}`,
            status: "error",
            duration: 8000,
            isClosable: true,
          });
         
        }
      })
  }
  const date = new Date(details.createdAt);
  const formattedDate = date.toLocaleDateString();
  const formattedTime = date.toLocaleTimeString();

  const formatDOB = (dob) => {
    const year = dob?.slice(0, 4);
    const month = dob?.slice(4, 6);
    const day = dob?.slice(6, 8);

    const mydate = new Date(`${year}-${month}-${day}`);
    const formattedDate = mydate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

    return formattedDate;
  };
  const dateOfBirth = details.dateOfBirth; // Replace with your input number

  const formattedDOB = formatDOB(dateOfBirth);
  
  const [reaError, setReaError] = useState("");
  const [otherError, setOtherError] =  useState("");

  const validate = () => {
   


    if (!otherReason) {
      setOtherError('please write a reason why you declined')
    }
  
    return true

  }


  return (
    <Container maxW={'full'} >
         <Flex justifyContent="flex-end">
       <Breadcrumb fontSize={'xs'} fontWeight={'semibold'}  spacing='8px' separator={<BsChevronRight color='gray.500' />}>
       <BreadcrumbItem>
       <NavLink to={'/admin/home-dashboard'}>
    <BreadcrumbLink >  Home</BreadcrumbLink>
  </NavLink>
  </BreadcrumbItem>
<BreadcrumbItem isCurrentPage>
  <BreadcrumbLink href='#'>Approval </BreadcrumbLink>
</BreadcrumbItem>
</Breadcrumb>
</Flex>
      <Stack minH={'40vh'} direction={{ base: 'column', md: 'row' }}>
        
      <Flex p={2} flex={2}>

<Box
  bg={'white'}
  borderRadius='lg'
  p={{ sm: 2, md: 2, lg: 8 }}
  m={2}
  // width="100%"
  minW={'30vw'}
>
  <Flex alignContent={'center'} justifyContent={'center'}>

    <Image
      mt={'-5'}
      borderRadius='full'
      boxSize='120px'
      src={oneMoney}

      alt='logo'


    />
  </Flex>
  <Heading size='md' textAlign={'center'} mb={2} > OneMoney Customer Profile</Heading>
  <Box
    borderRadius={'lg'}
    p={2}
    m={2}
    minW={'200px'}
  >

    <Card >
      <CardBody>
        <Stack divider={<StackDivider />} spacing='4'>

          <HStack spacing={16}>

            <Box>

              <Heading size='xs' textTransform='capitalize'>
                First Name:
              </Heading>
              <Text pt='2' fontSize='sm'>
                {details.firstName}
              </Text>
            </Box>
            <Box>
              <Heading size='xs' textTransform='capitalize'>
                Middle Name:
              </Heading>
              <Text pt='2' fontSize='sm'>
                {details.middleName == null ? <Text color={'gray.400'}>Not Available</Text> : details.middleName}
              </Text>
            </Box>

          </HStack>
          <HStack spacing={16}>
            <Box>
              <Heading size='xs' textTransform='capitalize'>
                Last Name:
              </Heading>
              <Text pt='2' fontSize='sm'>
                {details.lastName ? details.lastName : details.surNames}
              </Text>
            </Box>

            <Box>
              <Heading size='xs' textTransform='capitalize'>
                Phone Number
              </Heading>
              <Text pt='2' fontSize='sm'>
                {details?.phoneNumber ? details.phoneNumber : details.msisdn}
              </Text>
            </Box>
          </HStack>
          <Box>
            <Heading size='xs' textTransform='capitalize'>
              National ID Number:
            </Heading>
            <Text pt='2' fontSize='sm'>
              {details.user?.nationalIdNumber ? details.user.nationalIdNumber : details.documentNbr}
            </Text>
          </Box>
          <Box>
            <Heading size='xs' textTransform='capitalize'>
              Address
            </Heading>
            <Text pt='2' fontSize='sm'>
              {details.address == null ? <Text color={'gray.400'} >Not Available</Text> : details.address}
            </Text>
          </Box>

          <HStack spacing={16}>

            <Box>
              <Heading size='xs' textTransform='capitalize'>
                Date of Birth
              </Heading>
              <Text pt='2' fontSize='sm'>
                {/* {formattedDOB} */}
                {details.dateOfBirth ? formattedDOB : details.birthday}
              </Text>
            </Box>
            <Box>
              <Heading size='xs' textTransform='capitalize'>
                Citizenship
              </Heading>
              <Text pt='2' fontSize='sm'>
                {details.citizenship == null ? <Text color={'gray.400'}>Not Available</Text> : details.citizenship}
              </Text>
            </Box>
          </HStack>

          <Box>
            <Heading size='xs' textTransform='capitalize'>
              Gender
            </Heading>
            <Text pt='2' fontSize='sm'>
              {details.gender == null ? <Text color={'gray.400'}>Not Available</Text> : details.gender}
            </Text>
          </Box>
          <HStack spacing={16}>

            <Box>
              <Heading size='xs' textTransform='capitalize'>
                Username
              </Heading>
              <Text pt='2' fontSize='sm'>
                {details.user?.username == null ? <Text color={'gray.400'}>Not Available</Text> : details.user?.username}
              </Text>
            </Box>
            <Box>
              <Heading size='xs' textTransform='capitalize'>
                Email
              </Heading>
              <Text pt='2' fontSize='sm'>
                {/* {details.user?.email} */}
                {details.user?.email == null ? <Text color={'gray.400'}>Not Available</Text> : details.user?.email}

              </Text>
            </Box>
          </HStack>
          <Box>
            <Heading size='xs' textTransform='capitalize'>
              Signed Up Through
            </Heading>
            <Text pt='2' fontSize='sm'>
              {/* {details.user?.signupPlatform} */}
              {details.user?.signupPlatform === 'WEB_PORTAL' ? (<Button leftIcon={<BsFillLaptopFill />} colorScheme='linkedin' size='xs'>Web Portal</Button>) : details.user?.signupPlatform === 'MOBILE_PORTAL' ? (<Button leftIcon={<BsPhoneFill />} colorScheme='purple' size='xs'>Mobile Portal</Button>) : details.user?.signupPlatform === 'MOBILE-APP' ? (<Button leftIcon={<BsPhoneFill />} colorScheme='purple' size='xs'>Mobile App</Button>) : <Button leftIcon={<BsFillLaptopFill />} colorScheme='linkedin' size='xs'>Web Portal</Button>}
            </Text>
          </Box>
         
          <Box >
            <Heading size={'xs'} textTransform={'capitalize'}>
              Created at:
            </Heading>
            <Badge variant='solid' colorScheme='gray'>{formattedDate} : {formattedTime}</Badge>
          </Box>

        </Stack>
      </CardBody>
    </Card>
   
  </Box>
</Box>
</Flex>
        
        <Flex p={2} flex={2}>


          <Box
            bg={'white'}
            borderRadius='lg'
            p={{ sm: 2, md: 2, lg: 8 }}
            m={2}
            width="100%"
          >
            <Heading size='md' m={2} textAlign='center'>Photo National ID</Heading>
            <Center>

            {details.nationalIdPictureUrl === null ? (
              
              <Image
              boxSize='25%'
              width={'25%'}
              objectFit='cover'
              src={img}
              rounded={'md'}
              alt='image'
              
              />) : 
              (<Image
                boxSize='400px'
                width={'95%'}
                objectFit='cover'
                src={baseURL + 'onboarding/customer/view-picture' + details.nationalIdPictureUrl}
                rounded={'md'}
                alt='image'
                
                />
                )}
                </Center>
           <Box m={2}>
            <Center>
              {details.pictureUrl == null ? '' : (
            <Link target={'_blank'} to={baseURL + 'onboarding/customer/view-picture' + details.nationalIdPictureUrl}><Button>View Image</Button></Link>

              )}
            </Center>
           </Box>
           

           <Heading size='md' m={2} textAlign='center'>Photo holding National ID</Heading>
           <Center>

            {details.pictureUrl === null ? (
              
              <Image
              boxSize='25%'
              width={'25%'}
              objectFit='cover'
              src={img}
              rounded={'md'}
              alt='image'
              
              />) : 
              (<Image
                boxSize='400px'
                width={'95%'}
                objectFit='cover'
                src={baseURL + 'onboarding/customer/view-picture' + details.pictureUrl}
                rounded={'md'}
                alt='image'
                
                />
                )}
                </Center>
           <Box m={2}>
            <Center>
              {details.pictureUrl == null ? '' : (
                 <Link target={'_blank'} to={baseURL + 'onboarding/customer/view-picture' + details.pictureUrl}><Button>View Image</Button></Link>
              )}
           
            </Center>
           </Box>
            <Box mt={8}>

              {/* <FormControl id="reason">
                <FormLabel>Reason</FormLabel>
                <Textarea
                  type='text'
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  borderColor="gray.300"
                  _hover={{
                    borderRadius: 'gray.300',
                  }}
                  placeholder="message"
                  width='100%'
                />
              </FormControl> */}
              <Text fontFamily={'heading'} fontWeight={'bold'}>NB: Make sure you double check customer KYC before either Approving or Declining.</Text>
  
              <Stack direction='column' spacing={4} align='center' m={4}>
                <Button   onClick={onOpen}
                  // onChange={(e) => setVerificationStatus('APPROVED')}
                  // isLoading={loading}
                  // loadingText='Loading'
                  // spinnerPlacement='start'
                  colorScheme='green'
                  variant='solid'>
                  Approve
                </Button>
                <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
              >
              <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Approve Customer</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
           Are you sure you want proceed?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button colorScheme={'red'} ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Button 
           onClick={() => handleapprove('APPROVED')}
           onChange={(e) => setVerificationStatus('APPROVED')}
            isLoading={loading}
            loadingText='Loading'
            spinnerPlacement='start'
            colorScheme='green' ml={3}>
              Continue
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
                  </AlertDialog>

              <Select onChange={(e)=> setReason(e.target.value)}  
              // onFocus={() => { setReaError('') }} borderColor={reaError ? 'red.400' : 'gray.300'}
              borderColor={'red'}
                      _hover={{
                      borderRadius: 'red',
                  }} 
                  placeholder='Select Reason to Decline'>
                <option value='picture of your National ID is not clear'>1. Picture of your National ID is not clear </option>
                <option value='your picture is not clear'>2. Your picture is not clear</option>
                <option value='incorrectly entered National ID Number'>3.Your National ID number does not match</option>
                <option value ='date of birth is does not match'>4. Your Date of birth does not match</option>
                <option value='upload the correct pictures'>5.Upload the correct pictures</option>
                <option value='other'>Other</option>
                

              </Select>
              {/* <Text color={'red'}>{reaError}</Text> */}
              
                {
                showReasonInput  && 
                
              
                <FormControl  id="reason">
                <FormLabel> Other Reason</FormLabel>
                <Textarea
                  type='text'
                  value={otherReason}
                  onChange={(e) => setOtherReason(e.target.value)}
                  _hover={{
                    borderRadius: 'gray.300',
                  }}
                  onFocus={() => { setOtherError('') }} borderColor={otherError ? 'red.400' : 'gray.300'}

                  placeholder="message"
                  width='100%'
                />
                              <Text color={'red'}>{otherError}</Text>

              </FormControl>

              
    
            }
            {/* {
              errorMessage ? <Text color={'red'}>{errorMessage}</Text> : <></>
            } */}

            {reason && 
            
           
            <Button
             onClick={onDELOpen}
            //  onClick={() => handleapprove('DECLINED')}
            //  onChange={(e) => setVerificationStatus('DECLINED')}
            //   isLoading={loading}
            //   loadingText='Loading'
            //   spinnerPlacement='start'
              colorScheme='red' variant='solid'>
              Decline
            </Button>
             } 

          
                <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelDEL}
                onClose={onDELClose}
                isOpen={isDELOpen}
                isCentered
              >
              <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Decline Customer</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
           Are you sure you want proceed?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button colorScheme={'gray'} ref={cancelDEL} onClick={onDELClose}>
              No
            </Button>
            <Button 
            onClick={() => handleapprove('DECLINED')}
            onChange={(e) => setVerificationStatus('DECLINED')}
            isLoading={loading}
            loadingText='Loading'
            spinnerPlacement='start'
            colorScheme='red' ml={3}>
              Decline
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
                  </AlertDialog>
              </Stack>
            </Box>
          </Box>

        </Flex>
      </Stack>

    </Container>
  )
}

export default ManageApproval