import React, { useState, useEffect } from 'react'
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Stack,
    Box,
    chakra,
    Wrap,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
    Flex,
    Text,
    Center,
    useDisclosure,
    Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  ButtonGroup,
  } from '@chakra-ui/react'
  import { FiUserPlus } from 'react-icons/fi'
  import { BsBank, BsChevronRight, BsPerson } from 'react-icons/bs';
  import { NavLink, useNavigate } from 'react-router-dom'
  import axios from '../../../api/axios';

  const ADDPROVINCE_URL = '/employee/province/v1/create-province'
  const GETPROVINCE_URL = '/employee/province/v1/get-provinces'
  const EDITPROVINCE_URL = '/employee/province/v1/update-province'


function Province() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenEditProv, onOpen: onOpenEditProv, onClose: onCloseEditProv } = useDisclosure();
    const initialRef = React.useRef(null);
    const [editname, setEditName] = useState("");
  


    const toast = useToast();
    const navigate = useNavigate();

    const [name, setName] = useState('');

    const [ loading, setLoading] = useState(false);
    const [nameError, setNameError] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const handlesubmit = () => {
    //     setErrorMessage('')
    // const res = validate();
    //     if (!res) {
    //   console.log('validation failed')
    //   toast({
    //     title: 'Failed',
    //     description: "Form not complete",
    //     status: 'error',
    //     duration: 3000,

    //   });
    //   return;
    // }
        setLoading(true)
        const token = localStorage.getItem("token");
        axios.post(ADDPROVINCE_URL, {
          name,
          
        },{
          headers: { 
            "Content-type": "application/json",
            'Authorization': `Bearer ${token}`
          }
          
        })
        .then(response => {
            // console.log(response)
            if (response.data.success) {
                navigate('/admin/system-config')
                getProvinces();
                onClose();
                toast({
                  title: 'Success',
                  description: response.data.message,
                  status: 'success',
                  duration: 6000,
                  
                });
                setLoading(false)
              }
            
            else {
              setLoading(false)
              toast({
                title: 'Failed',
                description: response.message,
                status: 'error',
                duration: 3000,
               
              });
            }
        })
        .catch(error => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              toast({
                title: "Error",
                description: `Server responded with ${error.response.status}`,
                status: "error",
                duration: 5000,
                isClosable: true,
              });
              setLoading(false)
            } else if (error.request) {
              // The request was made but no response was received
              toast({
                title: "Network Error",
                description: "No response from server",
                status: "error",
                duration: 8000,
                isClosable: true,
              });
              setLoading(false)
            } else {
              // Something happened in setting up the request that triggered an Error
              toast({
                title: "Network Error",
                description: `Error: ${error.message}`,
                status: "error",
                duration: 8000,
                isClosable: true,
              });
             
            }
          })
    }

    const handleedit = () => {
    //     setErrorMessage('')
    // const res = validate();
    //     if (!res) {
    //   console.log('validation failed')
    //   toast({
    //     title: 'Failed',
    //     description: "Form not complete",
    //     status: 'error',
    //     duration: 3000,

    //   });
    //   return;
    // }
        setLoading(true)
        getProvinces();
        const token = localStorage.getItem("token");
        axios.put(EDITPROVINCE_URL, {
            id:prov.id,
            name: editname,
          
        },{
          headers: { 
            "Content-type": "application/json",
            'Authorization': `Bearer ${token}`
          }
          
        })
        .then(response => {
            // console.log(response)
            if (response.data.success) {
                navigate('/admin/system-config')
                getProvinces();
                onClose();
                toast({
                  title: 'Success',
                  description: response.data.message,
                  status: 'success',
                  duration: 6000,
                  
                });
                setLoading(false)
              }
            
            else {
              setLoading(false)
              toast({
                title: 'Failed',
                description: response.message,
                status: 'error',
                duration: 3000,
               
              });
            }
        })
        .catch(error => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              toast({
                title: "Error",
                description: `Server responded with ${error.response.status}`,
                status: "error",
                duration: 5000,
                isClosable: true,
              });
              setLoading(false)
            } else if (error.request) {
              // The request was made but no response was received
              toast({
                title: "Network Error",
                description: "No response from server",
                status: "error",
                duration: 8000,
                isClosable: true,
              });
              setLoading(false)
            } else {
              // Something happened in setting up the request that triggered an Error
              toast({
                title: "Network Error",
                description: `Error: ${error.message}`,
                status: "error",
                duration: 8000,
                isClosable: true,
              });
             
            }
          })
    }

    useEffect(() => {

    }, [loading])

    // const validate =() => {
    //     if (!name) {
    //         setNameError('Enter currency name')
    //         return false;
    //       }
    //       return true;
    // }

    const [prov, setProv] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [totalPages, setTotalPages] = useState(1);
    // const [getBanks, setGetBanks] = useState('');
    // const [getBan, setUpdateBank] = useState('')

    const getProvinces = async () => {
        setLoading(true);
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`${GETPROVINCE_URL}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
  
        if(response.status === 200) {
          setProv(response.data.body)
          console.log(response.data.body)
          setLoading(false);
        }
      } catch (error) {
      
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // toast({
          //   title: "Error",
          //   description: `Server responded with ${error.message}`,
          //   status: "error",
          //   duration: 5000,
          //   isClosable: true,
          // });
         
        } else if (error.request) {
          // The request was made but no response was received
          toast({
            title: "Network Error",
            description: "Please Check your Internet Connection",
            status: "error",
            duration: 8000,
            isClosable: true,
          });
       
        } else {
          // Something happened in setting up the request that triggered an Error
          toast({
            title: "Error",
            description: `Error:`,
            status: "error",
            duration: 8000,
            isClosable: true,
          });
         
        }
      }
    }
  

    useEffect(() => {
       
          getProvinces();      
      }, [])

      useEffect(() => {
        setTotalPages(Math.ceil(prov.length / itemsPerPage));
      }, [prov]);
      
      const paginateData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return prov.slice(startIndex, endIndex);
      };
      
      const handlePageChange = (page) => {
        setCurrentPage(page);
      }
      
      const paginatedData = paginateData(prov, currentPage, itemsPerPage);

//edit provinces


  return (
    <Box maxW="4xl" >
       {/* <chakra.h1
        textAlign={'left'}
        fontSize={'1xl'}
        py={5}
        fontWeight={'bold'}>
   Bank List
      </chakra.h1> */}
      <Stack direction='row' spacing={4}>
  <Button onClick={onOpen}  leftIcon={<BsBank/>} colorScheme='orange' variant='solid'>
    Add Province
  </Button>
  <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Province</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel> Province Name:</FormLabel>
              <Input type='text' value={name}  placeholder='Province name' onChange={(e) => setName(e.target.value)}  onFocus={()=> {setNameError('')}} borderColor={nameError ? 'red.400' : 'gray.300'} />
              <Text color={'red'}>{nameError}</Text>
            </FormControl>

            {
              errorMessage ? <Text color={'red'}>{errorMessage}</Text> : <></>
            }
          </ModalBody>

          <ModalFooter>
            <Button 
           onClick={handlesubmit} 
           isLoading={loading}
           loadingText='Loading'
           spinnerPlacement='start'
           colorScheme='orange' mr={3}>
             Save
           </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  </Stack>
    <Stack p="3" bg='white' boxShadow="lg" m="4" borderRadius="sm" width={'1100px'}>
    <TableContainer>
  <Center>

  <Table variant='simple' >
    <TableCaption> 
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
    
    <Button
    colorScheme={'orange'}
    m={1}
    key={page}
    onClick={() => handlePageChange(page)}
    color={page === currentPage ? 'black' : 'white'}
    cursor="pointer"

    >
            {page}
          </Button>
        ))}
        </TableCaption>
    <Thead>
      <Tr>
        <Th>#</Th>
        <Th>Province name</Th>
        <Th>Action</Th>
      </Tr>
    </Thead>
    <Tbody>
    {paginatedData.map((data,index) =>{
      return(
      <Tr key={data.id}>
      <Td>{index +1}</Td>
      <Td>{data.name == null ? '': data.name }</Td>
      <Td>
        <Wrap spacing={2} direction='row' align='center'>
          

           <Button onClick={() => navigate(`/admin/system-config-editprov`,{
                state:data
              })} colorScheme='yellow' size='xs'>Edit</Button>
            
        </Wrap>
      

      </Td>
    </Tr>
    )
  })}
    </Tbody>
    

    <Tfoot>
  
    </Tfoot>
    
  </Table>
  </Center>
</TableContainer>

    </Stack>
    </Box>
  )
}

export default Province