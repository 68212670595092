import React, { useState, useEffect } from 'react'
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Stack,
    Box,
    chakra,
    Wrap,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
    Flex,
    Text,
    Center,
    useToast,
    useDisclosure,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Badge,
    InputGroup,
    InputLeftElement,
    Input,
    FormControl,
    Spinner,
  } from '@chakra-ui/react'
  import { FiUserPlus,FiSearch } from 'react-icons/fi'
  import { BsChevronRight, BsPerson } from 'react-icons/bs';
  import { NavLink, useNavigate } from 'react-router-dom'
  import axios from '../../api/axios'
import { MdDelete, MdPreview } from 'react-icons/md';

// const GETUSER_URL = 'auth/employee/get-employees';
const GETUSER_URL = '/employee/profile/v1/get-employees?availability=PRESENT'
const DELETEUSER_URL = '/employee/profile/v1/delete-employee/'
 

export default function ManageUser() {
  const [user, setUsers] = useState([]);
const [loading, setLoading] = useState(false)
const [currentPage, setCurrentPage] = useState(1);
const [itemsPerPage, setItemsPerPage] = useState(50);
const [totalPages, setTotalPages] = useState(1);
const toast = useToast();
const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef()
    const [open,setOpen] = useState(false)
    const [currentData,setCurrentData] = useState()


const getUsers = async () => {
  setLoading(true);
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(`${GETUSER_URL}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })

    if(response.status === 200) {
      setUsers(response.data.body)
      setLoading(false);
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // toast({
      //   title: "Error",
      //   description: `Server responded with ${error.message}`,
      //   status: "error",
      //   duration: 5000,
      //   isClosable: true,
      // });
     
    } else if (error.request) {
      // The request was made but no response was received
      toast({
        title: "Network Error",
        description: "Please Check your Internet Connection",
        status: "error",
        duration: 8000,
        isClosable: true,
      
      });
   
    } else {
      // Something happened in setting up the request that triggered an Error
      toast({
        title: "Error",
        description: `Error:`,
        status: "error",
        duration: 8000,
        isClosable: true,
      });
     
    }
  }
}

useEffect(() => {

  getUsers();

}, [])

const handleDelete = async (data) => {
  setLoading(true)
  const token = localStorage.getItem("token");
  try {
    
    const response = await axios.delete(DELETEUSER_URL + `${data.id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    if(response.data.success) {
      getUsers();
      onClose();
      toast({
        title: 'Success',
        description: "Deleted",
        status: 'success',
        duration: 6000,
        
      });
      setLoading(false)
    
    }else{
      toast({
        title: 'Failed',
        description: "Failed to Delete",
        status: 'error',
        duration: 6000,
        
      });
      setLoading(false)
    }
    setOpen(false)
  } catch (error) {
    
  }
};
  
useEffect(() => {
  setTotalPages(Math.ceil(user.length / itemsPerPage));
}, [user]);

const paginateData = (user, currentPage,itemsPerPage) => {
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  return user.slice(startIndex, endIndex);
};

const handlePageChange = (page) => {
  setCurrentPage(page);
}

const paginatedData = paginateData(user, currentPage, itemsPerPage);

  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  console.log(search)
  return (
    <>
      <Stack direction='row' spacing={4}>
      <AlertDialog
        isOpen={open}
        leastDestructiveRef={cancelRef}
        onClose={()=>{setOpen(false)}}
      >

          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              <Text>
                Delete {currentData?.firstName}
              </Text>
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={()=>{setOpen(false)}}>
                Cancel
              </Button>
              <Button onClick={() => handleDelete(currentData)} colorScheme='red'  ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>

      </AlertDialog>
  <Button onClick={()=>navigate("/admin/create-user")} leftIcon={<FiUserPlus />} colorScheme='orange' variant='solid'>
    Create user
  </Button>
  </Stack>
  <Box mt={3} ml={2}>

<FormControl justifyContent={'right'} alignContent='flex-end' >
      <InputGroup>
      <InputLeftElement
      pointerEvents={'none'} 
      children={<FiSearch color='gray.300' /> }
      />
      <Input width='auto' placeholder='Search.. ' bg={'white'} onChange={(e)=>setSearch(e.target.value)} />
      </InputGroup>
    </FormControl>
      </Box>
 
    <Stack p="3" bg='white' boxShadow="lg" m="4" borderRadius="sm" >
    <TableContainer>
  <Center>

  <Table variant='simple' >
    <TableCaption> {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
    
    <Button
    colorScheme={'orange'}
    m={1}
    key={page}
    onClick={() => handlePageChange(page)}
    color={page === currentPage ? 'black' : 'white'}
    cursor="pointer"

    >
            {page}
          </Button>
        ))}</TableCaption>
    <Thead>
      <Tr>
        <Th>#</Th>
        <Th>First Name</Th>
        <Th>Last name</Th>
        {/* <Th>Email Address</Th>  */}
        <Th>Mobile Number</Th>
        <Th>Availability</Th>
        <Th>Action</Th>
      </Tr>
    </Thead>
    {loading ? (
      <Flex alignItems='center' justifyItems={'center'}>
      <Spinner
      thickness='4px'
      speed='0.85s'
      emptyColor='gray.200'
      color='blue.500'
      size='xl'
/>
      </Flex>
    ):(

    <Tbody>
    {paginatedData.filter((cust) => {
        return search.toLowerCase() === '' ? 
        cust : cust.firstName.toLowerCase().includes(search) ||
        cust.lastName.toLowerCase().includes(search)
        
      }).map((data,index)=>{
      return(
      <Tr key={index}>
      <Td>{index +1}</Td>
      <Td>{data.firstName == null ? '': data.firstName}</Td>
      <Td>{data.lastName == null ? '': data.lastName }</Td>
     {/* <Td>{data.employeeAccount.workEmail == null ? '' : data.employeeAccount.workEmail}</Td>  */}
      <Td>{data.employeeAccount?.workPhoneNumber == null ? (<Text fontSize={'3xs'}>No text</Text>) : data.employeeAccount.workPhoneNumber }</Td>
      {/* {data.employeeAccount?.roles == null ? '' : data.employeeAccount.roles.map((item) => (
         <Td key={item.id} pt='2' fontSize='sm'>
         {item.name}
       </Td>
        ))} */}
      <Td>    { data.availability === 'PRESENT' ? (<Badge  ml='1' textTransform={'capitalize'} variant={'solid'} colorScheme='green' size='xs'>Present</Badge>) : data.verificationStatus ==='ABSENT' ? (<Badge  ml='1' textTransform={'capitalize'} colorScheme='red' size='xs'>ABSENT</Badge>):<Badge  ml='1' textTransform={'capitalize'} colorScheme='orange' size='xs'>{data.verificationStatus}</Badge>}</Td>
     
      <Td>
        <Wrap spacing={2} direction='row' align='center'>
          <Button onClick={() => navigate(`/admin/view-user`,{
                state: data
              })} colorScheme='gray' size='xs' leftIcon={<MdPreview/>}>View</Button>
           {/* <Button colorScheme='cyan' size='xs'>Edit</Button> */}
          <Button onClick={()=>{
            setCurrentData(data)
            setOpen(true)}}   colorScheme='red' size='xs' leftIcon={<MdDelete/>}>Delete</Button> 
        </Wrap>
       
      </Td>
    </Tr>
    )
  })}
    </Tbody>
    )}

    <Tfoot>
  
    </Tfoot>
    
  </Table>
  </Center>

  <Flex justifyContent={'flex-end'} mr={10} >

 
        </Flex>
</TableContainer>

    </Stack>
    </>
  )
}
