import React from 'react'
import CustomerPendingList from '../components/form/CustomerPendingList'
import Statistics from '../components/stats/Statistics'

export default function Home() {
  return (
    <div>
        <Statistics/>
        
        
        </div>
  )
}
