import React from 'react'
import ManageCustomer from '../components/list/ManageCustomer'

export default function Customers() {
  return (
    <div> 
        <ManageCustomer />
    </div>
  )
}
