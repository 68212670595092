import React,{useEffect,useRef,useState} from 'react'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Stack,
  Box,
  chakra,
  Wrap,
  Button,
  Text,
  Center,
  useToast,
  useDisclosure,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  HStack,
  Heading,
  Flex,
  Badge,
  Image,
  Card,
  CardBody,
  StackDivider,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Select,
  FormControl,
  FormLabel,
  Textarea,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Tooltip,
  InputGroup,
  InputLeftAddon,
  Input,
  List,
  ListItem,
  ListIcon,
  Spinner,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import axios from "../../api/axios";
import {  MdCheckCircle, MdPreview } from "react-icons/md";
import { FiPhone, FiUser, FiUserPlus } from "react-icons/fi";
import { read, utils, writeFile } from 'xlsx'
import { FaIdCard, FaRegImage } from 'react-icons/fa';
import img from '../../images/noimage1.png'
import {  baseURL } from '../../api/baseUrl'
import { approveImageURL } from '../../api/imgUrl';
import { BsFillLaptopFill, BsPhoneFill } from 'react-icons/bs';
import { BiExport } from 'react-icons/bi';

const GET_CUSTOMER_URL = "/onboarding/operator/customer/declined";
const EMPLOYEE_ACC_URL = '/employee/profile/v1/get-employee-by-account-id/'
const BRAND_ACC_URL = '/employee/brand-ambassador/v1/get-all-brand-ambassadors'
const BRANDS_ACC_URL = '/employee/brand-ambassador/v1/get-brand-ambassador-by-account-id'
const CUSTOMER_APPROVAL_URL ='/onboarding/operator/customer/approve'
const CUSTOMER_DECLINE_URL = '/onboarding/operator/customer/decline'
const EDITDECLINE_CUSTOMER_URL = '/onboarding/operator/customer/edit-and-approve'
const GETUSERS_URL = '/employee/profile/v1/get-employees?availability=PRESENT'

function SingleApprovalReport() {
   
    const [searchresults, setSearchResults] = useState([])
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const [phoneNumber, setPhoneNumber] = useState("");
    const navigate = useNavigate();

  
    const handleExport = () => {
    const headings = [["#", "FullName","National Id Number","PhoneNumber","Gender","Approval Status", "CreatedBy", "Reasons"]];
    const wb = utils.book_new();
    const ws = utils.table_to_sheet(document.getElementById('customers'),{raw: true});
    utils.sheet_add_aoa(ws, headings);
 //    utils.sheet_add_json(ws, searchresults, { origin: "A2", skipHeader: true});
    utils.book_append_sheet(wb, ws, `${user?.firstName} ${user.lastName} Approval Report`);
    writeFile(wb,`${user?.firstName} ${user.lastName}s Approval Report.xlsx` )
     };

     const [customers, setCustomers] = useState([]);
     const getAllCustomers = async () => {
        const token = localStorage.getItem("token");
        try {
          setLoading(true);
          const response = await axios.get(`${GET_CUSTOMER_URL}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          if (response.status === 200) {
            setCustomers(response.data.body);
             console.log(response.data.body);
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
        }
      };
      useEffect(() => {
        getAllCustomers();
      }, []);
    

     const [updatedId, setUpdatedID] = useState([]);
  const getAllEmployees = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(GETUSERS_URL, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })

      if (response.status === 200) {
        setUpdatedID(response.data.body)
        console.log(response.data.body)
        setLoading(false);
      }
    } catch (error) {

    }
  }
  useEffect(() => {
    getAllEmployees();
  }, []);

  const [ambassadorId, setAmbassadorID] = useState([]);


  const getBrandAmbassadors = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(BRAND_ACC_URL, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })

      if (response.status === 200) {
        setAmbassadorID(response.data.body)
        console.log(response.data.body)
        setLoading(false);
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    getBrandAmbassadors()

  }, [])

  let user = localStorage.getItem('user')
  user = JSON.parse(user);
  console.log(user.jti)

  const [filteredData, setFilteredData] = useState([])
  const [loggedInUser, setLoggedInUser] = useState(user.jti);

  useEffect(()=>{
    const filtered = customers.filter(item => item.declinedBy === loggedInUser)
    setFilteredData(filtered);
    console.log(filtered)
  },[customers,loggedInUser])


  return (
    <Box maxW="60xl"  mx={'2'} pt={1} px={{ base: 2, sm: 12, md: 17 }}>
        <chakra.h1
          textAlign={'left'}
          fontSize={'2xl'}
          py={5}
          fontWeight={'bold'}>
          {user?.firstName}'s approval Report
        </chakra.h1>

     {user?.roles[0].name === 'CLERK' || user?.roles[0].name ==='APPROVER' || user?.title === 'KEY ACCOUNT REP' ?(
<>
<Button
                leftIcon={<BiExport/>}
                onClick={handleExport}
                bg={'white'}
                color={'orange'}
                _hover={{
                    bg: 'black',
                    color: 'white',
                }}
             
                variant='solid'
                w={'150px'}>
                    Export to Excel
                </Button>
</>
     ):(

<Stack direction={['column','row']}>

        <Button
                leftIcon={<BiExport/>}
                onClick={handleExport}
                bg={'white'}
                color={'orange'}
                _hover={{
                    bg: 'black',
                    color: 'white',
                }}
             
                variant='solid'
                w={'150px'}>
                    Export to Excel
                </Button>

                <Button
                leftIcon={<BiExport/>}
                onClick={() => navigate('/admin/declined-customers')}
                bg={'black'}
                color={'orange'}
                _hover={{
                    bg: 'orange',
                    color: 'white',
                }}
               
                variant='solid'
                w={'250px'}>
                    View Declined Customers
                </Button>
                    </Stack>
     )}

      
        <Box
           
            p={2}
            bg={useColorModeValue('white', 'gray.700')}
            rounded={'xl'}
            boxShadow={'lg'}
            mt={6}
            maxW={'90vw'}
            >


<>

<TableContainer  minW={'80vw'} maxW={'90vw'} overflowY={'scroll'} minH={'40vh'} maxH={'60vh'} bg={'white'} rounded={'lg'} boxShadow={'lg'} mt={2} p={3}>
    
    {filteredData.length > 0 ? (
  <Table id='customers' variant='simple'>
    <TableCaption></TableCaption>
    <Thead>
      <Tr>
        <Th>#</Th>
        <Th>Full Name</Th>
        <Th>National ID Number</Th>
        <Th>Phone Number</Th>
        <Th>Gender</Th>
        <Th>Approval Status</Th>
        <Th>Created By</Th>
        <Th>Declined Reasons</Th>
       
      </Tr>
    </Thead>
    {loading ? (
      <Flex>

      <Spinner
      justifyContent={'center'}
      alignContent='center'
      thickness='4px'
      speed='0.85s'
      Loadingtext='loading'
      emptyColor='gray.200'
      color='blue.500'
      size='xl'
/>
      </Flex>
    ):(
    <Tbody>
    {filteredData.map((data,index)=>{

            let agent;
            ambassadorId.forEach((item) => {
            if (data.createdBy === item.employeeAccount.id) {
            agent = item;
            }
            })

            let employ;
            updatedId.forEach((item) => {
            if (data.declinedBy === item.employeeAccount.id) {
            employ = item;
            }
            })
            
        return (
            <>
            
          <Tr key={index}>
          <Td>{index +1}</Td>
          <Td>{`${data?.firstName} ${data.middleName} ${data?.lastName}`}</Td>
          <Td>{data?.nationalIdNumber}</Td>
          <Td>263{data?.phoneNumber}</Td>
          <Td>{data?.gender}</Td>
          <Td>
          { data.approvalStatus === 'APPROVED' ? (<Button colorScheme='green' size='xs'>Approved</Button>) : data.approvalStatus ==='DECLINED' ? (<Button colorScheme='red' size='xs'>Declined</Button>):<Button colorScheme='orange' size='xs'>{data.approvalStatus}</Button>}

          </Td>
         
          <Td>{`${agent?.firstName} ${agent?.lastName} -(${agent?.employeeAccount?.title}) `}</Td>
         
          {/* <Td>{`${employ?.firstName} ${employ?.lastName} -(${employ?.employeeAccount.roles[0].name})`}</Td> */}
          <Td>
            {data.declineReasons.join(", ")}
          </Td>

        </Tr>
        </>
        )}) }
    </Tbody>
     )}
    <Tfoot>
    </Tfoot>
  </Table>
   ): (
    <Table>

    <Thead>
    <Tr>
    <Th>#</Th>
        <Th>Full Name</Th>
        <Th>Phone Number</Th>
        <Th>National ID Number</Th>
        <Th>Approval Status</Th>
        <Th>Created By</Th>
        <Th>Declined By</Th>
        <Th>Actions</Th>
    </Tr>
  </Thead>
  <Tr>
  <Tfoot display={'flex'}><Text fontSize={'lg'} textColor='gray.500'>No data available</Text></Tfoot>
  </Tr>
    
    </Table>
  )}
</TableContainer>
</>

            </Box>
        </Box>
  )
}

export default SingleApprovalReport