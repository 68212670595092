import React, { useState, useRef, useEffect } from 'react'
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Container,
  Box,
  RadioGroup,
  HStack,
  Radio,
  Text,
  Heading,
  Center,
  Stack,
  useToast,
  InputGroup,
  InputLeftAddon,
  Image,
  Spinner,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Flex,
  Tag,
  TagLabel,
  Textarea,
  Select,
  Tooltip,
} from '@chakra-ui/react'
import axios from '../../api/axios';
import { baseURL } from '../../api/baseUrl';
// import { useAuth } from '../../context/AuthProvider';
import { useLocation, useNavigate } from 'react-router-dom';
// import GetDateFormat from '../../context/GetDateFormat';
import { Link, NavLink } from 'react-router-dom'
import { BsChevronRight, BsPerson } from 'react-icons/bs';

import img from '../../images/noimage1.png'
const UPCUST_URL = 'onboarding/operator/update-customer/'



export default function OperatorEditCustomer() {
  const location = useLocation();
  const [details, setDetails] = useState(location.state)
  if (location.state == null) {
    //return to previous page
  }

  const initialState =
  {
    firstName: details.firstName,
    middleName: details.middleName,
    lastName: details.lastName,
    phoneNumber: details.phoneNumber,
    citizenship: details.citizenship,
    nationalIdNumber: details.user.nationalIdNumber,
    gender: details.gender,
    dateOfBirth: details.dateOfBirth,
    address: details.address,
    email: details.user.email,
    verificationStatus:details.verificationStatus,
    // imageFile: "",
  }
  const navigate = useNavigate();
  // const auth = useAuth();
  const [state, setState] = useState(initialState);
  const toast = useToast();
  const createForm = useRef();
  const [progress, setProgress] = useState(0);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure
  const cancelRef = React.useRef()


  const [fnameError, setFNameError] = useState('')
  const [mdnameError, setMdNameError] = useState('')
  const [lnameError, setLNameError] = useState('')
  const [citiError, setCitiError] = useState('')
  const [genderError, setGenderError] = useState('')
  const [bdateError, setBDError] = useState('')
  const [addressError, setAddressError] = useState('')
  const [imgError, setImgError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [phoneError, setPhoneError] = useState('')
  const [natIdError, setNatIdError] = useState('')

  // const [verificationStatus, setVerificationstatus] = useState("");


  const { firstName, middleName, phoneNumber, lastName, citizenship, nationalIdNumber, gender, dateOfBirth, address, email, verificationStatus} = state
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  const handlesubmit = () => {
    // e.preventDefault();
    const res = validate();
    if (!res) {
      console.log(`validation failed`)
      toast({
        title: 'Failed',
        description: `Check for errors`,
        status: 'error',
        duration: 3000,

      });
      return;
    }
    setLoading(true)
    const token = localStorage.getItem("token");
    axios.put(`${UPCUST_URL}`, {
      id: details.id,
      firstName: state.firstName,
      middleName: state.middleName,
      lastName: state.lastName,
      phoneNumber: state.phoneNumber,
      email: state.email,
      nationalIdNumber: state.nationalIdNumber,
      address: state.address,
      dateOfBirth: state.dateOfBirth,
      citizenship: state.citizenship,
      gender,
      signupPlatform: details.user.signupPlatform,
      verificationStatus: state.verificationStatus,
      userId: details.userId,
      // "pictureUrl": null,
      // "nationalIdPictureUrl": null,
      // "proofOfResidencePictureUrl": null
    }, {

      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(res => {

        if (res.data.success) {
          navigate('/admin/operator')
          toast({
            title: 'Profile Updated.',
            description: "Your Details have been submited successfully.",
            status: 'success',
            duration: 3000,

          });
          setLoading(false)
        } else {
          toast({
            title: 'Failed',
            description: res.data.message,
            status: 'error',
            duration: 3000,

          });
          setLoading(false)
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast({
            title: "Error",
            description: `Server responded with ${error.response.status}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          setLoading(false)
        } else if (error.request) {
          // The request was made but no response was received
          toast({
            title: "Network Error",
            description: "Please Check your Internet Connection",
            status: "error",
            duration: 8000,
            isClosable: true,
          });
          setLoading(false)
        } else {
          // Something happened in setting up the request that triggered an Error
          toast({
            title: "Network Error",
            description: `Error: ${error.message}`,
            status: "error",
            duration: 8000,
            isClosable: true,
          });

        }
      })
  }

  useEffect(() => {

  }, [loading])
  const validate = () => {
    const birthDate = new Date(dateOfBirth)
    const minimumAge = 16 // min age date set to 16 years

    const minimumAgeDate = new Date()
    minimumAgeDate.setFullYear(minimumAgeDate.getFullYear() - minimumAge)

    const maximumAge = 120; //maximum age date set to 120 years
    const maximumAgeDate = new Date();
    maximumAgeDate.setFullYear(maximumAgeDate.getFullYear() - maximumAge);
    if (birthDate > minimumAgeDate) {
        // console.log("Date is less than 16 years ago");
        setBDError(`You must be at least ${minimumAge} years old`)
        return false;
      }
  
      if (birthDate < maximumAgeDate) {
        setBDError(`You cannot be older than ${maximumAge} years old`)
        return false;
      }


      return true

    }

  return (
    <Container maxW={'3xl'}>
      <Flex justifyContent="flex-end">
        <Breadcrumb fontSize={'xs'} fontWeight={'semibold'} spacing='8px' separator={<BsChevronRight color='gray.500' />}>
          <BreadcrumbItem>
            <NavLink to={'/admin/operator'}>
              <BreadcrumbLink >  Home</BreadcrumbLink>
            </NavLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href='#'>Update Customer</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>

      <Center>

        <Box

          borderRadius='lg' shadow='md' borderWidth='2px' bg='white'
          style={{
            width: "85%",
            justifyContent: "space-around",
            boxShadow: 50,
            marginTop: "20px",
            marginBottom: "20px",
            // display: "flex",
            //     flexDirection: "column",
            //     justifyContent: "space-evenly",

          }}

        >

          <Stack spacing={4} marginLeft='10' marginRight='10' marginBottom='2'  >
            <Box justifyContent={'center'} alignContent='center' display={'flex'}>
              {loading &&
                <Spinner
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='orange'
                  size='xl'

                />
              }
            </Box>
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: '1xl', sm: '2xl', lg: '3xl' }}
              marginBottom='2'
              marginTop='5'
            >
              <Text as={'span'}>

                <Text
                  as={'span'}
                  color="black"
                  textAlign={'center'}
                >
                  Customer Registration Form

                </Text>
              </Text>
            </Heading>
            <HStack spacing={8}>


              <FormControl isRequired>
                <FormLabel htmlFor='firstName'>First name:</FormLabel>
                <Input
                  marginBottom={2}
                  id='firstName'
                  placeholder='First name'
                  type='text'
                  name='firstName'
                  value={state.firstName}
                  onFocus={() => { setFNameError('') }} borderColor={fnameError ? 'red.400' : 'gray.300'}
                  onChange={handleInputChange}


                />
                <Text color={'red'}>{fnameError}</Text>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor='middleName'>Middle name</FormLabel>
                <Input
                  marginBottom={2}
                  id='middleName'
                  placeholder='Middle name'
                  type='text'
                  name='middleName'
                  value={state.middleName}
                  onFocus={() => { setMdNameError('') }} borderColor={mdnameError ? 'red.400' : 'gray.300'}
                  onChange={handleInputChange}

                />
                <Text color={'red'}>{mdnameError}</Text>
              </FormControl>

            </HStack>
            <HStack spacing={8}>

              <FormControl>
                <FormLabel htmlFor='lastName'>Last name</FormLabel>
                <Input
                  marginBottom={2}
                  id='lastName'
                  placeholder='Last name'
                  type='text'
                  name='lastName'
                  value={state.lastName}
                  onFocus={() => { setLNameError('') }} borderColor={lnameError ? 'red.400' : 'gray.300'}
                  onChange={handleInputChange}


                />
                <Text color={'red'}>{lnameError}</Text>
              </FormControl>

              <Tooltip label='You cannot edit customer phone number' placement='bottom-end'  bg='red.600'>
              <FormControl isReadOnly>
           
                <FormLabel htmlFor='phoneNumber'>Mobile Number:</FormLabel>
                <InputGroup>
                  {/* <InputLeftAddon children='+263' /> */}
                  <Input id='phoneNumber'
                    type='number'
                    placeholder='712000000'
                    _placeholder={{ color: 'gray.500' }}
                    name='phoneNumber'
                    value={state.phoneNumber}
                    onChange={handleInputChange}
                    
                  />
                </InputGroup>
              </FormControl>
             </Tooltip>



            </HStack>
            <HStack spacing={8}>

            <Tooltip label='You cannot edit customer national Id Number' placement='bottom-end'  bg='red.600'>
              <FormControl isReadOnly>
                <FormLabel htmlFor='nationalIdNumber'>National ID:</FormLabel>
                <Input
                  marginBottom={2}
                  id='nationalIdNumber'
                  placeholder='00-00000000A00'
                  type='text'
                  name='nationalIdNumber'
                  value={state.nationalIdNumber}
                  onFocus={() => { setNatIdError('') }} borderColor={natIdError ? 'red.400' : 'gray.300'}
                  onChange={handleInputChange}
                  textTransform='uppercase'
                />
                <Text color={'red'}>{natIdError}</Text>
              </FormControl>
              </Tooltip>

              <FormControl>
                <FormLabel htmlFor='address'>Address</FormLabel>
                <Textarea
                  marginBottom={2}
                  id='address'
                  placeholder='Address'
                  type='text'
                  name='address'
                  value={state.address}
                  onFocus={() => { setAddressError('') }} borderColor={addressError ? 'red.400' : 'gray.300'}
                  onChange={handleInputChange}
                />
                <Text color={'red'}>{addressError}</Text>
              </FormControl>


            </HStack>

            <HStack spacing={8}>

              <FormControl>
                <FormLabel htmlFor='email'>Email </FormLabel>
                <Input
                  marginBottom={2}
                  id='email'
                  placeholder='example@email.com'
                  name='email'
                  type='email'
                  value={state.email}
                  onChange={handleInputChange}

                />
              </FormControl>



              <FormControl>
                <FormLabel htmlFor='dob'>Date Of Birth: <Tag>{details.dateOfBirth}</Tag></FormLabel>
                <Input
                  width={'190px'}
                  marginBottom={2}
                  letterSpacing={4}
                  name='dateOfBirth'
                  type='date'
                  id='dateOfBirth'
                  value={state.dateOfBirth}
                  onFocus={() => { setBDError('') }} borderColor={bdateError ? 'red.400' : 'gray.300'}
                  onChange={handleInputChange}
                />
                <Text color={'red'}>{bdateError}</Text>
              </FormControl>

            </HStack>
            <HStack spacing={8}>
              <FormControl>
                <FormLabel htmlFor='citizenship'>Citizenship</FormLabel>
                <Input id='citizenship'
                  placeholder='eg Zimbabwean'
                  type='text'
                  name='citizenship'
                  value={state.citizenship}
                  onFocus={() => { setCitiError('') }} borderColor={citiError ? 'red.400' : 'gray.300'}
                  onChange={handleInputChange}


                />
                <Text color={'red'}>{citiError}</Text>
              </FormControl>

              <FormControl as='fieldset'>
                <FormLabel as='legend' marginTop='10px' marginBottom={2}>Gender</FormLabel>
                <RadioGroup defaultValue={state.gender}>
                  <HStack spacing='24px' >
                    <Radio value='Male' name="gender" onChange={handleInputChange}>Male</Radio>
                    <Radio value='Female' name="gender" onChange={handleInputChange} >Female</Radio>
                  </HStack>
                </RadioGroup>
                <Text color={'red'}>{genderError}</Text>
                <FormHelperText>Select Gender</FormHelperText>
              </FormControl>
            </HStack>
            <FormControl>
                <FormLabel htmlFor='verificationStatus'  marginTop='10px'>Verification Status: <Tag>{details.verificationStatus}</Tag> </FormLabel>
                <Select placeholder='Select Status' mt={'10px'} width={'250px'} name='verificationStatus' onChange={handleInputChange}>
            <option name='verificationStatus' onChange={handleInputChange} value='APPROVED'>Approved</option>
            <option name='verificationStatus' onChange={handleInputChange} value='DECLINED'>Declined</option>
            <option name='verificationStatus' onChange={handleInputChange} value='PENDING'>Pending</option>
            </Select>
              </FormControl>
         
            {details.nationalIdPictureUrl === null ? (

              <Image
                boxSize='200px'
                width={'280px'}
                objectFit='cover'
                src={img}
                rounded={'md'}
                alt='image'

              />) :
              (<Image
                boxSize='200px'
                width={'280px'}
                objectFit='cover'
                src={baseURL + 'onboarding/customer/view-picture' + details.nationalIdPictureUrl}
                rounded={'md'}
                alt='image'

              />
              )}
          </Stack>
          <Button
            isLoading={loading}
            loadingText='Loading'
            colorScheme='teal'
            variant='outline'
            spinnerPlacement='end'
            onClick={handlesubmit}
            bg={'orange.400'}
            color={'white'}
            _hover={{
              bg: 'orange.500',
            }}
            m={3}
          >
            Update Customer
          </Button>
        </Box>
      </Center>

    </Container>
  )
}


