import React, { useEffect, useState } from 'react'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Stack,
  Box,
  chakra,
  Wrap,
  Button,
  FormControl,
  InputGroup,
  InputLeftElement,
  Input,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Flex,
  Spinner,
  Center,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
} from '@chakra-ui/react'
import { BsChevronRight, BsPerson } from 'react-icons/bs';
import { FiSearch, FiUserPlus } from 'react-icons/fi'
import { NavLink, useNavigate } from 'react-router-dom'
import axios from '../../api/axios'

const GETCUST_URL = '/onboarding/operator/get-customers';

export default function ManageCustomer() {
  const toast = useToast();
const [customers, setCustomers] = useState([]);
const [loading, setLoading] = useState(false)
const [currentPage, setCurrentPage] = useState(1);
const [itemsPerPage, setItemsPerPage] = useState(50);
const [totalPages, setTotalPages] = useState(1);


useEffect(() => {
  setLoading(true);
  const getCustomers = async () => {
    const token = localStorage.getItem("token");
    
      const response = await axios.get(`${GETCUST_URL}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
.then(response =>{
  if(response.status === 200) {
    setCustomers(response.data.body)
    setLoading(false);
  }
})
     
.catch(error => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    toast({
      title: "Error",
      description: `Server responded with ${error.response.status}`,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
    setLoading(false)
  } else if (error.request) {
    // The request was made but no response was received
    toast({
      title: "Network Error",
      description: "Please Check your Internet Connection",
      status: "error",
      duration: 8000,
      isClosable: true,
    });
    setLoading(false)
  } else {
    // Something happened in setting up the request that triggered an Error
    toast({
      title: "Network Error",
      description: `Error: ${error.message}`,
      status: "error",
      duration: 8000,
      isClosable: true,
    });
   
  }
})};
  getCustomers();

  }, [])

useEffect(() => {
  setTotalPages(Math.ceil(customers.length / itemsPerPage));
}, [customers]);

const paginateData = (customers,currentPage,itemsPerPage) => {
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  return customers.slice(startIndex, endIndex);
};

const handlePageChange = (page) => {
  setCurrentPage(page);
}

const paginatedData = paginateData(customers, currentPage, itemsPerPage);

  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  console.log(search)
  return (
  
     <Box maxW="7xl" mx={'4'} pt={1} px={{ base: 2, sm: 12, md: 17 }}>
      <Flex justifyContent="flex-end">
       <Breadcrumb fontSize={'xs'} fontWeight={'semibold'}  spacing='8px' separator={<BsChevronRight color='gray.500' />}>
       <BreadcrumbItem>
       <NavLink to={'/admin/operator'}>
    <BreadcrumbLink >  Home</BreadcrumbLink>
  </NavLink>
  </BreadcrumbItem>
<BreadcrumbItem isCurrentPage>
  <BreadcrumbLink href='#'>Manage Customer</BreadcrumbLink>
</BreadcrumbItem>
</Breadcrumb>
</Flex>
       <chakra.h1
        textAlign={'left'}
        fontSize={'2xl'}
        py={5}
        fontWeight={'bold'}>
     List of customers
      </chakra.h1>
      <Stack ml={2} direction='row' spacing={4}>
  <Button onClick={()=>navigate("/admin/create-form")} leftIcon={<FiUserPlus />} colorScheme='orange' variant='solid'>
    Create customer
  </Button>
  </Stack>
  <Box mt={3} ml={2}>

  <FormControl justifyContent={'right'} alignContent='flex-end' >
        <InputGroup>
        <InputLeftElement
        pointerEvents={'none'} 
        children={<FiSearch color='gray.300' /> }
        />
        <Input width='auto' placeholder='Search.. ' bg={'white'} onChange={(e)=>setSearch(e.target.value)} />
        </InputGroup>
      </FormControl>
        </Box>
    <Stack p="3" bg='white' boxShadow="lg" m="2" borderRadius="sm">

       <TableContainer>
  
    
    <Center>
  <Table variant='simple'>
  <TableCaption> {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
    <Button
    colorScheme={'orange'}
    m={1}
    key={page}
    onClick={() => handlePageChange(page)}
    color={page === currentPage ? 'black' : 'white'}
    cursor="pointer"
    >
            {page}
          </Button>
        ))}</TableCaption>
     
    <Thead>
      <Tr>
        <Th>#</Th>
        <Th>First Name</Th>
        <Th>Last name</Th>
        <Th>Phone</Th>
        <Th>National ID</Th>
        <Th>Status</Th>
        <Th>Action</Th>
      </Tr>
    </Thead>
    
    {loading ? (
      <Flex>

      <Spinner
      justifyContent={'center'}
      alignContent='center'
      thickness='4px'
      speed='0.85s'
      Loadingtext='loading'
      emptyColor='gray.200'
      color='blue.500'
      size='xl'
/>
      </Flex>
    ):(
    
      <Tbody>
      {paginatedData.filter((cust) => {
        return search.toLowerCase() === '' ? 
        cust : cust.firstName.toLowerCase().includes(search) ||
        cust.lastName.toLowerCase().includes(search)
        
      }).map((data,index)=>{
        return (
     
          <Tr key={index}>
          <Td>{index +1}</Td>
          <Td>{data.firstName == null ? '': data.firstName}</Td>
          <Td>{data.lastName == null ? '': data.lastName }</Td>
          <Td>{data.user?.phoneNumber }</Td>
          <Td>{data.user?.nationalIdNumber}</Td>
            
          <Td >
            { data.verificationStatus === 'APPROVED' ? (<Button colorScheme='green' size='xs'>Approved</Button>) : data.verificationStatus ==='DECLINED' ? (<Button colorScheme='red' size='xs'>Declined</Button>):<Button colorScheme='orange' size='xs'>{data.verificationStatus}</Button>}

          </Td>
          <Accordion allowToggle>
          <AccordionItem>
          <Td>
          
          <AccordionButton>
            <Wrap spacing={2} direction='row' align='center'>
            <Button onClick={() => navigate(`/admin/viewcustomer`,{
              state:data
            })} colorScheme='gray' size='xs'>View</Button>
             {/* <Button onClick={() => navigate(`/admin/edit-cust`,{
                state:data
              })} colorScheme='yellow' size='xs'>Edit</Button> */}
             
              {/* <Button colorScheme='red' size='xs'>Delete</Button> */}
            </Wrap>
            <AccordionIcon ml={4} />
            </AccordionButton>
                  <AccordionPanel>
         <Button isDisabled mt={2} onClick={() => navigate(`/admin/junior-wallet`,{
                state:data
              })} colorScheme='cyan' size='xs'>Add Junior wallet</Button>
        </AccordionPanel>
        
          </Td>
          </AccordionItem>
              </Accordion>
        </Tr>
                 

        )}) }
    </Tbody>
   
      )}
      
      <Tfoot>
    </Tfoot>
  
  </Table>
  
      </Center>

</TableContainer>

    </Stack>
    </Box>

  )
}
