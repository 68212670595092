import React, { useRef, useState } from 'react'
import { 
  Box, 
  Button, 
  Container, 
  Flex, 
  FormControl, 
  FormLabel, 
  HStack, 
  Heading, 
  Input, 
  Radio, 
  RadioGroup, 
  SimpleGrid, 
  Stack, 
  VStack, 
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Select,
  Textarea,
  Card,
  CardBody,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  CardHeader,
  StackDivider,
  Text,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Badge,
  useToast,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Spinner,
} from '@chakra-ui/react'
import { read, utils, writeFile } from 'xlsx'
import { FaFileImport } from 'react-icons/fa';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { BiExport } from 'react-icons/bi';
import { FiSearch } from 'react-icons/fi';
import dayjs from 'dayjs';
import axios from '../../../api/axios';

const Bulk_URL = 'onboarding/operator/bulk-register'
const SEARCH_URL = 'onboarding/operator/bulk-job'


function BulkSimForm() {
  const [kycList, setBulkAirtimeExcel] = useState(null);
  const [platform, setPlatform] = useState('')
  const [selectedOption, setSelectedOption] = useState("");
  const [report, setReport] =useState([])
  const [jobid, setJobId]= useState('')
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const firstField = React.useRef()
  const createForm = useRef();

  const toast = useToast();

  const [platErrr, setPlatError] = useState('')
  const [fileError, setFileError] = useState('')
  const [error, setError] = useState("");
  const [jobError, setJobError]= useState('')



  const handleFileUpload = (e)=> {
    const file = e.target.files[0];
    const reader = new FileReader();
    // reader.readAsBinaryString(e.target.value[0]);
    const rabs =  !!reader.readAsBinaryString;
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, {type: "binary", cellDates: true} );
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      let parsedData = utils.sheet_to_json(sheet);
      parsedData = parsedData.map(item => {
        item['DateOfBirth (dd/MM/yyyy)'] = dayjs(new Date(item['DateOfBirth (dd/MM/yyyy)'])).add(1, 'day').format("DD/MM/YYYY");
        return item;
      })
      setBulkAirtimeExcel(parsedData)
      console.log(parsedData)
    };
    // if (rabs) reader.readasbinarystring(file);
    // else reader.readasarraybuffer(file);
    if (rabs) reader.readAsBinaryString(file);
    else reader.readAsBinaryString(file);
  
  }

  const handleExport = () => {
    const headings = [[ "Phone Number", "National Id Number", "Reasons"]];
    const wb = utils.book_new();
    const ws = utils.table_to_sheet(document.getElementById('report'),{raw: true});
    utils.sheet_add_aoa(ws, headings);
 //    utils.sheet_add_json(ws, searchresults, { origin: "A2", skipHeader: true});
    utils.book_append_sheet(wb, ws, "Failed records_Report");
    writeFile(wb, "Failed records_Report.xlsx")
     };

     const handlesubmit = () => {
      setError('')
      const res = validate();
      if (!res) {
        console.log(`validation failed`)
        toast({
          title: 'Failed',
          description: `Check for errors`,
          status: 'error',
          duration: 3000,
    
        });
        return;
      }
      setLoading(true);
    
      const token = localStorage.getItem("token");
      
      let user = localStorage.getItem('user')
        user = JSON.parse(user);
    
      const formData = new FormData(createForm.current);
      formData.set("email", `${user.workEmail}`)
      axios.post(`${Bulk_URL}`, formData,
       {
       
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      })
        .then(res => {
          if (res.data.success) {
            console.log(res.data.body);
            // getAllBatches();
            toast({
              title: 'Batch Upload success.',
              description: res.data.message,
              status: 'success',
              variant: 'left-accent',
              position: 'top',
              duration: 9000,
    
            });
            setTimeout(() => {
              window.location.reload(); // Reload the page
            }, 100);
            setLoading(false)
          } else if (res.data.body === null) {
            toast({
              title: 'Batch Upload Failed',
              description: res.data.message,
              status: 'error',
              variant: 'left-accent',
              position:'top',
              duration: 6000,
    
            });
            setLoading(false)
          } 
           else {
            toast({
              title: 'Bulk Upload Failed',
              description: res.data.message,
              status: 'error',
              variant:'left-accent',
              position: 'top',
              duration: 6000,
    
            });
            setLoading(false)
          }
        })
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            toast({
              title: "Batch Upload Error",
              description: `${error.response.data.message}`,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            setLoading(false)
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              title: "Network Error",
              description: "Please Check your Internet Connection",
              status: "error",
              duration: 8000,
              isClosable: true,
            });
            setLoading(false)
          } else {
            // Something happened in setting up the request that triggered an Error
            toast({
              title: "Network Error",
              description: `Error: ${error.message}`,
              status: "error",
              duration: 8000,
              isClosable: true,
            });
    
          }
    
        })
    };

    const handlesearch = () => {
      // e.preventDefault();
      setError('')
      const req = valid();
      if (!req) {
        console.log(`validation failed`)
        toast({
          title: 'Failed',
          description: `Check for errors`,
          status: 'error',
          duration: 3000,
    
        });
        return;
      }
      setLoading(true)
      const token = localStorage.getItem("token");
      axios.get(`${SEARCH_URL}/${jobid}`,
      {
  
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }
      )
        .then(res => {
  
          if (res.data.success) {
              setReport(res.data.body);
              onOpen();
              // console.log(res.data.body)
            setLoading(false)
          } else {
            toast({
              title: 'Job Search Failed',
              description: res.data.message,
              status: 'error',
              duration: 3000,
  
            });
            setLoading(false)
          }
        })
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            toast({
              title: "Job Search Error",
              description: `${error.response.data.message}`,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            setLoading(false)
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              title: "Network Error",
              description: "Please Check your Internet Connection",
              status: "error",
              duration: 8000,
              isClosable: true,
            });
            setLoading(false)
          } else {
            // Something happened in setting up the request that triggered an Error
            toast({
              title: "Network Error",
              description: `Error: ${error.message}`,
              status: "error",
              duration: 8000,
              isClosable: true,
            });
  
          }
        })
    }
    
    const validate = () =>{
     
      if (!platform) {
        setPlatError("Please select platform");
        return false;
      }
      if (!kycList) {
        setFileError('Please attatch an excel file');
        return false;
      }
      
    
      return true;
    }
    const valid = () =>{
     
      
      if (!jobid) {
        setJobError('Please enter job ID ')
        return false;
      }
    
      return true;
    }
      
const platforms = [
  {id: 1, label: "Z-Smart" ,value: "NETONE"},
  {id: 2, label: "OneMoney" ,value: "ONEMONEY"},
  {id: 3, label: "All (Z-Smart & Onemoney)" ,value: "ALL"},

]
 
const failedRegs = report?.totalRegs - report?.successfulRegs
console.log(failedRegs)
  /* End Of Fetching Data From Server */

  /* Table Header */
  
  const renderHeader = () => {
    
   return (
     <>

<Heading fontSize={'lg'} fontWeight={'semibold'}>List of records</Heading>
        
      </>
   );
  };

//   const dateBodyTemplate = (rowData) => {
//     return formatDate(rowData.dateOfBirth);
  
//   };
  

//   const dateFilterTemplate = (options) => {
//     return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
//   };

  const header = renderHeader();
  /* End of Table Header */


  return (
 <>
  <Breadcrumb ml={2} mb={2} fontWeight='medium' fontSize='sm'>
  <BreadcrumbItem>
    <BreadcrumbLink href='#'>Home</BreadcrumbLink>
  </BreadcrumbItem>

  <BreadcrumbItem isCurrentPage>
    <BreadcrumbLink href='#'>Bulk Registration</BreadcrumbLink>
  </BreadcrumbItem>
</Breadcrumb>
<Box mt={6} ml={4}>
  <Heading fontSize={'xl'} fontWeight={'semibold'}>Bulk Sim Registration</Heading>
</Box>




<Stack spacing={12} justifyContent={'flex-start'} direction={{ base: 'column', md: 'row' }}>


<SimpleGrid spacing={'4'} column={3} >
<Box 
mt={4}
ml={'10px'}
mb={6}
>

  <Card
   maxW={'40vw'}
  >
  <CardBody>
  <Flex justifyContent={'center'} >
    <HStack ml={2} mt={3} spacing={2}>
    <Heading color={'#2C2727'} size={'sm'}>Search Job:</Heading>
<Box>

<FormControl>
                    {/* <FormLabel>Search Customers Using</FormLabel>  */}
        <Input type='text'name='jobid' placeholder='Enter JOB ID' onChange={(e)=>setJobId(e.target.value)}
          onFocus={() => { setJobError('') }} 
          borderColor={jobError ? 'red.400' : 'gray.300'}
        />
         <Text color={'red'}>{jobError}</Text>
        </FormControl>
              </Box>
              <Button
                leftIcon={<FiSearch/>}
                isLoading={loading}
                loadingText='Loading'
                spinnerPlacement='end'
                onClick={()=>{
                  handlesearch();
                  
                }}
                bg={'#2C2727'}
                color={'white'}
                    _hover={{
                        bg: 'orange.500',
                    }}
                    mt={8}
                    variant='solid'
                    w={'180px'}>
                    Search 
                </Button>
        </HStack>
        </Flex>
  </CardBody>
</Card>
    </Box>
  <Box
   bg={'white'}
   borderWidth="1px"
   rounded="lg"
   shadow="1px 1px 3px rgba(0,0,0,0.3)"
   p={10}
   m="10px auto"
  >
     <Flex justifyContent={'center'}>
      <Heading
        lineHeight={1.1}
        fontWeight={500}
        fontSize={{ base: 'xl', sm: 'lg', lg: '1xl' }}
        marginTop='-4'
        marginBottom='2'
        textColor={'#2C2727'}
      >
       Bulk Sim Registration Form
      </Heading>
    </Flex>
    <Box display={'flex'}>
    <form ref={createForm}>
      <HStack mt={6} spacing={2}>
      <FormControl as='fieldset'>
              {/* <FormLabel as='legend' marginTop='10px' marginBottom={2}>Pla</FormLabel> */}
              <RadioGroup onChange={(e) => setPlatform(e)} value={platform} 
              onFocus={() => { setPlatError('') }} 
              borderColor={platErrr ? 'red.400' : 'gray.300'}
               >
                <HStack spacing='24px' >
                  {platforms.map((option) => (
                    <Radio key={option.value} value={option.value} name='platform'>
                      {option.label}
                    </Radio>
                  ))}
                </HStack>
              </RadioGroup>
              <Text color={'red'}>{platErrr}</Text>
            </FormControl>
      </HStack>
        <HStack mt={4} spacing={4}>
     
        <FormControl>
        <FormLabel htmlFor='bulkcustomersExcel'>Upload Excel Sheet</FormLabel>
        <Input name='kycList' id='file' type='file'  accept='.xlsx, .xls'   maxW={'260px'} onChange={handleFileUpload} 
        onFocus={() => { setFileError('') }} borderColor={fileError ? 'red.400' : 'gray.300'}
        />
        <Text color={'red'}>{fileError}</Text>
        </FormControl>

        {/* <Button onClick={handleReadExcel}>Read Excel</Button> */}
      </HStack>
    </form>
    </Box>

    <Box m={8}  justifyContent={'center'} alignContent={'center'} display={'flex'} spacing={4}>
      <Button
      leftIcon={<FaFileImport/>}
       isLoading={loading} 
       loadingText='...loading'
       colorScheme='teal'
       variant='outline'
       spinnerPlacement='end'
      // onClick={onOpen}
      onClick={handlesubmit}
      bg={'#3E3939'}
      color={'white'}
      _hover={{
        bg: 'orange.500',
      }}
      width='150px'
    >
      Import Excel
    </Button>
    </Box>

  </Box>
 
         {/* <Button
                leftIcon={<BiExport/>}
                //  onClick={handleExport}
                 bg={'#FF5722'}
                 color={'white'}
                 _hover={{
                     bg: '#2C2727',
                     color: 'white',
                 }}
                 mt={8}
                 mb={2}
                 variant='solid'
                 shadow="1px 1px 3px rgba(0,0,0,0.3)"
                 rounded={'md'}
                w={'190px'}>
                    Search Batch Job
                </Button> */}
 
 

{kycList?.length > 0 ? (
   <Box
   bg={'white'}
   borderWidth="1px"
   rounded="lg"
   shadow="1px 1px 3px rgba(0,0,0,0.3)"
   p={10}
   m="10px auto"
   maxW={{ base: '80vw', sm: '70vw', lg: '80vw'}}
  >


       <DataTable 
       value={kycList} 
       dataKey="id" header={header} tableStyle={{ minWidth: '60rem' }}
      
      showGridlines
      filterDisplay="row"
      loading={loading}
      paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
      currentPageReportTemplate="{first} to {last} of {totalRecords}"
      globalFilterFields={[
      

      ]}
      emptyMessage="No data found."
              >
                 <Column
          header="#"
           headerStyle={{ width: '3rem' }} 
           body={(data, options) => options.rowIndex + 1}></Column>
                <Column field='FirstName' header='First Name' sortable ></Column>
                <Column field='MiddleName' header='Middle Name' sortable ></Column>
                <Column field='LastName' header='Last Name' sortable ></Column>
                <Column field='PhoneNumber(263710000000)' header='Phone Number' sortable ></Column>
              <Column field='NationalIdNumber (00-0000000X00)' header='National Id Number'  sortable ></Column>          
              <Column field='Address' header='Address' sortable ></Column>
              <Column field='DateOfBirth (dd/MM/yyyy)' header='D.O.B'  ></Column>
              <Column field='Citizenship' header='Citizenship' sortable ></Column>
              <Column field='Gender(M/F)' header='Gender' sortable ></Column>
              <Column field='City' header='City' sortable ></Column>
              <Column field='Region' header='Region' sortable ></Column>
              <Column field='Country' header='Country' sortable ></Column>
              <Column field='Province' header='Province' sortable ></Column>


              

              {/* <Column field="amount" header="Amount" body={amountBodyTemplate} sortable></Column> */}
          {/* <Column field="stage" header="Status" sortable body={statusBodyTemplate} /> */}
          </DataTable>
  </Box>

):(
<>
</>
)
}

</SimpleGrid>


</Stack>
 


  <Drawer
        isOpen={isOpen}
        placement='right'
        initialFocusRef={firstField}
        onClose={onClose}
        size={'xl'}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          {loading ? (
           <Box padding='6' boxShadow='lg' bg='white'>
            <Text fontWeight={'semibold'} textAlign={'center'} >Please wait while I fetch your report...</Text>
           <SkeletonCircle size='10' />
           <SkeletonText mt='4' noOfLines={28} spacing='4' skeletonHeight='2' />
         </Box>
          ): (
            <>

          <DrawerHeader borderBottomWidth='1px'>
              <Text>

                 Bulk Sim Registration Report 
              </Text>
            <HStack m={1} spacing={2}>
            <Text fontStyle={'oblique'} fontWeight={'semibold'} fontSize={'sm'}>Status:</Text>
            {report.bulkJobStatus === 'DONE' ? (

              <Badge variant={'solid'} colorScheme='green' fontSize={'0.6em'}>{report.bulkJobStatus}</Badge>
            ):(
              <Badge variant={'solid'} colorScheme='orange' fontSize={'0.6em'}>{report.bulkJobStatus}</Badge>

            )}
            </HStack>
          </DrawerHeader>

          <DrawerBody>
          <Stack  spacing={4} justifyContent={'flex-start'} direction={{ base: 'column', md: 'row' }}>
           
            <SimpleGrid  mt={4} spacing={2} column={2}>
                <Heading fontSize={'xl'} fontWeight={'extrabold'}>Overview</Heading>
            <Stack divider={<StackDivider />} spacing='4'>
            <Text m={2}>
            This report provides a summary of the current status of SIM registrations on the system. 
            The statistics are categorized into three sections: successful, 
            pending, and failed registrations, offering users a clear overview of their registration progress.
            </Text>
           </Stack>
            <Box
            boxSize={'lg'}
              >
                <Card size={'lg'}>

          <CardBody>
            <Stack divider={<StackDivider />} spacing='4'>
              <Box>
                <Stat mt={4}>
                <StatLabel fontSize={'lg'} >Sim Registration Successful records:</StatLabel>
                <StatNumber fontWeight={'bold'} textShadow={'inner'}>{report.successfulRegs}</StatNumber>
                {`${report.successfulRegs}`.length > 0 ? (

                <StatHelpText>
                  <StatArrow type='increase' />
                  +{report.successfulRegs}
                </StatHelpText>
                ):(
                    <>
                    </>
                )}
              </Stat>
              </Box>
              <Box>
                <Stat mt={4}>
                <StatLabel fontSize={'lg'} > Sim Registration Failed records:</StatLabel>
                <StatNumber fontWeight={'bold'} textShadow={'inner'}>{failedRegs}</StatNumber>
                <StatHelpText>
                  <StatArrow type='decrease' />
                  -{failedRegs}
                </StatHelpText>
              </Stat>
              </Box>
              <Box>
              <Stat mt={4}>
                <StatLabel fontSize={'lg'} > Sim Registration Total records:</StatLabel>
                <StatNumber fontWeight={'bold'} textShadow={'inner'}>{report.totalRegs}</StatNumber>
                <StatHelpText>
                  
                </StatHelpText>
              </Stat>
              </Box>
            </Stack>
          </CardBody>
        </Card>
                </Box>
                   <Button
                leftIcon={<BiExport/>}
                 onClick={handleExport}
                 bg={'#FF5722'}
                 color={'white'}
                 _hover={{
                     bg: '#2C2727',
                     color: 'white',
                 }}
                 mt={-2}
                 mb={2}
                 variant='solid'
                 shadow="1px 1px 3px rgba(0,0,0,0.3)"
                 rounded={'md'}
                w={'150px'}>
                    Export to Excel
                </Button>
                {report?.failedRegsList?.length > 0 ? (
   <Box
   bg={'white'}
   borderWidth="1px"
   rounded="lg"
   shadow="1px 1px 3px rgba(0,0,0,0.3)"
   p={2}
   
   maxW={{ base: '60vw', sm: '45vw', lg: '60vw'}}
  >


       <DataTable 
        id="report"
       value={[...report.failedRegsList]} 
       dataKey="id" header={header} tableStyle={{ minWidth: '60rem' }}
      
      showGridlines
      loading={loading}
      paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
      currentPageReportTemplate="{first} to {last} of {totalRecords}"
      emptyMessage="No data found."
              >
                <Column field='phoneNumber' header='Phone Number'></Column>
              <Column field='nationalId' header='National Id Number' ></Column>          
              <Column field='reason' header='Reason'></Column>

              

              {/* <Column field="amount" header="Amount" body={amountBodyTemplate} sortable></Column> */}
          {/* <Column field="stage" header="Status" sortable body={statusBodyTemplate} /> */}
          </DataTable>
  </Box>

):(
<>
</>
)
}
            </SimpleGrid>

 
          </Stack>
          </DrawerBody>
            </>
          )}

          <DrawerFooter borderTopWidth='1px'>
            <Button variant='solid' colorScheme='red' mr={3} onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
       
      </Drawer>


 </>
  )
}

export default BulkSimForm