import React from 'react'
import {
  Button,
    chakra,
    Flex,
      Heading
  
  } from '@chakra-ui/react';
import ManageBA_Approval from '../list/ManageBA_Approval';
import Config from '../list/CustomerConfig/Config';
import { MdReport } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

let user = localStorage.getItem('user')
user = JSON.parse(user);

const currentHour = new Date().getHours();
let greeting;

if (currentHour < 12) {
  greeting = 'Good morning';
} else if (currentHour < 18) {
  greeting = 'Good afternoon';
} else {
  greeting = 'Good evening';
}

function Approver() {
  const navigate = useNavigate();
  return (
    <>
    {user?.title == 'APPROVER' &&

        <chakra.h1
        textAlign={'left'}
        fontSize={'1xl'}
        mb={4}
        fontWeight={'bold'}>
     {greeting}, {user?.firstName} {user?.lastName}
      </chakra.h1>
    }
      <Button
                leftIcon={<MdReport/>}
                onClick={() => navigate('/admin/view-declined-reports')}
                bg={'black'}
                color={'orange'}
                _hover={{
                    bg: 'orange',
                    color: 'white',
                }}
               mt={4}
                variant='solid'
                w={'150px'}>
                    View Report
                </Button>

    <Flex justify={'center'}>


      <Heading mt={4} mb={2} size={'md'} color={'orange.400'}>Please verify customer KYC</Heading>
    </Flex>

      <Config />
    </>
  )
}

export default Approver