import {
    Box,
    chakra,
    Flex,
    SimpleGrid,
    Stat,
    StatLabel,
    StatNumber,
    useColorModeValue,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
    useToast,
      StatHelpText,
      StatArrow,
      StatGroup,
      Stack,
      Heading,
      Spinner

  } from '@chakra-ui/react';
  import { BsChevronRight, BsPerson } from 'react-icons/bs';
  import { FiUsers } from 'react-icons/fi';
import CustomerPendingList from '../form/CustomerPendingList';
import axios from '../../api/axios';
import React,{useEffect, useState} from 'react';
import { NavLink, useNavigate } from 'react-router-dom'
import { FaUserCheck, FaUserClock, FaUserMinus, FaUserPlus, FaUsers } from 'react-icons/fa';
import RegionalStats from './RegionalStats';
import ZoneStats from './ZoneStats';
import TodayStats from './TodayStats';
import ShopStats from './ShopStats';
  
const GETSTATS_URL = '/onboarding/operator/dashboard';
const GETALLSTATS_URL = '/onboarding/operator/get-statistics?resultType=STATISTICS'
const GETALLTELCO_URL ='/onboarding/telco/operator/get-statistics?resultType=STATISTICS'
 
function StatsCard(props) {
   
    const { title, stat, icon } = props;
    return (
      <Stat
        px={{ base: 2, md: 4 }}
        py={'5'} 
        shadow={'xl'}
        border={'1px solid'}
        bgColor={useColorModeValue('white')}
        borderColor={useColorModeValue('white', 'white')}
        rounded={'lg'}>

        <Flex justifyContent={'space-between'}>
          <Box pl={{ base: 2, md: 2 }}>
            <StatLabel textColor={'gray.500'} fontSize={'xs'} isTruncated>
              {title}
            </StatLabel>
            <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
              {stat}
            </StatNumber>
          </Box>

          <Box
            my={'auto'}
            color={useColorModeValue('white', 'white')}
            alignContent={'center'}>
            {icon}
          </Box>

        </Flex>
      </Stat>
    );
  }
function HomeDash() {
    const [statistics, setStatistics] = useState({});
    const [stats, setStats] = useState({})
    const [telco, setTelco] = useState({})
    const toast = useToast();
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    const getPending = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`${GETSTATS_URL}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        if(response.data.success === true) {
          setStatistics(response.data.body)
          setLoading(false)
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
         // The request was made and the server responded with a status code
         // that falls out of the range of 2xx
        //  toast({
        //    title: "Error",
        //    description: `Server responded with ${error.message}`,
        //    status: "error",
        //    duration: 5000,
        //    isClosable: true,
        //  });
        
       } else if (error.request) {
         // The request was made but no response was received
         toast({
           title: "Network Error",
           description: "Please Check your Internet Connection",
           status: "error",
           duration: 8000,
           isClosable: true,
         });
      
       } else {
         // Something happened in setting up the request that triggered an Error
         toast({
           title: "Error",
           description: `Error:`,
           status: "error",
           duration: 8000,
           isClosable: true,
         });
        
       }
      }                                        
    }
  
    useEffect(() => {
      getPending();
  
    }, [])

    const getAllWeeks = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`${GETALLSTATS_URL}&statisticsType=SHOP&dashboardType=LANDING&shopIdParam=${user.shopId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        if(response.data.success === true) {
          setStats(response.data.body)
          setLoading(false)
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
         // The request was made and the server responded with a status code
         // that falls out of the range of 2xx
        //  toast({
        //    title: "Error",
        //    description: `Server responded with ${error.message}`,
        //    status: "error",
        //    duration: 5000,
        //    isClosable: true,
        //  });
        
       } else if (error.request) {
         // The request was made but no response was received
         toast({
           title: "Network Error",
           description: "Please Check your Internet Connection",
           status: "error",
           duration: 8000,
           isClosable: true,
         });
      
       } else {
         // Something happened in setting up the request that triggered an Error
         toast({
           title: "Error",
           description: `Error:`,
           status: "error",
           duration: 8000,
           isClosable: true,
         });
        
       }
      }                                        
    }

    useEffect(() => {
      getAllWeeks();

  
    }, [])

    const getAllTelco = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`${GETALLTELCO_URL}&statisticsType=SHOP&dashboardType=LANDING&shopIdParam=${user.shopId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        if(response.data.success === true) {
          setTelco(response.data.body)
          setLoading(false)
        }
      } catch (error) {
        console.log(error);
        if (error.response.status) {
         // The request was made and the server responded with a status code
         // that falls out of the range of 2xx
         
        //  toast({
        //    title: "Error: Unauthorized Entry",
        //    description: `Server responded with ${error.message}.Please Login`,
        //    status: "error",
        //    duration: 5000,
        //    isClosable: true,
        //  });
        
       } else if (error.request) {
         // The request was made but no response was received
         toast({
           title: "Network Error",
           description: "Please Check your Internet Connection",
           status: "error",
           duration: 8000,
           isClosable: true,
         });
      
       } else {
         // Something happened in setting up the request that triggered an Error
         toast({
           title: "Error",
           description: `Error:`,
           status: "error",
           duration: 8000,
           isClosable: true,
         });
        
       }
      }                                        
    }

    useEffect(() => {
      getAllTelco();

  
    }, [])

    let user = localStorage.getItem('user')
      user = JSON.parse(user);

      const currentHour = new Date().getHours();
      let greeting;
    
      if (currentHour < 12) {
        greeting = 'Good morning';
      } else if (currentHour < 18) {
        greeting = 'Good afternoon';
      } else {
        greeting = 'Good evening';
      }
      
    return (
        <>
      <Box maxW="full" mx={'2'} pt={1} px={{ base: 2, sm: 12, md: 12 }}>
      <Breadcrumb spacing='8px' separator={<BsChevronRight color='gray.500' />}>
    <BreadcrumbItem isCurrentPage>
    <BreadcrumbLink href='#'></BreadcrumbLink>
    </BreadcrumbItem>
  </Breadcrumb>
        <chakra.h1
          textAlign={'left'}
          fontSize={'1xl'}
          
          fontWeight={'bold'}>
       {greeting}, {user?.firstName} {user?.lastName}
        </chakra.h1>
       
      
  {user?.title == 'SHOP SUPERVISOR' || user?.title == 'KEY ACCOUNT REP' || user?.roles[0].name == 'CLERK' 
   ? (
    <>
          <Stack
            spacing={4}
            
            minW={'10vw'}
            // bg={useColorModeValue('gray.100', 'gray.800')}
            rounded={'xl'}
            boxShadow={'sm'}
            p={6}
            my={8}>
<Heading lineHeight={1.1} fontSize={{ base: '1xl', sm: 'md' }}>
              OneMoney Statistics
            </Heading>
            {loading ? (
      <Flex>
      <Spinner
      thickness='4px'
      speed='0.85s'
      emptyColor='gray.200'
      color='orange.300'
      size='md'
        />
      </Flex>
    ):(
        <SimpleGrid mb={2} columns={{ base: 1, md: 4 }} spacing={{ base: 5, lg: 4 }} >
            <StatsCard 
            
            title={'Total Registered Today'}
            stat={stats.daily}
            icon={<FaUserPlus color='blue' size={'2em'} />}
            />
        
        <StatsCard
          title={'Total Registered Weekly'}
          stat={stats.weekly}
          icon={<FaUsers color='purple' size={'2em'} />}
        />
        <StatsCard
          title={'Total Registered Monthly'}
          stat={stats.monthly}
          icon={<FaUsers color='orange' size={'2em'} />}
        />
          <StatsCard
          title={'Total Registered Annualy'}
          stat={stats.annual}
          icon={<FaUsers color='green' size={'2em'} />}
        />

    
        </SimpleGrid>
    )}
  </Stack>

  <Stack
            spacing={4}
            
            minW={'10vw'}
            // bg={useColorModeValue('gray.100', 'gray.800')}
            rounded={'xl'}
            boxShadow={'sm'}
            p={6}
            my={8}>
<Heading lineHeight={1.1} fontSize={{ base: '1xl', sm: 'md' }}>
              Telco Statistics
            </Heading>
        <SimpleGrid mb={2} columns={{ base: 1, md: 4 }} spacing={{ base: 5, lg: 4 }} >
            <StatsCard 
            
            title={'Total Registered Today'}
            stat={telco.daily}
            icon={<FaUserPlus color='blue' size={'2em'} />}
            />
        
        <StatsCard
          title={'Total Registered Weekly'}
          stat={telco.weekly}
          icon={<FaUsers color='purple' size={'2em'} />}
        />
        <StatsCard
          title={'Total Registered Monthly'}
          stat={telco.monthly}
          icon={<FaUsers color='orange' size={'2em'} />}
        />
          <StatsCard
          title={'Total Registered Annualy'}
          stat={telco.annual}
          icon={<FaUsers color='green' size={'2em'} />}
        />

    
        </SimpleGrid>
  </Stack>

  

</>
  ): (
    <>
    
    </>
  )}
             

     
      </Box>
  
  

      {user?.title === 'REGIONAL SALES MANAGER' ?( 
        <>
        <TodayStats />
        <RegionalStats />
        </>
        // <ZoneStats/>
      ):(
<></>
        ) }
        {user?.title === 'ZONE COMMANDER' || user?.title === 'NATIONAL SALES ADMIN' || user?.title === 'REPORTING ANALYST' ?( 
          <>
          <ShopStats />
        <ZoneStats/>
          </>

):(
<></>
) }
            </>
    );
  }

export default HomeDash