import React, { useState, useEffect, useRef } from 'react'
import {
     Box,
     Button,
     Flex,
     FormControl,
     FormLabel,
     Input,
     InputGroup,
     InputLeftElement,
     Stack,
     Card, 
     CardHeader, 
     CardBody, 
     CardFooter,
     Heading,
     Text,
     StackDivider,
     useColorModeValue,
     HStack,
     Avatar,
     IconButton,
     Image,
     Wrap,
     Tag,
     Table,
     Thead,
     Tbody,
     Tfoot,
     Tr,
     Th,
     Td,
     TableCaption,
     TableContainer,
     Spinner,
     Center,
     useToast,
     Accordion,
     AccordionItem,
     AccordionButton,
     AccordionPanel,
     AccordionIcon,
     Select,
     chakra,
     Link,
    } from '@chakra-ui/react'
import { FiBook, FiBookOpen, FiPause, FiPhoneCall, FiSearch, FiUser, FiUserPlus } from 'react-icons/fi'
import { Form, useNavigate } from 'react-router-dom'
import { MdEdit, MdPreview } from 'react-icons/md'
import {CiWallet} from 'react-icons/ci';
import {GiWallet} from 'react-icons/gi';
import axios from '../../api/axios';
import { FaUserEdit } from 'react-icons/fa'
import { BsFillWalletFill, BsSimFill } from 'react-icons/bs'
// import { useDownloadExcel } from 'react-export-table-to-excel'
import { read, utils, writeFile } from 'xlsx'
import { BiExport } from 'react-icons/bi'
import moment from 'moment'
import { RiExternalLinkFill } from 'react-icons/ri'




const GETUSERS_URL = '/employee/profile/v1/get-employees?availability=PRESENT'
const BRAND_ACC_URL = '/employee/brand-ambassador/v1/get-all-brand-ambassadors'
const SEARCH_URL = '/onboarding/operator/search-customer'
const GETREPORT_URL = '/onboarding/operator/get-report'
const GETREGION_URL = '/employee/region/v1/get-regions';
const GETZONES_URL = '/employee/zone/v1/get-zones'
const GETSHOPS_URL = '/employee/shop/v1/get-shops'

const GETTELCO_REPORT = '/onboarding/telco/operator/get-report'

function CustomersReport () {
    const [searchvalue, setSearchValue] = useState("");
    const [searchresults, setSearchResults] = useState([])
    const [phoneNumber, setPhoneNumber] = useState('');
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [nationalIdNumber, setNationalIDNumber] = useState("");

    const toast = useToast();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [selectedOption, setSelectedOption] = useState("");
    const [showSearch, setShowSearch] = useState(false)

    const [showOnemoney, setShowOneMoney] = useState(false)
    const [showZsmart, setShowZsmart] = useState(false);

    const [showAllCust, setShowAllCust] = useState(false)
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [selectedReport, setSelectedReport] = useState("");

    const [selectCustomerSearch, setSelectCustomerSearch] = useState('')
 

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
      }
      const handleSearchInputChange = (e) => {
        setSearchValue(e.target.value);
      };
    const handlesubmit = () => {
        // e.preventDefault();
        setLoading(true)
        const token = localStorage.getItem("token");
        axios.get(`${SEARCH_URL}/${selectedOption}?searchValue=${searchvalue}`,
        {
    
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        }
        )
          .then(res => {
    
            if (res.data.success) {
                setSearchResults(res.data.body);
                // console.log(res.data.body)
              setLoading(false)
            } else {
              toast({
                title: 'Failed',
                description: res.data.message,
                status: 'error',
                duration: 3000,
    
              });
              setLoading(false)
            }
          })
          .catch(error => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              toast({
                title: "Error",
                description: `Server responded with ${error.response.status}`,
                status: "error",
                duration: 5000,
                isClosable: true,
              });
              setLoading(false)
            } else if (error.request) {
              // The request was made but no response was received
              toast({
                title: "Network Error",
                description: "Please Check your Internet Connection",
                status: "error",
                duration: 8000,
                isClosable: true,
              });
              setLoading(false)
            } else {
              // Something happened in setting up the request that triggered an Error
              toast({
                title: "Network Error",
                description: `Error: ${error.message}`,
                status: "error",
                duration: 8000,
                isClosable: true,
              });
    
            }
          })
      }
      const options = [
        // { label: 'Operator', value: "OPERATOR" },
        // { label: 'All Customers', value: "firstName"},
        { label: 'Customers using Dates', value: "Dates"},
        // { label: 'Phone Number', value: "phoneNumber"},
        // { label: 'National ID', value: "nationalIdNumber"}
      ]

      const reportType = [
        { label: 'ALL', value: "ALL" },
        { label: 'ZONE', value: "ZONAL"},
        { label: 'REGIONAL', value: "REGIONAL"},
        { label: 'SHOP', value: "SHOP"},
        // { label: 'National ID', value: "nationalIdNumber"}
      ]

      const customers = [
        // { label: 'Operator', value: "OPERATOR" },
        { label: 'ZSmart', value: "zsmart"},
        { label: 'OneMoney', value: "onemoney"},
      ]
      useEffect(() => {
        if(selectCustomerSearch === 'onemoney') {
          setShowOneMoney(true)
        } else {
          setShowOneMoney(false)
        }
      }, [selectCustomerSearch])

      useEffect(() => {
        if(selectCustomerSearch === 'zsmart') {
          setShowZsmart(true)
        } else {
          setShowZsmart(false)
        }
      }, [selectCustomerSearch])

      useEffect(() => {
        if(selectedOption === 'Dates') {
          setShowSearch(true)
        } else {
          setShowSearch(false)
        }
      }, [selectedOption])

      useEffect(() => {
        if(selectedOption === 'firstName') {
          setShowAllCust(true)
        } else {
          setShowAllCust(false)
        }
      }, [selectedOption])

      const [updatedId, setUpdatedID] = useState([]);
      const [ambassadorId, setAmbassadorID] = useState([]);

      const getAllEmployees = async () => {
        setLoading(true);
        const token = localStorage.getItem("token");
        try {
          const response = await axios.get(GETUSERS_URL, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
    
          if (response.status === 200) {
            setUpdatedID(response.data.body)
            //  console.log(response.data.body)
            setLoading(false);
          }
        } catch (error) {
    
        }
      }
      const getBrandAmbassadors = async () => {
        setLoading(true);
        const token = localStorage.getItem("token");
        try {
          const response = await axios.get(BRAND_ACC_URL, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
    
          if (response.status === 200) {
            setAmbassadorID(response.data.body)
            // console.log(response.data.body)
            setLoading(false);
          }
        } catch (error) {
    
        }
      }

      useEffect(() => {
        getBrandAmbassadors()
    
      }, [])
    
      useEffect(() => {
        getAllEmployees();
    
      }, [])
    
      const handleStartDateInputChange = (e) => {
        setStartDate(e.target.value);
      };
      const handleEndDateInputChange = (e) => {
        setEndDate(e.target.value);
      };

      const [region, setRegion] = useState([]);
      const [selectedRegionId, setSelectedRegionId] = useState("")
      const [shop, setShop] = useState([]);
      const [selectedShopId, setSelectedShopId] = useState("")
      const [zone, setZone] = useState([]);
      const [selectedZoneId, setSelectedZoneId] = useState("")
  
      const getAllRegions = async () => {
          setLoading(true);
        const token = localStorage.getItem("token");
        try {
          const response = await axios.get(`${GETREGION_URL}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
    
          if(response.status === 200) {
            setRegion(response.data.body)    
           
            setLoading(false);
          }
        } catch (error) {
  
        }
      }

      const getAllShop = async () => {
        setLoading(true);
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`${GETSHOPS_URL}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
  
        if(response.status === 200) {
          setShop(response.data.body)    
         
          setLoading(false);
        }
      } catch (error) {

      }
    }

    const getAllZones = async () => {
      setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${GETZONES_URL}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })

      if(response.status === 200) {
        setZone(response.data.body)    
       
        setLoading(false);
      }
    } catch (error) {

    }
  }
      // console.log(regId)
    
  
      useEffect(() => {
          getAllRegions();
        
        }, [])
        
      useEffect(() => {
        getAllShop();
      
      }, [])
      
      useEffect(() => {
        getAllZones();
      
      }, [])
  


      const handleAudit = () => {
        setLoading(true);
        const token = localStorage.getItem("token");
        let user = localStorage.getItem('user')
        user = JSON.parse(user);
        // console.log(user)
        axios.get(`${GETREPORT_URL}?startDate=${startDate}&endDate=${endDate}&reportType=${selectedReport}&resultType=""&regionIdParam=${selectedRegionId}&shopIdParam=${selectedShopId}&zoneIdParam=${selectedZoneId}`,
            {
      
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            }
        )
            .then(res => {
      
                if (res.data.success) {
                    setSearchResults(res.data.body.customers);
                    // console.log(res.data.body.customers)
                    setLoading(false)
                
                } else {
                    toast({
                        title: 'Failed',
                        description: res.data.message,
                        status: 'error',
                        duration: 3000,
      
                    });
                    setLoading(false)
                }
            })
            .catch(error => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    toast({
                        title: "Error",
                        description: `Server responded with ${error.response.status}`,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                    setLoading(false)
                } else if (error.request) {
                    // The request was made but no response was received
                    toast({
                        title: "Network Error",
                        description: "Please Check your Internet Connection",
                        status: "error",
                        duration: 8000,
                        isClosable: true,
                    });
                    setLoading(false)
                } else {
                    // Something happened in setting up the request that triggered an Error
                    toast({
                        title: "Network Error",
                        description: `Error: ${error.message}`,
                        status: "error",
                        duration: 8000,
                        isClosable: true,
                    });
      
                }
            })
      }

      const handleTelco = () => {
        setLoading(true);
        const token = localStorage.getItem("token");
        let user = localStorage.getItem('user')
        user = JSON.parse(user);
        // console.log(user)
        axios.get(`${GETTELCO_REPORT}?startDate=${startDate}&endDate=${endDate}&reportType=${selectedReport}&resultType=""&regionIdParam=${selectedRegionId}&shopIdParam=${selectedShopId}&zoneIdParam=${selectedZoneId}`,
            {
      
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            }
        )
            .then(res => {
      
                if (res.data.success) {
                    setSearchResults(res.data.body.prepaidSubscribers);
                    // console.log(res.data.body.customers)
                    setLoading(false)
                
                } else {
                    toast({
                        title: 'Failed',
                        description: res.data.message,
                        status: 'error',
                        duration: 3000,
      
                    });
                    setLoading(false)
                }
            })
            .catch(error => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    toast({
                        title: "Error",
                        description: `Server responded with ${error.response.status}`,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                    setLoading(false)
                } else if (error.request) {
                    // The request was made but no response was received
                    toast({
                        title: "Network Error",
                        description: "Please Check your Internet Connection",
                        status: "error",
                        duration: 8000,
                        isClosable: true,
                    });
                    setLoading(false)
                } else {
                    // Something happened in setting up the request that triggered an Error
                    toast({
                        title: "Network Error",
                        description: `Error: ${error.message}`,
                        status: "error",
                        duration: 8000,
                        isClosable: true,
                    });
      
                }
            })
      }

      const handleExport = () => {
        if (selectedReport === "ALL") {
          const headings = [[ "#","FullName", "NationalID", "DOB","Gender", "Address", "Phone", "Shop", "Zone", "Region","Date Registered", "Registered By","Title","Contact","Approved by", "Platform Registered"]];
          const wb = utils.book_new();
          const ws = utils.table_to_sheet(document.getElementById('customers'),{raw: true});
          utils.sheet_add_aoa(ws, headings);
       //    utils.sheet_add_json(ws, searchresults, { origin: "A2", skipHeader: true});
          utils.book_append_sheet(wb, ws, "Customer_Report");
          writeFile(wb, `Customer_Report ${startDate} to ${endDate}.xlsx`)

        }else if (selectedReport === "SHOP") {
          const headings = [[ "#","FullName", "NationalID", "DOB","Gender", "Address", "Phone", "Shop", "Zone","Date Registered", "Registered By", "Title", "Contact", "Approved by","Platform Registered"]];
  const wb = utils.book_new();
       const ws = utils.table_to_sheet(document.getElementById('customers'),{raw: true});
       utils.sheet_add_aoa(ws, headings);
    //    utils.sheet_add_json(ws, searchresults, { origin: "A2", skipHeader: true});
       utils.book_append_sheet(wb, ws, "Customer_Report");
       writeFile(wb, "Customer_Report.xlsx")

        }else if (selectedReport === 'ZONAL') {
          const headings = [[ "#","FullName", "NationalID", "DOB","Gender", "Address", "Phone", "Zone","Date Registered", "Registered By", "Title", "Contact", "Approved by", "Platform Registered"]];
  const wb = utils.book_new();
       const ws = utils.table_to_sheet(document.getElementById('customers'),{raw: true});
       utils.sheet_add_aoa(ws, headings);
    //    utils.sheet_add_json(ws, searchresults, { origin: "A2", skipHeader: true});
       utils.book_append_sheet(wb, ws, "Zonal_Customer_Report");
       writeFile(wb, "Zonal_Customer_Report.xlsx")

        }else if (selectedReport === "REGIONAL") {
          const headings = [[ "#","FullName", "NationalID", "DOB","Gender", "Address", "Phone", "Region","Date Registered", "Registered By","Title", "Contact", "Approved by", "Platform Registered"]];
  const wb = utils.book_new();
       const ws = utils.table_to_sheet(document.getElementById('customers'),{raw: true});
       utils.sheet_add_aoa(ws, headings);
    //    utils.sheet_add_json(ws, searchresults, { origin: "A2", skipHeader: true});
       utils.book_append_sheet(wb, ws, "Regional_Customer_Report");
       writeFile(wb, "Regional_Customer_Report.xlsx")
        }
    //    const wb = utils.book_new();
    //    const ws = utils.table_to_sheet(document.getElementById('customers'));
    //    utils.sheet_add_aoa(ws, headings);
    // //    utils.sheet_add_json(ws, searchresults, { origin: "A2", skipHeader: true});
    //    utils.book_append_sheet(wb, ws, "Customer_Report");
    //    writeFile(wb, "Customer_Report.xlsx")
        };

        
console.log(searchresults)
     
  return (
    <Box maxW="60xl"  mx={'2'} pt={1} px={{ base: 2, sm: 12, md: 17 }}>
    {/* <Heading size={'lg'}>Search Customers</Heading> */}
    <chakra.h1
          textAlign={'left'}
          fontSize={'2xl'}
          py={5}
          fontWeight={'bold'}>
      Reports
        </chakra.h1>
    <Center mt={4}>

    <Heading mr={2} mt={'2'} color={'teal'} size={'sm'}>Search Report In:</Heading>
<Flex justifyContent={'space-between'} >
    <HStack ml={2} mt={3} direction='row' spacing={-4}>
    <FormControl>
                    {/* <FormLabel>Search Customers Using</FormLabel>  */}
                    <Select placeholder='Select customer using' w={'245px'} bg={'white'} onChange={(e) => setSelectCustomerSearch(e.target.value)} >
                        {customers.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </Select>
        </FormControl>
        </HStack>
</Flex>
        {showOnemoney && <>
       
          <Flex justifyContent={'space-between'} >
                <HStack  mt={'-5'}  direction='row' spacing={2}>
                <FormControl ml={2}>
                    <FormLabel>Report Type:</FormLabel> 
                    <Select placeholder='Search by:' w={'128px'} bg={'white'} onChange={(e) => setSelectedReport(e.target.value)} >
                        {reportType.map((opt) => (
                            <option key={opt.value} value={opt.value}>
                                {opt.label}
                            </option>
                        ))}
                    </Select>
        </FormControl>
        {
          selectedReport === 'REGIONAL' && <FormControl>
          <FormLabel>Select Region:</FormLabel> 
          <Select placeholder='Search by:' w={'128px'} bg={'white'}  onChange={(e)=> setSelectedRegionId(e.target.value)} >
          {region.length === 0 && <Spinner />}
    {region.map((regions) => (
      <option key={regions.id} value={regions.id}>
        {regions.name}
      </option>
    ))} 
          </Select>
</FormControl>
        }
       {
        selectedReport === 'SHOP' &&  <FormControl>
        <FormLabel>Select Shop:</FormLabel> 
        <Select placeholder='Search by:' w={'128px'} bg={'white'}  onChange={(e)=> setSelectedShopId(e.target.value)} >
        {shop?.length === 0 && <Spinner />}
  {shop?.map((shop) => (
    <option key={shop.id} value={shop.id}>
      {shop.name}
    </option>
  ))} 
        </Select>
</FormControl>
       }
        {
          selectedReport === 'ZONAL' && <FormControl>
          <FormLabel>Select Zone:</FormLabel> 
          <Select placeholder='Search by:' w={'128px'} bg={'white'}  onChange={(e)=> setSelectedZoneId(e.target.value)} >
          {zone.length === 0 && <Spinner />}
    {zone?.map((zones) => (
      <option key={zones.id} value={zones.id}>
        {zones.name}
      </option>
    ))} 
          </Select>
</FormControl> 
        }
    <FormControl>
              <FormLabel>Start Date:</FormLabel>
            <Input
                placeholder="Select Date and Time"
                size="md"
                name='startDate'
                type="date"
                bg={'white'}
                onChange={handleStartDateInputChange}
                />
                </FormControl>
                <FormControl>
                <FormLabel>End Date:</FormLabel>
                <Input
                placeholder="Select Date and Time"
                size="md"
                name='endDate'
                type="date"
                bg={'white'}
                onChange={handleEndDateInputChange}
                />
                </FormControl>
           
</HStack>
  </Flex>
  <Button
                leftIcon={<FiSearch/>}
                    isLoading={loading}
                    loadingText='Loading'
                    spinnerPlacement='end'
                    onClick={handleAudit}
                    bg={'teal'}
                    color={'white'}
                    _hover={{
                        bg: 'orange.500',
                    }}
                    mt={2}
                    ml={5}
                    variant='solid'
                    w={'180px'}>
                    Search
                </Button>
  
                </>}  



{ showZsmart && <>
  <Flex justifyContent={'space-between'} >
                <HStack  mt={'-5'}  direction='row' spacing={2}>
                <FormControl ml={2}>
                    <FormLabel>Report:</FormLabel> 
                    <Select placeholder='Search by:' w={'128px'} bg={'white'} onChange={(e) => setSelectedReport(e.target.value)} >
                        {reportType.map((opt) => (
                            <option key={opt.value} value={opt.value}>
                                {opt.label}
                            </option>
                        ))}
                    </Select>
        </FormControl>
        {
          selectedReport === 'REGIONAL' && <FormControl>
          <FormLabel>Select Region:</FormLabel> 
          <Select placeholder='Search by:' w={'128px'} bg={'white'}  onChange={(e)=> setSelectedRegionId(e.target.value)} >
          {region.length === 0 && <Spinner />}
    {region.map((regions) => (
      <option key={regions.id} value={regions.id}>
        {regions.name}
      </option>
    ))} 
          </Select>
</FormControl>
        }
       {
        selectedReport === 'SHOP' &&  <FormControl>
        <FormLabel>Select Shop:</FormLabel> 
        <Select placeholder='Search by:' w={'128px'} bg={'white'}  onChange={(e)=> setSelectedShopId(e.target.value)} >
        {shop.length === 0 && <Spinner />}
  {shop.map((shop) => (
    <option key={shop.id} value={shop.id}>
      {shop.name}
    </option>
  ))} 
        </Select>
</FormControl>
       }
        {
          selectedReport === 'ZONAL' && <FormControl>
          <FormLabel>Select Zone:</FormLabel> 
          <Select placeholder='Search by:' w={'128px'} bg={'white'}  onChange={(e)=> setSelectedZoneId(e.target.value)} >
          {zone.length === 0 && <Spinner />}
    {zone.map((zones) => (
      <option key={zones.id} value={zones.id}>
        {zones.name}
      </option>
    ))} 
          </Select>
</FormControl> 
        }
    <FormControl>
              <FormLabel>Start Date:</FormLabel>
            <Input
                placeholder="Select Date and Time"
                size="md"
                name='startDate'
                type="date"
                bg={'white'}
                onChange={handleStartDateInputChange}
                />
                </FormControl>
                <FormControl>
                <FormLabel>End Date:</FormLabel>
                <Input
                placeholder="Select Date and Time"
                size="md"
                name='endDate'
                type="date"
                bg={'white'}
                onChange={handleEndDateInputChange}
                />
                </FormControl>
           
</HStack>
  </Flex>
  <Button
                leftIcon={<FiSearch/>}
                    isLoading={loading}
                    loadingText='Loading'
                    spinnerPlacement='end'
                    onClick={handleTelco}
                    bg={'teal.300'}
                    color={'white'}
                    _hover={{
                        bg: 'orange.500',
                    }}
                    mt={2}
                    ml={5}
                    variant='solid'
                    w={'180px'}>
                    Search
                </Button>


</>}

       

                        </Center>
                        <Button
                leftIcon={<BiExport/>}
                    isLoading={loading}
                    loadingText='Loading'
                    spinnerPlacement='end'
                     onClick={handleExport}
                    bg={'blue'}
                    color={'white'}
                    _hover={{
                        bg: 'orange.500',
                    }}
                    mt={2}
                    ml={2}
                    variant='solid'
                    w={'150px'}>
                    Export to Excel
                </Button>

<TableContainer maxW={'100vw'} overflowX={'scroll'} bg={'white'} rounded={'lg'} mt={8} p={3}>

{searchresults?.length > 0 ? (
    


<Table id='customers'  variant='simple'>

                {/* <ExportToExcel data ={searchresults} fileName={'statistics_data'} /> */}
<TableCaption><Heading size='sm'>Search Results</Heading></TableCaption>

<Thead>
<Tr>
 <Th>#</Th>
 {/* <Th>First Name</Th>
 <Th>Last name</Th> */}
 <Th>Full Name</Th>
 <Th>National ID</Th>
 <Th>D.O.B</Th>
 <Th>Gender</Th>
 <Th>Address</Th>
 <Th>Phone</Th>
 {selectedReport === 'ALL' && <>
 <Th>Shop</Th>
 <Th>Zone</Th>
 <Th>Region</Th>
 </>}
 {selectedReport === 'SHOP'&& <> 
 <Th>Shop</Th>
 <Th>Zone</Th>
 </>}
 {selectedReport === 'ZONAL' &&  
  <Th>Zone</Th>
 }
 {selectedReport === 'REGIONAL' && 
  <Th>Region</Th>
 }
 <Th>Date/Time Created</Th>
 <Th>Registered by</Th>
 <Th>User Title</Th>
 <Th>Work Number</Th>
<Th>Approved by</Th>
 {selectCustomerSearch === 'onemoney' && 
 
 <Th>Platform Registered</Th>
}
<Th>Registration Type</Th>

</Tr>
</Thead>

<Tbody>
{searchresults?.map((data,index)=>{
     let user;
     let approver
     updatedId.forEach((item) => {
       if (data.createdBy === item.employeeAccount.id) {
         user = item;
       }
       if (data.approvedBy === item.employeeAccount.id) {
        approver = item
       }
     })
    //  let approver;
    //  updatedId.forEach((item) => {
    //    if (data.approvedBy === item.employeeAccount.id) {
    //      approver = item;
    //    }
    //  })

     let agent;
     ambassadorId.forEach((item) => {
      if (data.createdBy === item.employeeAccount.id) {
        agent = item;
      }
     })

     let myshop;
     shop?.forEach((item) => {
       if (data.registeredShopId === item.id) {
         myshop = item;
       }
     })
     let myzone;
     zone?.forEach((item) => {
       if (data.registeredZoneId === item.id) {
         myzone = item;
       }
     })
     let myregion;
     region?.forEach((item) => {
      if (data.registeredRegionId === item.id) {
        myregion = item
      }
     })

     
     const dateString =  data.birthday;
  const formattedDate = moment(dateString).format('DD-MM-YYYY');

    const formatDOB = (dob) => {
      const year = dob?.slice(0, 4);
      const month = dob?.slice(4, 6);
      const day = dob?.slice(6, 8);
      
      const mydate = new Date(`${year}-${month}-${day}`);
      const formattedDate = mydate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      });
    
      return formattedDate;
    };
    const dateOfBirth = data.dateOfBirth; // Replace with your input number
    const formattedDOB = formatDOB(dateOfBirth);

    const date = new Date(data.createdAt);
    const changedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();


        return (
           
          <Tr key={index}>
          <Td>{index +1}</Td>
          {/* <Td>{data.firstName}</Td>
          <Td>{data.lastName}</Td> */}
          <Td>{data.fullName ? `${data.firstName} ${data.lastName}` : `${data.firstName} ${data.surNames} ` }</Td>
        <Td>{data.nationalIdNumber ? data.nationalIdNumber : data.documentNbr}</Td>
        <Td>{data.dateOfBirth ? formattedDOB : data.birthday}</Td>
        <Td>{data.gender}</Td>
          <Td>{data.address}</Td>
          <Td>{data.user?.phoneNumber ? data.user?.phoneNumber : data.msisdn}</Td>
          {/* <Td >
          { data.verificationStatus === 'APPROVED' ? (<Button colorScheme='green' size='xs'>Approved</Button>) : data.verificationStatus ==='DECLINED' ? (<Button colorScheme='red' size='xs'>Declined</Button>):<Button colorScheme='orange' size='xs'>{data.verificationStatus}</Button>}
          </Td> */}
          {selectedReport === 'ALL' && 
          <>
          <Td>{myshop?.name}</Td>
          <Td>{myzone?.name}</Td>
          <Td>{myregion?.name}</Td>
          </>
          }
          {selectedReport === 'SHOP'&& 
          <>
          <Td>{myshop?.name}</Td>
          <Td>{myzone?.name}</Td>
          </>
          }
           {selectedReport === 'ZONAL' && 
         
          <Td>{myzone?.name}</Td>
        
          }
          {selectedReport === 'REGIONAL' &&
         <Td>{myregion?.name}</Td>

          }
          
          <Td>
          {`${changedDate}; ${formattedTime}`}</Td>
          {/* <Td>{ user?.firstName + user?.lastName ?  `${user?.firstName} ${user?.lastName}` : <Text color={'gray.500'}>Self-Registration</Text>}</Td> */}
          {/* <Td>{ user?.employeeAccount?.roles[0].name ?  `${user?.employeeAccount?.roles[0].name}` : <Text color={'gray.400'}>Not Available</Text>}</Td> */}
          <Td>
         
          { user?.firstName + user?.lastName  ?  `${user?.firstName} ${user?.lastName}`: agent?.firstName + agent?.lastName ? `${agent?.firstName} ${agent?.lastName}` :  data.user?.signupPlatform ==='MOBILE-APP' ?  (<Text color={'gray.500'}>Self-Registration</Text>): <Text color={'gray.600'}fontStyle={'italic'}>Not Available</Text>}
          
            </Td>
            <Td>
              {agent?.employeeAccount?.title ? agent?.employeeAccount?.title : user?.employeeAccount.title}
            </Td>
            <Td>
              {agent?.employeeAccount.workPhoneNumber ? agent?.employeeAccount?.workPhoneNumber : user?.employeeAccount.workPhoneNumber }
            </Td>
            <Td>
           { approver?.firstName + approver?.lastName  ? `${approver?.firstName} ${approver?.lastName}` : data.user?.signupPlatform ==='MOBILE-APP' ?  (<Text color={'gray.500'}>Self-Registration</Text>): data.user?.signupPlatform ==='WEB_PORTAL' ?  (<Text color={'gray.500'}>Web-Registration</Text>): data.user?.registrationType ==='BULK' ?  (<Text color={'gray.500'}>Bulk Web registration</Text>): <Text color={'gray.500'}fontStyle={'italic'}>Web-Registration</Text> }
            </Td>
           
          {selectCustomerSearch === 'onemoney' && 
            <Td>
              {data.user?.signupPlatform === 'WEB_PORTAL' ? (<Text fontSize={'md'}>Web Portal</Text>) : data.user?.signupPlatform === 'MOBILE_PORTAL' ? (<Text fontSize={'md'}>OneKyc Mobile App</Text>) : data.user?.signupPlatform === 'MOBILE-APP' ? (<Text fontSize={'md'}>OneMoney Mobile App</Text>) : <Text textColor={'gray.500'}>Not Recognised</Text>}

            </Td>
            }
                      <Td>{data.registrationType}</Td>

          
            {/* <Td>
              {agent?.employeeAccount.workPhoneNumber ? agent?.employeeAccount?.workPhoneNumber : user?.employeeAccount.workPhoneNumber}
            </Td> */}
         </Tr>
     )}) }        
</Tbody>

      

<Tfoot>
</Tfoot>

</Table>


 ): (
    <Table>
        <TableCaption><Text>No Data to Display</Text></TableCaption>
  <Tr>
  </Tr>
    
    </Table>
  )}
</TableContainer>


      
      </Box>
  )
}

export default CustomersReport
