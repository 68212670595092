
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Stack,
    Box,
    chakra,
    Wrap,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
    Flex,
    Text,
    Center,
    useToast,
    useDisclosure,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Badge,
    InputGroup,
    InputLeftElement,
    Input,
    FormControl,
    Spinner,
  } from '@chakra-ui/react'
  import { FiUserPlus,FiSearch } from 'react-icons/fi'
  import { BsChevronRight, BsPerson } from 'react-icons/bs';
  import { NavLink, useNavigate } from 'react-router-dom'
  import axios from '../../api/axios'
import { MdDelete, MdPreview } from 'react-icons/md';
import React, { useEffect, useState } from 'react'

const GETB_A_URL = 'employee/brand-ambassador/v1/get-brand-ambassador-by-key-account-rep-account'
const GETB_A_BYSHOP_URL = '/employee/brand-ambassador/v1/get-brand-ambassador-by-shop'
const GETUSERID_URL = '/employee/profile/v1/get-employee/'
const GETALL_BA_URL = '/employee/brand-ambassador/v1/get-all-brand-ambassadors'

function External() {
    const [user, setUsers] = useState([]);
const [loading, setLoading] = useState(false)
const [currentPage, setCurrentPage] = useState(1);
const [itemsPerPage, setItemsPerPage] = useState(50);
const [totalPages, setTotalPages] = useState(1);
const[keyAccountRepAccountId, setKeyaccountRepaccountId] = useState("")
const toast = useToast();
const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef()

    let emp = localStorage.getItem('user')
    emp = JSON.parse(emp);
// console.log(emp.jti)





const getUsers = async () => {
  setLoading(true);
  const token = localStorage.getItem("token");
  try {
   if(emp?.title === "KEY ACCOUNT REP"){

       const response = await axios.get(`${GETB_A_URL}?keyAccountRepAccountId=${emp?.jti}`, {
         headers: {
           'Authorization': `Bearer ${token}`
         }
       })
  
       if(response.status === 200) {
         setUsers(response.data.body)
         console.log(response.data.body)
         setLoading(false);
       }
   }
   if(emp?.title === "SHOP SUPERVISOR"|| emp?.title === 'CAREDESK PERSONNEL'){
    const res = await axios.get(`${GETB_A_BYSHOP_URL}?shopId=${emp.shopId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
  console.log(res.body)
      if(res.status === 200) {
        setUsers(res.data.body)
        setLoading(false);
      }
   }
   
   if(emp?.title === 'SUPER ADMIN') {
    
          const response = await axios.get(GETALL_BA_URL, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
      
          if (response.status === 200) {
            setUsers(response.data.body)
            // console.log(response.data.body)
            setLoading(false);
          }
   }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // toast({
      //   title: "Error",
      //   description: `Server responded with ${error.message}`,
      //   status: "error",
      //   duration: 5000,
      //   isClosable: true,
      // });
     
    } else if (error.request) {
      // The request was made but no response was received
      toast({
        title: "Network Error",
        description: "Please Check your Internet Connection",
        status: "error",
        duration: 8000,
        isClosable: true,
      
      });
   
    } else {
      // Something happened in setting up the request that triggered an Error
      toast({
        title: "Error",
        description: `Error:`,
        status: "error",
        duration: 8000,
        isClosable: true,
      });
     
    }
  }
}

// const getEmployee = async () => {
//   setLoading(true);
//   const token = localStorage.getItem("token");
//   try {
//     const response = await axios.get(GETALL_BA_URL, {
//       headers: {
//         'Authorization': `Bearer ${token}`
//       }
//     })

//     if (response.status === 200) {
//       setUsers(response.data.body)
//       // console.log(response.data.body)
//       setLoading(false);
//     }
//   } catch (error) {

//   }
// }

// useEffect(() => {

//   getEmployee();

// }, [])

useEffect(() => {

  getUsers();

}, [])



// const handleDelete = async (id) => {
//   setLoading(true)
//   const token = localStorage.getItem("token");
//   try {
    
//     const response = await axios.delete(DELETEUSER_URL + `${id}`, {
//       headers: {
//         'Authorization': `Bearer ${token}`
//       }
//     });
//     if(response.data.success) {
//       getUsers();
//       onClose();
//       toast({
//         title: 'Success',
//         description: "Deleted",
//         status: 'success',
//         duration: 6000,
        
//       });
//       setLoading(false)
    
//     }else{
//       toast({
//         title: 'Failed',
//         description: "Failed to Delete",
//         status: 'error',
//         duration: 6000,
        
//       });
//       setLoading(false)
//     }
//   } catch (error) {
    
//   }
// };
  
useEffect(() => {
  setTotalPages(Math.ceil(user?.length / itemsPerPage));
}, [user]);

const paginateData = (user, currentPage,itemsPerPage) => {
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  return user?.slice(startIndex, endIndex);
};

const handlePageChange = (page) => {
  setCurrentPage(page);
}

const paginatedData = paginateData(user, currentPage, itemsPerPage);

  const navigate = useNavigate();
  const [search, setSearch] = useState('');
//   console.log(search)
  return (
    <Box mx={'auto'} pt={1} px={{ base: 2, sm: 12, md: 17 }}>
    <Stack direction='row' spacing={4}>
<Button onClick={()=>navigate("/admin/create-extuser")} leftIcon={<FiUserPlus />} colorScheme='messenger' variant='solid'>
  Add user
</Button>
</Stack>
<Box mt={3} ml={2}>

<FormControl justifyContent={'right'} alignContent='flex-end' >
      <InputGroup>
      <InputLeftElement
      pointerEvents={'none'} 
      children={<FiSearch color='gray.300' /> }
      />
      <Input width='auto' placeholder='Search.. ' bg={'white'} onChange={(e)=>setSearch(e.target.value)} />
      </InputGroup>
    </FormControl>
      </Box>
 
    <Stack p="3" bg='white' boxShadow="lg" m="4" borderRadius="sm">
    <TableContainer>
  <Center>

  <Table variant='simple' >
    <TableCaption> {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
    
    <Button
    colorScheme={'orange'}
    m={1}
    key={page}
    onClick={() => handlePageChange(page)}
    color={page === currentPage ? 'black' : 'white'}
    cursor="pointer"

    >
            {page}
          </Button>
        ))}</TableCaption>
    <Thead>
      <Tr>
        <Th>#</Th>
        <Th>First Name</Th>
        <Th>Last name</Th>
        {/* <Th>Email Address</Th>  */}
        <Th>Mobile Number</Th>
        <Th>Action</Th>
      </Tr>
    </Thead>
    {loading ? (
      <Flex alignItems='center' justifyItems={'center'}>
      <Spinner
      thickness='4px'
      speed='0.85s'
      emptyColor='gray.200'
      color='blue.500'
      size='xl'
/>
      </Flex>
    ):(
    <Tbody>
    {paginatedData?.filter((cust) => {
        return search.toLowerCase() === '' ? 
        cust : cust.firstName.toLowerCase().includes(search) ||
        cust.lastName.toLowerCase().includes(search)
        
      }).map((data,index)=>{
      return(
      <Tr key={index}>
      <Td>{index +1}</Td>
      <Td>{data.firstName == null ? '': data.firstName}</Td>
      <Td>{data.lastName == null ? '': data.lastName }</Td>
     {/* <Td>{data.employeeAccount.workEmail == null ? '' : data.employeeAccount.workEmail}</Td>  */}
      <Td>{data.employeeAccount.workPhoneNumber == null ? (<Text fontSize={'3xs'}>No text</Text>) : data.employeeAccount.workPhoneNumber }</Td>
      {/* {data.employeeAccount?.roles == null ? '' : data.employeeAccount.roles.map((item) => (
         <Td key={item.id} pt='2' fontSize='sm'>
         {item.name}
       </Td>
        ))} */}
     
      <Td>
        <Wrap spacing={2} direction='row' align='center'>
          <Button onClick={() => navigate(`/admin/view-user`,{
                state: data
              })} colorScheme='gray' size='xs' leftIcon={<MdPreview/>}>View</Button>
           {/* <Button colorScheme='cyan' size='xs'>Edit</Button> */}
         
        </Wrap>
      
      </Td>
    </Tr>
    )
  })}
    </Tbody>
    )}

    <Tfoot>
  
    </Tfoot>
    
  </Table>
  </Center>
  <Flex justifyContent={'flex-end'} mr={10} >

 
        </Flex>
</TableContainer>

    </Stack>
</Box>
  )
}

export default External