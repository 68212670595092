import {
  Box,
  chakra,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  useToast,
  StatHelpText,
  StatArrow,
  StatGroup,
  Stack,
  Heading,
  Spinner,
  Text,
  Badge,
} from '@chakra-ui/react';
import { BsChevronRight, BsPerson } from 'react-icons/bs';
import { FiUsers } from 'react-icons/fi';
import CustomerPendingList from '../form/CustomerPendingList';
import axios from '../../api/axios';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom'
import { FaCity, FaUserCheck, FaUserClock, FaUserMinus, FaUserPlus, FaUsers } from 'react-icons/fa';
import TodayStats from './TodayStats';

const GETSTATS_URL = '/onboarding/operator/dashboard';
const GETALLSTATS_URL = '/onboarding/operator/get-statistics?resultType=STATISTICS'
const GETREGION_URL = '/employee/region/v1/get-regions';
const GETTELCO_URL = '/onboarding/telco/operator/get-statistics?resultType=STATISTICS'


function StatsCard(props) {

  const { title, stat, icon } = props;
  return (
    <Stat
      px={{ base: 2, md: 4 }}
      py={'5'}
      shadow={'xl'}
      border={'1px solid'}
      bgColor={useColorModeValue('white')}
      borderColor={useColorModeValue('white', 'white')}
      rounded={'lg'}>

      <Flex justifyContent={'space-between'}>
        <Box pl={{ base: 2, md: 4 }}>
          <StatLabel fontSize={'xs'} fontWeight={'medium'} isTruncated>
        Total Registered in<Badge ml='1' variant={'solid'} colorScheme='teal' fontSize='0.8em'>{title}</Badge>
          </StatLabel>
          <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
            {stat}
          </StatNumber>
        </Box>

        <Box
          my={'auto'}
          color={useColorModeValue('white', 'white')}
          alignContent={'center'}>
          {icon}
        </Box>

      </Flex>
    </Stat>
  );
}
function RegionalStats() {
  const [loading, setLoading] = useState(false);
  const [statistics, setStatistics] = useState({});
  const [stats, setStats] = useState({})
  const [telco, setTelco] = useState({})
  const toast = useToast();

  // const getStats = async () => {
  //   const token = localStorage.getItem("token");
  //   try {
  //     const response = await axios.get(`${GETSTATS_URL}`, {
  //       headers: {
  //         'Authorization': `Bearer ${token}`
  //       }
  //     })
  //     if (response.data.success === true) {
  //       setStatistics(response.data.body)
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     if (error.response) {
  //       // The request was made and the server responded with a status code
  //       // that falls out of the range of 2xx
  //       toast({
  //         title: "Error",
  //         description: `Server responded with ${error.message}`,
  //         status: "error",
  //         duration: 5000,
  //         isClosable: true,
  //       });

  //     } else if (error.request) {
  //       // The request was made but no response was received
  //       toast({
  //         title: "Network Error",
  //         description: "Please Check your Internet Connection",
  //         status: "error",
  //         duration: 8000,
  //         isClosable: true,
  //       });

  //     } else {
  //       // Something happened in setting up the request that triggered an Error
  //       toast({
  //         title: "Error",
  //         description: `Error:`,
  //         status: "error",
  //         duration: 8000,
  //         isClosable: true,
  //       });

  //     }
  //   }
  // }

  // useEffect(() => {
  //   getStats();

  // }, [])

  const getAllWeeks = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${GETALLSTATS_URL}&statisticsType=REGIONAL_GROUP&dashboardType=LANDING`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      if (response.data.success === true) {
        setStats(response.data.body.regionalStatistics)
        setLoading(false)
        // console.log(response.data.body.regionalStatistics)
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // toast({
        //   title: "Error",
        //   description: `Server responded with ${error.message}`,
        //   status: "error",
        //   duration: 5000,
        //   isClosable: true,
        // });

      } else if (error.request) {
        // The request was made but no response was received
        toast({
          title: "Network Error",
          description: "Please Check your Internet Connection",
          status: "error",
          duration: 8000,
          isClosable: true,
        });

      } else {
        // Something happened in setting up the request that triggered an Error
        toast({
          title: "Error",
          description: `Error:`,
          status: "error",
          duration: 8000,
          isClosable: true,
        });

      }
    }
  }

  useEffect(() => {
    getAllWeeks();

  }, [])

  const getAllTelco = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${GETTELCO_URL}&statisticsType=REGIONAL_GROUP&dashboardType=LANDING`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      if (response.data.success === true) {
        setTelco(response.data.body.regionalStatistics)
        setLoading(false)
        // console.log(response.data.body.regionalStatistics)
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // toast({
        //   title: "Error",
        //   description: `Server responded with ${error.message}`,
        //   status: "error",
        //   duration: 5000,
        //   isClosable: true,
        // });

      } else if (error.request) {
        // The request was made but no response was received
        toast({
          title: "Network Error",
          description: "Please Check your Internet Connection",
          status: "error",
          duration: 8000,
          isClosable: true,
        });

      } else {
        // Something happened in setting up the request that triggered an Error
        toast({
          title: "Error",
          description: `Error:`,
          status: "error",
          duration: 8000,
          isClosable: true,
        });

      }
    }
  }

  useEffect(() => {
    getAllTelco();

  }, [])

  const [region, setRegion] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(75);
  const [totalPages, setTotalPages] = useState(1);

  const getAllRegions = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${GETREGION_URL}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })

      if (response.status === 200) {
        setRegion(response.data.body)
        setLoading(false)
        setLoading(false);
      }
    } catch (error) {

    }
  }
  // console.log(regId)


  useEffect(() => {
    getAllRegions();

  }, [])



  let user = localStorage.getItem('user')
  user = JSON.parse(user);

  const currentHour = new Date().getHours();
  let greeting;

  if (currentHour < 12) {
    greeting = 'Good morning';
  } else if (currentHour < 18) {
    greeting = 'Good afternoon';
  } else {
    greeting = 'Good evening';
  }

 
  //  console.log(stats.regionalStatistics)


  return (
    <>
      <Box maxW="full" pt={1} px={{ base: 2, sm: 12, md: 12 }}>
        <Breadcrumb spacing='8px' separator={<BsChevronRight color='gray.500' />}>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href='#'></BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        {/* <chakra.h1
          textAlign={'left'}
          fontSize={'1xl'}
          
          fontWeight={'bold'}>
       {greeting}, {user.firstName} {user.lastName}
        </chakra.h1> */}

        {/* <TodayStats/> */}
        <Stack
          spacing={4}

          minW={'10vw'}
          bg={useColorModeValue('gray.100', 'gray.800')}
          rounded={'xl'}
          boxShadow={'sm'}
          p={6}
          my={8}>

          <Heading lineHeight={1.1} fontSize={{ base: '1xl', sm: 'md' }}>
           OneMoney Regional Statistics
          </Heading>
          {loading ? (
      <Flex>
      <Spinner
      thickness='4px'
      speed='0.85s'
      emptyColor='gray.200'
      color='orange.300'
      size='lg'
/>
      </Flex>
    ):(
          <SimpleGrid mb={2} columns={{ base: 1, md: 4 }} spacing={{ base: 5, lg: 4 }} >
            {/* {stats.length === 0 &&
              <Spinner
                alignItems={'center'}
                justifyItems={'center'}
                thickness='4px'
                speed='0.95s'
                emptyColor='gray.200'
                color='orange.500'
                size='md'
              />} */}


            {Object.entries(stats)?.map(([key, value]) => {
              // console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"

              return (
                <StatsCard
                  title={key}
                  stat={value}
                  icon={<FaCity color='brown' size={'1.3em'} />}

                />
              )
            })}
            {/* <StatsCard
                    title={regiones?.name}
                    stat={regvalue}
                    icon={<FaUsers color='orange' size={'2em'} />}
        
                    /> */}


            {/* <StatsCard
          title={'Total Registered Weekly'}
          stat={stats.weekly}
          icon={<FaUsers color='purple' size={'2em'} />}
        />
        <StatsCard
          title={'Total Registered Monthly'}
          stat={stats.monthly}
          icon={<FaUsers color='orange' size={'2em'} />}
        />
          <StatsCard
          title={'Total Registered Annualy'}
          stat={stats.annual}
          icon={<FaUsers color='green' size={'2em'} />}
        /> */}


          </SimpleGrid>
    )}
        </Stack>
       

        {/* <Stack
          spacing={4}

          minW={'10vw'}
          bg={useColorModeValue('gray.100', 'gray.800')}
          rounded={'xl'}
          boxShadow={'sm'}
          p={6}
          my={8}>

          <Heading lineHeight={1.1} fontSize={{ base: '1xl', sm: 'md' }}>
           Telco Regional Statistics
          </Heading>
          <SimpleGrid mb={2} columns={{ base: 1, md: 4 }} spacing={{ base: 5, lg: 4 }} >
            {telco.length === 0 &&
              <Spinner
                alignItems={'center'}
                justifyItems={'center'}
                thickness='4px'
                speed='0.95s'
                emptyColor='gray.200'
                color='orange.500'
                size='md'
              />}


            {Object.entries(telco).map(([key, value]) => {
              console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
              let regiones;

              region.map((item) => {
                if (`${key}` === item.id) {
                  regiones = item;
                  console.log(regiones);

                }
              })

              return (
                <StatsCard
                  title={regiones?.name ? (<Text display={{ base: 'flex', md: 'none' }} fontSize="xl" fontFamily="monospace" fontStyle={'oblique'}>{regiones?.name} </Text>) : <Text color={'gray.400'} >Region not found</Text>}
                  stat={value}
                  icon={<FaUsers color='orange' size={'2em'} />}

                />
              )
            })}
           
          </SimpleGrid>
        </Stack> */}
       
        </Box>
      </>

    
  );
}

export default RegionalStats