import React,{useEffect,useState} from 'react'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Stack,
  Box,
  chakra,
  Wrap,
  Button,
  Text,
  Center,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import axios from '../../api/axios'
import { MdPreview } from 'react-icons/md';

const GETPENDINGCUST_URL = '/onboarding/operator/get-pending-customers';

export default function CustomerPendingList() {
  // const params = useParams();
  // const id = params.id
  const [pendingcustomers, setPendingCustomers] = useState([]);

useEffect(() => {
  const getPendingCustomers = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${GETPENDINGCUST_URL}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })

      if(response.status === 200) {
        setPendingCustomers(response.data.body)
      }
    } catch (error) {
    }
  }

  getPendingCustomers();

}, [])


  const navigate = useNavigate();
  return (
  
     <>
       <chakra.h1
        textAlign={'left'}
        fontSize={'1xl'}
        mt={16}
        mb={2}
        px={16}
        fontWeight={'bold'}>
     OneMoney Account Approval Request list:
      </chakra.h1>
      <Stack direction='row' spacing={4}>
 
  </Stack>
    <Stack width={'1000px'} mx={16} p="4" bg='white' boxShadow="lg"  borderRadius="sm">
    <TableContainer width={'1190px'}>
    {pendingcustomers.length > 0 ? (
  <Table variant='simple'>
    <TableCaption></TableCaption>
    <Thead>
      <Tr>
        <Th>#</Th>
        <Th>First Name</Th>
        <Th>Last Name</Th>
        <Th>Mobile number</Th>
        <Th>Status</Th>
        
        {/* <Th isNumeric>multiply by</Th> */}
        
        <Th>Action</Th>
      </Tr>
    </Thead>
    <Tbody>
    {pendingcustomers.map((data,index)=>{
        return (
          <Tr key={index}>
          <Td>{index +1}</Td>
          <Td>{data.firstName}</Td>
          <Td>{data.lastName}</Td>
          <Td>{data.user.phoneNumber}</Td>
       
          <Td >
            { data.verificationStatus === 'APPROVED' ? (<Button colorScheme='green' size='xs'>APPROVED</Button>) : <Button colorScheme='yellow' size='xs'>{data.verificationStatus}</Button> }
          </Td>
  
          <Td>
            <Wrap spacing={2} direction='row' align='center'>
              <Button onClick={() => navigate(`/admin/manage-approval`,{
                state: data
              })} colorScheme='blue' size='md' leftIcon={<MdPreview/>}>View</Button>
              {/* <Button colorScheme='cyan' size='xs'>Edit</Button>
              <Button colorScheme='red' size='xs'>Delete</Button> */}
            </Wrap>
          </Td>
        </Tr>
        )}) }
    </Tbody>
    <Tfoot>
    </Tfoot>
  </Table>
   ): (
    <Table>

    <Thead>
    <Tr>
      <Th>#</Th>
      <Th>First Name</Th>
      <Th>Last Name</Th>
      <Th>Mobile number</Th>
      <Th>Status</Th>
      <Th>Action</Th>
    </Tr>
  </Thead>
  <Tr>
  <Tfoot display={'flex'}><Text fontSize={'lg'} textColor='gray.500'>No data available</Text></Tfoot>
  </Tr>
    
    </Table>
  )}
</TableContainer>

    </Stack>
    </>

  )
}
