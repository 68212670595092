import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, Heading, Input, Stack, Text, Wrap, useDisclosure, useToast } from "@chakra-ui/react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Box, Button,chakra } from "@chakra-ui/react";
import { read, utils, writeFile } from 'xlsx'
import { BiExport } from 'react-icons/bi'
import { Tag } from 'primereact/tag';

import axios from "../../../../api/axios";
import { useNavigate } from "react-router-dom";
import { MdDelete, MdPreview } from "react-icons/md";
import { FiUserPlus } from "react-icons/fi";

const GET_EMPLOYEE_URL = "/employee/profile/v1/get-employees?availability=LEAVE";
const DELETEUSER_URL = '/employee/profile/v1/delete-employee/'


export default function OnLeaveNew() {
  const [products, setProducts] = useState([]);
  const [getEmployee, setGetemployee] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [open,setOpen] = useState(false)
  const [currentData,setCurrentData] = useState()
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef()


  const handleExport = () => {
    const headings = [[ "FirstName","LastName","JobTitle","Department", "Station","DOB","National Id Number","Drivers license Class","Mobile Number(980)","Alternate Number","Residential Address","NextofKin Name","NextofKin Relationship", "NextofKin Phone Number", "NextOfKin Address"]];
    const wb = utils.book_new();
    const ws = utils.table_to_sheet(document.getElementById('id'),{raw: true});
    utils.sheet_add_aoa(ws, headings);
 //    utils.sheet_add_json(ws, searchresults, { origin: "A2", skipHeader: true});
    utils.book_append_sheet(wb, ws, "Employee_Report");
    writeFile(wb, "Employee_Report.xlsx")
     };

  

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    firstName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    lastName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    availability: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "employeeAccount.workPhoneNumber": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  /* Fetching Data From Server */
  const getAllEmployees = async () => {
    const token = localStorage.getItem("token");
    try {
      setLoading(true);
      const response = await axios.get(`${GET_EMPLOYEE_URL}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setGetemployee(response.data.body);
        console.log(response.data.body);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllEmployees();
  }, []);

  const handleDelete = async (data) => {
    setLoading(true)
    const token = localStorage.getItem("token");
    try {
      
      const response = await axios.delete(DELETEUSER_URL + `${data.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if(response.data.success) {
        getAllEmployees();
        onClose();
        toast({
          title: 'Success',
          description: "Deleted",
          status: 'success',
          duration: 6000,
          
        });
        setLoading(false)
      
      }else{
        toast({
          title: 'Failed',
          description: "Failed to Delete",
          status: 'error',
          duration: 6000,
          
        });
        setLoading(false)
      }
      setOpen(false)
    } catch (error) {
      
    }
  };

  // const date = new Date(dateOfBirth);
  // const formattedDate = date.toLocaleDateString();

  /* End Of Fetching Data From Server */

  /* Table Header */
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <Input
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const statusBodyTemplate = (getEmployee) => {
    return <Tag value={getEmployee.availability} severity={getSeverity(getEmployee)}></Tag>;
};

  const getSeverity = (getEmployee) => {
    switch (getEmployee.availability) {
        case 'PRESENT':
            return 'success';

        case 'LEAVE':
            return 'warning';

        case 'ABSENT':
            return 'danger';

        default:
            return null;
    }
};

const actionBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            <Wrap spacing={2} direction='row' align='center'>
            <Button colorScheme='gray' size='xs' leftIcon={<MdPreview />} onClick={() => navigate(`/admin/view-user`,{
                state: rowData
              })} >View</Button>
            {/* <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeleteProduct(rowData)} /> */}
            <Button onClick={()=>{
            setCurrentData(rowData)
            setOpen(true)}}   colorScheme='red' size='xs' leftIcon={<MdDelete />}>Delete</Button> 
               </Wrap>
        </React.Fragment>
    );
};


  const header = renderHeader();
  /* End of Table Header */

 

  return (
    <>
          <Stack direction='row' spacing={4}>
      <AlertDialog
        isOpen={open}
        leastDestructiveRef={cancelRef}
        onClose={()=>{setOpen(false)}}
      >

          <AlertDialogContent>
            <AlertDialogHeader bg={'yellow.200'} fontSize='lg' fontWeight='bold'>
              <Text>
                Confirm 
              </Text>
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete <Heading size={'xs'}>{`${currentData?.firstName} ${currentData?.lastName}`}'s account?
                </Heading> You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={()=>{setOpen(false)}}>
                Cancel
              </Button>
              <Button onClick={() => handleDelete(currentData)} colorScheme='red'  ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>

      </AlertDialog>
  {/* <Button onClick={()=>navigate("/admin/create-user")} leftIcon={<FiUserPlus />} colorScheme='orange' variant='solid'>
    Create user
  </Button> */}
  </Stack>

 
    <Button
               leftIcon={<FiUserPlus />}
                onClick={()=>navigate("/admin/create-user")}
                bg={'orange'}
                color={'white'}
                _hover={{
                    bg: 'black',
                    color: 'white',
                }}
               
                mb={2}
                variant='solid'
                w={'150px'}>
                    Create User
                </Button>
    <Box
    mt={4}
    boxShadow={'lg'}
    rounded={'md'}
    >

      
    
      <DataTable
      id="id"
        value={getEmployee}
        header={header}
        paginator
        rows={50}
        dataKey="id"
        tableStyle={{ minWidth: "50rem" }}
        filters={filters}
        showGridlines
        filterDisplay="row"
        loading={loading}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        globalFilterFields={[
          "firstName",
          "lastName",
          "employeeAccount.workPhoneNumber",
          "availability"
       
        ]}
        emptyMessage="No employees found.">
        <Column
          field="firstName"
          header="First Name"
          sortable
          filter
          filterPlaceholder="Search by first name"
          style={{ minWidth: "8rem" }}></Column>
        <Column
          field="lastName"
          header="Last Name"
          sortable
          filter
          filterField="lastName"
          filterPlaceholder="Search by last name"
          style={{ minWidth: "12rem" }}></Column>

<Column
          field="employeeAccount.workPhoneNumber"
          header="Mobile Number"
          sortable
          filter
          filterField="employeeAccount.workPhoneNumber"
          filterPlaceholder="Search by number"
          style={{ minWidth: "12rem" }}></Column>
        <Column header="Status" body={statusBodyTemplate}></Column>
        <Column header="Action" body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem'  }}></Column>


          
         
      </DataTable>
      </Box>
    </>
  );
}