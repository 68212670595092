import React, { useState, useRef, useEffect } from 'react'
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Container,
  Box,
  RadioGroup,
  HStack,
  Radio,
  Text,
  Heading,
  Center,
  Stack,
  useToast,
  InputGroup,
  InputLeftAddon,
  Image,
  Spinner,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Flex,
  CircularProgress,
  Progress,
  Textarea,
  Img,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import axios from '../../api/axios';
// import { useAuth } from '../../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
// import GetDateFormat from '../../context/GetDateFormat';
import { Link, NavLink } from 'react-router-dom'
import { BsChevronRight, BsPerson } from 'react-icons/bs';
import c2 from '../../images/c2.png'
import db from '../data/db'
import citydata from '../data/citydata'
import coutrydata from '../data/countrydata.json'
import { stream } from 'xlsx';
import { FaCamera } from 'react-icons/fa';



const SIMREG_URL = 'onboarding/telco/operator/register-prepaid-subscriber'
const CREATECUST_URL = 'onboarding/operator/create-customer'

const initialState =
{
  firstName: "",
  middleName: "",
  phoneNumber: "",
  lastName: "",
  citizenship: "",
  nationalIdNumber: "",
  country: "",
  gender: "",
  dateOfBirth: "",
  address: "",
  email: "",
  imageFile: "",
  // pictureFile: "",
}

export default function SimRegistration() {
  const navigate = useNavigate();
  // const auth = useAuth();
  const [state, setState] = useState(initialState);
  const toast = useToast();
  const createForm = useRef();
  const [progress, setProgress] = useState(0);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [previewProofUrl, setPreviewProofUrl] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);


  const [fnameError, setFNameError] = useState('')
  const [mdnameError, setMdNameError] = useState('')
  const [lnameError, setLNameError] = useState('')
  const [citiError, setCitiError] = useState('')
  const [genderError, setGenderError] = useState('')
  const [bdateError, setBDError] = useState('')
  const [addressError, setAddressError] = useState('')
  const [imgError, setImgError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [phoneError, setPhoneError] = useState('')
  const [natIdError, setNatIdError] = useState('')
  const [oneMoney, setOneMoney] = useState("Yes")

  const [city, setCity] = useState("")
  const [province, setProvince] = useState("")
  const [region, setRegion] = useState("")
  // const [country, setCountry] = useState("")

  const { isOpen, onOpen, onClose } = useDisclosure()
  const {isOpen: isModeOpen, onOpen: onModeOpen, onClose: onModeClose} = useDisclosure();

  const cancelRef = React.useRef()

  const [selectType, setSelectType] = useState('')


  // const [selectedDocumentType, setSelectedDocumentType] = useState("");
  const [showID, setShowID] = useState(false)
  const [showPassport, setShowPassport] = useState(false);

  const customers = [
    // { label: 'Operator', value: "OPERATOR" },
    { label: 'National ID', value: "id"},
    { label: 'Passport', value: "passport"},
  ]
  useEffect(() => {
    if(selectType === 'id') {
      setShowID(true)
    } else {
      setShowID(false)
    }
  }, [selectType])

  useEffect(() => {
    if(selectType === 'passport') {
      setShowPassport(true)
    } else {
      setShowPassport(false)
    }
  }, [selectType])


  const { firstName, middleName, phoneNumber, lastName, citizenship, nationalIdNumber,country, gender, dateOfBirth, address, email, imageFile } = state
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  const handleAllsubmit = () => {
    setError('')
    const res = validate();
    if (!res) {
      console.log(`validation failed`)
      toast({
        title: 'Failed',
        description: `Check for errors`,
        status: 'error',
        duration: 3000,

      });
      return;
    }
    setLoading(true);
  
    const token = localStorage.getItem("token");
    // alert(firstName + ", ||" + middleName + ",||" + lastName + ",||" + phoneNumber + ",||" + citizenship + ",||" + nationalIdNumber + ",||" + gender + ",||" + dateOfBirth + ",||" + address + ",||" + email + ",||" + password)
    const formData = new FormData(createForm.current);
    formData.set("msisdn", `263${state.phoneNumber}`)
    formData.set("surNames", state.lastName)
    formData.set("birthday", state.dateOfBirth)
    formData.set("country", selectedCountry)
    formData.set("gender", state.gender.toUpperCase())
    formData.set("documentType", "1")
    formData.set("dcoumentExpiryDate", "2040-04-12")
    formData.set("documentNbr", state.nationalIdNumber.toUpperCase())
    formData.append("nationalIdPicture", imageData)
    formData.set("signupPlatform", "WEB_PORTAL")

    axios.post(`${SIMREG_URL}`, formData, {
      onUploadProgress: (progressEvent) => {
        setProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100));
      },
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      }
    })
      .then(res => {
        if (res.data.success) {
          toast({
            title: 'Sim Registration success.',
            description: res.data.message,
            status: 'success',
            duration: 8000,

          });
          setLoading(false)
          if (oneMoney === 'Yes') {
            formData.set("dateOfBirth", state.dateOfBirth.split("-").join(""))
            formData.set("gender", state.gender.toUpperCase() === 'M' ? "MALE" : "FEMALE")
            formData.set("nationalIdNumber", state.nationalIdNumber.toUpperCase().replace("-", ""))
            formData.set("country", selectedCountry)
            formData.append("nationalIdPicture", imageData)
            formData.set("signupPlatform", "WEB_PORTAL")
            return axios.post(`${CREATECUST_URL}`, formData, {
              onUploadProgress: (progressEvent) => {
                setProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100));
              },
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
              }
            })
          } else {
            navigate('/admin/search-customer')
            setLoading(false)
          }
        } else {
          toast({
            title: 'Sim Registration Failed',
            description: res.data.message,
            status: 'error',
            duration: 6000,

          });
          setLoading(false)
        }
      })
      .then(res => {
        if (res) {
          if (res.data.success) {
            navigate('/admin/search-customer')
            toast({
              title: 'OneMoney Registration success.',
              description: res.data.message,
              status: 'success',
              duration: 8000,

            });
            setLoading(false)
          } else {
            toast({
              title: 'OneMoney Registration Failed',
              description: res.data.message,
              status: 'error',
              duration: 6000,

            });
            setLoading(false)
          }
        } else {
          toast({
            title: 'OneMoney Registration Failed',
            description: 'Failed to register because sim registration was unsuccessfull',
            status: 'error',
            duration: 6000,

          });
          setLoading(false)
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast({
            title: "Error",
            description: `Server responded with ${error.response.status}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          setLoading(false)
        } else if (error.request) {
          // The request was made but no response was received
          toast({
            title: "Network Error",
            description: "Please Check your Internet Connection",
            status: "error",
            duration: 8000,
            isClosable: true,
          });
          setLoading(false)
        } else {
          // Something happened in setting up the request that triggered an Error
          toast({
            title: "Network Error",
            description: `Error: ${error.message}`,
            status: "error",
            duration: 8000,
            isClosable: true,
          });

        }

      })
  };
  const handlesubmit = () => {
    setError('')
    const res = validate();
    if (!res) {
      console.log(`validation failed`)
      toast({
        title: 'Failed',
        description: `Check for errors`,
        status: 'error',
        duration: 3000,

      });
      return;
    }
    setLoading(true);
  
    const token = localStorage.getItem("token");
    // alert(firstName + ", ||" + middleName + ",||" + lastName + ",||" + phoneNumber + ",||" + citizenship + ",||" + nationalIdNumber + ",||" + gender + ",||" + dateOfBirth + ",||" + address + ",||" + email + ",||" + password)
    const formData = new FormData(createForm.current);
    formData.set("msisdn", `263${state.phoneNumber}`)
    formData.set("surNames", state.lastName)
    formData.set("birthday", state.dateOfBirth)
    formData.set("country", selectedCountry)
    formData.set("gender", state.gender.toUpperCase())
    formData.set("documentType", "1")
    formData.set("dcoumentExpiryDate", "2040-04-12")
    formData.set("documentNbr", state.nationalIdNumber.toUpperCase())
    formData.append("nationalIdPicture", imageData)
    formData.set("signupPlatform", "WEB_PORTAL")

    axios.post(`${SIMREG_URL}`, formData, {
      onUploadProgress: (progressEvent) => {
        setProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100));
      },
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      }
    })
      .then(res => {
        if (res.data.success) {
          toast({
            title: 'Sim Registration success.',
            description: res.data.message,
            status: 'success',
            duration: 8000,

          });
          setLoading(false)
        } else {
          toast({
            title: 'Sim Registration Failed',
            description: res.data.message,
            status: 'error',
            duration: 6000,

          });
          setLoading(false)
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast({
            title: "Sim Registration Error",
            description: `Server responded with ${error.response.status}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          setLoading(false)
        } else if (error.request) {
          // The request was made but no response was received
          toast({
            title: "Network Error",
            description: "Please Check your Internet Connection",
            status: "error",
            duration: 8000,
            isClosable: true,
          });
          setLoading(false)
        } else {
          // Something happened in setting up the request that triggered an Error
          toast({
            title: "Network Error",
            description: `Error: ${error.message}`,
            status: "error",
            duration: 8000,
            isClosable: true,
          });

        }

      })
  };
  const handleAttachFile = (e) => {
    const imageFile = e.target.files[0];

    if (imageFile && (imageFile.type === "image/png" || imageFile.type === "image/jpeg" || imageFile.type === "image/jpg")) {
      setState({ ...state, imageFile: e.target.files[0] });
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(imageFile);

    } else {
      setState({ ...state, imageFile: null });
      // setError("Please select a PNG or JPEG image file.");
      toast({
        title: 'Failed',
        description: 'Please select a PNG or JPEG image file.',
        status: 'error',
        duration: 3000,

      });

      setPreviewUrl(null);
    }


  }
  // const handleProof = (e) => {
  //   const pictureFile = e.target.files[0];

  //   if (pictureFile && (pictureFile.type === "image/png" || pictureFile.type === "image/jpeg" || pictureFile.type === "image/jpg")) {
  //     setState({ ...state, pictureFile: e.target.files[0] });
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setPreviewProofUrl(reader.result);
  //     };
  //     reader.readAsDataURL(pictureFile);

  //   } else {
  //     setState({ ...state, pictureFile: null });
  //     // setError("Please select a PNG or JPEG image file.");
  //     toast({
  //       title: 'Failed',
  //       description: 'Please select a PNG or JPEG image file.',
  //       status: 'error',
  //       duration: 3000,

  //     });

  //     setPreviewProofUrl(null);
  //   }


  // }
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("")
  // const towns = db
  // console.log(towns)
  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };
  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    console.log(event.target.value)
  };
  const cities = db

  const countries = coutrydata
  // Filter the cities array to get the unique provinces
  const provinces = [...new Set(cities.map((city) => city.province))];
  const regions = [...new Set(cities.map((city) => city.province))];
  const nationalities = [...new Set(countries.map((country) => country.nationality))]

  const validate = () => {
    const EMAIL_REGEX = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
    const nameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/i;
    const PHONE_REGEX = /^(71)/;
    const DATE_BIRTH_REGEX = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
    const NATID_regex = /^([0-9][0-9])-([0-9]{6}|[0-9]{7})([a-zA-Z])([0-9]{2})$/;
    const birthDate = new Date(dateOfBirth)
    const minimumAge = 16 // min age date set to 16 years

    const minimumAgeDate = new Date()
    minimumAgeDate.setFullYear(minimumAgeDate.getFullYear() - minimumAge)

    const maximumAge = 120; //maximum age date set to 120 years
    const maximumAgeDate = new Date();
    maximumAgeDate.setFullYear(maximumAgeDate.getFullYear() - maximumAge);

    if (!firstName) {
      setFNameError('Firstname is required');
      return false;
    }

    if (!nameRegex.test(firstName)) {
      setFNameError("Invalid first name. Only letters are allowed.");
      return false;
    }

    if (!lastName) {
      setLNameError('Lastname is required');
      return false;
    }

    if (!nameRegex.test(lastName)) {
      setLNameError(`Invalid ${lastName}. Only letters are allowed.`);
      return false;
    }

    // if (!citizenship) {
    //   setCitiError('citizenship is required');
    //   return false;
    // }
    // if (!citizenship.match(nameRegex)) {
    //   setCitiError("Invalid citizenship. Only letters are allowed.");
    //   return false;
    // }


    if (!gender) {
      setGenderError('gender is required');
      return false;
    }
    if (!dateOfBirth) {
      setBDError('date of birth is required');
      return false;
    }
    // if (!DATE_BIRTH_REGEX.test(dateOfBirth)) {
    //   setBDError("date of birth should be in correct format");
    //   return false;
    // }


    if (birthDate > minimumAgeDate) {
      // console.log("Date is less than 16 years ago");
      setBDError(`You must be at least ${minimumAge} years old`)
      return false;
    }

    if (birthDate < maximumAgeDate) {
      setBDError(`You cannot be older than ${maximumAge} years old`)
      return false;
    }

    if (!address) {
      setAddressError('address is required');
      return false;
    }
    // if (!email) {
    //   setEmailError('email is required');
    //   return false;
    // }
    // if (!EMAIL_REGEX.test(email)) {
    //   setEmailError('email not valid');
    //   return false;
    // }

    if (!phoneNumber) {
      setPhoneError('phone number required');
      return false;
    }
    if (phoneNumber.length < 9) {
      setPhoneError('phone number invalid');
      return false;
    }
    if (phoneNumber.length > 10) {
      setPhoneError('phone number invalid');
      return false;
    }
    if (!PHONE_REGEX.test(phoneNumber)) {
      setPhoneError('please enter a netone number');
      return false;
    }
    if (!nationalIdNumber) {
      setNatIdError('national id required')
      return false;
    }

    if (!NATID_regex.test(nationalIdNumber)) {
      setNatIdError('please put hyphen after 2 digits');
      return false;
    }

    if (!imageFile) {
      setImgError('Please select a picture')
      return false;
    }
    // if (!pictureFile) {
    //   setImgError('Please insert picture')
    //   return false;
    // }

    return true

  }

  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const [imageData, setImageData] = useState(null);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
    } catch (error) {
      console.error('Error accessing the camera:', error);
      toast({
        title: 'Camera Access',
        description: 'Failed to get permission to start camera',
        status: 'error',
        duration: 6000,

      });
    }
  };

  function dataURLtoFile(dataURL, filename) {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([new Blob([u8arr], { type: mime })], filename, { type: mime });
  }

  const captureImage = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);

    const dataURL = canvas.toDataURL('image/png');
    const file = dataURLtoFile(dataURL, 'nationalIdPicture');
    setImageData(file);
    // setState({ ...state, imageFile: file });
    console.log(file)
    onModeClose()
  };

  

  const [showFileUpload, setShowFileUpload] = useState(false)
  const [showCamera, setShowCamera] = useState(false);
  const [selectCategory, setSelectCategory] = useState('')

  const category = [
    { label: 'File Upload', value: "fileUpload" },
    { label: 'Camera', value: "camera" },
  ]

  useEffect(() => {
    if (selectCategory === 'fileUpload') {
      setShowFileUpload(true)
    } else {
      setShowFileUpload(false)
    }
  }, [selectCategory])

  useEffect(() => {
    if (selectCategory === 'camera') {
      setShowCamera(true)
      onModeOpen()
    } else {
      setShowCamera(false)
    }
  }, [selectCategory])


  useEffect(() => {

  }, [loading])


  return (
    <Container maxW={'3xl'}>
      <Flex justifyContent="flex-end">
        <Breadcrumb fontSize={'xs'} fontWeight={'semibold'} spacing='8px' separator={<BsChevronRight color='gray.500' />}>
          <BreadcrumbItem>
            <NavLink to={'/admin/operator'}>
              <BreadcrumbLink >  Home</BreadcrumbLink>
            </NavLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href='#'>Sim Registration</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>

      <Center>

        <Box

          borderRadius='lg' shadow='md' borderWidth='2px' bg='white'
          style={{
            width: "85%",
            justifyContent: "space-around",
            boxShadow: 50,
            marginTop: "20px",
            marginBottom: "20px",
            // display: "flex",
            //     flexDirection: "column",
            justifyContent: "space-evenly",

          }}

        >

          <Stack spacing={4} marginLeft='10' marginRight='10' marginBottom='2'  >
            <Box justifyContent={'center'} alignContent='center' display={'flex'}>
              {loading &&
                <Spinner
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='orange'
                  size='xl'

                />
              }
            </Box>
            <Stack align={'center'}>
              <Img
                src={c2}
                alt='logo'
                boxSize='100px'
              />
            </Stack>
            <Flex justifyContent={'center'}>
              <Heading
                lineHeight={1.1}
                fontWeight={400}
                fontSize={{ base: '1xl', sm: '2xl', lg: '3xl' }}
                marginTop='-4'
                marginBottom='2'
                textColor={'orange.500'}
              >
                Sim Registration Form
              </Heading>
            </Flex>
            <form ref={createForm}>
              <HStack spacing={8}>


                <FormControl isRequired>
                  <FormLabel htmlFor='firstName'>First name</FormLabel>
                  <Input
                    marginBottom={2}
                    id='firstName'
                    placeholder='First name'
                    type='text'
                    name='firstName'
                    value={firstName}
                    onFocus={() => { setFNameError('') }} borderColor={fnameError ? 'red.400' : 'gray.300'}
                    onChange={handleInputChange}


                  />
                  <Text color={'red'}>{fnameError}</Text>
                </FormControl>





                <FormControl>
                  <FormLabel htmlFor='middleName'>Middle name</FormLabel>
                  <Input
                    marginBottom={2}
                    id='middleName'
                    placeholder='Middle name'
                    type='text'
                    name='middleName'
                    value={middleName}
                    onFocus={() => { setMdNameError('') }} borderColor={mdnameError ? 'red.400' : 'gray.300'}
                    onChange={handleInputChange}

                  />
                  <Text color={'red'}>{mdnameError}</Text>
                </FormControl>

              </HStack>
              <HStack spacing={8}>

                <FormControl isRequired>
                  <FormLabel htmlFor='lastName'>Last name</FormLabel>
                  <Input
                    marginBottom={2}
                    id='lastName'
                    placeholder='Last name'
                    type='text'
                    name='lastName'
                    value={lastName}
                    onFocus={() => { setLNameError('') }} borderColor={lnameError ? 'red.400' : 'gray.300'}
                    onChange={handleInputChange}


                  />
                  <Text color={'red'}>{lnameError}</Text>
                </FormControl>


                <FormControl isRequired>
                  <FormLabel htmlFor='phoneNumber'>Mobile Number</FormLabel>
                  <InputGroup>
                    <InputLeftAddon children='+263' />
                    <Input id='phoneNumber'
                      type='text'
                      placeholder='712000000'
                      _placeholder={{ color: 'gray.500' }}
                      name='phoneNumber'
                      value={phoneNumber}
                      onFocus={() => { setPhoneError('') }} borderColor={phoneError ? 'red.400' : 'gray.300'}
                      onChange={handleInputChange}

                    />
                  </InputGroup>
                  <Text color={'red'}>{phoneError}</Text>
                </FormControl>



              </HStack>
              <HStack spacing={8}>


                <FormControl isRequired>
                  <FormLabel htmlFor='nationalIdNumber'>National ID:</FormLabel>
                  <FormHelperText>eg. 00-0000000A00</FormHelperText>
                  <Input
                    marginBottom={2}
                    id='nationalIdNumber'
                    placeholder='00-00000000A00'
                    type='text'
                    name='nationalIdNumber'
                    value={nationalIdNumber}
                    onFocus={() => { setNatIdError('') }} borderColor={natIdError ? 'red.400' : 'gray.300'}
                    onChange={handleInputChange}
                    textTransform='uppercase'
                  />
                  <Text color={'red'}>{natIdError}</Text>
                </FormControl>


                <FormControl isRequired>
                  <FormLabel htmlFor='address'>Address</FormLabel>
                  <Textarea
                    marginBottom={2}
                    id='address'
                    placeholder='Address'
                    type='text'
                    name='address'
                    value={address}
                    onFocus={() => { setAddressError('') }} borderColor={addressError ? 'red.400' : 'gray.300'}
                    onChange={handleInputChange}
                  />
                  <Text color={'red'}>{addressError}</Text>
                </FormControl>


              </HStack>

              <HStack spacing={8}>

                <FormControl>
                  <FormLabel htmlFor='email'>Email </FormLabel>
                  <Input
                    marginBottom={2}
                    id='email'
                    placeholder='example@email.com'
                    name='email'
                    type='email'
                    value={email}
                    onChange={handleInputChange}

                  />
                </FormControl>



                <FormControl isRequired >
                  <FormLabel htmlFor='dob'>Date Of Birth</FormLabel>
                  <Input
                    marginBottom={2}
                    letterSpacing={4}
                    name='dateOfBirth'
                    type='date'
                    id='dateOfBirth'
                    value={dateOfBirth}
                    onFocus={() => { setBDError('') }} borderColor={bdateError ? 'red.400' : 'gray.300'}
                    onChange={handleInputChange}
                  />
                  <Text color={'red'}>{bdateError}</Text>
                </FormControl>

              </HStack>
              <HStack spacing={8}>
                <FormControl>
                  <FormLabel>Country</FormLabel>
                  <Select
                    _hover={{
                      borderRadius: 'orange',
                    }}
                    placeholder='Select country' name='country' value={selectedCountry} onChange={handleCountryChange}>
                    {/* {towns.length === 0 && <Spinner />} */}
                    {countries.map((country) => (
                      <option key={country.en_short_name} value={country.en_short_name}>
                        {country.en_short_name}
                      </option>
                    ))}

                  </Select>
                </FormControl>

                <FormControl as='fieldset'>
                  <FormLabel as='legend' marginTop='10px' marginBottom={2}>Gender</FormLabel>
                  <RadioGroup defaultValue=''>
                    <HStack spacing='24px' >
                      <Radio value='M' name="gender" onChange={handleInputChange}>Male</Radio>
                      <Radio value='F' name="gender" onChange={handleInputChange} >Female</Radio>
                    </HStack>
                  </RadioGroup>
                  <Text color={'red'}>{genderError}</Text>
                  <FormHelperText>Select Gender</FormHelperText>
                </FormControl>
              </HStack>
              <HStack mt={2} spacing={8}>
                {/* <FormControl>
                  <FormLabel>City</FormLabel>
                  <Select value={city} width={'200px'} placeholder='Select City' name='city' onChange={(e) => setCity(e.target.value)}>
                    <option value='Harare'>Harare</option>
                  </Select>
                </FormControl> */}
                <FormControl>
                  <FormLabel>City</FormLabel>
                  <Select
                    _hover={{
                      borderRadius: 'orange',
                    }}
                    placeholder='Select city' name='city' value={selectedCity} onChange={handleCityChange}>
                    {/* {towns.length === 0 && <Spinner />} */}
                    {cities.map((city) => (
                      <option key={city.name} value={city.name}>
                        {city.name}
                      </option>
                    ))}

                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel>Region</FormLabel>
                  <Select placeholder="Select Region" name='region' value={selectedCity ? cities.find((city) => city.name === selectedCity).province : ''} isDisabled={!selectedCity}>
                    {regions.map((province) => (
                      <option key={province} value={province}>
                        {province}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </HStack>
              <HStack mt={2} spacing={8}>
                {/* <FormControl>
                  <FormLabel>Province</FormLabel>
                  <Select value={province} width={'200px'} placeholder='Select Province' name='province' onChange={(e) => setProvince(e.target.value)}>
                    <option value='Harare'>Harare</option>
                  </Select>
                </FormControl> */}
                <FormControl>
                  <FormLabel>Province</FormLabel>
                  <Select placeholder="Select Province" name='province' value={selectedCity ? cities.find((city) => city.name === selectedCity).province : ''} isDisabled={!selectedCity}>
                    {provinces.map((province) => (
                      <option key={province} value={province}>
                        {province}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor='citizenship'>Citizenship</FormLabel>
                  {/* <Input id='citizenship'
                    placeholder='eg Zimbabwean'
                    type='text'
                    name='citizenship'
                    // value={citizenship}
                    value={selectedCountry ? countries.find((country) => country.en_short_name === selectedCountry).nationality : ''} isDisabled={!selectedCountry}
                    onFocus={() => { setCitiError('') }} borderColor={citiError ? 'red.400' : 'gray.300'}
                    onChange={handleInputChange}
                  /> */}
                  <Select placeholder="citizenship" name='citizenship' value={selectedCountry ? countries.find((country) => country.en_short_name === selectedCountry).nationality : ''} isReadOnly={!selectedCountry}>
                    {nationalities.map((citizenship) => (
                      <option key={citizenship} value={citizenship}>
                        {citizenship}
                      </option>
                    ))}
                  </Select>
                  <Text color={'red'}>{citiError}</Text>
                </FormControl>

              </HStack>
              <HStack mt={4} spacing={8}>
            <FormControl>
            <FormLabel htmlFor='upload'>Attach a File:</FormLabel>
              <Select placeholder='Select action to attach a file' w={'250px'} bg={'white'} onChange={(e) => setSelectCategory(e.target.value)} >
                {category.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            {showFileUpload && 
              <>
                <FormControl isRequired>
                  <FormLabel htmlFor='upload'>Upload National Id:</FormLabel>
                  <Input name='nationalIdPicture' id='file' width={'250px'} type='file' accept="image/png, image/jpeg, image/jpg" onChange={handleAttachFile} />
                  <Text color={'red'}>{imgError}</Text>
                </FormControl>
              
              </>}
               {showCamera && <>
                {/* <Button onClick={onModeOpen}>Take pic</Button> */}
                <Modal isOpen={isModeOpen} onClose={onModeClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Capture National ID</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Box m={2} boxSize='sm'>

            <Button onClick={startCamera}>Start Camera</Button>
         <video ref={videoRef} autoPlay muted />
         <Button leftIcon={<FaCamera/>} colorScheme={'blue'} onClick={captureImage}>Capture Image</Button>

          </Box>
   
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='yellow' mr={3} onClick={onModeClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
               
               </>}
                {progress > 0 && <Progress value={progress} size='sm' thickness="4px" color="blue" isIndeterminate />}
              
      
                {progress > 0 && <Progress value={progress} size='sm' thickness="4px" color="blue" isIndeterminate />}
                {/* {imageFile && <img  src={URL.createObjectURL(imageFile)} alt="Preview" style={{ maxWidth: "100%", maxHeight: "100%" }} />} */}

              </HStack>
             
                {progress > 0 && <Progress value={progress} size='sm' thickness="4px" color="blue" isIndeterminate />}
                {/* {imageFile && <img  src={URL.createObjectURL(imageFile)} alt="Preview" style={{ maxWidth: "100%", maxHeight: "100%" }} />} */}

                {/* <FormControl isRequired>
                  <FormLabel htmlFor='upload'>Proof of Residence</FormLabel>
                  <Input name='proofOfResidencePicture' id='file' type='file' accept="image/png, image/jpeg, image/jpg" onChange={handleProof} onFocus={() => { setImgError('') }} borderColor={imgError ? 'red.400' : 'gray.300'} />

                  <Text color={'red'}>{imgError}</Text>
                </FormControl>
                {progress > 0 && <Progress value={progress} size='sm' thickness="4px" color="blue" isIndeterminate />} */}
              {/* </HStack> */}
              {/* <Stack mt={4}>
                <FormControl as='fieldset'>
                  <FormLabel as='legend' marginBottom={2} fontWeight='bold' textColor={'orange.400'} >Register to OneMoney</FormLabel>
                  <RadioGroup defaultValue='Yes'>
                    <HStack spacing='24px' >
                      <Radio value={oneMoney} name='oneMoney' onChange={(e) => setOneMoney(e.target.value)} >Yes</Radio>
                    </HStack>
                  </RadioGroup>

                  <FormHelperText fontWeight={'semibold'}>Note: Tick 'YES' if customer wants to be registered on OneMoney.</FormHelperText>
                </FormControl>
              </Stack> */}
              <HStack mt={2} ml={4} spacing={8}>

                {previewUrl &&
                  <Image
                    boxSize='30%'
                    width={'35%'}
                    objectFit='cover'
                    src={previewUrl}
                    rounded={'md'}
                    alt="Preview"

                  />}
                        <canvas ref={canvasRef} style={{ display: 'none' }} />
         {imageData && 
          <Image
                  mt={2}
                  boxSize='25%'
                  width={'50%'}
                  objectFit='cover'
                  src={URL.createObjectURL(imageData)}
                  rounded={'md'}
                  alt="Preview"

                />}
                {/* {previewProofUrl &&
                  <Image
                    boxSize='30%'
                    width={'35%'}
                    objectFit='cover'
                    src={previewProofUrl}
                    rounded={'md'}
                    alt="Preview"

                  />} */}
              </HStack>
            </form>

          </Stack>
          <HStack m={6} ml={8} alignContent={'center'} spacing={4}>

            <Button
              //  isLoading={loading} 
              //  loadingText='Loading'
              //  colorScheme='teal'
              //  variant='outline'
              //  spinnerPlacement='end'
              onClick={onOpen}
              // onClick={handlesubmit}
              bg={'orange.400'}
              color={'white'}
              _hover={{
                bg: 'orange.500',
              }}
              width='150px'
            >
              Submit
            </Button>
            <Button
              onClick={() => navigate('/admin/search-customer')}
              bg={'red'}
              color={'white'}
              _hover={{
                bg: 'gray',
              }}
              width={'150px'}
            >
              Cancel
            </Button>
          </HStack>
          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                  Are you sure you want to add customer with details:
                </AlertDialogHeader>

                <AlertDialogBody>
                  <Stack spacing={2} marginLeft='2' marginRight='2' marginBottom='2'  >
                    <form>
                      <HStack spacing={8}>


                        <FormControl isReadOnly>
                          <FormLabel htmlFor='firstName'>First name</FormLabel>
                          <Input
                            marginBottom={2}
                            id='firstName'

                            type='text'
                            name='firstName'
                            value={firstName}
                            onFocus={() => { setFNameError('') }} borderColor={fnameError ? 'red.400' : 'gray.300'}



                          />
                          <Text color={'red'}>{fnameError}</Text>
                        </FormControl>





                        <FormControl isReadOnly>
                          <FormLabel htmlFor='middleName'>Middle name</FormLabel>
                          <Input
                            marginBottom={2}
                            id='middleName'

                            type='text'
                            name='middleName'
                            value={middleName}
                            onFocus={() => { setMdNameError('') }} borderColor={mdnameError ? 'red.400' : 'gray.300'}


                          />
                          <Text color={'red'}>{mdnameError}</Text>
                        </FormControl>

                      </HStack>
                      <HStack spacing={8}>

                        <FormControl isReadOnly>
                          <FormLabel htmlFor='lastName'>Last name</FormLabel>
                          <Input
                            marginBottom={2}
                            id='lastName'

                            type='text'
                            name='lastName'
                            value={lastName}
                            onFocus={() => { setLNameError('') }} borderColor={lnameError ? 'red.400' : 'gray.300'}
                            onChange={handleInputChange}


                          />
                          <Text color={'red'}>{lnameError}</Text>
                        </FormControl>


                        <FormControl isReadOnly>
                          <FormLabel htmlFor='phoneNumber'>Mobile Number</FormLabel>
                          <InputGroup>
                            <Input id='phoneNumber'
                              type='number'

                              name='phoneNumber'
                              value={phoneNumber}
                              onFocus={() => { setPhoneError('') }} borderColor={phoneError ? 'red.400' : 'gray.300'}

                            />
                          </InputGroup>
                          <Text color={'red'}>{phoneError}</Text>
                        </FormControl>



                      </HStack>
                      <HStack spacing={8}>


                        <FormControl isReadOnly>
                          <FormLabel htmlFor='nationalIdNumber'>National ID:</FormLabel>

                          <Input
                            marginBottom={2}
                            id='nationalIdNumber'

                            type='text'
                            name='nationalIdNumber'
                            value={nationalIdNumber}
                            onFocus={() => { setNatIdError('') }} borderColor={natIdError ? 'red.400' : 'gray.300'}

                            textTransform='uppercase'
                          />
                          <Text color={'red'}>{natIdError}</Text>
                        </FormControl>


                        <FormControl isReadOnly>
                          <FormLabel htmlFor='address'>Address</FormLabel>
                          <Textarea
                            marginBottom={2}
                            id='address'

                            type='text'
                            name='address'
                            value={address}
                            onFocus={() => { setAddressError('') }} borderColor={addressError ? 'red.400' : 'gray.300'}

                          />
                          <Text color={'red'}>{addressError}</Text>
                        </FormControl>


                      </HStack>

                      <HStack spacing={8}>

                        <FormControl isReadOnly>
                          <FormLabel htmlFor='email'>Email </FormLabel>
                          <Input
                            marginBottom={2}
                            id='email'

                            name='email'
                            type='email'
                            value={email}


                          />
                        </FormControl>



                        <FormControl isReadOnly >
                          <FormLabel htmlFor='dob'>Date Of Birth</FormLabel>
                          <Input
                            marginBottom={2}
                            letterSpacing={4}
                            name='dateOfBirth'
                            type='date'
                            id='dateOfBirth'
                            value={dateOfBirth}
                            onFocus={() => { setBDError('') }} borderColor={bdateError ? 'red.400' : 'gray.300'}
                            width={'178px'}
                          />
                          <Text color={'red'}>{bdateError}</Text>
                        </FormControl>

                      </HStack>
                      <HStack spacing={8}>
                        <FormControl isReadOnly>
                          <FormLabel htmlFor='citizenship'>Citizenship</FormLabel>
                          <Input id='citizenship'

                            type='text'
                            name='citizenship'
                            // value={citizenship}
                            value={selectedCountry ? countries.find((country) => country.en_short_name === selectedCountry).nationality : ''}

                          />
                          <Text color={'red'}>{citiError}</Text>
                        </FormControl>

                        <FormControl isReadOnly>
                          <FormLabel>Gender</FormLabel>
                          <Input id='gender'

                            type='text'

                            value={gender} />
                        </FormControl>
                      </HStack>
                      <HStack spacing={8}>
                        <FormControl isReadOnly>
                          <FormLabel>City</FormLabel>
                          <Input id='city'

                            type='text'

                            value={selectedCity} />
                        </FormControl>
                        <FormControl isReadOnly>
                          <FormLabel>Region</FormLabel>
                          <Input id='region'

                            type='text'

                            value={selectedCity ? cities.find((city) => city.name === selectedCity).province : ''}
                          />
                        </FormControl>
                      </HStack>
                      <HStack spacing={8}>
                        <FormControl isReadOnly>
                          <FormLabel>Province</FormLabel>
                          <Input id='province'

                            type='text'

                            value={selectedCity ? cities.find((city) => city.name === selectedCity).province : ''}
                          />
                        </FormControl>
                        <FormControl isReadOnly>
                          <FormLabel>Country</FormLabel>
                          <Input id='country'

                            type='text'

                            value={selectedCountry} />
                        </FormControl>
                      </HStack>

                      {loading && progress > 0 && <Progress value={progress} size='sm' thickness="4px" color="blue" isIndeterminate />}
                    </form>

                  </Stack>
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button colorScheme={'red'} ref={cancelRef} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    isLoading={loading}
                    loadingText='Loading'
                    spinnerPlacement='end'
                    onClick={handlesubmit}
                    bg={'teal'}
                    color={'white'}
                    _hover={{
                      bg: 'orange.500',
                    }}
                    ml={3}>
                    Confirm
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>

        </Box>
      </Center>

    </Container>
  )
}


