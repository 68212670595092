import React, { useState, useEffect } from 'react'
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Stack,
    Box,
    chakra,
    Wrap,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
    Flex,
    Text,
    Center,
    useDisclosure,
    Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Badge,
  Radio,
  RadioGroup,
  } from '@chakra-ui/react'
  import { FiUserPlus } from 'react-icons/fi'
  import { BsBank, BsChevronRight, BsPerson } from 'react-icons/bs';
  import { NavLink, useNavigate } from 'react-router-dom'
  import axios from '../../api/axios'

  
  const ADDBANK_URL = 'mobile-api/config/bank'
  const GETBANK_URL = 'mobile-api/config/banks'
  const DELETEBANK_URL = 'mobile-api/config/bank/'

function Banks() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const initialRef = React.useRef(null);

    const toast = useToast();
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [bankCode, setBankCode] = useState('')
    const[active, setActive] = useState(false)

    const [ loading, setLoading] = useState(false);
    const [nameError, setNameError] = useState('')
    const [bankcoderror, setBackCoderror] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const handlesubmit = () => {
        setErrorMessage('')
    const res = validate();
        if (!res) {
      console.log('validation failed')
      toast({
        title: 'Failed',
        description: "Form not complete",
        status: 'error',
        duration: 3000,

      });
      return;
    }
        setLoading(true)
        const token = localStorage.getItem("token");
        axios.post(ADDBANK_URL, {
          name,
          bankCode,
          active
          
        },{
          headers: { 
            "Content-type": "application/json",
            'Authorization': `Bearer ${token}`
          }
          
        })
        .then(response => {
            // console.log(response)
            if (response.data.success) {
                navigate('/admin/config')
                getBanks();
                onClose();
                toast({
                  title: 'Success',
                  description: response.data.message,
                  status: 'success',
                  duration: 6000,
                  
                });
                setLoading(false)
              }
            
            else {
              setLoading(false)
              toast({
                title: 'Failed',
                description: response.message,
                status: 'error',
                duration: 3000,
               
              });
            }
        })
        .catch(error => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              toast({
                title: "Error",
                description: `Server responded with ${error.response.status}`,
                status: "error",
                duration: 5000,
                isClosable: true,
              });
              setLoading(false)
            } else if (error.request) {
              // The request was made but no response was received
              toast({
                title: "Network Error",
                description: "No response from server",
                status: "error",
                duration: 8000,
                isClosable: true,
              });
              setLoading(false)
            } else {
              // Something happened in setting up the request that triggered an Error
              toast({
                title: "Network Error",
                description: `Error: ${error.message}`,
                status: "error",
                duration: 8000,
                isClosable: true,
              });
             
            }
          })
    }
    useEffect(() => {

    }, [loading])

    const validate =() => {
        if (!name) {
            setNameError('Enter currency name')
            return false;
          }
          if (!bankCode) {
            setBackCoderror('Enter Bankcode')
            return false;
          }
          return true;
    }

    const [bank, setBank] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [totalPages, setTotalPages] = useState(1);
    // const [getBanks, setGetBanks] = useState('');
    // const [getBan, setUpdateBank] = useState('')

    const getBanks = async () => {
        setLoading(true);
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`${GETBANK_URL}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
  
        if(response.status === 200) {
          setBank(response.data.body)
          setLoading(false);
        }
      } catch (error) {
      
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // toast({
          //   title: "Error",
          //   description: `Server responded with ${error.message}`,
          //   status: "error",
          //   duration: 5000,
          //   isClosable: true,
          // });
         
        } else if (error.request) {
          // The request was made but no response was received
          toast({
            title: "Network Error",
            description: "Please Check your Internet Connection",
            status: "error",
            duration: 8000,
            isClosable: true,
          });
       
        } else {
          // Something happened in setting up the request that triggered an Error
          toast({
            title: "Error",
            description: `Error:`,
            status: "error",
            duration: 8000,
            isClosable: true,
          });
         
        }
      }
    }
  

    useEffect(() => {
       
          getBanks();      
      }, [])
      
      const handleDelete = async (id) => {
        setLoading(true)
        const token = localStorage.getItem("token");
        try {
          
          const response = await axios.delete(DELETEBANK_URL + `${id}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          if(response.data.success) {
            getBanks();
            toast({
              title: 'Success',
              description: "Deleted",
              status: 'success',
              duration: 6000,
              
            });
            setLoading(false)
          
          }else{
            toast({
              title: 'Failed',
              description: "Failed to Delete",
              status: 'error',
              duration: 6000,
              
            });
            setLoading(false)
          }
        } catch (error) {
          
        }
      };
      useEffect(() => {
        setTotalPages(Math.ceil(bank.length / itemsPerPage));
      }, [bank]);
      
      const paginateData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return bank.slice(startIndex, endIndex);
      };
      
      const handlePageChange = (page) => {
        setCurrentPage(page);
      }
      
      const paginatedData = paginateData(bank, currentPage, itemsPerPage);
      const handleStatus = (event) => {
        setActive(event.target.value);
      };

  return (
    <Box maxW="4xl" >
       {/* <chakra.h1
        textAlign={'left'}
        fontSize={'1xl'}
        py={5}
        fontWeight={'bold'}>
   Bank List
      </chakra.h1> */}
      <Stack direction='row' spacing={4}>
  <Button onClick={onOpen}  leftIcon={<BsBank/>} colorScheme='orange' variant='solid'>
    Add Bank
  </Button>
  <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Bank</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Bank name:</FormLabel>
              <Input type='text'  placeholder='Bank name' onChange={(e) => setName(e.target.value)}  onFocus={()=> {setNameError('')}} borderColor={nameError ? 'red.400' : 'gray.300'} />
              <Text color={'red'}>{nameError}</Text>
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Bank Code:</FormLabel>
              <Input type={'number'} placeholder='Bank Code' onChange={(e) => setBankCode(e.target.value)}  onFocus={()=> {setBackCoderror('')}} borderColor={bankcoderror ? 'red.400' : 'gray.300'}  />
              <Text color={'red'}>{bankcoderror}</Text>
            </FormControl>
            <FormControl>
              <FormLabel>Status:</FormLabel>
              <RadioGroup onChange={handleStatus} name={active}>
      <Stack direction='row'>
        <Radio value={true}>Active</Radio>
        <Radio value={false}>Not Active</Radio>
      </Stack>
    </RadioGroup>
            </FormControl>
            {
              errorMessage ? <Text color={'red'}>{errorMessage}</Text> : <></>
            }
          </ModalBody>

          <ModalFooter>
            <Button 
           onClick={handlesubmit} 
           isLoading={loading}
           loadingText='Loading'
           spinnerPlacement='start'
           colorScheme='orange' mr={3}>
             Save
           </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  </Stack>
    <Stack p="3" bg='white' boxShadow="lg" m="4" borderRadius="sm" width={'1100px'}>
    <TableContainer>
  <Center>

  <Table variant='simple' >
    <TableCaption> 
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
    
    <Button
    colorScheme={'orange'}
    m={1}
    key={page}
    onClick={() => handlePageChange(page)}
    color={page === currentPage ? 'black' : 'white'}
    cursor="pointer"

    >
            {page}
          </Button>
        ))}
        </TableCaption>
    <Thead>
      <Tr>
        <Th>#</Th>
        <Th>Bank Code</Th>
        <Th>Bank name</Th>
        <Th>Status</Th>
        <Th>Action</Th>
      </Tr>
    </Thead>
    <Tbody>
    {paginatedData.map((data,index) =>{
      return(
      <Tr key={index}>
      <Td>{index +1}</Td>
      <Td>{data.bankCode == null ? '': data.bankCode}</Td>
      <Td>{data.name == null ? '': data.name }</Td>
      <Td>{data.active == false}</Td>
      <Td>
        <Wrap spacing={2} direction='row' align='center'>
          {/* <Button  colorScheme='gray' size='xs'>View</Button> */}
          <Button onClick={() => handleDelete(data.id)}  colorScheme='red' size='xs'>Delete</Button>
         
        </Wrap>
      </Td>
    </Tr>
    )
  })}
    </Tbody>
    

    <Tfoot>
  
    </Tfoot>
    
  </Table>
  </Center>
  <Flex justifyContent={'flex-end'} mr={10} >

 
        </Flex>
</TableContainer>

    </Stack>
    </Box>
  )
}

export default Banks