
import React, { useState,useRef, useEffect } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Stack,
    Box,
    chakra,
    Wrap,
    Button,
    Text,
    Center,
    useToast,
    useDisclosure,
    useColorModeValue,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    HStack,
    Heading,
    Flex,
    Badge,
    Image,
    Card,
    CardBody,
    StackDivider,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Select,
    FormControl,
    FormLabel,
    Textarea,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Tooltip,
    InputGroup,
    InputLeftAddon,
    Input,
    List,
    ListItem,
    ListIcon,
  } from '@chakra-ui/react'
  import { FilterMatchMode, FilterOperator } from "primereact/api";
  import { Tag } from 'primereact/tag';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useNavigate } from 'react-router-dom'
import axios from "../../api/axios";
import { baseURL } from '../../api/baseUrl';
import {  MdCheckCircle, MdPreview, MdReport } from "react-icons/md";
import { FiPhone, FiUser, FiUserPlus } from "react-icons/fi";
import { read, utils, writeFile } from 'xlsx'
import { FaIdCard, FaRegImage } from 'react-icons/fa';
import img from '../../images/noimage1.png'
import { approveImageURL } from '../../api/imgUrl';
import { BsFillLaptopFill, BsPhoneFill } from 'react-icons/bs';
import testId from '../../images/id2.jpeg';
import Multiselect from 'multiselect-react-dropdown';




const GET_CUSTOMER_URL = "/onboarding/operator/customer/declined";
const EMPLOYEE_ACC_URL = '/employee/profile/v1/get-employee-by-account-id/'
const BRAND_ACC_URL = '/employee/brand-ambassador/v1/get-all-brand-ambassadors'
const BRANDS_ACC_URL = '/employee/brand-ambassador/v1/get-brand-ambassador-by-account-id'
const CUSTOMER_APPROVAL_URL ='/onboarding/operator/customer/approve'
const CUSTOMER_DECLINE_URL = '/onboarding/operator/customer/decline'
const EDITDECLINE_CUSTOMER_URL = '/onboarding/operator/customer/edit-and-approve'
const GETUSERS_URL = '/employee/profile/v1/get-employees?availability=PRESENT'

function ManageDeclinedCustomer() {
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [open,setOpen] = useState(false)
    const [currentData,setCurrentData] = useState()
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
      const cancelRef = React.useRef()
  
      const [currentSelected,setCurrentSelected] = useState(null)
      const { isOpen: isDeclineOpen, onOpen: onDeclineOpen, onClose: onDeclineClose } = useDisclosure();
    const initiateDecline = React.useRef()
  
    const { isOpen: isApOpen, onOpen: onApOpen, onClose: onApClose } = useDisclosure();
    const cancelAp = React.useRef()
  
    const { isOpen: isDrOpen, onOpen: onDrOpen, onClose: onDrClose } = useDisclosure()
    const btnRef = React.useRef()
  
    const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure()
    const initiateEdit = React.useRef()
  
  
  
    const [reasons, setReason] = useState('');
    const [otherReason, setOtherReason] = useState('');
    const [showReasonInput, setShowReasonInput] = useState(false);
  
    const [selectedReasons, setSelectedReasons] = useState([]);
  
    const options =[
      { id:1, value: 'National-ID-picture-not-clear', name: 'National ID picture not clear' },
      {id: 3, value:'National Id number entered does not match with national Id picture' , name: 'National Id number entered does not match with national Id picture' },
      {id: 2, value:'incorrect-Address' , name: 'Invalid Residential Address' },
      // { value: 'incorrect-Gender', name: 'Incorrect Gender' },
  
  
    ]
    const onSelect = (selectedList, selectedItem) =>{
      setSelectedReasons(selectedList);
      setReason([...selectedReasons.map(item=> item.value)])
    
    }
    const onRemove = (selectedList, selectedItem) =>{
      setSelectedReasons(selectedList);
    }
  
  
  
    const handleExport = () => {
      const headings = [[ "FirstName","LastName","JobTitle","Department", "Station","DOB","National Id Number","Drivers license Class","Mobile Number(980)","Alternate Number","Residential Address","NextofKin Name","NextofKin Relationship", "NextofKin Phone Number", "NextOfKin Address"]];
      const wb = utils.book_new();
      const ws = utils.table_to_sheet(document.getElementById('id'),{raw: true});
      utils.sheet_add_aoa(ws, headings);
   //    utils.sheet_add_json(ws, searchresults, { origin: "A2", skipHeader: true});
      utils.book_append_sheet(wb, ws, "Employee_Report");
      writeFile(wb, "Employee_Report.xlsx")
       };

       const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        firstName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        lastName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        phoneNumber: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        nationalIdNumber: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        approvalStatus: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        createdBy: { value: null, matchMode: FilterMatchMode.STARTS_WITH },

      });
    
  
       const initialUpdate =
       {
         firstName: "",
         middleName: "",
         phoneNumber: "",
         lastName: "",
         citizenship: "",
         nationalIdNumber: "",
         country: "",
         gender: "",
         dateOfBirth: "",
         address: "",
         nationalIdPictureUrl: "",
         city:"",
         province: "",
         region:"",
         country: "",
         registeredShopId: "",
         registeredProvinceId: "",
         registeredRegionId: "",
         registeredZoneId:"",
         createdBy: "",
         declinedBy: "",
         platform: "",
         signupPlatform: "",
         lastUpdatedBy: "",
         creatorType: "",
         approvalStatus: "",
  
       }
       const [state, setState] = useState(initialUpdate);
  
       const { firstName, middleName, phoneNumber, lastName, citizenship,
         nationalIdNumber,country,gender, dateOfBirth, address, nationalIdPictureUrl, city,province,region,registeredShopId,registeredProvinceId,registeredRegionId,
        registeredZoneId,createdBy,declinedBy,platform,signupPlatform, lastUpdatedBy,creatorType,approvalStatus} = state
     
        const handleInputChange = (e) => {
         const { name, value } = e.target;
         setState({ ...state, [name]: value });
       };
  
       const handleUpdate = () => {
        let user = localStorage.getItem('user')
        user = JSON.parse(user);
        setLoading(true)
        const token = localStorage.getItem("token");
        const formData = new FormData(createForm.current);
        formData.set("firstName", state.firstName)
        formData.set("middleName", state.middleName)
        formData.set("phoneNumber", `${state.phoneNumber}`)
        formData.set("lastName", state.lastName)
        formData.set("dateOfBirth", state.dateOfBirth)
        formData.set("country", state.country)
        formData.set("gender", state.gender)
        formData.set("nationalIdNumber", state.nationalIdNumber)
        formData.set("citizenship", state.citizenship)
        formData.set("address", state.address)
        formData.set("nationalIdPictureUrl", state.nationalIdPictureUrl)
        formData.set("city", state.city)
        formData.set("province", state.province)
        formData.set("region", state.region)
        formData.set("registeredShopId", state.registeredShopId)
        formData.set("registeredProvinceId", state.registeredProvinceId)
        formData.set("registeredRegionId", state.registeredRegionId)
        formData.set("registeredZoneId", state.registeredZoneId)
        formData.set("createdBy", state.createdBy)
        formData.set("declinedBy", user?.id)
        formData.set("platform", state.platform)
        formData.set("signupPlatform", "WEB_PORTAL")
        formData.set("lastUpdatedBy", user?.id)
        formData.set("creatorType", state.creatorType)
        formData.set("approvalStatus", "")
  
        axios.post(`${EDITDECLINE_CUSTOMER_URL}/${currentSelected.id}`,formData, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
    
        ).then(response => {
            // console.log(response)
            if (response.data.success) {
              
                navigate('/admin/approver-dashboard')
                getAllCustomers();
                onApClose();
                onClose();
                onEditClose()
                onDeclineClose();
              toast({
                title: 'Success.',
                description: 'Processed successfully',
                status: 'success',
                duration: 6000,
                
              });
              setLoading(false)
            }
            else {
            
              toast({
                title: 'Failed',
                description: 'Something went wrong',
                status: 'error',
                duration: 6000,
    
              });
              setLoading(false)
            }
          })
          .catch(error => {
            // console.log(error.response)
            if (error.response) {
              toast({
                title: "Customer Approval Message",
                description: `${error.response.data.message}`,
                status: 'info',
                duration: 5000,
                isClosable: true,
                position: 'top'
              });
              setLoading(false)
            } else if (error.request) {
              // The request was made but no response was received
              toast({
                title: "Network Error",
                description: "Please Check your Internet Connection",
                status: "error",
                duration: 8000,
                isClosable: true,
              });
              setLoading(false)
            } else {
              // Something happened in setting up the request that triggered an Error
              toast({
                title: "Network Error",
                description: `Error: ${error.message}`,
                status: "error",
                duration: 8000,
                isClosable: true,
              });
             
            }
          })
      }
  
    /* Fetching Data From Server */
    const getAllCustomers = async () => {
      const token = localStorage.getItem("token");
      try {
        setLoading(true);
        const response = await axios.get(`${GET_CUSTOMER_URL}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.status === 200) {
          setCustomers(response.data.body);
          // console.log(response.data.body);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    };
    useEffect(() => {
      getAllCustomers();
    }, []);

    const [updatedId, setUpdatedID] = useState([]);
  const getAllEmployees = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(GETUSERS_URL, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })

      if (response.status === 200) {
        setUpdatedID(response.data.body)
        // console.log(response.data.body)
        setLoading(false);
      }
    } catch (error) {

    }
  }
  useEffect(() => {
    getAllEmployees();
  }, []);
  
    const [ambassadorId, setAmbassadorID] = useState([]);
  
  
    const getBrandAmbassadors = async () => {
      setLoading(true);
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(BRAND_ACC_URL, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
  
        if (response.status === 200) {
          setAmbassadorID(response.data.body)
           console.log(response.data.body)
          setLoading(false);
        }
      } catch (error) {
  
      }
    }
  
    useEffect(() => {
      getBrandAmbassadors()
  
    }, [])


    const AgentCreatedByBodyTemplate = (customer) => {
      return <Text>{getAgentById(customer.createdBy)}</Text>;
  };

    function getAgentById(id){
     const Agent = ambassadorId?.find((item) => id === item.employeeAccount.id);
    if(Agent){
    return `${Agent.firstName} ${Agent.lastName}`
    }else{
      return ""
    }
    }

    const EmployeeByBodyTemplate = (customer) => {
      return <Text>{getEmployeeById(customer.declinedBy)}</Text>;
  };

    function getEmployeeById(id){
     const Agent = updatedId?.find((item) => id === item.employeeAccount.id);
    if(Agent){
      return `${Agent.firstName} ${Agent.lastName}`
    }else{
      return "OneMoney Declined"
    }
    }
  
    // const [ambassId, setAmbassID] = useState("");
  
  
    // const getSingleAmbassadors = async () => {
    //   setLoading(true);
    //   const token = localStorage.getItem("token");
    //   try {
    //     const response = await axios.get(`${BRANDS_ACC_URL}?accountId=${currentSelected.createdBy}`, {
    //       headers: {
    //         'Authorization': `Bearer ${token}`
    //       }
    //     })
  
    //     if (response.status === 200) {
    //       setAmbassID(response.data.body)
    //       setLoading(false);
    //     }
    //   } catch (error) {
  
    //   }
    // }
  
    // useEffect(() => {
    //   getSingleAmbassadors();
    // }, [])
  
  
    const date = new Date(currentSelected?.createdAt);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
  
    const formatDOB = (dob) => {
      const year = dob?.slice(0, 4);
      const month = dob?.slice(4, 6);
      const day = dob?.slice(6, 8);
  
      const mydate = new Date(`${year}-${month}-${day}`);
      const formattedDate = mydate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
  
      return formattedDate;
    };
    const Birth = currentSelected?.dateOfBirth; // Replace with your input number
  
    const formattedDOB = formatDOB(Birth);
  
    const createForm = useRef();
  
    const handleDecline = async () =>{
      // const formData = new FormData(createForm.current);
      setLoading(true)
      const token = localStorage.getItem("token");
     
      const data_reasons = selectedReasons.map(item=> item.value)
     
      // console.log(data_reasons)
      axios.post(`${CUSTOMER_DECLINE_URL}/${currentSelected.id}`, {
        reasons: data_reasons
      }, {
        headers: {
          "Content-type": "application/json",
          'Authorization': `Bearer ${token}`
        }
      }
  
      ).then(response => {
          // console.log(response)
          if (response.data.success) {
            setReason("")
            setSelectedReasons([])
              navigate('/admin/approver-dashboard')
              getAllCustomers();
              onApClose();
              onClose();
              onDeclineClose()
              onEditClose()
            toast({
              title: 'Customer Declined Success .',
              description: response.data.message,
              status: 'success',
              duration: 6000,
              
            });
            setLoading(false)
          }
          else {
          
            toast({
              title: 'Failed to Decline Customer',
              description: 'failed to decline',
              status: 'error',
              duration: 6000,
  
            });
            setLoading(false)
          }
        })
        .catch(error => {
          if (error.response) {
            toast({
              title: "Customer Approval Message",
              description: `${error.response.data.message}`,
              status: 'info',
              duration: 5000,
              isClosable: true,
              position: 'top'
            });
            setLoading(false)
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              title: "Network Error",
              description: "Please Check your Internet Connection",
              status: "error",
              duration: 8000,
              isClosable: true,
            });
            setLoading(false)
          } else {
            // Something happened in setting up the request that triggered an Error
            toast({
              title: "Network Error",
              description: `Error: ${error.message}`,
              status: "error",
              duration: 8000,
              isClosable: true,
            });
           
          }
        })
  
    }
  
  
    const handleapprove = () => {
      setLoading(true)
      const token = localStorage.getItem("token");
      axios.post(`${CUSTOMER_APPROVAL_URL}/${currentSelected.id}`, {
  
      }, {
        headers: {
          "Content-type": "application/json",
          'Authorization': `Bearer ${token}`
        }
      }
  
      ).then(response => {
          // console.log(response)
          if (response.data.success) {
            
              navigate('/admin/approver-dashboard')
              getAllCustomers();
              onApClose();
              onClose();
            toast({
              title: 'Success.',
              description: 'Processed successfully',
              status: 'success',
              duration: 6000,
              
            });
            setLoading(false)
          }
          else {
          
            toast({
              title: 'Failed',
              description: 'Something went wrong',
              status: 'error',
              duration: 6000,
  
            });
            setLoading(false)
          }
        })
        .catch(error => {
          if (error.response) {
            toast({
              title: "Customer Approval Message",
              description: `${error.response.data.message}`,
              status: 'info',
              duration: 5000,
              isClosable: true,
              position: 'top'
            });
            setLoading(false)
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              title: "Network Error",
              description: "Please Check your Internet Connection",
              status: "error",
              duration: 8000,
              isClosable: true,
            });
            setLoading(false)
          } else {
            // Something happened in setting up the request that triggered an Error
            toast({
              title: "Network Error",
              description: `Error: ${error.message}`,
              status: "error",
              duration: 8000,
              isClosable: true,
            });
           
          }
        })
    }

    const renderHeader = () => {
        return (
          <div className="flex justify-content-end">
            {/* <span className="p-input-icon-left">
              <Input
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                placeholder="Keyword Search"
              />
            </span> */}
          </div>
        );
      };

    

    const statusBodyTemplate = (customers) => {
        return <Tag value={customers.approvalStatus} severity={getSeverity(customers)}></Tag>;
    };
    
      const getSeverity = (customers) => {
        switch (customers.approvalStatus) {
            case 'APPROVED':
                return 'success';
    
            case 'PENDING':
                return 'warning';
    
            case 'DECLINED':
                return 'danger';
    
            default:
                return null;
        }
    };
    
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/* <Wrap spacing={2} direction='row' align='center'>
                <Button colorScheme='gray' size='xs' leftIcon={<MdPreview />} onClick={() => navigate(`/admin/view-user`,{
                    state: rowData
                  })} >View</Button>
               
                   </Wrap> */}
                   <Wrap spacing={2} direction='row' align='center'>
              <Button  
              bg={'black'} 
              color={'white'} 
              size='md' 
              leftIcon={<MdPreview/>}
            onClick={()=>{
                onOpen()
                setCurrentSelected(rowData)
                setState(rowData)
                
            }}

              >View
              </Button>
              
            </Wrap>
            </React.Fragment>
        );
    };

    const header = renderHeader();
    /* End of Table Header */
   
  let user = localStorage.getItem('user')
  user = JSON.parse(user);
    return (
        <>
        <div className="card">
        <DataTable 
        value={customers}  
        scrollable scrollHeight="600px" 
        paginator
        header={header}
        filters={filters}
        rows={100}      
        loading={loading}
        showGridlines 
        filterDisplay="row"
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        globalFilterFields={[
          "firstName",
          "lastName",
          "phoneNumber",
          "nationalIdNumber",
       
        ]}
        tableStyle={{ minWidth: '50rem' }}
        emptyMessage="No customers found.">
            <Column 
            field="firstName" 
            header="First Name"
            filter
            filterPlaceholder="Search"></Column>
            <Column 
            field="lastName" 
            header="Last Name"
            filter
            filterPlaceholder="Search"></Column>
            <Column 
            field="phoneNumber" 
            header="Mobile Number"
            filter
            filterPlaceholder="Search"></Column>
            <Column 
            field="nationalIdNumber" 
            header="National ID"
            filter
            filterPlaceholder="Search"></Column>
            <Column field="approvalStatus" body={statusBodyTemplate} header="Approval Status"></Column>
            <Column field="createdBy" body={AgentCreatedByBodyTemplate} header="Created by"></Column>
            <Column field="declinedBy" body={EmployeeByBodyTemplate} header="Declined by"></Column>


            <Column field="Action" body={actionBodyTemplate} header="Actions"></Column>


        </DataTable>
    </div>
      {/* Modal to view customers */}

      <Modal  closeOnOverlayClick={false} size={'4xl'} isOpen={isOpen} onClose={onClose}  motionPreset='slideInRight'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg={'yellow.400'} color={'white'}>Customer Profile</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={2} bg={'gray.50'} pb={6}>
          <Stack minH={'60vh'} direction={{ base: 'column', md: 'row' }}>
        
          <Flex  flex={2}>
          <Box
              borderRadius={'lg'}
              p={2}
              m={2}
              minW={'200px'}
            >

              <Card>
                <CardBody w={'400px'}>
                  <Stack divider={<StackDivider />} spacing='4'>

            
          <HStack spacing={16}>

            <Box>

            <Heading size='xs' textTransform='capitalize'>
                First Name:
            </Heading>
            <Text  fontSize='sm'>
                {currentSelected?.firstName}
            </Text>
            </Box>
            <Box>
            <Heading size='xs' textTransform='capitalize'>
                Middle Name:
            </Heading>
            <Text  fontSize='sm'>
                {currentSelected?.middleName == '' ? <Text color={'gray.400'}>Not Available</Text> : currentSelected?.middleName}
            </Text>
            </Box>

        </HStack>
        <HStack spacing={16}>
                      <Box>
                        <Heading size='xs' textTransform='capitalize'>
                          Last Name:
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          {currentSelected?.lastName ? currentSelected?.lastName : currentSelected?.surNames}
                        </Text>
                      </Box>

                      <Box>
                        <Heading size='xs' textTransform='capitalize'>
                          Phone Number:
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          {currentSelected?.phoneNumber ? currentSelected?.phoneNumber : currentSelected?.msisdn}
                        </Text>
                      </Box>
                    </HStack>
                    <HStack spacing={8}>
                    <Box>
                      <Heading size='xs' textTransform='capitalize'>
                        National ID Number:
                      </Heading>
                      <Text pt='2' fontSize='sm'>
                        {currentSelected?.nationalIdNumber ? currentSelected?.nationalIdNumber : currentSelected?.documentNbr}
                      </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='capitalize'>
                          Date of Birth:
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          {/* {formattedDOB} */}
                          {currentSelected?.dateOfBirth ? formattedDOB : currentSelected?.birthday}
                        </Text>
                      </Box>
                      </HStack>
                    <Box>
                      <Heading size='xs' textTransform='capitalize'>
                        Address:
                      </Heading>
                      <Text pt='2' fontSize='sm'>
                        {currentSelected?.address == null ? <Text color={'gray.400'} >Not Available</Text> : currentSelected?.address}
                      </Text>
                    </Box>

                    <HStack spacing={16}>

                      <Box>
                        <Heading size='xs' textTransform='capitalize'>
                         Country:
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          {/* {formattedDOB} */}
                          {currentSelected?.country == null ? <Text color={'gray.400'}>Not Available</Text> : currentSelected?.country}
                        </Text>
                      </Box>
                      <Box>
                        <Heading size='xs' textTransform='capitalize'>
                          Citizenship:
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          {currentSelected?.citizenship == null ? <Text color={'gray.400'}>Not Available</Text> : currentSelected?.citizenship}
                        </Text>
                      </Box>
                    </HStack>

                    <Box>
                      <Heading size='xs' textTransform='capitalize'>
                        Gender:
                      </Heading>
                      <Text pt='2' fontSize='sm'>
                        {currentSelected?.gender == null ? <Text color={'gray.400'}>Not Available</Text> : currentSelected?.gender}
                      </Text>
                    </Box>
                    
                    <Box>
                      <Heading size='xs' textTransform='capitalize'>
                        Signed Up Through:
                      </Heading>
                      <Text pt='2' fontSize='sm'>
                        {/* {details.user?.signupPlatform} */}
                        {currentSelected?.signupPlatform === 'WEB_PORTAL' ? (<Button leftIcon={<BsFillLaptopFill />} colorScheme='linkedin' size='xs'>Web Portal</Button>) : currentSelected?.signupPlatform === 'MOBILE_PORTAL' ? (<Button leftIcon={<BsPhoneFill />} colorScheme='purple' size='xs'>Mobile Portal</Button>) : currentSelected?.signupPlatform === 'MOBILE-APP' ? (<Button leftIcon={<BsPhoneFill />} colorScheme='purple' size='xs'>Mobile App</Button>) : <Button leftIcon={<BsFillLaptopFill />} colorScheme='linkedin' size='xs'>Web Portal</Button>}
                      </Text>
                    </Box>
                    
                    <HStack spacing={16}>
                    {currentSelected?.approvalStatus === 'DECLINED' && 
                    <>
                    
                    <Box paddingLeft={2}>
                      <Heading size='xs' textTransform='capitalize'>
                        Declined Reason:
                      </Heading>
                      {/* <Text pt='2' fontSize='sm'>
                      {currentSelected?.declineReasons == null ? <Text color={'gray.400'}>Not Available</Text> :<Button colorScheme='red' size={'sm'}>{`${currentSelected?.declineReasons.join(', ')}`}</Button>}
                      </Text> */}
                      {currentSelected?.declineReasons.map((value, index) => (
                      <List key={index} spacing={3}>
                      <ListItem>
                        <ListIcon as={MdCheckCircle} color='red.500' />
                        {value}
                      </ListItem>
                      </List>
                      ))}
                    </Box>
                    </>}


                    </HStack>
                    </Stack>
                </CardBody>
              </Card>
                   
        </Box>
        </Flex>
        <Flex p={2} flex={2}>
        <Box
            
            borderRadius='lg'
            p={2}
            m={2}
            width="100%"
          >
             <Card>
                <CardBody>

               
            <Heading size='md' m={4} textAlign='center' >Photo National ID</Heading>

            <Center>
              {currentSelected?.nationalIdPictureUrl === null ? (



                <Image
                  boxSize='25%'
                  width={'25%'}
                  objectFit='cover'
                  src={img}
                  rounded={'md'}
                  alt='image'

                />
              ) :
                (
                    
                  <Image
                    boxSize='400px'
                    width={'95%'}
                    // height={'20%'}
                    objectFit='cover'
                    src={baseURL +`onboarding/operator/customer/nationalId/pending/${currentSelected?.id}`}
                    // src={testId}
                    rounded={'md'}
                    alt='image'

                  />

                )}
            </Center>
            <Box m={2}>
              <Center>
                {currentSelected?.nationalIdPictureUrl == null ? '' : (
                //   <a target={'_blank'} href={`${baseURL}${currentSelected?.nationalIdPictureUrl}`}><Button leftIcon={<FaRegImage />} colorScheme='orange'>View Image</Button></a>
                <Button onClick={onDrOpen} leftIcon={<FaRegImage />} colorScheme='orange'>View Image</Button>
                )}
              </Center>
              <Drawer
        isOpen={isDrOpen}
        placement='bottom'
        onClose={onDrClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>View Image</DrawerHeader>

          <DrawerBody minH={'2vh'} overflowY={'scroll'}>

          <Image
                    boxSize='400px'
                    width={'50%'}
                    height={'500px'}
                    // height={'20%'}
                    objectFit='cover'
                    src={baseURL +`onboarding/operator/customer/nationalId/pending/${currentSelected?.id}`}
                    rounded={'md'}
                    alt='image'
                    

                  />
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onDrClose}>
              Cancel
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
            </Box>

            </CardBody>
            </Card>
            <Text mt={2} fontFamily={'heading'} fontWeight={'bold'}>NB: Make sure you double check customer KYC before either Approving or Declining.</Text>
  
        {user.roles[0].name === 'APPROVER' || user.roles[0].name ==='SUPER_ADMIN' ? 
        (
          <>
            <Stack direction='column' spacing={4} align='center' m={4}>
              {currentSelected?.approvalStatus === 'PENDING' &&
             <>
             
                <Button   onClick={onApOpen}
                  // onChange={(e) => setVerificationStatus('APPROVED')}
                  // isLoading={loading}
                  // loadingText='Loading'
                  // spinnerPlacement='start'
                  colorScheme='green'
                  variant='solid'>
                  Approve
                </Button>

                <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelAp}
                onClose={onApClose}
                isOpen={isApOpen}
                isCentered
              >
              <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Approve Customer</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
           Are you sure you want proceed?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button colorScheme={'red'} ref={cancelRef} onClick={onApClose}>
              No
            </Button>
            <Button 
          onClick={handleapprove}
        //    onChange={(e) => setVerificationStatus('APPROVED')}
            isLoading={loading}
            loadingText='Loading'
            spinnerPlacement='start'
            colorScheme='green' ml={3}>
              Continue
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
                  </AlertDialog>


            
              
                <Button   onClick={onDeclineOpen}
                  // onChange={(e) => setVerificationStatus('APPROVED')}
                  // isLoading={loading}
                  // loadingText='Loading'
                  // spinnerPlacement='start'
                  colorScheme='red'
                  variant='solid'>
                  Decline
                </Button>

                <Modal
                size={'lg'}
        initialFocusRef={initiateDecline}
        isOpen={isDeclineOpen}
        onClose={onDeclineClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg={'red'} color={'white'}>Decline a Customer</ModalHeader>
          <ModalCloseButton color={'white'} />
          <ModalBody pb={6}>
            <Text mb={4}>Are you sure you want to decline customer {`${currentSelected?.firstName} ${currentSelected?.lastName}`}</Text>
          
              <Box mb={6}>

              <Text mb={2}>Select reason for Decline</Text>
            <Multiselect
            options={options}
            displayValue="name"
            onSelect={onSelect}
            onRemove={onRemove}
            selectedValues={selectedReasons}
            showCheckbox={true}

            
            
            />
            </Box>
            
            
            
          </ModalBody>

          <ModalFooter>
            <Button onClick={handleDecline} colorScheme='red' mr={3}>
              Decline
            </Button>
            <Button onClick={onDeclineClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      </>
       }
      {currentSelected?.approvalStatus === 'DECLINED' && 
                    <>
                    
                    <Button isDisabled  onClick={
                      onEditOpen}
                  // onChange={(e) => setVerificationStatus('APPROVED')}
                  // isLoading={loading}
                  // loadingText='Loading'
                  // spinnerPlacement='start'
                  colorScheme='blue'
                  variant='solid'>
                  Edit
                </Button>


                <Modal
                size={'2xl'}
        initialFocusRef={initiateEdit}
        isOpen={isEditOpen}
        onClose={onEditClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg={'blue.600'} color={'white'}>Edit Declined Customer</ModalHeader>
          <ModalCloseButton color={'white'} />
          <ModalBody pb={6}>
            <form ref={createForm}>
{currentSelected?.declineReasons.map((value,index) => (
<>

<Flex key={index} direction={['column', 'row']}>


{value === 'incorrect-First Name' && 



<FormControl isRequired>
  <FormLabel htmlFor='firstName'>First name</FormLabel>
  <InputGroup>
  <InputLeftAddon children={<FiUser />} />
  <Input
    placeholder='First name'
    type='text'
    name='firstName'
    value={firstName}
    onChange={handleInputChange}
    />
    </InputGroup>
</FormControl>
    
}

{value === 'incorrect-Middle Name' && 
<FormControl mt={4} isRequired>
  <FormLabel>Middle name</FormLabel>
  <InputGroup>
  <InputLeftAddon children={<FiUser />} />
  <Input
    placeholder='Middle name'
    type='text'
    name='middleName'
    value={middleName}
    onChange={handleInputChange}
   
    
    />
    </InputGroup>
</FormControl>
}
   
{value === 'incorrect-Last Name' && 
<FormControl mt={4} isRequired>
  <FormLabel htmlFor='firstName'>Last name</FormLabel>
  <InputGroup>
  <InputLeftAddon children={<FiUser />} />
  <Input
    placeholder='Last name'
    type='text'
    name='lastName'
    value={lastName}
    onChange={handleInputChange}
    />
    </InputGroup>
</FormControl>
}
{value === 'incorrect-National-Id-Number' && 
<FormControl mt={4} isRequired>
  <FormLabel htmlFor='firstName'>National ID Number</FormLabel>
  <InputGroup>
  <InputLeftAddon children={<FaIdCard />} />
  <Input
    placeholder='national Id'
    type='text'
    name='nationalIdNumber'
    value={nationalIdNumber}
    onChange={handleInputChange}
    />
    </InputGroup>
</FormControl>
}
{value === 'incorrect-Date-of-Birth' && 
    <FormControl isRequired >
    <FormLabel htmlFor='dob'>Date Of Birth</FormLabel>
    <Input
      marginBottom={2}
      letterSpacing={4}
      name='dateOfBirth'
      type='date'
      value={dateOfBirth}
      onChange={handleInputChange}
          />
      </FormControl>
}
{value === 'incorrect-Address' && 
<FormControl mt={4} isRequired>
  <FormLabel htmlFor='address'>Address</FormLabel>
 
  <Textarea
    type='text'
    name='address'
    value={address}
    onChange={handleInputChange}
    />
   
</FormControl>
}
{
    value === '' &&

    <Text>Nothing to edit</Text>
}
    </Flex>

</>






))}
           
     
            
</form>
            
            
          </ModalBody>

          <ModalFooter>
            <Button onClick={handleUpdate} colorScheme='blue' mr={3}>
              Update
            </Button>
            <Button onClick={onEditClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
                    </>

      }
            
              </Stack>
              </>
        ):
        (
            <Stack direction='column' spacing={4} align='center' m={4}>
                <Tooltip hasArrow label='Only Approver role is allowed to approve.' bg={'red.600'}>

            <Button isDisabled   onClick={onApOpen}
              // onChange={(e) => setVerificationStatus('APPROVED')}
              // isLoading={loading}
              // loadingText='Loading'
              // spinnerPlacement='start'
              colorScheme='green'
              variant='solid'>
              Approve
            </Button>
                  </Tooltip>
           

   

       
          </Stack>
        )}
              

            </Box>
            
        </Flex>
        </Stack>
          </ModalBody>

          <ModalFooter>
          
            <Button bg={'red'} color={'white'} onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

            </>
    );
}

export default ManageDeclinedCustomer