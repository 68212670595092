import axios from "../api/axios";
// const LOGIN_URL = 'auth/employee/signin';
const LOGIN_URL = 'employee/auth/v1/signin';

export async function login(workEmail,password){
    try {
        const response = await axios.post(LOGIN_URL, {workEmail,password,signinPlatform: "WEB_PORTAL"});
        const {
          token,
          accountVerified,
          id,
          firstName,
          lastName,
          roles,
          employeeAccount,
          title
      } = response.data.body
        if(response.status === 200) {
          // console.log(response)
            // return response.data.body
            if(accountVerified) {
              const res = await axios.get("/employee/profile/v1/get-employee/" + id, {
                  
                  headers: {
                      Authorization:  `Bearer ${token}`
                  }
              });
              console.log(res)
              if(res.status === 200) {
                  return {
                      success:true,
                      message: 'Login successfull',
                      data: {
                          token,
                          accountVerified,
                          id,
                          firstName,
                          lastName,
                          roles,
                          employeeAccount,
                          title,
                          signinPlatform:" WEB_PORTAL"
                      }
                  }
              }
          } else {
              return {
                  success:true,
                  message: 'Login successfull',
                  data: {
                      token,
                      accountVerified,
                      id 
                  }
              }
          }
          }
          else {
            return {success: false, message: "Failed to login", data: null}
          }
          // else if(response.success === false) {
          //   return "Invalid Username and Password"
          // }
    } catch (error) {
        console.log(error);
    // return "Invalid Username and Password"
    if (error.response) {
      
      if (error.response.status === 401) {
        
        console.log(error.response.status);
        return {success: false, message: "Invalid credentials", data: null}
      }
    }else{
      return {success:false, message: "Network Error", data: null}
    }
    }
}