import {
  Box,
  chakra,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  useToast,
  Button,
  Stack,
  Text,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Tooltip

} from '@chakra-ui/react';
import { BsChevronRight, BsPerson, BsSimFill } from 'react-icons/bs';
import { FiUsers, FiUserPlus } from 'react-icons/fi';
import CustomerPendingList from '../form/CustomerPendingList';
import axios from '../../api/axios';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom'
import OperatorCustomer from '../Shortcut/OperatorCustomer';
import OperatorSearch from '../Shortcut/OperatorSearch';
import { FaUserPlus, FaUsers } from 'react-icons/fa';

const GETSTATS_URL = '/onboarding/operator/dashboard';
const GETSTATISTICS_URL = '/onboarding/operator/get-operator-audit-trail'
const GETALLSTATS_URL = '/onboarding/operator/get-statistics'
const GETTELCO_URL = '/onboarding/telco/operator/get-statistics'
const GETEMPLOYEE_URL = '/employee/profile/v1/get-employee/'



function StatsCard(props) {

  const { title, stat, icon } = props;
  return (
    <Stat
      px={{ base: 2, md: 4 }}
      py={'5'}
      shadow={'xl'}
      border={'1px solid'}
      bgColor={useColorModeValue('white')}
      borderColor={useColorModeValue('white', 'white')}
      rounded={'lg'}>

      <Flex justifyContent={'space-between'}>
        <Box pl={{ base: 2, md: 4 }}>
          <StatLabel fontSize={'xs'} textColor={'gray.500'} isTruncated>
            {title}
          </StatLabel>
          <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
            {stat}
          </StatNumber>
        </Box>
        <Box
          my={'auto'}
          color={useColorModeValue('white', 'white')}
          alignContent={'center'}>
          {icon}
        </Box>


      </Flex>
    </Stat>
  );
}

export default function Operator() {
  const [statistics, setStatistics] = useState({});
  const toast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchresults, setSearchResults] = useState("")
  const [employee, setEmployee] = useState('')
  const [stats, setStats] = useState('')
  const [telco, setTelco] = useState('')

  // const getEmployees = async () => {
  //     let user = localStorage.getItem('user')
  //   user = JSON.parse(user);
  //     const token = localStorage.getItem("token");
  //     try {
  //       const response = await axios.get(GETEMPLOYEE_URL + `${user.id}`, {
  //         headers: {
  //           'Authorization': `Bearer ${token}`
  //         }
  //       })
  //       if(response.data.success === true) {
  //         setEmployee(response.data.body)
  //         console.log(response.data.body.employeeAccount)
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       if (error.response) {
  //        // The request was made and the server responded with a status code
  //        // that falls out of the range of 2xx
  //        toast({
  //          title: "Error",
  //          description: `Server responded with ${error.message}`,
  //          status: "error",
  //          duration: 5000,
  //          isClosable: true,
  //        });

  //      } else if (error.request) {
  //        // The request was made but no response was received
  //        toast({
  //          title: "Network Error",
  //          description: "Please Check your Internet Connection",
  //          status: "error",
  //          duration: 8000,
  //          isClosable: true,
  //        });

  //      } else {
  //        // Something happened in setting up the request that triggered an Error
  //        toast({
  //          title: "Error",
  //          description: `Error:`,
  //          status: "error",
  //          duration: 8000,
  //          isClosable: true,
  //        });

  //      }
  //     }                                        
  //   }

  // useEffect(() => {

  //   getEmployees();

  // }, [])

  const getMyStats = async () => {
    const token = localStorage.getItem("token");
    // const myUser = employee
    // const accountId = myUser.map(employee => employee.employeeAccount.id)
    // console.log(details.employeeAccount.id);
    try {
      const res = await axios.get(`${GETALLSTATS_URL}?employeeAccountId=${user.jti}&resultType=STATISTICS&statisticsType=CREATED_BY&dashboardType=PROFILE`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      if (res.data.success === true) {
        setStats(res.data.body)
      }
    } catch (error) {
      console.log(error);
      if (error.res) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // toast({
        //   title: "Error",
        //   description: `Server responded with ${error.message}`,
        //   status: "error",
        //   duration: 5000,
        //   isClosable: true,
        // });

      } else if (error.request) {
        // The request was made but no response was received
        toast({
          title: "Network Error",
          description: "Please Check your Internet Connection",
          status: "error",
          duration: 8000,
          isClosable: true,
        });

      } else {
        // Something happened in setting up the request that triggered an Error
        toast({
          title: "Error",
          description: `Error: Please try to login`,
          status: "error",
          duration: 8000,
          isClosable: true,
        });

      }
    }
  }

  useEffect(() => {
    getMyStats();

  }, [])

  const getMyTelcoStats = async () => {
    const token = localStorage.getItem("token");
    // const myUser = employee
    // const accountId = myUser.map(employee => employee.employeeAccount.id)
    // console.log(details.employeeAccount.id);
    try {
      const res = await axios.get(`${GETTELCO_URL}?employeeAccountId=${user.jti}&resultType=STATISTICS&statisticsType=CREATED_BY&dashboardType=PROFILE`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      if (res.data.success === true) {
        setTelco(res.data.body)
      }
    } catch (error) {
      console.log(error);
          if (error.res.status) {
         // The request was made and the server responded with a status code
         // that falls out of the range of 2xx
        //  toast({
        //    title: "Error: Unauthorized Entry",
        //    description: `Server responded with ${error.message}.Please Login`,
        //    status: "error",
        //    duration: 5000,
        //    isClosable: true,
        //  });

      } else if (error.request) {
        // The request was made but no response was received
        toast({
          title: "Network Error",
          description: "Please Check your Internet Connection",
          status: "error",
          duration: 8000,
          isClosable: true,
        });

      } else {
        // Something happened in setting up the request that triggered an Error
        toast({
          title: "Error",
          description: `Error: Please try to login`,
          status: "error",
          duration: 8000,
          isClosable: true,
        });

      }
    }
  }

  useEffect(() => {
    getMyTelcoStats();

  }, [])


  let user = localStorage.getItem('user')
  user = JSON.parse(user);
  // console.log(user)

  const currentHour = new Date().getHours();
  let greeting;

  if (currentHour < 12) {
    greeting = 'Good morning';
  } else if (currentHour < 18) {
    greeting = 'Good afternoon';
  } else {
    greeting = 'Good evening';
  }

  return (
    <>
      <Box maxW="8xl" mx={'5'} pt={1} px={{ base: 2, sm: 12, md: 17 }}>
        <Breadcrumb spacing='8px' separator={<BsChevronRight color='gray.500' />}>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href='#'></BreadcrumbLink>
          </BreadcrumbItem>

        </Breadcrumb>
        <chakra.h1
          textAlign={'left'}
          fontSize={'1xl'}
          fontWeight={'bold'}
          textColor={'gray.600'}
          ml={3}
        >
          {greeting}, {user?.firstName} {user?.lastName}
        </chakra.h1>
        <Stack
          spacing={4}

          minW={'10vw'}
          bg={useColorModeValue('gray.100', 'gray.800')}
          rounded={'xl'}
          boxShadow={'sm'}
          p={2}
          my={8}>

          <Heading lineHeight={1.1} fontSize={{ base: '1xl', sm: 'md' }}>
            OneMoney Statistics
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 4 }} spacing={{ base: 5, lg: 4 }} >
            <StatsCard

              title={'Customers Registered Today'}
              stat={stats.daily}
              icon={<FaUserPlus color='blue' size={'2em'} />}
            />

            <StatsCard
              title={'Customers Registered Weekly'}
              stat={stats.weekly}
              icon={<FaUsers color='purple' size={'2em'} />}
            />
            <StatsCard
              title={'Customers Registered Monthly'}
              stat={stats.monthly}
              icon={<FaUsers color='orange' size={'2em'} />}
            />
            <StatsCard
              title={'Customers Registered Annualy'}
              stat={stats.annual}
              icon={<FaUsers color='green' size={'2em'} />}
            />


          </SimpleGrid>
        </Stack>

        <Stack
          spacing={4}

          minW={'10vw'}
          bg={useColorModeValue('gray.100', 'gray.800')}
          rounded={'xl'}
          boxShadow={'sm'}
          p={2}
          my={8}>

          <Heading lineHeight={1.1} fontSize={{ base: '1xl', sm: 'md' }}>
            Telco Statistics
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 4 }} spacing={{ base: 5, lg: 4 }} >
            <StatsCard

              title={'Customers Registered Today'}
              stat={telco.daily}
              icon={<FaUserPlus color='blue' size={'2em'} />}
            />

            <StatsCard
              title={'Customers Registered Weekly'}
              stat={telco.weekly}
              icon={<FaUsers color='purple' size={'2em'} />}
            />
            <StatsCard
              title={'Customers Registered Monthly'}
              stat={telco.monthly}
              icon={<FaUsers color='orange' size={'2em'} />}
            />
            <StatsCard
              title={'Customers Registered Annualy'}
              stat={stats.annual}
              icon={<FaUsers color='green' size={'2em'} />}
            />


          </SimpleGrid>
        </Stack>
        {user?.title === "CAREDESK PERSONNEL" ? (
          <Flex justifyContent={'flex-start'}>
        <Stack 
         spacing={2} 
         >
          <Button w={'250px'} onClick={() =>navigate('/admin/external-users')} leftIcon={<FaUserPlus />} colorScheme='orange' variant={'solid'}>Create Brand Ambassador</Button>
        </Stack>
         <Stack ml={4} spacing={4}>
          <Tooltip label="New Feature coming soon" placement='auto-end' hasArrow arrowSize={10}>

         <Button isDisabled w={'200px'} onClick={()=>navigate("/admin/bulk-form")} leftIcon={<BsSimFill />} colorScheme='blue' variant='solid'>
                Sim Bulk Registration
             </Button>
          </Tooltip>
         </Stack>
           </Flex>
        ):(
<></>
        )}
    {/* <CustomerPendingList/> */}
       
      </Box>
      <OperatorSearch />
    </>
  );
}