import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  useColorModeValue,
  Img,
  useToast,
  Text,
  InputRightElement,
  InputGroup,
  Tag,
} from "@chakra-ui/react";
import React, { useState, useEffect, useLayoutEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import c2 from "../../images/c2.png";
import axios from "../../api/axios";
import jwt from "jwt-decode";
import CountdownTimer from "./CountdownTimer";

const SETPASS_URL = "employee/auth/v1/set-password";
const VERYEMAIL_URL = "employee/auth/v1/verify-email";

function PasswordSet() {
  const toast = useToast();
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [showmatchpxd, setShowMatchPxd] = useState(false);
  const handleTclick = () => setShowMatchPxd(!showmatchpxd);
  const [confpassword, setConfPassword] = useState("");
  const [confirmPassError, setConfirmPassError] = useState("");
  const [expiryTime, setExpiryTime] = useState("");

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [workEmail, setEmail] = useState();
  const token = searchParams.get("token");
  let initialized = false
  useEffect(()=>{
    if(!initialized){
      initialized = true;
      const Verify = async () => {
        const decodedToken = jwt(token);
        setExpiryTime(new Date(decodedToken.exp * 1000).toLocaleString());
        const response = await axios
          .post(
            VERYEMAIL_URL,
            {
              workEmail: decodedToken.sub,
              verificationToken: token,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            if (response.data.success) {
              setLoading(false);
              toast({
                title: "Success.",
                description: `Email verified`,
                status: "success",
                duration: 6000,
              });
            } else {
              setLoading(false);
            
              toast({
                title: "Failed",
                description: response.data.message,
                status: "error",
                duration: 6000,
              });
            }
          })
          .catch((error) => {
            // setSuccess(true);
            setLoading(false);
            if (error.response) {
              //  setErrMsg(error.response.data.message)
              toast({
                title: "Failed",
                description: "not okay",
                status: "error",
              });
            } else if (error.request) {
              // setErrMsg("Network problem")
              toast({
                title: "Failed ",
                description: "Failed",
                status: "error",
              });
            } else {
              toast({
                title: "Failed ",
                description: "Something went wrong",
                status: "error",
              });
            }
          });
      };
  
      Verify();
    }
  },[])



  const handlepass = () => {
    setLoading(true);
    const decodedToken = jwt(token);
    axios
      .post(
        SETPASS_URL,
        {
          workEmail:decodedToken.sub,
          password,
        },
        {
          headers: {},
        }
      )
      .then((response) => {
        if (response.data.success) {
          navigate(`/`);
          setLoading(false);
          toast({
            title: "Account Success.",
            description: `Password successfully set`,
            status: "success",
            duration: 6000,
          });
        } else {
          setLoading(false);
          toast({
            title: "Failed",
            description: response.data.message,
            status: "error",
            duration: 6000,
          });
        }
      })
      .catch((error) => {
        // setSuccess(true);
        setLoading(false);
        console.log(error);
        if (error.response) {
          //  setErrMsg(error.response.data.message)
          toast({
            title: "Failed",
            description: error.response.data.message,
            status: "error",
          });
        } else if (error.request) {
          // setErrMsg("Network problem")
          toast({
            title: "Failed ",
            description: "Failed",
            status: "error",
          });
        } else {
          toast({
            title: "Failed ",
            description: "Something went wrong",
            status: "error",
          });
        }
      });
  };

  const countdownDate = new Date(expiryTime).getTime();
  //console.log(new Date)
  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("white")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Img
            src={c2}
            alt="logo"
            size="full"
            position="relative"
            display="block"
            width="40%"
            height="40%"
            justify={"center"}
            margin-left="100%"
            align={"center"}
          />

          <Heading fontSize={"20px"} mb={2}>
            Set Password
          </Heading>
          {/* <Text color={'green'} m={2} fontWeight={'semibold'}>Link expires at:</Text> */}
          {/* <Tag variant='solid' colorScheme='teal'>Link expires at:</Tag> */}
          <CountdownTimer countdownDate={countdownDate} />
        </Stack>

        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            <FormControl isRequired>
              <FormLabel htmlFor="password">Password</FormLabel>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  type={show ? "text" : "password"}
                  placeholder="Enter password"
                  name="password"
                  value={password}
                  onFocus={() => {
                    setPasswordError("");
                  }}
                  borderColor={passwordError ? "red.400" : "gray.300"}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleClick}>
                    {show ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <Text color={"red"}>{passwordError}</Text>
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="password">Confirm Password</FormLabel>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  type={showmatchpxd ? "text" : "password"}
                  placeholder="Enter password"
                  name="confpassword"
                  value={confpassword}
                  onFocus={() => {
                    setConfirmPassError("");
                  }}
                  borderColor={confirmPassError ? "red.400" : "gray.300"}
                  onChange={(e) => setConfPassword(e.target.value)}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleTclick}>
                    {showmatchpxd ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <Text color={"red"}>{confirmPassError}</Text>
            </FormControl>
            <Stack spacing={10}>
              <Button
                onClick={handlepass}
                isLoading={loading}
                loadingText="Loading"
                spinnerPlacement="start"
                bg={"orange.400"}
                color={"white"}
                _hover={{
                  bg: "orange.500",
                }}
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}

export default PasswordSet;
