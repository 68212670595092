import React from 'react'
import ManageUser from '../components/list/ManageUser'

export default function EmployeeUser() {
  return (
    <div>
        <ManageUser />
    </div>
  )
}
