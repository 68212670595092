import React from 'react'
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Flex,
  chakra,
  TabIndicator,

} from '@chakra-ui/react'
import { BsChevronRight, BsPerson } from 'react-icons/bs';
import { NavLink, useNavigate } from 'react-router-dom'
import ManageUser from '../../ManageUser';
import OnLeave from './Onleave';
import Absent from './Absent';
import UserManage from '../../UserManage';
import OnLeaveNew from './OnLeaveNew';
import AbsentNew from './AbsentNew';

function UserAvailable() {
  let user = localStorage.getItem('user')
  user = JSON.parse(user);
  console.log(user)

  return (
    <Box maxW="full"  mt={-3}>
      <Flex justifyContent="flex-end">
        <Breadcrumb fontSize={'xs'} fontWeight={'semibold'} spacing='8px' separator={<BsChevronRight color='gray.500' />}>
          <BreadcrumbItem>
            <NavLink to={'/admin/operator'}>
              <BreadcrumbLink >  Home</BreadcrumbLink>
            </NavLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href='#'>Users</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>
            <Tabs variant="unstyled">
              <TabList>
                <Tab _selected={{ color: 'white', bg: 'green' }}>Present</Tab>
                <Tab _selected={{ color: 'white', bg: 'orange' }}>Onleave</Tab>
                <Tab _selected={{ color: 'white', bg: 'red' }}>Absent</Tab>
              </TabList>
              
              <TabPanels>
                <TabPanel>
                  {/* <ManageUser /> */}
                  <UserManage />
                </TabPanel>
                <TabPanel>
                  {/* <OnLeave /> */}
                  <OnLeaveNew />
                </TabPanel>
                <TabPanel>
                    {/* <Absent/> */}
                    <AbsentNew />
                </TabPanel>
              </TabPanels>
            </Tabs>
        
    


      </Box>
    
  )
}

export default UserAvailable