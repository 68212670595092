import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  HStack,
  Avatar,
  Center,
  Box,
  Text,
  StackDivider,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Portal,
  Wrap,
  Radio,
  RadioGroup,
  FormHelperText,
  useToast,
  Tag,
  Badge,
  Container,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Stat,
  StatLabel,
  StatNumber,
  SimpleGrid,
  Select,
  Spinner,
  IconButton,
  VStack,
  InputAddon,
  InputLeftAddon,
  InputGroup,
  Textarea,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Highlight,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { FaEdit, FaFileExport, FaSearch, FaUserPlus, FaUserTag, FaUsers } from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';
import { MdArrowBack, MdOutlinePersonRemoveAlt1, MdPersonRemove, MdSms, MdVerifiedUser } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../../api/axios';
import { read, utils, writeFile } from 'xlsx';

const CHANGEROLE_URL = '/employee/profile/v1/change-employee-roles'
const RESENDLINK_URL = 'employee/auth/v1/send-email-verification-link'
const AUDITTRAIL_URL = '/onboarding/operator/get-operator-audit-trail'
const TELCO_ADUIT_URL = '/onboarding/telco/operator/get-operator-audit-trail'
const GETALLSTATS_URL = '/onboarding/operator/get-statistics'
const SENDSMSPASS_URL = '/employee/auth/v1/send-default-password'
const GETSHOPS_URL = '/employee/shop/v1/get-shops'
const GETREGION_URL = '/employee/region/v1/get-regions';
const CHANGELOCATION_URL = '/employee/profile/v1/update-employee-location'
const CHANGE_BA_LOC_URL = '/employee/brand-ambassador/v1/update-brand-ambassador-location'
const GETALLUSERS_URL = '/employee/profile/v1/get-employees?availability=PRESENT'
const GETTELCO_URL = '/onboarding/telco/operator/get-statistics'
const DEACTIVATE_USER_URL = '/employee/auth/v1/deactivate-employee'
const ACTIVATE_USER_URL = '/employee/auth/v1/activate-employee'
const UPDATEPROFILE_URL = 'employee/profile/v1/update-employee/'
const UPDATEBRAND_A_URL = '/employee/brand-ambassador/v1/update-brand-ambassador'
const UPDATEAVAILABLE_URL = 'employee/profile/v1/update-employee-availability'
const EMPLOYEE_ACC_URL = '/employee/profile/v1/get-employee-by-account-id/'



function ViewUser() {
  const location = useLocation()
  const [details, setDetails] = useState(location.state)
  const [arrayrole, setArrayRole] = useState([location.state])
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  // const [workEmail, setWorkEmail] = useState("");

  const [employeeAccountId, setEmployeeAccountID] = useState("")
  const [roles, setRoles] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchresults, setSearchResults] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const [stats, setStats] = useState('')
  const [telco, setTelco] = useState('')

  const [regionId, setRegionId] = useState('')
  const [shopId, setShopId] = useState('')
  const [keyAccountRepAccountId, setKeyAccountRepAccountId] = useState('')
  const [selectregion, setSelectRegion] = useState([]);
  const [selectshop, setSelectShop] = useState([])
  const [selectkeyaccrep, setSelectKeyAccRep] = useState([])

  console.log(details)

  const {isOpen, onOpen, onClose} = useDisclosure();
  const cancelRef = React.useRef();

  const {isOpen: isActiveOpen, onOpen: onActiveOpen, onClose: onActiveClose} = useDisclosure();
  const cancelActiveRef = React.useRef();
  // to update shop or region

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios.get(GETSHOPS_URL, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((response) => {
      setSelectShop(response.data.body);
    });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios.get(GETREGION_URL, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((response) => {
      setSelectRegion(response.data.body);

    });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios.get(GETALLUSERS_URL, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((response) => {
      setSelectKeyAccRep(response.data.body);
    });
  }, []);


  const handleChangeLocation = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axios.put(CHANGELOCATION_URL, {
      employeeProfileId: details.id,
      shopId,
      regionId

    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
    ).then(res => {

      if (res.data.success) {
        navigate('/admin/users')

        toast({
          title: 'Success',
          description: res.data.message,
          status: 'success',
          duration: 6000,

        });
        setLoading(false)

      } else {
        // console.log(res.data)
        toast({
          title: 'Failed',
          description: res.data.message,
          status: 'error',
          duration: 3000,

        });
        setLoading(false)
      }
    })
      .catch(error => {
        console.log(error)
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast({
            title: "Error",
            description: `Server responded with ${error.response.status}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          setLoading(false)
        } else if (error.request) {
          // The request was made but no response was received
          toast({
            title: "Network Error",
            description: "Please Check your Internet Connection",
            status: "error",
            duration: 8000,
            isClosable: true,
          });
          setLoading(false)
        } else {
          // Something happened in setting up the request that triggered an Error
          toast({
            title: "Network Error",
            description: `Error: ${error.message}`,
            status: "error",
            duration: 8000,
            isClosable: true,
          });

        }
      })
  }

  const handleBA_Location = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axios.put(CHANGE_BA_LOC_URL, {
      brandAmbassadorProfileId: details.id,
      shopId,
      keyAccountRepAccountId

    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
    ).then(res => {

      if (res.data.success) {
        navigate('/admin/users')

        toast({
          title: 'Success',
          description: res.data.message,
          status: 'success',
          duration: 6000,

        });
        setLoading(false)

      } else {
        // console.log(res.data)
        toast({
          title: 'Failed',
          description: res.data.message,
          status: 'error',
          duration: 3000,

        });
        setLoading(false)
      }
    })
      .catch(error => {
        console.log(error)
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast({
            title: "Error",
            description: `Server responded with ${error.response.status}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          setLoading(false)
        } else if (error.request) {
          // The request was made but no response was received
          toast({
            title: "Network Error",
            description: "Please Check your Internet Connection",
            status: "error",
            duration: 8000,
            isClosable: true,
          });
          setLoading(false)
        } else {
          // Something happened in setting up the request that triggered an Error
          toast({
            title: "Network Error",
            description: `Error: ${error.message}`,
            status: "error",
            duration: 8000,
            isClosable: true,
          });

        }
      })
  }

  const handleDeactivateUser = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axios.post(`${DEACTIVATE_USER_URL}?employeeAccountId=${details.employeeAccount.id}`, {},{
      headers: {
        "Content-type": "application/json",
        'Authorization': `Bearer ${token}`
      }

    })


      .then(response => {
        if (response.data.success) {
          navigate('/admin/users')
          setLoading(false)
          toast({
            title: 'User Account',
            description: response.data.message,
            status: 'warning',
            duration: 6000,
            position: 'top-right'

          });
        }

        else {
          setLoading(false)
          toast({
            title: 'User Account Failed',
            description: response.data.message,
            status: 'error',
            duration: 3000,

          });
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast({
            title: "Error",
            description: `Server responded with ${error.response.status}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          setLoading(false)
        } else if (error.request) {
          // The request was made but no response was received
          toast({
            title: "Network Error",
            description: "Please Check your Internet Connection",
            status: "error",
            duration: 8000,
            isClosable: true,
          });
          setLoading(false)
        } else {
          // Something happened in setting up the request that triggered an Error
          toast({
            title: "Network Error",
            description: `Error: ${error.message}`,
            status: "error",
            duration: 8000,
            isClosable: true,
          });

        }
      })
  }

  const handleActivateUser = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axios.post(`${ACTIVATE_USER_URL}?employeeAccountId=${details.employeeAccount.id}`, {
    

    }, {
      headers: {
        "Content-type": "application/json",
        'Authorization': `Bearer ${token}`
      }

    })


      .then(response => {
        if (response.data.success) {
          navigate('/admin/users')
          setLoading(false)
          toast({
            title: 'User Account',
            description: response.data.message,
            status: 'info',
            duration: 6000,
            position: 'top-right'

          });
        }

        else {
          setLoading(false)
          toast({
            title: 'User Account Failed',
            description: response.data.message,
            status: 'error',
            duration: 3000,

          });
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast({
            title: "Error",
            description: `Server responded with ${error.response.status}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          setLoading(false)
        } else if (error.request) {
          // The request was made but no response was received
          toast({
            title: "Network Error",
            description: "Please Check your Internet Connection",
            status: "error",
            duration: 8000,
            isClosable: true,
          });
          setLoading(false)
        } else {
          // Something happened in setting up the request that triggered an Error
          toast({
            title: "Network Error",
            description: `Error: ${error.message}`,
            status: "error",
            duration: 8000,
            isClosable: true,
          });

        }
      })
  }


  const options = [
    // { label: 'Operator', value: "OPERATOR" },
    { label: 'Clerk', value: "CLERK", roles: ["ADMIN"] },
    { label: 'Clerk', value: "CLERK", roles: ["SUPER_ADMIN"] },
    { label: 'Admin', value: "ADMIN", roles: ["ADMIN"] },
    { label: 'Approver', value: "APPROVER", roles: ["SUPER_ADMIN"] },
    { label: 'Approver', value: "APPROVER", roles: ["ADMIN"] },
    { label: 'Admin', value: "ADMIN", roles: ["SUPER_ADMIN"] },
    { label: 'Super Admin', value: "SUPER_ADMIN", roles: ["SUPER_ADMIN"] },
    { label: 'Executive', value: "EXECUTIVE", roles: ["SUPER_ADMIN"] },
  ];
  let user = localStorage.getItem('user')
  user = JSON.parse(user);

  const userRoles = user.roles[0].name
  const filteredOptions = options.filter(item => userRoles === item.roles[0]);



  const handleRoles = () => {
    // e.preventDefault();
    const newData = {
      option: selectedOption,
    };
    // setRoles([...roles, selectedOption]);
    const token = localStorage.getItem("token");
    axios.put(CHANGEROLE_URL, {
      employeeAccountId: details.employeeAccount.id,
      roles: [selectedOption],

    }, {
      headers: {
        "Content-type": "application/json",
        'Authorization': `Bearer ${token}`
      }

    })


      .then(response => {
        if (response.data.success) {
          navigate('/admin/users')
          toast({
            title: 'User Role Updated',
            description: response.data.message,
            status: 'success',
            duration: 6000,

          });
        }

        else {

          toast({
            title: 'Failed',
            description: response.data.message,
            status: 'error',
            duration: 3000,

          });
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast({
            title: "Error",
            description: `Server responded with ${error.response.status}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });

        } else if (error.request) {
          // The request was made but no response was received
          toast({
            title: "Network Error",
            description: "Please Check your Internet Connection",
            status: "error",
            duration: 8000,
            isClosable: true,
          });

        } else {
          // Something happened in setting up the request that triggered an Error
          toast({
            title: "Network Error",
            description: `Error: ${error.message}`,
            status: "error",
            duration: 8000,
            isClosable: true,
          });

        }
      })
  }

  const handleResendlink = () => {

    axios.post(RESENDLINK_URL, {
      workEmail: details.employeeAccount.workEmail,
    }, {
      headers: { "Content-type": "application/json" }
    }

    )
      .then(response => {
        // console.log(response)
        if (response.data.success) {

          toast({
            title: 'Success.',
            description: `Done`,
            status: 'success',
            duration: 6000,

          });
        }
        else {

          toast({
            title: 'Failed',
            description: response.data.message,
            status: 'error',
            duration: 6000,

          });
        }
      })
      .catch(error => {
        // setSuccess(true);
        console.log(error);
        if (error.response) {
          //  setErrMsg(error.response.data.message)
          toast({
            title: "Failed To Log In",
            description: error.response.data.message,
            status: "error",
          });

        } else if (error.request) {
          // setErrMsg("Network problem")
          toast({
            title: "Failed To Log In",
            description: "Failed",
            status: "error",
          });

        } else {
          toast({
            title: "Failed ",
            description: "Something went wrong",
            status: "error",
          });

        }

      })

  }
  const handleResendPassword = () => {

    const token = localStorage.getItem("token");
    axios.post(SENDSMSPASS_URL, {
      firstName: details.firstName,
      lastName: details.lastName,
      phoneNumber: details.employeeAccount.workPhoneNumber,
      email: details.employeeAccount.workEmail
    }, {
      headers: {
        "Content-type": "application/json",
        'Authorization': `Bearer ${token}`
      }
    }

    )
      .then(response => {
        // console.log(response)
        if (response.data.success) {

          toast({
            title: 'Success.',
            description: `An SMS  has been send to ${details.employeeAccount.workPhoneNumber} wit`,
            status: 'success',
            duration: 6000,

          });
        }
        else {

          toast({
            title: 'Failed',
            description: response.data.message,
            status: 'error',
            duration: 6000,

          });
        }
      })
      .catch(error => {
        // setSuccess(true);
        console.log(error);
        if (error.response) {
          //  setErrMsg(error.response.data.message)
          toast({
            title: "Failed To Send",
            description: error.response.data.message,
            status: "error",
          });

        } else if (error.request) {
          // setErrMsg("Network problem")
          toast({
            title: "Failed To Send",
            description: "Failed",
            status: "error",
          });

        } else {
          toast({
            title: "Failed ",
            description: "Something went wrong",
            status: "error",
          });

        }

      })

  }

  const handleStartDateInputChange = (e) => {
    setStartDate(e.target.value);
  };
  const handleEndDateInputChange = (e) => {
    setEndDate(e.target.value);
  };
  const handleAudit = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axios.get(`${AUDITTRAIL_URL}?employeeAccountId=${details.employeeAccount.id}&resultType=&startDate=${startDate}&endDate=${endDate}`,
      {

        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }
    )
      .then(res => {

        if (res.data.success) {
          setSearchResults(res.data.body);
          toast({
            title: 'OneMoney Data Retrieved Successfully',
            description: res.data.message,
            status: 'info',
            duration: 3000,
            position: 'top'

          });
          setLoading(false);

        } else {
          toast({
            title: 'OneMoney Data Retrieved Failed',
            description: res.data.message,
            status: 'error',
            duration: 3000,

          });
          setLoading(false)
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast({
            title: "Error",
            description: `Server responded with ${error.response.status}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          setLoading(false)
        } else if (error.request) {
          // The request was made but no response was received
          toast({
            title: "Network Error",
            description: "Please Check your Internet Connection",
            status: "error",
            duration: 8000,
            isClosable: true,
          });
          setLoading(false)
        } else {
          // Something happened in setting up the request that triggered an Error
          toast({
            title: "Network Error",
            description: `Error: ${error.message}`,
            status: "error",
            duration: 8000,
            isClosable: true,
          });

        }
      })
  }

  const handleTelcoAudit = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axios.get(`${TELCO_ADUIT_URL}?employeeAccountId=${details.employeeAccount.id}&resultType=&startDate=${startDate}&endDate=${endDate}`,
      {

        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }
    )
      .then(res => {

        if (res.data.success) {
          if(res.data.body === null){
            toast({
              title: 'Telco Data Retrieved',
              description: `No customers registered from ${startDate} to ${endDate} `,
              status: 'info',
              duration: 4000,
              
            });
            setLoading(false)

              return;
        }
          setSearchResults(res.data.body);
          console.log(res.data.body)
          toast({
            title: 'Telco Data Retrieved Successfully',
            description: res.data.message,
            status: 'info',
            duration: 3000,
            position: 'top-right',

          });
          setLoading(false);

        } else {
          toast({
            title: 'Telco Data Retrieved Failed',
            description: res.data.message,
            status: 'error',
            duration: 3000,

          });
          setLoading(false)
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast({
            title: "Error",
            description: `Server responded with ${error.response.status}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          setLoading(false)
        } else if (error.request) {
          // The request was made but no response was received
          toast({
            title: "Network Error",
            description: "Please Check your Internet Connection",
            status: "error",
            duration: 8000,
            isClosable: true,
          });
          setLoading(false)
        } else {
          // Something happened in setting up the request that triggered an Error
          toast({
            title: "Network Error",
            description: `Error: ${error.message}`,
            status: "error",
            duration: 8000,
            isClosable: true,
          });

        }
      })
  }

  const handleExport = () => {
    const headings = [["#", "FirstName", "LastName", "Phone Number"]];
    const wb = utils.book_new();
    const ws = utils.table_to_sheet(document.getElementById('users'));
    utils.sheet_add_aoa(ws, headings);
    //    utils.sheet_add_json(ws, searchresults, { origin: "A2", skipHeader: true});
    utils.book_append_sheet(wb, ws, "User_Report");
    writeFile(wb, "User_Report.xlsx")
  };

  const getMyStats = async () => {
    const token = localStorage.getItem("token");
    // const myUser = employee
    // const accountId = myUser.map(employee => employee.employeeAccount.id)
    // console.log(details.employeeAccount.id);
    try {
      const res = await axios.get(`${GETALLSTATS_URL}?employeeAccountId=${details.employeeAccount.id}&resultType=STATISTICS&statisticsType=CREATED_BY&dashboardType=PROFILE`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      if (res.data.success === true) {
        setStats(res.data.body)
      }
    } catch (error) {
      console.log(error);
      if (error.res) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // toast({
        //   title: "Error",
        //   description: `Server responded with ${error.message}`,
        //   status: "error",
        //   duration: 5000,
        //   isClosable: true,
        // });

      } else if (error.request) {
        // The request was made but no response was received
        toast({
          title: "Network Error",
          description: "Please Check your Internet Connection",
          status: "error",
          duration: 8000,
          isClosable: true,
        });

      } else {
        // Something happened in setting up the request that triggered an Error
        toast({
          title: "Error",
          description: `Error:`,
          status: "error",
          duration: 8000,
          isClosable: true,
        });

      }
    }
  }

  useEffect(() => {
    getMyStats();

  }, [])

  const getMyTelcoStats = async () => {
    const token = localStorage.getItem("token");
    // const myUser = employee
    // const accountId = myUser.map(employee => employee.employeeAccount.id)
    // console.log(details.employeeAccount.id);
    try {
      const res = await axios.get(`${GETTELCO_URL}?employeeAccountId=${details.employeeAccount?.id}&resultType=STATISTICS&statisticsType=CREATED_BY&dashboardType=PROFILE`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      if (res.data.success === true) {
        setTelco(res.data.body)
      }
    } catch (error) {
      console.log(error);
      if (error.res) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // toast({
        //   title: "Error",
        //   description: `Server responded with ${error.message}`,
        //   status: "error",
        //   duration: 5000,
        //   isClosable: true,
        // });

      } else if (error.request) {
        // The request was made but no response was received
        toast({
          title: "Network Error",
          description: "Please Check your Internet Connection",
          status: "error",
          duration: 8000,
          isClosable: true,
        });

      } else {
        // Something happened in setting up the request that triggered an Error
        toast({
          title: "Error",
          description: `Error:`,
          status: "error",
          duration: 8000,
          isClosable: true,
        });

      }
    }
  }

  useEffect(() => {
    getMyTelcoStats();

  }, [])


  function StatsCard(props) {

    const { title, stat, icon } = props;
    return (
      <Stat
        px={{ base: 2, md: 4 }}
        py={'5'}
        shadow={'xl'}
        border={'1px solid'}
        bgColor={useColorModeValue('white')}
        borderColor={useColorModeValue('white', 'white')}
        rounded={'lg'}>

        <Flex justifyContent={'space-between'}>
          <Box pl={{ base: 2, md: 4 }}>
            <StatLabel fontSize={'xs'} textColor={'gray.500'} isTruncated>
              {title}
            </StatLabel>
            <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
              {stat}
            </StatNumber>
          </Box>
          <Box
            my={'auto'}
            color={useColorModeValue('white', 'white')}
            alignContent={'center'}>
            {icon}
          </Box>


        </Flex>
      </Stat>
    );
  }

  useEffect(() => {
    setTotalPages(Math.ceil(searchresults.length / itemsPerPage));
  }, [searchresults]);

  const paginateData = (searchresults, currentPage, itemsPerPage) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return searchresults.slice(startIndex, endIndex);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  }

  const paginatedData = paginateData(searchresults, currentPage, itemsPerPage);

  const titles = [
   
    { label: 'Super Admin', value: "SUPER ADMIN",roles: ["SUPER_ADMIN"] },
    {label: 'Approver', value: "APPROVER", roles:  ["SUPER_ADMIN"] },
    { label: 'Executive', value: "EXECUTIVE",roles: ["SUPER_ADMIN"] },
    { label: 'Zone Commander', value: "ZONE COMMANDER",roles: ["SUPER_ADMIN"] },
    { label: 'Zone Admin', value: "ZONE ADMIN ",roles: ["SUPER_ADMIN"] },
    { label: 'National sales admin', value: "NATIONAL SALES ADMIN",roles: ["SUPER_ADMIN"] },
    { label: 'Shop Supervisor', value: "SHOP SUPERVISOR",roles: ["SUPER_ADMIN"] },
    { label: 'Regional Sales Manager', value: "REGIONAL SALES MANAGER",roles: ["SUPER_ADMIN"] },
    { label: 'Key Account Rep', value: "KEY ACCOUNT REP",roles: ["SUPER_ADMIN"] },
    { label: 'Business development Officer', value: "BUSINESS DEVELOPMENT OFFICER ",roles: ["SUPER_ADMIN"] },
    { label: 'Customer Care Rep', value: "CUSTOMER CARE REP",roles: ["SUPER_ADMIN"] },
    { label: 'Cashier', value: "CASHIER",roles: ["SUPER_ADMIN"] },
    { label: 'Call Center', value: "CALL CENTER",roles: ["SUPER_ADMIN"] },
    { label: 'Graduate Trainee', value: "GRADUATE TRAINEE",roles: ["SUPER_ADMIN"] },
    {label: 'CareDesk Personnel', value: "CAREDESK PERSONNEL", roles: ["SUPER_ADMIN"] },
    {label: 'MFS Customer Experience Agent', value: "MFS CUSTOMER EXPERIENCE AGENT", roles: ["SUPER_ADMIN"] },
    {label: 'Reporting Analyst', value: "REPORTING ANALYST", roles: ["SUPER_ADMIN"] },
    {label: 'Regional Administrator', value: "REGIONAL ADMINISTRATOR", roles: ["SUPER_ADMIN"] },


   
  ];
  const userRol = user.roles[0].name
// console.log(userRol);


const filteredTitles = titles.filter(itek => userRol === itek.roles[0])
// edit infor


const inititate = {
  ecNumber: details.ecNumber,
  firstName: details.firstName,
  lastName: details.lastName,
  personalPhoneNumber: details.personalPhoneNumber,
  personalEmail: details.personalEmail,
  workPhoneNumber: details.employeeAccount?.workPhoneNumber,
  email: details.employeeAccount?.workEmail,
  address: details.address,
  title: details.employeeAccount?.title,
  grade:details.grade
}

const BAinitiate ={
  firstName: details.firstName,
  lastName: details.lastName,
  workPhoneNumber: details.employeeAccount?.workPhoneNumber,
  email: details.employeeAccount?.workEmail,
  nationalIdNumber: details.nationalIdNumber,
  address: details.address,

}

const [employee, setEmployee] = useState(inititate)
const [brandAmb, setBrandAmb] = useState(BAinitiate)

    const {ecNumber, firstName, lastName, personalPhoneNumber, personalEmail, workPhoneNumber, workEmail, address,title,grade } = employee
    const {name, surname, phoneNumber, baEmail, ba_address, nationalIdNumber } = brandAmb

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setEmployee({ ...employee, [name]: value });
    };
    const handleBAInputChange = (e) => {
      const { name, value } = e.target;
      setBrandAmb({ ...brandAmb, [name]: value });
    };

    const handleEditUser = () => {
      setLoading(true);
      const token = localStorage.getItem("token");
      axios.put(UPDATEPROFILE_URL, {
        id: details.id,
        ecNumber:employee.ecNumber,
        firstName: employee.firstName,
        lastName: employee.lastName,
        personalPhoneNumber:employee.personalPhoneNumber,
        personalEmail: employee.personalEmail,
        workPhoneNumber: employee.workPhoneNumber,
        workEmail: employee.email,
        address:employee.address,
        title: employee.title,
        grade:employee.grade
  
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
  
      })
  
  
        .then(response => {
          if (response.data.success) {
            navigate('/admin/users')
            setLoading(false)
            toast({
              title: 'User Updated',
              description: response.data.message,
              status: 'success',
              duration: 6000,
  
            });
          }
  
          else {
            setLoading(false)
            toast({
              title: 'Failed',
              description: response.data.message,
              status: 'error',
              duration: 3000,
  
            });
          }
        })
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            toast({
              title: "Error",
              description: `Server responded with ${error.response.status}`,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            setLoading(false)
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              title: "Network Error",
              description: "Please Check your Internet Connection",
              status: "error",
              duration: 8000,
              isClosable: true,
            });
            setLoading(false)
          } else {
            // Something happened in setting up the request that triggered an Error
            toast({
              title: "Network Error",
              description: `Error: ${error.message}`,
              status: "error",
              duration: 8000,
              isClosable: true,
            });
  
          }
        })
    }

    const handleEditBA = () => {
      setLoading(true);
      const token = localStorage.getItem("token");
      axios.put(UPDATEBRAND_A_URL, {
        brandAmbassadorProfileId: details.id,
        firstName: brandAmb.firstName,
        lastName: brandAmb.lastName,
        workPhoneNumber: brandAmb.workPhoneNumber,
        workEmail: brandAmb.email,
        address:brandAmb.address,
        nationalIdNumber: brandAmb.nationalIdNumber,
  
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
  
      })
  
  
        .then(response => {
          if (response.data.success) {
            navigate('/admin/users')
            setLoading(false)
            toast({
              title: 'Brand Ambassador Updated',
              description: response.data.message,
              status: 'success',
              duration: 6000,
  
            });
          }
  
          else {
            setLoading(false)
            toast({
              title: 'Failed',
              description: response.data.message,
              status: 'error',
              duration: 3000,
  
            });
          }
        })
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            toast({
              title: "Error",
              description: `Server responded with ${error.response.status}`,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            setLoading(false)
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              title: "Network Error",
              description: "Please Check your Internet Connection",
              status: "error",
              duration: 8000,
              isClosable: true,
            });
            setLoading(false)
          } else {
            // Something happened in setting up the request that triggered an Error
            toast({
              title: "Network Error",
              description: `Error: ${error.message}`,
              status: "error",
              duration: 8000,
              isClosable: true,
            });
  
          }
        })
    }
    const [availability, setAvailability] = useState('');

    const handleapprove = (status) => {
      // e.preventDefault();
      setLoading(true)
      let user = localStorage.getItem('user')
      user = JSON.parse(user);
      const token = localStorage.getItem("token");
      axios.put(UPDATEAVAILABLE_URL, {
        id: details.id,
        availability: status
  
      }, {
        headers: {
          "Content-type": "application/json",
          'Authorization': `Bearer ${token}`
        }
      }
  
      ).then(response => {
        if (response.data.success) {
          navigate('admin/users')
          
          toast({
            title: 'Success.',
            description: 'Status Updated',
            status: 'success',
            duration: 6000,
  
          });
          setLoading(false)
        }
  
        else {
          setLoading(false)
          toast({
            title: 'Failed',
            description: 'Failed to change',
            status: 'error',
            duration: 6000,
  
          });
        }
      })
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            toast({
              title: "Error",
              description: `Server responded with ${error.response.status}`,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            setLoading(false)
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              title: "Network Error",
              description: "Please Check your Internet Connection",
              status: "error",
              duration: 8000,
              isClosable: true,
            });
            setLoading(false)
          } else {
            // Something happened in setting up the request that triggered an Error
            toast({
              title: "Network Error",
              description: `Error: ${error.message}`,
              status: "error",
              duration: 8000,
              isClosable: true,
            });
  
          }
        })
    }

    const [accountId, setAccountId] = useState("");
  
    const getEmployee = async () => {
      setLoading(true);
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(EMPLOYEE_ACC_URL + `${details.employeeAccount?.createdBy}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
  
        if (response.status === 200) {
          setAccountId(response.data.body)
          // console.log(response.data.body)
          setLoading(false);
        }
      } catch (error) {
  
      }
    }
  
  
    useEffect(() => {
      getEmployee();
  
    }, [])

    const [showOnemoney, setShowOneMoney] = useState(false)
    const [showZsmart, setShowZsmart] = useState(false);
    const [selectCustomerSearch, setSelectCustomerSearch] = useState('')

    const customers = [
      // { label: 'Operator', value: "OPERATOR" },
      { label: 'OneMoney', value: "onemoney"},
      { label: 'ZSmart', value: "zsmart"},
      
    ]
    useEffect(() => {
      if(selectCustomerSearch === 'onemoney') {
        setShowOneMoney(true)
      } else {
        setShowOneMoney(false)
      }
    }, [selectCustomerSearch])

    useEffect(() => {
      if(selectCustomerSearch === 'zsmart') {
        setShowZsmart(true)
      } else {
        setShowZsmart(false)
      }
    }, [selectCustomerSearch])

  
  

  return (
    <Container bgColor={'white'} maxW={'full'} minW={'fit-content'}>

      <HStack alignItems={'flex-start'}>
        <Box
          p={2}
          minW={'-moz-fit-content'}
        >
          <Stack
            spacing={4}
            w={'full'}
            maxW={'md'}
            minW={'-moz-fit-content'}
            bg={useColorModeValue('white', 'gray.700')}
            rounded={'xl'}
            boxShadow={'lg'}
            p={4}
            my={8}>
            <Heading lineHeight={1.1} fontSize={{ base: '1xl', sm: '2xl' }} align={'center'} justify={'center'}>
              User Profile
            </Heading>
            <FormControl id="userName">
              <Stack direction={['column', 'row']} spacing={6} align={'center'} justify={'center'}>
                <VStack>
                  <Avatar
                    size={'xl'}
                    name={details.firstName}
                  />
                  
                  {details.employeeAccount?.title === 'BRAND AMBASSADOR' ? (
                    <>
                    
                    {user?.roles[0].name === 'SUPER_ADMIN'  && 
                   
                   <>
                 
                   <Box display={{ base: 'none', md: 'flex' }}>
                     <Popover placement='right'>
                       <PopoverTrigger>
 
                         <IconButton colorScheme='orange' size='sm' icon={<FaEdit />} />
                       </PopoverTrigger >
                       <Portal>
                         <PopoverContent>
                           <PopoverArrow />
                           <PopoverHeader bg={'yellow.400'} color={'white'}>Edit BA Personal Info</PopoverHeader>
                           <PopoverCloseButton />
                           <PopoverBody>
                             <HStack mb={4} spacing={4}>
                               <FormControl isRequired>
                                 <FormLabel htmlFor='firstName'>First name</FormLabel>
                                 <Input
                                   id='firstName'
                                   placeholder='First name'
                                   type='text'
                                   name='firstName'
                                   value={brandAmb.firstName}
                                   onChange={handleBAInputChange}
                                   />
                               </FormControl>
 
                               <FormControl isRequired>
                                 <FormLabel htmlFor='lastName'>Last name</FormLabel>
                                 <Input
                                   id='lastName'
                                   placeholder='Last name'
                                   type='text'
                                   name='lastName'
                                   value={brandAmb.lastName}
                                   onChange={handleBAInputChange}
 
                                 />
                               </FormControl>
                             </HStack>
                           
                             <FormControl mt={2} isRequired>
               <FormLabel htmlFor='phoneNumber'>Mobile Number</FormLabel>
               <InputGroup>
                 <InputLeftAddon children='+263' />
                 <Input id='workPhoneNumber'
                   type='number'
                   placeholder='712000000'
                   _placeholder={{ color: 'gray.500' }}
                   name='workPhoneNumber'
                   value={brandAmb.workPhoneNumber}
                   onChange={handleBAInputChange}
 
                   />
               </InputGroup>
               {/* <Text color={'red'}>{phoneError}</Text> */}
             </FormControl>
             <FormControl isRequired>
                  <FormLabel htmlFor='nationalIdNumber'>National ID:</FormLabel>
                  <FormHelperText>eg. 00-0000000A00</FormHelperText>
                  <Input
                    marginBottom={2}
                    id='nationalIdNumber'
                    placeholder='00-00000000A00'
                    type='text'
                    name='nationalIdNumber'
                    value={brandAmb.nationalIdNumber}
                    // onFocus={() => { setNatIdError('') }} borderColor={natIdError ? 'red.400' : 'gray.300'}
                    onChange={handleBAInputChange}
                    textTransform='uppercase'
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor='address'>Address</FormLabel>
                  <Textarea
                    marginBottom={2}
                    id='address'
                    placeholder='Address'
                    type='text'
                    name='address'
                    value={brandAmb.address}
                    onChange={handleBAInputChange}

                  />
                </FormControl>
 
             <FormControl isRequired>
               <FormLabel mt={2} htmlFor='email'>Email: </FormLabel>
               <Input
 
                 id='email'
                 placeholder='example@email.com'
                 name='email'
                 type='email'
                 value={brandAmb.email}
                 onChange={handleBAInputChange}
                 
               />
               {/* <Text color={'red'}>{emailError}</Text> */}
             </FormControl>
             
             <Button onClick={handleEditBA}  mt={2} colorScheme={'blue'}>Update</Button>
 
           
                           </PopoverBody>
                         </PopoverContent>
                       </Portal>
                     </Popover>
                   </Box>
                   </>
                   
                   }
                   {user?.roles[0].name === 'ADMIN'  && 
                   
                   <>
                 
                   <Box display={{ base: 'none', md: 'flex' }}>
                     <Popover placement='right'>
                       <PopoverTrigger>
 
                         <IconButton colorScheme='orange' size='sm' icon={<FaEdit />} />
                       </PopoverTrigger >
                       <Portal>
                         <PopoverContent>
                           <PopoverArrow />
                           <PopoverHeader bg={'yellow.400'} color={'white'}>Edit BA Personal Info</PopoverHeader>
                           <PopoverCloseButton />
                           <PopoverBody>
                             <HStack mb={4} spacing={4}>
                               <FormControl isRequired>
                                 <FormLabel htmlFor='firstName'>First name</FormLabel>
                                 <Input
                                   id='firstName'
                                   placeholder='First name'
                                   type='text'
                                   name='firstName'
                                   value={brandAmb.firstName}
                                   onChange={handleBAInputChange}
                                   />
                               </FormControl>
 
                               <FormControl isRequired>
                                 <FormLabel htmlFor='lastName'>Last name</FormLabel>
                                 <Input
                                   id='lastName'
                                   placeholder='Last name'
                                   type='text'
                                   name='lastName'
                                   value={brandAmb.lastName}
                                   onChange={handleBAInputChange}
 
                                 />
                               </FormControl>
                             </HStack>
                           
                             <FormControl mt={2} isRequired>
               <FormLabel htmlFor='phoneNumber'>Mobile Number</FormLabel>
               <InputGroup>
                 <InputLeftAddon children='+263' />
                 <Input id='workPhoneNumber'
                   type='number'
                   placeholder='712000000'
                   _placeholder={{ color: 'gray.500' }}
                   name='workPhoneNumber'
                   value={brandAmb.workPhoneNumber}
                   onChange={handleBAInputChange}
 
                   />
               </InputGroup>
               {/* <Text color={'red'}>{phoneError}</Text> */}
             </FormControl>
             <FormControl isRequired>
                  <FormLabel htmlFor='nationalIdNumber'>National ID:</FormLabel>
                  <FormHelperText>eg. 00-0000000A00</FormHelperText>
                  <Input
                    marginBottom={2}
                    id='nationalIdNumber'
                    placeholder='00-00000000A00'
                    type='text'
                    name='nationalIdNumber'
                    value={brandAmb.nationalIdNumber}
                    // onFocus={() => { setNatIdError('') }} borderColor={natIdError ? 'red.400' : 'gray.300'}
                    onChange={handleBAInputChange}
                    textTransform='uppercase'
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor='address'>Address</FormLabel>
                  <Textarea
                    marginBottom={2}
                    id='address'
                    placeholder='Address'
                    type='text'
                    name='address'
                    value={brandAmb.address}
                    onChange={handleBAInputChange}

                  />
                </FormControl>
 
             <FormControl isRequired>
               <FormLabel mt={2} htmlFor='email'>Email: </FormLabel>
               <Input
 
                 id='email'
                 placeholder='example@email.com'
                 name='email'
                 type='email'
                 value={brandAmb.email}
                 onChange={handleBAInputChange}
                 
               />
               {/* <Text color={'red'}>{emailError}</Text> */}
             </FormControl>
             
             <Button onClick={handleEditBA}  mt={2} colorScheme={'blue'}>Update</Button>
 
           
                           </PopoverBody>
                         </PopoverContent>
                       </Portal>
                     </Popover>
                   </Box>
                   </>
                   
                   }
                    </>

                  ): (
                    <>
                       {user?.roles[0].name === 'SUPER_ADMIN' &&
                  
                 
                  <Box display={{ base: 'none', md: 'flex' }}>
                    <Popover placement='right'>
                      <PopoverTrigger>

                        <IconButton colorScheme='yellow' size='sm' icon={<FaEdit />} />
                      </PopoverTrigger >
                      <Portal>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverHeader bg={'yellow.400'} color={'white'}>Edit Personal Info</PopoverHeader>
                          <PopoverCloseButton />
                          <PopoverBody>
                            <HStack mb={4} spacing={4}>
                              <FormControl isRequired>
                                <FormLabel htmlFor='firstName'>First name</FormLabel>
                                <Input
                                  id='firstName'
                                  placeholder='First name'
                                  type='text'
                                  name='firstName'
                                  value={employee.firstName}
                                  onChange={handleInputChange}
                                />
                              </FormControl>

                              <FormControl isRequired>
                                <FormLabel htmlFor='lastName'>Last name</FormLabel>
                                <Input
                                  id='lastName'
                                  placeholder='Last name'
                                  type='text'
                                  name='lastName'
                                  value={employee.lastName}
                                  onChange={handleInputChange}

                                />
                              </FormControl>
                            </HStack>
                            <FormControl >
              <FormLabel mt={2} htmlFor='title'>Designation: </FormLabel>
                <Select 
                    // maxW={'260px'}
                   _hover={{
                     borderRadius: 'orange',
                   }} 
                   placeholder='Select Title' name='title'value={employee.title} onChange={handleInputChange} >
          
           {filteredTitles.map((option) => (
             <option key={option.id} value={option.value}>
               {option.label}
             </option>
           ))}
             
           </Select>
            </FormControl>
                          
                            <FormControl mt={2} isRequired>
              <FormLabel htmlFor='phoneNumber'>Mobile Number</FormLabel>
              <InputGroup>
                <InputLeftAddon children='+263' />
                <Input id='workPhoneNumber'
                  type='number'
                  placeholder='712000000'
                  _placeholder={{ color: 'gray.500' }}
                  name='workPhoneNumber'
                  value={employee.workPhoneNumber}
                  onChange={handleInputChange}

                  />
              </InputGroup>
              {/* <Text color={'red'}>{phoneError}</Text> */}
            </FormControl>

            <FormControl isRequired>
              <FormLabel mt={2} htmlFor='email'>Work Email: </FormLabel>
              <Input

                id='email'
                placeholder='example@email.com'
                name='email'
                type='email'
                value={employee.email}
                onChange={handleInputChange}
                
              />
              {/* <Text color={'red'}>{emailError}</Text> */}
            </FormControl>
            
            <Button onClick={handleEditUser}  mt={2} colorScheme={'blue'}>Update</Button>

          
                          </PopoverBody>
                        </PopoverContent>
                      </Portal>
                    </Popover>
                  </Box>
}
                    </>
                  )
                  
                  
 

                
}
                </VStack>
              </Stack>
              {details.employeeAccount?.title === 'BRAND AMBASSADOR' ? 
              (
                <>
                
                </>
              ):
               (
<>
          {user?.roles[0].name === 'SUPER_ADMIN' &&

            <Flex mt={2} alignContent={'center'} justifyContent={'center'} >
              <Heading size='xs'>
                Status:
              </Heading>
              <Popover>
                <PopoverTrigger>

                  {details.availability === 'PRESENT' ? (<Button ml={2} colorScheme='green' size='xs'>Present</Button>) : details.availability === 'ABSENT' ? (<Button ml={2} colorScheme='red' size='xs'>Absent</Button>) : details.availability === 'LEAVE' ? (<Button ml={2} colorScheme='orange' size='xs'>OnLeave</Button>):<Button ml={2} colorScheme='gray' size='xs'>Available</Button>}
                </PopoverTrigger>
                <Portal>
                  <PopoverContent >
                    <PopoverArrow />
                    <PopoverHeader>Change Status</PopoverHeader>
                    <PopoverCloseButton />
                    <PopoverBody>
                      <Wrap spacing={2} direction='row' align='center'>

                        <Button
                          onClick={() => handleapprove('PRESENT')}
                          onChange={(e) => setAvailability('PRESENT')}
                          colorScheme='green'>Present</Button>
                        <Button
                          onClick={() => handleapprove('LEAVE')}
                          onChange={(e) => setAvailability('LEAVE')}
                          colorScheme='orange'>OnLeave</Button>
                        <Button
                          onClick={() => handleapprove('ABSENT')}
                          onChange={(e) => setAvailability('ABSENT')}
                          colorScheme='red'>Absent</Button>
                      </Wrap>
                    </PopoverBody>

                  </PopoverContent>
                </Portal>
              </Popover>
                </Flex>

          }
               </>
               )}
            </FormControl>

        <Flex justifyContent={'space-evenly'}>
            <Stack  divider={<StackDivider />} spacing={4} justifyContent={'space-evenly'} alignContent={'space-evenly'}>
              <HStack spacing={14}>

                <Box>
                  <Heading size='xs' textTransform='capitalize'>
                    First Name:
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    {details.firstName}
                  </Text>
                </Box>
                <Box>
                  <Heading size='xs' textTransform='capitalize'>
                    Last Name:
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    {details.lastName}
                  </Text>
                </Box>
              </HStack>
              <HStack spacing={8}>
                <Box>
                  <Heading size='xs' textTransform='capitalize'>
                    Phone Number:
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    {details?.employeeAccount?.workPhoneNumber}
                  </Text>
                </Box>
                <Box>
                  <Heading size='xs' textTransform='capitalize'>
                    Email:
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    {details.employeeAccount?.workEmail? details.employeeAccount.workEmail : employee.email}
                  </Text>
                </Box>
              </HStack>
              <Box>
                <Heading size='xs' textTransform='capitalize'>
                  Title:
                </Heading>

                <Text pt='2' fontSize='sm'>
                  {details.employeeAccount?.title == null ? (<Text color={'gray.400'}>Not Available </Text>) : <Text pt='2' fontSize='sm'>  {details.employeeAccount?.title}</Text>}
                </Text>
              </Box>
              {details?.employeeAccount?.title === 'APPROVER' ? (
                  <>
                   <HStack spacing={20}>
                   <Box>
                  <Heading size='xs' textTransform='capitalize'>
                    Approver for:
                  </Heading>
                  <Text pt='2' fontSize='sm'>


                    {details?.provinces[0].name == null ? (<Text color={'gray.400'}>Not Available</Text>) : <Badge variant='solid' colorScheme='blackAlpha'>  {details?.provinces[0].name}</Badge>}
                  </Text>
                </Box>
                <Box>
                  <Heading size='xs' textTransform='capitalize'>
                    Region:
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    {details.zone?.name == null ? (<Text color={'gray.400'}>Not Available</Text>) : <Badge variant='solid' colorScheme='blackAlpha'>  {details.zone?.name}</Badge>}
                  </Text>
                </Box>
                </HStack>
                  </>
              ): (
                <>
                 <HStack spacing={20}>
                <Box>
                  <Heading size='xs' textTransform='capitalize'>
                    Shop:
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    {details.shop?.name == null ? (<Text color={'gray.400'}>Not Available</Text>) : <Badge variant='solid' colorScheme='blackAlpha'>  {details.shop?.name}</Badge>}
                  </Text>
                </Box>
                <Box>
                  <Heading size='xs' textTransform='capitalize'>
                    Zone:
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    {details.shop?.zone?.name == null ? (<Text color={'gray.400'}>Not Available</Text>) : <Badge variant='solid' colorScheme='blackAlpha'>  {details.shop?.zone?.name}</Badge>}
                  </Text>
                </Box>
              </HStack>
              <HStack spacing={20}>
                <Box>
                  <Heading pt='2' size='xs' textTransform='capitalize'>
                    Province:
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    {details.region?.province?.name == null ? (<Text color={'gray.400'}>Not Available</Text>) : <Badge variant='solid' colorScheme='gray'> {details.region?.province?.name}</Badge>}
                  </Text>
                </Box>
                <Box>
                  <Heading pt='2' size='xs' textTransform='capitalize'>
                    Region:
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    {details.region?.name == null ? (<Text color={'gray.400'}>Not Available</Text>) : <Badge variant='solid' colorScheme='gray'>{details.region?.name}</Badge>}
                  </Text>
                </Box>
              </HStack>
                
                </>
              )}
             



              {user?.roles[0].name === 'SUPER_ADMIN' && <>
                <Box>

                  {details.employeeAccount?.title === 'BRAND AMBASSADOR' ? (
                    <Popover>
                      <PopoverTrigger>

                        <Button mt={2} leftIcon={<FaEdit />} colorScheme={'purple'}>Update Location</Button>
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent w={'auto'} >
                          <PopoverArrow />
                          <PopoverHeader bg={'yellow.200'} >Update</PopoverHeader>
                          <PopoverCloseButton />
                          <PopoverBody >
                            <FormControl mt={2}>

                              <FormLabel>Key Account Rep</FormLabel>
                              <Select
                                _hover={{
                                  borderRadius: 'orange',
                                }}
                                placeholder='Select keyAcc Rep' name='regionId' onChange={(e) => setKeyAccountRepAccountId(e.target.value)}>
                                {selectkeyaccrep?.length === 0 && <Spinner />}
                                {selectkeyaccrep?.map((rep) => {
                                  if (rep.employeeAccount.title === 'KEY ACCOUNT REP') {
                                    return (
                                      <option key={rep.employeeAccount.id} value={rep.employeeAccount.id}>
                                        {rep.firstName} {rep.lastName}
                                      </option>
                                    )
                                  }
                                })}

                              </Select>
                            </FormControl>

                            <FormControl mt={2}>
                              <FormLabel>Shop</FormLabel>
                              <Select
                                _hover={{
                                  borderRadius: 'orange',
                                }}
                                placeholder='Select Shop' name='regionId' onChange={(e) => setShopId(e.target.value)}>
                                {selectshop?.length === 0 && <Spinner />}
                                {selectshop?.map((shopes) => (
                                  <option key={shopes.id} value={shopes.id}>
                                    {shopes.name}
                                  </option>
                                ))}

                              </Select>
                            </FormControl>
                            <Button onClick={handleBA_Location} mt={2} colorScheme={'teal'}>Submit</Button>

                          </PopoverBody>

                        </PopoverContent>
                      </Portal>
                    </Popover>

                  ) : (
                    <Popover>
                      <PopoverTrigger>

                        <Button mt={2} leftIcon={<FaEdit />} colorScheme={'blue'}>Update Shop or Region</Button>
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent w={'auto'} >
                          <PopoverArrow />
                          <PopoverHeader >Update</PopoverHeader>
                          <PopoverCloseButton />
                          <PopoverBody >
                            {details.employeeAccount?.title === 'KEY ACCOUNT REP' &&
                           
                            <FormControl mt={2}>
                              <FormLabel>Region</FormLabel>
                              <Select
                                _hover={{
                                  borderRadius: 'orange',
                                }}
                                placeholder='Select Region' name='regionId' onChange={(e) => setRegionId(e.target.value)}>
                                {selectregion?.length === 0 && <Spinner />}
                                {selectregion?.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}

                              </Select>
                            </FormControl>
                            }
                             {details.employeeAccount?.title === 'SHOP SUPERVISOR' &&  
                            <FormControl mt={2}>
                              <FormLabel>Shop</FormLabel>
                              <Select
                                _hover={{
                                  borderRadius: 'orange',
                                }}
                                placeholder='Select Shop' name='regionId' onChange={(e) => setShopId(e.target.value)}>
                                {selectshop?.length === 0 && <Spinner />}
                                {selectshop?.map((shopes) => (
                                  <option key={shopes.id} value={shopes.id}>
                                    {shopes.name}
                                  </option>
                                ))}

                              </Select>
                            </FormControl>
                            }
                            {/* FILTER */}
                            {details.employeeAccount?.title === 'CARE DESK PERSONNEL' &&  
                            <FormControl mt={2}>
                              <FormLabel>Shop</FormLabel>
                              <Select
                                _hover={{
                                  borderRadius: 'orange',
                                }}
                                placeholder='Select Shop' name='regionId' onChange={(e) => setShopId(e.target.value)}>
                                {selectshop?.length === 0 && <Spinner />}
                                {selectshop?.map((shopes) => (
                                  <option key={shopes.id} value={shopes.id}>
                                    {shopes.name}
                                  </option>
                                ))}

                              </Select>
                            </FormControl>
}

          {details.employeeAccount?.title === 'GRADUATE TRAINEE' &&  
                            <FormControl mt={2}>
                              <FormLabel>Shop</FormLabel>
                              <Select
                                _hover={{
                                  borderRadius: 'orange',
                                }}
                                placeholder='Select Shop' name='regionId' onChange={(e) => setShopId(e.target.value)}>
                                {selectshop?.length === 0 && <Spinner />}
                                {selectshop?.map((shopes) => (
                                  <option key={shopes.id} value={shopes.id}>
                                    {shopes.name}
                                  </option>
                                ))}

                              </Select>
                            </FormControl>
                              }
                              {details.employeeAccount?.title === 'CALL CENTER' &&  
                            <FormControl mt={2}>
                              <FormLabel>Shop</FormLabel>
                              <Select
                                _hover={{
                                  borderRadius: 'orange',
                                }}
                                placeholder='Select Shop' name='regionId' onChange={(e) => setShopId(e.target.value)}>
                                {selectshop?.length === 0 && <Spinner />}
                                {selectshop?.map((shopes) => (
                                  <option key={shopes.id} value={shopes.id}>
                                    {shopes.name}
                                  </option>
                                ))}

                              </Select>
                            </FormControl>
                              }
                              {details.employeeAccount?.title === 'CASHIER' &&  
                            <FormControl mt={2}>
                              <FormLabel>Shop</FormLabel>
                              <Select
                                _hover={{
                                  borderRadius: 'orange',
                                }}
                                placeholder='Select Shop' name='regionId' onChange={(e) => setShopId(e.target.value)}>
                                {selectshop?.length === 0 && <Spinner />}
                                {selectshop?.map((shopes) => (
                                  <option key={shopes.id} value={shopes.id}>
                                    {shopes.name}
                                  </option>
                                ))}

                              </Select>
                            </FormControl>
                              }
                              {details.employeeAccount?.title === 'CUSTOMER CARE REP' &&  
                            <FormControl mt={2}>
                              <FormLabel>Shop</FormLabel>
                              <Select
                                _hover={{
                                  borderRadius: 'orange',
                                }}
                                placeholder='Select Shop' name='regionId' onChange={(e) => setShopId(e.target.value)}>
                                {selectshop?.length === 0 && <Spinner />}
                                {selectshop?.map((shopes) => (
                                  <option key={shopes.id} value={shopes.id}>
                                    {shopes.name}
                                  </option>
                                ))}

                              </Select>
                            </FormControl>
                            
                              }
                              {details.employeeAccount?.title === 'SUPER ADMIN' &&  
                              <>
                            <FormControl mt={2}>
                              <FormLabel>Shop</FormLabel>
                              <Select
                                _hover={{
                                  borderRadius: 'orange',
                                }}
                                placeholder='Select Shop' name='regionId' onChange={(e) => setShopId(e.target.value)}>
                                {selectshop?.length === 0 && <Spinner />}
                                {selectshop?.map((shopes) => (
                                  <option key={shopes.id} value={shopes.id}>
                                    {shopes.name}
                                  </option>
                                ))}

                              </Select>
                            </FormControl>
                            <FormControl mt={2}>
                              <FormLabel>Region</FormLabel>
                              <Select
                                _hover={{
                                  borderRadius: 'orange',
                                }}
                                placeholder='Select Region' name='regionId' onChange={(e) => setRegionId(e.target.value)}>
                                {selectregion?.length === 0 && <Spinner />}
                                {selectregion?.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}

                              </Select>
                            </FormControl>
                            
                                </>
                            
                              }
                    
                            <Button onClick={handleChangeLocation} mt={2} colorScheme={'teal'}>Submit</Button>

                          </PopoverBody>

                        </PopoverContent>
                      </Portal>
                    </Popover>

                  )}

                </Box>
              </>}


              <Box>
                <Heading size='xs' textTransform='capitalize'>
                  Roles:
                </Heading>
                {details.employeeAccount?.roles === null ? '' : details.employeeAccount?.roles.map((item) => (
                  <Text key={item.id} pt='2' fontSize='sm' fontFamily={'monospace'}>
                    {item.name === 'SUPER_ADMIN' ? (<Badge variant='solid' colorScheme='cyan'>Super Admin</Badge>) : item.name === 'ADMIN' ? (<Button colorScheme={'yellow'} size={'xs'}>ADMIN</Button>) : item.name === 'CLERK' ? (<Button colorScheme={'orange'} size={'xs'}>Clerk</Button>) : item.name === 'SUPPORT' ? (<Button colorScheme={'purple'} size={'xs'}>Support</Button>) : item.name === 'BRAND_AMBASSADOR' ? (<Badge variant={'solid'} colorScheme={'red'} size={'xs'}>BA</Badge>): item.name === 'APPROVER' ? (<Badge variant={'solid'} colorScheme={'yellow'} size={'xs'}>Approver</Badge>): item.name === 'EXECUTIVE' ? (<Badge variant={'solid'} colorScheme={'linkedin'} size={'xs'}>Executive</Badge>) : <Button bg={'gray.500'} size={'xs'}>Employee</Button>}
                  </Text>
                ))}
                <Popover placement='right'>
                  <PopoverTrigger>

                    <Button mt={2} leftIcon={<FaUserTag />} colorScheme={'cyan'}>Change Roles</Button>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent w={'auto'} >
                      <PopoverArrow />
                      <PopoverHeader bg={'yellow.400'}>Change Roles</PopoverHeader>
                      <PopoverCloseButton />
                      <PopoverBody >
                        <FormControl as='fieldset'>
                          <FormLabel as='legend' marginTop='10px' marginBottom={2}>Roles</FormLabel>
                          <RadioGroup onChange={(e) => setSelectedOption(e)} value={selectedOption} >
                            <HStack spacing='24px' >
                              {filteredOptions.map((option) => (
                                <Radio key={option.value} value={option.value} >
                                  {option.label}
                                </Radio>
                              ))}
                            </HStack>
                          </RadioGroup>
                          <FormHelperText>Select User Role</FormHelperText>
                          <Button onClick={handleRoles} mt={2} colorScheme={'blue'}>Update</Button>
                        </FormControl>
                      </PopoverBody>

                    </PopoverContent>
                  </Portal>
                </Popover>
              </Box>
              <Box >
                {details.employeeAccount?.title === 'BRAND AMBASSADOR' ? (
                  <Button onClick={handleResendPassword} leftIcon={<MdSms />} colorScheme={'purple'}>Send Invitation Details</Button>

                ) : (
                  <Button onClick={handleResendlink} leftIcon={<FiMail />} colorScheme={'teal'}>Send Invitation link</Button>

                )}
              </Box>
              <Box>
           
                <Heading size='xs' textTransform='capitalize'>
                  Status:
                </Heading>
                <HStack direction={['column', 'row']} spacing={8}>
                  {details.employeeAccount?.accountVerified === true ? 
                  (

                <Badge textTransform={'capitalize'} bg={'green.400'} color={'white'} rounded={'md'} boxShadow={'sm'} >
                  Verified
                </Badge>
                  ):
                  (
                    <Badge textTransform={'capitalize'} bg={'yellow.400'} color={'white'} rounded={'md'} boxShadow={'sm'} >
                    Not Active
                  </Badge>
                  )
                  }
                <Text pt='2' fontSize='sm'>
                  {details.employeeAccount?.accountVerified === true ? (<Button rightIcon={<MdPersonRemove />} onClick={onOpen} colorScheme='red' size='md'>Deactivate  </Button>) : (<Button rightIcon={<MdVerifiedUser />} onClick={onActiveOpen} variant={'solid'} colorScheme='green' size='xs'>Activate</Button>)}

                </Text>
                </HStack>

{/* alert to user to deactivate */}
<AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Discard Changes</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
           Are you sure you want to deactivate <Badge textTransform={'capitalize'} bg={'white'} fontSize={'md'}> {`${details.firstName} ${details.lastName}`}'s </Badge> account?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button colorScheme={'red'} ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Button 
            onClick={handleDeactivateUser} 
            isLoading={loading}
            loadingText='Loading'
            spinnerPlacement='start'
            colorScheme='green' ml={3}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {/* alert to user to deactivate */}
<AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelActiveRef}
        onClose={onActiveClose}
        isOpen={isActiveOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Discard Changes</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
           Are you sure you want to activate <Badge textTransform={'capitalize'} bg={'white'} fontSize={'md'}> {`${details.firstName} ${details.lastName}`}'s </Badge> account?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button colorScheme={'red'} ref={cancelActiveRef} onClick={onActiveClose}>
              No
            </Button>
            <Button 
            onClick={handleActivateUser} 
            isLoading={loading}
            loadingText='Loading'
            spinnerPlacement='start'
            colorScheme='green' ml={3}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>


           
                
              </Box>
              
              <Box>
                      <Heading size={'xs'} textTransform={'capitalize'}>
                        Created by:
                      </Heading>
                      <Text pt='2' fontSize='sm'>
                        {accountId?.firstName} {accountId?.lastName}
                      </Text>
                      {accountId?.employeeAccount?.roles[0].name === 'SUPER_ADMIN' ? (<Badge variant='solid' colorScheme='cyan'>Super Admin</Badge>) : accountId?.employeeAccount?.roles[0].name === 'ADMIN' ? (<Badge variant={'solid'} colorScheme={'yellow'} size={'xs'}>ADMIN</Badge>) : accountId?.employeeAccount?.roles[0].name === 'CLERK' ? (<Badge variant={'solid'} colorScheme={'orange'} size={'xs'}>Clerk</Badge>) : accountId?.employeeAccount?.roles[0].name === 'SUPPORT' ? (<Badge variant={'solid'} colorScheme={'purple'} size={'xs'}>Support</Badge>) : accountId?.employeeAccount?.roles[0].name === 'OPERATOR' ? (<Badge variant={'solid'} colorScheme={'red'} size={'xs'}>Operator</Badge>) : accountId?.employeeAccount?.roles[0].name === 'EXECUTIVE' ? (<Badge variant={'solid'} colorScheme={'linkedin'} size={'xs'}>Executive</Badge>) : <Badge variant={'solid'}  size={'xs'}>Not Available</Badge>}
                    </Box>
            </Stack>

              </Flex>
            <Stack spacing={6} direction={['column', 'row']}>
              {/* <Button
                bg={'red.400'}
                color={'white'}
                w="full"
                _hover={{
                  bg: 'red.500',
                }}>
                Cancel
              </Button> */}
              <Button
                leftIcon={<MdArrowBack />}
                onClick={() => navigate('/admin/users')}
                bg={'orange.400'}
                color={'white'}
                w="full"
                _hover={{
                  bg: 'blue.500',
                }}>
                Back
              </Button>
            </Stack>
          </Stack>
        </Box>

        <Box
          p={2}
        >
           
          <Stack
            spacing={4}

            minW={'30vw'}
            bg={useColorModeValue('white', 'gray.700')}
            rounded={'xl'}
            boxShadow={'lg'}
            p={6}
            my={12}>
              


              <FormControl>
                    {/* <FormLabel>Search Customers Using</FormLabel>  */}
                    <Select textAlign={'center'}  placeholder='Select customer using' w={'245px'} bg={'white'} ml={14} onChange={(e) => setSelectCustomerSearch(e.target.value)} >
                        {customers.map((option) => (
                          <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </Select>
        </FormControl>
                      
                      
{showOnemoney &&
<>
<Heading lineHeight={1.1} textAlign={'center'} fontSize={{ base: 'xl', sm: 'lg' }}>
              Search OneMoney Using:
            </Heading>
            <HStack spacing={4}>
              <FormControl>
                <FormLabel>Start Date:</FormLabel>
                <Input
                  placeholder="Select Date and Time"
                  size="md"
                  name='startDate'
                  type="date"
                  onChange={handleStartDateInputChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel>End Date:</FormLabel>
                <Input
                  placeholder="Select Date and Time"
                  size="md"
                  name='endDate'
                  type="date"
                  onChange={handleEndDateInputChange}
                />
              </FormControl>
            </HStack>
            <Stack ml={4} spacing={4}>
              <Button onClick={handleAudit} w={'150px'} leftIcon={<FaSearch />} colorScheme='orange' variant='solid'>
                Search
              </Button>
            </Stack>
</>
}
{showZsmart && <>
  <Heading lineHeight={1.1} textAlign={'center'} fontSize={{ base: 'xl', sm: 'lg' }}>
              Search Telco Using:
            </Heading>
            <HStack spacing={4}>
              <FormControl>
                <FormLabel>Start Date:</FormLabel>
                <Input
                  placeholder="Select Date and Time"
                  size="md"
                  name='startDate'
                  type="date"
                  onChange={handleStartDateInputChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel>End Date:</FormLabel>
                <Input
                  placeholder="Select Date and Time"
                  size="md"
                  name='endDate'
                  type="date"
                  onChange={handleEndDateInputChange}
                />
              </FormControl>
            </HStack>
            <Stack ml={4} spacing={4}>
              <Button onClick={handleTelcoAudit} w={'150px'} isLoading={loading} loadingText='please wait' leftIcon={<FaSearch />} colorScheme='orange' variant='solid'>
                Search
              </Button>
            </Stack>

</>
}
           
          </Stack>
          <Stack
            spacing={4}

            minW={'30vw'}
            rounded={'xl'}
            boxShadow={'lg'}
            p={6}
            my={12}>
            <Stack spacing={4}>
              <Button onClick={handleExport} w={'150px'} leftIcon={<FaFileExport />} colorScheme='blue' variant='solid'>
                Export to Excel
              </Button>
            </Stack>
            <Heading size={'sm'}>Customers Registered:
            </Heading>

            <TableContainer maxH={'50vh'} overflowY={'scroll'}  maxW={'45vw'}>

              {searchresults.length > 0 ? (
                <Center>

                  <Table id='users' variant='simple'>
                    <TableCaption>
                      </TableCaption>

                    <Thead>
                      <Tr>
                        <Th>#</Th>
                        <Th>Full Name</Th>
                        <Th>National ID</Th>
                        <Th>Gender</Th>
                        <Th>PhoneNumber</Th>
                      </Tr>
                    </Thead>



                    <Tbody>
                      {[...searchresults].map((data, index) => {
                        return (
                          <Tr key={index}>
                            <Td>{index + 1}</Td>
                            <Td>{data.fullName ? `${data.firstName} ${data.lastName}` : `${data.firstName} ${data.surNames} ` }</Td>
                            <Td>{data.nationalIdNumber ? data.nationalIdNumber : data.documentNbr}</Td>
                            <Td>{data?.gender}</Td>
                            <Td>{data.user?.phoneNumber ? data.user?.phoneNumber : data.msisdn}</Td>

                          </Tr>
                        )
                      })}
                    </Tbody>



                    <Tfoot>
                    </Tfoot>

                  </Table>

                </Center>
              ) : (
                <Table>
                  <TableCaption><Text>No Data to Display</Text></TableCaption>
                  <Tr>
                  </Tr>

                </Table>
              )}
            </TableContainer>


          </Stack>
        </Box>

        <Box
          p={2}

          minW={'-moz-fit-content'}

        >
          <Stack
            spacing={4}
            minW={'17vw'}
            bg={useColorModeValue('white', 'gray.700')}
            rounded={'xl'}
            boxShadow={'lg'}
            p={6}
            my={8}

          >

            <Heading lineHeight={1.1} fontSize={{ base: '1xl', sm: 'xl' }}>
              Statistics
            </Heading>
            <SimpleGrid maxH={'68vh'} p={5} overflowY={'scroll'} columns={{ base: 1, md: 1 }} spacing={{ base: 5, lg: 4 }} >
              <Heading lineHeight={1.1} fontSize={{ base: '1xl', sm: 'xl' }}>
                OneMoney
              </Heading>
              <StatsCard

                title={'Customers Registered Today'}
                stat={stats.daily}
                icon={<FaUserPlus color='blue' size={'2em'} />}
              />

              <StatsCard
                title={'Customers Registered Weekly'}
                stat={stats.weekly}
                icon={<FaUsers color='purple' size={'2em'} />}
              />
              <StatsCard
                title={'Customers Registered Monthly'}
                stat={stats.monthly}
                icon={<FaUsers color='orange' size={'2em'} />}
              />
              <StatsCard
                title={'Customers Registered Annualy'}
                stat={stats.annual}
                icon={<FaUsers color='green' size={'2em'} />}
              />
              <Heading mt={'4'} lineHeight={1.1} fontSize={{ base: '1xl', sm: 'xl' }}>
                Telco
              </Heading>

              <StatsCard

                title={'Customers Registered Today'}
                stat={telco.daily}
                icon={<FaUserPlus color='blue' size={'2em'} />}
              />

              <StatsCard
                title={'Customers Registered Weekly'}
                stat={telco.weekly}
                icon={<FaUsers color='purple' size={'2em'} />}
              />
              <StatsCard
                title={'Customers Registered Monthly'}
                stat={telco.monthly}
                icon={<FaUsers color='orange' size={'2em'} />}
              />
              <StatsCard
                title={'Customers Registered Annualy'}
                stat={telco.annual}
                icon={<FaUsers color='green' size={'2em'} />}
              />


            </SimpleGrid>



          </Stack>
        </Box>


      </HStack>


    </Container>
  );
}

export default ViewUser