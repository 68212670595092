import {
    Button,
    FormControl,
    Flex,
    Heading,
    Input,
    Stack,
    Text,
    useColorModeValue,
    FormLabel,
    InputGroup,
    InputLeftAddon,
    useToast,
    useDisclosure,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
  } from '@chakra-ui/react';
import React, { useState } from 'react';
import { MdEmail } from 'react-icons/md';
import { useNavigate } from 'react-router';
import axios from '../../api/axios';
const FORGETPASS_URL ='employee/auth/v1/send-email-verification-link'
  
 
  
  export default function ForgotPassword() {
    const toast = useToast();
    const [workEmail, setWorkEmail] = useState('');
    const[workEmailError, setWorkEmailError] = useState('')
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const navigate = useNavigate();

    const {isOpen, onOpen, onClose} = useDisclosure()
    const cancelRef = React.useRef();

    const handlesubmit = () => {
        setErrorMessage('')
        const res = validity();
        if (!res) {
            toast({
                title: 'Failed',
                description: "Please enter Phone number",
                status: 'error',
                duration: 3000,
        
              });
              return;
            }
        setLoading(true)
        axios.post(FORGETPASS_URL,{
            workEmail,
        },{
            headers: {"Content-type" : "application/json"}
          }
          
          )
          .then(response => {
            console.log(response)
            if (response.data.success) {
              navigate(`/email-success/${workEmail}`)
              setLoading(false)
              toast({
                title: 'Success.',
                description: `Check your ${workEmail} for link to set new password.`,
                status: 'success',
                duration: 6000,
    
              });
            }
            else {
              setLoading(false)
              toast({
                title: 'Failed',
                description: response.data.message,
                status: 'error',
                duration: 6000,
    
              });
            }
          })
          .catch(error => {
            // setSuccess(true);
            setLoading(false)
            console.log(error);
            if (error.response) {
              //  setErrMsg(error.response.data.message)
              toast({
                title: "Failed To Log In",
                description: error.response.data.message,
                status: "error",
              });
    
            } else if (error.request) {
              // setErrMsg("Network problem")
              toast({
                title: "Failed To Log In",
                description: "Failed",
                status: "error",
              });
    
            } else {
              toast({
                title: "Failed ",
                description: "Something went wrong",
                status: "error",
              });
    
            }
    
          })
        
    }
    const validity = () => {
        if (!workEmail) {
            setWorkEmailError('email required');
            return false;
          }
          return true
    }


    return (
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}>
        <Stack
          spacing={4}
          w={'full'}
          maxW={'md'}
          bg={useColorModeValue('white', 'gray.700')}
          rounded={'xl'}
          boxShadow={'lg'}
          p={6}
          my={12}>
          <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
            Forgot your password?
          </Heading>
          <Text
            fontSize={{ base: 'sm', sm: 'md' }}
            color={useColorModeValue('gray.800', 'gray.400')}>
            You&apos;ll receive link to set your new password
          </Text>
          <FormControl isRequired>
              {/* <FormLabel htmlFor='workEmail'>Mobile Number</FormLabel> */}
              <InputGroup>
                <InputLeftAddon children ={ <MdEmail/> }/>
                <Input id='workEmail'
                  type='email'
                  placeholder='name@netone.co.zw'
                  _placeholder={{ color: 'gray.500' }}
                  name='workEmail'
                  value={workEmail}
                 onFocus={() => { setWorkEmailError('') }} borderColor={workEmailError ? 'red.400' : 'gray.300'}
                onChange={(e) => setWorkEmail(e.target.value)}

                />
              </InputGroup>
              <Text color={'red'}>{workEmailError}</Text>
            </FormControl>
            {
              errorMessage ? <Text color={'red'}>{errorMessage}</Text> : <></>
            }
          <Stack spacing={6}>
            <Button
            onClick={onOpen}
              bg={'orange.400'}
              color={'white'}
              _hover={{
                bg: 'orange.600',
              }}>
              Submit
            </Button>
            <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Discard Changes?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
           Are you sure you have entered the correct number? : {workEmail}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button colorScheme={'red'} ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Button 
            onClick={handlesubmit} 
            isLoading={loading}
            loadingText='Loading'
            spinnerPlacement='start'
            colorScheme='green' ml={3}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
          </Stack>
        </Stack>
      </Flex>
    );
  }