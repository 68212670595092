import React, { useState, useEffect } from 'react'
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Stack,
    Box,
    chakra,
    Wrap,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
    Flex,
    Text,
    Center,
    useDisclosure,
    Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Textarea,
  Select,
  Spinner,
  } from '@chakra-ui/react'
  import { FiUserPlus } from 'react-icons/fi'
  import { BsBank, BsChevronRight, BsPerson } from 'react-icons/bs';
  import { NavLink, useNavigate } from 'react-router-dom'
  import axios from '../../../api/axios';

  const CREATEREGION_URL = '/employee/region/v1/create-region';
  const GETPROVINCE_URL = '/employee/province/v1/get-provinces';
  const GETREGION_URL = '/employee/region/v1/get-regions';


function Region() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const initialRef = React.useRef(null);

    const [name, setName] = useState('');
    const [provinceId, setProvinceId] = useState('')
    const [selectprovince, setSelectProvince] = useState([])

    const toast = useToast();
    const navigate = useNavigate();

    const [ loading, setLoading] = useState(false);
    const [nameError, setNameError] = useState('')
    const [addressError, setAddressError] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

      useEffect(() => {
        const token = localStorage.getItem("token");
        axios.get(GETPROVINCE_URL, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).then((response) => {
          setSelectProvince(response.data.body);
    
        });
      }, []);

      const handlesubmit = () => {
        setErrorMessage('')
    const res = validate();
        if (!res) {
      toast({
        title: 'Failed',
        description: "Form not complete",
        status: 'error',
        duration: 3000,

      });
      return;
    }
        setLoading(true)
        const token = localStorage.getItem("token");
        axios.post(CREATEREGION_URL, {
          name,
          provinceId
          
        },{
          headers: { 
            "Content-type": "application/json",
            'Authorization': `Bearer ${token}`
          }
          
        })
        .then(response => {
            if (response.data.success) {
                navigate('/admin/system-config')
                getAllRegions();
                onClose();
                toast({
                  title: 'Success',
                  description: response.data.message,
                  status: 'success',
                  duration: 6000,
                  
                });
                setLoading(false)
              }
            
            else {
              setLoading(false)
              toast({
                title: 'Failed',
                description: response.message,
                status: 'error',
                duration: 3000,
               
              });
            }
        })
        .catch(error => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              toast({
                title: "Error",
                description: `Server responded with ${error.response.status}`,
                status: "error",
                duration: 5000,
                isClosable: true,
              });
              setLoading(false)
            } else if (error.request) {
              // The request was made but no response was received
              toast({
                title: "Network Error",
                description: "No response from server",
                status: "error",
                duration: 8000,
                isClosable: true,
              });
              setLoading(false)
            } else {
              // Something happened in setting up the request that triggered an Error
              toast({
                title: "Network Error",
                description: `Error: ${error.message}`,
                status: "error",
                duration: 8000,
                isClosable: true,
              });
             
            }
          })
    }
    useEffect(() => {

    }, [loading])

    const validate =() => {
        if (!name) {
            setNameError('Enter Name')
            return false;
          }
          return true;
    }


      const [region, setRegion] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(75);
    const [totalPages, setTotalPages] = useState(1);

    const getAllRegions = async () => {
        setLoading(true);
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`${GETREGION_URL}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
  
        if(response.status === 200) {
          setRegion(response.data.body)
          setLoading(false);
        }
      } catch (error) {

      }
    }
  

    useEffect(() => {
        getAllRegions();
      
      }, [])
      
  
      useEffect(() => {
        setTotalPages(Math.ceil(region.length / itemsPerPage));
      }, [region]);
      
      const paginateData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return region.slice(startIndex, endIndex);
      };
      
      const handlePageChange = (page) => {
        setCurrentPage(page);
      }
      
      const paginatedData = paginateData(region, currentPage, itemsPerPage);


 


  return (
    <Box maxW="4xl" >
    {/* <chakra.h1
     textAlign={'left'}
     fontSize={'1xl'}
     py={5}
     fontWeight={'bold'}>
Bank List
   </chakra.h1> */}
   <Stack direction='row' spacing={4}>
<Button onClick={onOpen}  leftIcon={<BsBank/>} colorScheme='orange' variant='solid'>
 Add Region
</Button>
<Modal
     initialFocusRef={initialRef}
     isOpen={isOpen}
     onClose={onClose}
   >
     <ModalOverlay />
     <ModalContent mt={2}>
       <ModalHeader>Create Region:</ModalHeader>
       <ModalCloseButton />
       <ModalBody pb={6}>
       

         <FormControl mt={2}>
           <FormLabel>Region name:</FormLabel>
           <Input type='text'  placeholder='Region name' onChange={(e) => setName(e.target.value)}  onFocus={()=> {setNameError('')}} borderColor={nameError ? 'red.400' : 'gray.300'} />
           <Text color={'red'}>{nameError}</Text>
         </FormControl>
       
       <FormControl mt={2}>
       <FormLabel>Province</FormLabel>
            <Select 
                   _hover={{
                     borderRadius: 'orange',
                   }} 
                   placeholder='Select Province' name='currencyId' onChange={(e)=> setProvinceId(e.target.value)}>
              {selectprovince.length === 0 && <Spinner />}
           {selectprovince.map((province) => (
             <option key={province.id} value={province.id}>
               {province.name}
             </option>
           ))} 
           </Select>
       </FormControl>
                     
               
         {
           errorMessage ? <Text color={'red'}>{errorMessage}</Text> : <></>
         }
       </ModalBody>

       <ModalFooter>
         <Button 
        onClick={handlesubmit} 
        isLoading={loading}
        loadingText='Loading'
        spinnerPlacement='start'
        colorScheme='orange' mr={3}>
          Save
        </Button>
         <Button onClick={onClose}>Cancel</Button>
       </ModalFooter>
     </ModalContent>
   </Modal>
</Stack>
<Stack p="3" bg='white' boxShadow="lg" m="4" borderRadius="sm" width={'1100px'}>
    
    <TableContainer>
  <Center>

  <Table variant='striped' size={'md'} >
    <TableCaption> 
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
    
    <Button
    colorScheme={'orange'}
    m={1}
    key={page}
    onClick={() => handlePageChange(page)}
    color={page === currentPage ? 'black' : 'white'}
    cursor="pointer"

    >
            {page}
          </Button>
        ))}
        </TableCaption>
    <Thead>
      <Tr>
        <Th>#</Th>
        <Th>Name</Th>
        <Th>Province</Th>
        <Th>Action</Th>
      </Tr>
    </Thead>
    <Tbody>
    {paginatedData.map((data,index) =>{
      return(
      <Tr key={index}>
      <Td>{index +1}</Td>
      <Td>{data.name == null ? '': data.name}</Td>
      <Td>{data.province.name == null ? '': data.province.name}</Td>
      <Td>
        <Wrap spacing={2} direction='row' align='center'>
          {/* <Button  colorScheme='blue' size='xs'>View</Button> */}
          {/* <Button onClick={onOpen}  colorScheme='red' size='xs'>Delete</Button> */}
          <Button   colorScheme='yellow' size='xs'>Edit</Button>
          {/* <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
              >
              <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Delete Bundle</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
           Are you sure you want proceed?
          </AlertDialogBody>
          <AlertDialogFooter>
          <Button colorScheme={'gray'} ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Button 
          onClick={() => handleDelete(data.id)}
            isLoading={loading}
            loadingText='Loading'
            spinnerPlacement='start'
            colorScheme='red' ml={3}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
                  </AlertDialog> */}
        </Wrap>
      </Td>
    </Tr>
    )
  })}
    </Tbody>
    

    <Tfoot>
  
    </Tfoot>
    
  </Table>
  </Center>
  <Flex justifyContent={'flex-end'} mr={10} >

 
        </Flex>
</TableContainer>

    </Stack>
    </Box>
  )
}

export default Region