import React, { useState, useEffect } from 'react'
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Stack,
    Box,
    chakra,
    Wrap,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
    Flex,
    Text,
    Center,
    useDisclosure,
    Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  Select,
  useToast,
  } from '@chakra-ui/react'
  import {RiCurrencyFill} from 'react-icons/ri';
  import { NavLink, useNavigate } from 'react-router-dom'
  import axios from '../../api/axios'

  const ADDCURRENCY_URL = 'mobile-api/config/currency'
  const GETCURRENCY_URL = 'mobile-api/config/currencies'
  const DELETECURRENCY_URL = 'mobile-api/config/currency/'

function Currency() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const initialRef = React.useRef(null);
    const toast = useToast();
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [shortDesc, setShortDesc] = useState('')
    const [active, setActive] = useState('');
    const [ loading, setLoading] = useState(false);
    const [nameError, setNameError] = useState('')
    const [descerror, setDescError] = useState('')
    const [activeerror, setActiveError] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

const handlesubmit = () => {
        setErrorMessage('')
    const res = validate();
        if (!res) {
     
      toast({
        title: 'Failed',
        description: "Form not complete",
        status: 'error',
        duration: 3000,

      });
      return;
    }
        setLoading(true)
        const token = localStorage.getItem("token");
        axios.post(ADDCURRENCY_URL, {
          name,
          shortDesc,
          active: true
          
        },{
          headers: { 
            "Content-type": "application/json",
            'Authorization': `Bearer ${token}`
          }
          
        })
        .then(response => {
            if (response.data.success) {
                navigate('/admin/config')
                getCurrencies();
                onClose();
                setLoading(false)
                toast({
                  title: 'Success',
                  description: response.data.message,
                  status: 'success',
                  duration: 6000,
                  
                });
              }
            
            else {
              setLoading(false)
              toast({
                title: 'Failed',
                description: response.message,
                status: 'error',
                duration: 3000,
               
              });
            }
        })
        .catch(error => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              toast({
                title: "Error",
                description: `Server responded with ${error.response.status}`,
                status: "error",
                duration: 5000,
                isClosable: true,
              });
              setLoading(false)
            } else if (error.request) {
              // The request was made but no response was received
              toast({
                title: "Network Error",
                description: "No response from server",
                status: "error",
                duration: 8000,
                isClosable: true,
              });
              setLoading(false)
            } else {
              // Something happened in setting up the request that triggered an Error
              toast({
                title: "Network Error",
                description: `Error: ${error.message}`,
                status: "error",
                duration: 8000,
                isClosable: true,
              });
             
            }
          })
    }
    useEffect(() => {

    }, [loading])
    
    const validate =() => {
        if (!name) {
            setNameError('Enter currency name')
            return false;
          }
          if (!shortDesc) {
            setDescError('Enter Description')
            return false;
          }
          if (!active) {
            setActiveError('Select Status')
            return false;
            
          }
          return true;
    }
// get all currency 
    const [currency, setCurrency] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [totalPages, setTotalPages] = useState(1);

    const getCurrencies = async () => {
      setLoading(true);
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`${GETCURRENCY_URL}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
  
        if(response.status === 200) {
          setCurrency(response.data.body)
          setLoading(false);
        }
      } catch (error) {
 
      }
    }

    useEffect(() => {
       
        getCurrencies();
      
      }, [])

      const handleDelete = async (id) => {
        setLoading(true)
        const token = localStorage.getItem("token");
        try {
          
          const response = await axios.delete(DELETECURRENCY_URL + `${id}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          if(response.data.success) {
            getCurrencies()
            toast({
              title: 'Success',
              description: `Deleted currency`,
              status: 'success',
              duration: 6000,
              
            });
            setLoading(false)
          
          }else{
            toast({
              title: 'Failed',
              description: "Failed to Delete",
              status: 'error',
              duration: 6000,
              
            });
            setLoading(false)
          }
        } catch (error) {
          
        }
      };
      
        
      useEffect(() => {
        setTotalPages(Math.ceil(currency.length / itemsPerPage));
      }, [currency]);
      
      const paginateData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return currency.slice(startIndex, endIndex);
      };
      
      const handlePageChange = (page) => {
        setCurrentPage(page);
      }
      
      const paginatedData = paginateData(currency, currentPage, itemsPerPage);

  return (
    <Box maxW="4xl" >
       {/* <chakra.h1
        textAlign={'left'}
        fontSize={'1xl'}
        py={5}
        fontWeight={'bold'}>
   Bank List
      </chakra.h1> */}
      <Stack direction='row' spacing={4}>
  <Button onClick={onOpen}  leftIcon={<RiCurrencyFill/>} colorScheme='orange' variant='solid'>
    Add Currency
  </Button>
  <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Currency</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Currency name:</FormLabel>
              <Input type='text'  placeholder='name' onChange={(e) => setName(e.target.value)}  onFocus={()=> {setNameError('')}} borderColor={nameError ? 'red.400' : 'gray.300'} />
              <Text color={'red'}>{nameError}</Text>
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Short description:</FormLabel>
              <Input 
              type= 'text' 
              placeholder='Description' 
              onChange={(e) => setShortDesc(e.target.value)} 
              onFocus={()=> {setDescError('')}} borderColor={descerror ? 'red.400' : 'gray.300'} />
              <Text color={'red'}>{descerror}</Text>
            </FormControl>
            <FormControl mt={4}>
                <FormLabel>Status</FormLabel>
            <Select placeholder='Select option' onChange={(e)=>setActive(e.target.value)} onFocus={()=> {setActiveError('')}} borderColor={activeerror ? 'red.400' : 'gray.300'}>
            <option value='true' >Active</option>
            <option value='false'>Not Active</option>
            </Select>
            <Text color={'red'}>{activeerror}</Text>
            </FormControl>
            {
              errorMessage ? <Text color={'red'}>{errorMessage}</Text> : <></>
            }
          </ModalBody>

          <ModalFooter>
            <Button 
            onClick={handlesubmit} 
            isLoading={loading}
            loadingText='Loading'
            spinnerPlacement='start'
            colorScheme='orange' mr={3}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  </Stack>
    <Stack p="3" bg='white' boxShadow="lg" m="4" borderRadius="sm" width={'1100px'}>
    <TableContainer>
  <Center>

  <Table variant='simple' >
    <TableCaption> 
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
    
    <Button
    colorScheme={'orange'}
    m={1}
    key={page}
    onClick={() => handlePageChange(page)}
    color={page === currentPage ? 'black' : 'white'}
    cursor="pointer"

    >
            {page}
          </Button>
        ))}
        </TableCaption>
    <Thead>
      <Tr>
        <Th>#</Th>
        <Th>Currency Name</Th>
        <Th>Description</Th>
        {/* <Th>Status</Th> */}
        <Th>Action</Th>
      </Tr>
    </Thead>
    <Tbody>
    {paginatedData.map((data,index) =>{
      return(
      <Tr key={index}>
      <Td>{index +1}</Td>
      <Td>{data.name == null ? '': data.name}</Td>
      <Td>{data.shortDesc == null ? '': data.shortDesc }</Td>
      {/* <Td>{data.active == null ? (<Text fontSize={'3xs'}>No text</Text>) : data.active }</Td> */}
      <Td>
        <Wrap spacing={2} direction='row' align='center'>
        <Button onClick={() => handleDelete(data.id)}  colorScheme='red' size='xs'>Remove</Button>
        </Wrap>
      </Td>
    </Tr>
    )
  })}
    </Tbody>
    

    <Tfoot>
  
    </Tfoot>
    
  </Table>
  </Center>
  <Flex justifyContent={'flex-end'} mr={10} >

 
        </Flex>
</TableContainer>

    </Stack>
    </Box>
  )
}

export default Currency