import React, { useState, useEffect } from 'react'
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Center,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Box,
    Stack,
    Input,
    FormControl,
    FormLabel,
    useToast,
    Select,
    HStack,
    Spinner,
    Button,
    chakra,
    AvatarGroup,
    Tooltip,
    Avatar,
    Text,
    Wrap,
    
  } from '@chakra-ui/react'
  import { BsBank, BsChevronRight, BsPerson } from 'react-icons/bs';
  import { NavLink, useNavigate } from 'react-router-dom'
import axios from '../../api/axios';
import { GrGroup } from 'react-icons/gr';
import Multiselect from 'multiselect-react-dropdown';

const CREATE_DEPARTMENT_URL = '/employee/department/v1/create-department'
const GETUSER_URL = '/employee/profile/v1/get-employees?availability=PRESENT'

const GETDEPARTS_URL = '/employee/department/v1/get-departments'

function Department() {

  
      const { isOpen, onOpen, onClose } = useDisclosure();
      const initialRef = React.useRef(null);

      const [loading, setLoading] = useState("")
      const toast = useToast();
      const navigate = useNavigate();
      const [name, setName] = useState("")
      const [members, setMembers] = useState([]);
      const [selectedMember, setSelectedMember] = useState([]);
      const [selectedHead, setSelectedHead] = useState('');
      const [teamMembersTemp, setTeamMembersTemp] = useState([])
      const [departments, setDepartments] = useState([])
  
    //   const handleInputChange = (event) => {
    //     setDepartment({
    //       ...department,
    //       [event.target.name]: event.target.value,
    //     });
    //   };
    //   const [user, setUsers] = useState([]);
    const handleMemberSelect =(selectedList, selectedItem) => {
        const workPhoneNumber = (selectedItem.split("-")[selectedItem.split("-").length -1]).trim();
        const member = members.filter(member => member.employeeAccount.workPhoneNumber == workPhoneNumber)[0];
        setTeamMembersTemp([...teamMembersTemp, member])
        
      };
    
      const handleTeamHeadSelect = (event) => {
        setSelectedHead(event.target.value);
      };
     

      const handleDepartment = (event) => {
        setName(event.target.value);
      };
    
      const getAllMembers = async () => {
        setLoading(true);
        const token = localStorage.getItem("token");
        try {
          const response = await axios.get(`${GETUSER_URL}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
      
          if(response.status === 200) {
            setMembers(response.data.body)
            // console.log(response.data.body)

           
            setLoading(false);
          }
        } catch (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            toast({
              title: "Error",
              description: `Server responded with ${error.message}`,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
           
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              title: "Network Error",
              description: "Please Check your Internet Connection",
              status: "error",
              duration: 8000,
              isClosable: true,
            
            });
         
          } else {
            // Something happened in setting up the request that triggered an Error
            toast({
              title: "Error",
              description: `Error:`,
              status: "error",
              duration: 8000,
              isClosable: true,
            });
           
          }
        }
      }
      
      useEffect(() => {
      
        getAllMembers();
      
      }, [])

      const getAllDeparts = async () => {
        setLoading(true);
        const token = localStorage.getItem("token");
        try {
          const response = await axios.get(`${GETDEPARTS_URL}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
      
          if(response.status === 200) {
            setDepartments(response.data.body)
            // console.log(response.data.body)

           
            setLoading(false);
          }
        } catch (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            toast({
              title: "Error",
              description: `Server responded with ${error.message}`,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
           
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              title: "Network Error",
              description: "Please Check your Internet Connection",
              status: "error",
              duration: 8000,
              isClosable: true,
            
            });
         
          } else {
            // Something happened in setting up the request that triggered an Error
            toast({
              title: "Error",
              description: `Error:`,
              status: "error",
              duration: 8000,
              isClosable: true,
            });
           
          }
        }
      }

      useEffect(() => {
      
        getAllDeparts();
      
      }, [])
      const [options, setOptions] = useState(members)

     
      const handlesubmit = () => {
    //     setErrorMessage('')
    // const res = validate();
    //     if (!res) {
    //   toast({
    //     title: 'Failed',
    //     description: "Form not complete",
    //     status: 'error',
    //     duration: 3000,

    //   });
    //   return;
    // }
        setLoading(true)
        const selectedList  = teamMembersTemp
        const memberId = selectedList.map(members=> members.employeeAccount.id)
        console.log(memberId)
        const token = localStorage.getItem("token");
        console.log(selectedList)
        // axios.post(CREATE_DEPARTMENT_URL, {
        //     name: name,
        //     head: selectedHead,
        //     members: memberId
          
        // },{
        //   headers: { 
        //     "Content-type": "application/json",
        //     'Authorization': `Bearer ${token}`
        //   }
          
        // })
        // .then(response => {
        //     if (response.data.success) {
        //         // navigate('/admin/config')
        //         onClose();
        //         toast({
        //           title: 'Success',
        //           description: response.data.message,
        //           status: 'success',
        //           duration: 6000,
                  
        //         });
        //         setLoading(false)
        //       }
            
        //     else {
        //       setLoading(false)
        //       toast({
        //         title: 'Failed',
        //         description: response.message,
        //         status: 'error',
        //         duration: 3000,
               
        //       });
        //     }
        // })
        // .catch(error => {
        //     if (error.response) {
        //       // The request was made and the server responded with a status code
        //       // that falls out of the range of 2xx
        //       toast({
        //         title: "Error",
        //         description: `Server responded with ${error.response.status}`,
        //         status: "error",
        //         duration: 5000,
        //         isClosable: true,
        //       });
        //       setLoading(false)
        //     } else if (error.request) {
        //       // The request was made but no response was received
        //       toast({
        //         title: "Network Error",
        //         description: "No response from server",
        //         status: "error",
        //         duration: 8000,
        //         isClosable: true,
        //       });
        //       setLoading(false)
        //     } else {
        //       // Something happened in setting up the request that triggered an Error
        //       toast({
        //         title: "Network Error",
        //         description: `Error: ${error.message}`,
        //         status: "error",
        //         duration: 8000,
        //         isClosable: true,
        //       });
             
        //     }
        //   })
    }
    useEffect(() => {

    }, [loading])

  return (
   <Box maxW={'4xl'}>
    <chakra.h1
        textAlign={'left'}
        fontSize={'1xl'}
        py={5}
        fontWeight={'bold'}>
        All Departments
      </chakra.h1>
  <Stack direction='row' ml={4} spacing={4}>
  <Button onClick={onOpen}  leftIcon={<GrGroup/>} colorScheme='orange' variant='solid'>
    Create Department
  </Button>
  <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent mt={2}>
          <ModalHeader>Create Department:</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
          

            <FormControl mt={2}>
              <FormLabel>Department name:</FormLabel>
              <Input type='text' value={name} name='name' placeholder='Department Name' onChange={handleDepartment}  
            //   onFocus={()=> {setNameError('')}} borderColor={nameError ? 'red.400' : 'gray.300'} 
              />
              {/* <Text color={'red'}>{nameError}</Text> */}
            </FormControl>

            <FormControl mt={2}>
            <FormLabel>Members</FormLabel>
            {/* <Select 
                      _hover={{
                        borderRadius: 'orange',
                      }} 
                      placeholder='Select members' value={selectedMember} onChange={handleMemberSelect}>
              {members.length === 0 && <Spinner />}
              {members.map((option) => (
                <option key={option.employeeAccount.id} value={option.employeeAccount.id}>
                  {option.firstName} {option.lastName}
                </option>
              ))}
                
              </Select> */}
              <Multiselect
              placeholder='Select Members'
              isObject={false}
            options = {Object.values(members).map(member => `${member.firstName} ${member.lastName} - ${member.employeeAccount.workPhoneNumber}`)}
              selectedValues={selectedMember}
              onSelect={handleMemberSelect}
              showCheckbox
              />
              </FormControl>
              <FormControl mt={2}>
            <FormLabel>Head</FormLabel>
            <Select 
                      _hover={{
                        borderRadius: 'orange',
                      }} 
                      placeholder='Assign Head' value={selectedHead} onChange={handleTeamHeadSelect}>
              {members.length === 0 && <Spinner />}
              {members.map((option) => (
                <option key={option.id} value={option.employeeAccount.id}>
                  {option.firstName} {option.lastName}
                </option>
              ))}
                
              </Select>
              </FormControl>
          
                        
                  
            {/* {
              errorMessage ? <Text color={'red'}>{errorMessage}</Text> : <></>
            } */}
          </ModalBody>

          <ModalFooter>
            <Button 
         onClick={handlesubmit} 
           isLoading={loading}
           loadingText='Loading'
           spinnerPlacement='start'
           colorScheme='orange' mr={3}>
             Save
           </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  </Stack>
 
  <Stack p="4" bg='white' boxShadow="lg" m="4" borderRadius="sm" width={'1100px'}>
  <Center>

<Table variant='simple' >
<TableCaption>All Departments</TableCaption>
  <Thead>
    <Tr>
      <Th>#</Th>
      <Th>Department</Th>
      <Th>Members</Th>
      <Th>Action</Th>
    </Tr>
  </Thead>
  <Tbody>
   {departments.map((data,index) =>{
      return(
      <Tr key={index}>
      <Td>{index +1}</Td>
      <Td><Text fontWeight={'semibold'}>{data.name == null ? '': data.name}</Text></Td>
      <Td>
      <AvatarGroup size="md" max={3}>
       {
            members.length ? members.map(member => {return (<Tooltip placement="top-start" hasArrow label= {`${member.firstName} ${member.lastName}`}><Avatar _hover={{cursor: "pointer"}} key={member.id} bg="orange.400" name={`${member.firstName} ${member.lastName}`}/></Tooltip>)}) : <Text> No Team Members Added </Text>
          }
       </AvatarGroup>
        
        </Td>
      
      <Td>
        <Wrap spacing={2} direction='row' align='center'>
          <Button  colorScheme='blue' size='xs'>View</Button>
          <Button   colorScheme='yellow' size='xs'>Edit</Button>
          <Button  colorScheme='red' size='xs'>Delete</Button>
         
        </Wrap>
      </Td>
    </Tr>
    )
  })}
  </Tbody>
  

  <Tfoot>

  </Tfoot>
  
</Table>
</Center>
  </Stack>
   </Box>
  )
}

export default Department