
import React from 'react'; 
// import { TabView, TabPanel } from 'primereact/tabview';
import { FiUserPlus, FiUserX, FiUsers } from 'react-icons/fi';

import { 
    Box, 
    Tab, 
    TabList,
    TabPanels, 
    TabPanel,
    Tabs, 
    useColorModeValue,
    chakra, 
    TabIndicator
} from '@chakra-ui/react';
import { FaGrinSquintTears, FaUserAlt, FaUsers } from 'react-icons/fa';
import { TbUserCircle, TbUserSearch, TbUsers } from 'react-icons/tb';

import ManageBA_Approval from '../ManageBA_Approval';
import DeclinedCustomer from '../DeclinedCustomers';
import ManageAppApprovals from '../ManageAppApprovals';
import ManageDeclinedCustomer from '../ManageDeclinedCustomers';

 function Config() {
    return (
        <>
        
        <Box
         maxW="full" 
         mx={'2'}
         px={{ base: 2, md: 4 }}
         py={'5'} 
         shadow={'xl'}
         border={'1px solid'}
         bgColor={useColorModeValue('gray.50')}
         borderColor={useColorModeValue('gray.50', 'gray.50')}
         rounded={'lg'}>
        

<Tabs variant='solid-rounded' colorScheme='blackAlpha' >
  <TabList mb='1em'>
    <Tab whiteSpace={'pre'} fontWeight={'bold'} ><TbUsers color='orange' size={'1.3em'} /> Pending Customers </Tab>
    <Tab whiteSpace={'pre'} fontWeight={'bold'} ><TbUsers color='red' size={'1.5em'} /> Declined Customers</Tab>
  </TabList>

  <TabPanels>
    <TabPanel ml={2}>
      {/* <ManageBA_Approval /> */}
      <ManageAppApprovals />
    </TabPanel>
    <TabPanel>
    {/* <DeclinedCustomer /> */}
    <ManageDeclinedCustomer />
    </TabPanel>
  </TabPanels>
</Tabs>
        </Box>
        </>
    )
}
export default Config