import React from 'react'
import { Tabs,
     TabList, 
     TabPanels, 
     Tab, 
     TabPanel, 
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
    Flex,
    chakra,

    } from '@chakra-ui/react'
    import { BsChevronRight, BsPerson } from 'react-icons/bs';
import { NavLink, useNavigate } from 'react-router-dom'
import Banks from './Banks';
import Currency from './Currency';
import TransLimit from './TransLimit';
import Bundles from './Bundles/Bundles';
import BundleType from './BundleType';
import Merchant from './Merchant';
import Biller from './Biller';
import AppFeature from './AppFeature';

function Config() {
  return (
    <Box maxW="7xl" mx={'auto'} pt={1} px={{ base: 2, sm: 12, md: 17 }}>
        <Flex justifyContent="flex-end" mb={2}>
       <Breadcrumb fontSize={'xs'} fontWeight={'semibold'}  spacing='8px' separator={<BsChevronRight color='gray.500' />}>
       <BreadcrumbItem>
       <NavLink to={'/admin/operator'}>
    <BreadcrumbLink >  Home</BreadcrumbLink>
  </NavLink>
  </BreadcrumbItem>
<BreadcrumbItem isCurrentPage>
  <BreadcrumbLink href='#'>Mobile Config</BreadcrumbLink>
</BreadcrumbItem>
</Breadcrumb>
</Flex>
<chakra.h1
        textAlign={'left'}
        fontSize={'2xl'}
        py={2}
        fontWeight={'bold'}>
    Mobile Configurations
      </chakra.h1>
<Box bg={'white'}  pt={1} px={{ base: 2, sm: 8, md: 10 }}>


    <Tabs isLazy>
    <TabList  mb='1em'>
      <Tab>Banks</Tab>
        <Tab>Transaction limits</Tab>
      <Tab>Currency</Tab>
        <Tab>Bundles</Tab>
        <Tab>Bundle Type</Tab>
        <Tab>Billers</Tab>
        <Tab>App Features</Tab>
    </TabList>
    <TabPanels>
      {/* initially mounted */}
      <TabPanel>
        <Banks/>
      </TabPanel>
      {/* initially not mounted */}
      <TabPanel>
       <TransLimit/>
      </TabPanel>
      <TabPanel>
        <Currency/>
      </TabPanel>
      <TabPanel>
      <Bundles/>
      </TabPanel>
      <TabPanel>
    <BundleType/>
      </TabPanel>
      <TabPanel>
    <Biller />
      </TabPanel>
      <TabPanel>
    <AppFeature />
      </TabPanel>
    </TabPanels>
  </Tabs>
        </Box>
  </Box>
  )
}

export default Config