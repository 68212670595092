import React from 'react'
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Flex,
  chakra,
  TabIndicator,

} from '@chakra-ui/react'
import { BsChevronRight, BsPerson } from 'react-icons/bs';
import { NavLink, useNavigate } from 'react-router-dom'
import ManageUser from '../ManageUser'
import External from '../External';
import UserAvailable from './UserStatus.js/UserAvailable';
import ExternalNew from '../ExternalNew';

function UserConfig() {
  let user = localStorage.getItem('user')
  user = JSON.parse(user);
  console.log(user)

  return (
    <Box maxW="full" mx={'2'} pt={1} px={{ base: 2, sm: 12, md: 17 }}>
      <Flex justifyContent="flex-end" mb={2}>
        <Breadcrumb fontSize={'xs'} fontWeight={'semibold'} spacing='8px' separator={<BsChevronRight color='gray.500' />}>
          <BreadcrumbItem>
            <NavLink to={'/admin/operator'}>
              <BreadcrumbLink >  Home</BreadcrumbLink>
            </NavLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href='#'>Users</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>
      {/* <chakra.h1
        textAlign={'left'}
        fontSize={'2xl'}
        py={2}
        fontWeight={'bold'}>
        Manage Users
      </chakra.h1> */}
      <Box bg={'white'} pt={1} px={{ base: 2, sm: 8, md: 10 }}>

        {user?.title === "KEY ACCOUNT REP" || user?.title === "CareDesk Personnel" ? (
          <>
            <Tabs position="relative" variant="unstyled">
              <TabList>
                <Tab>External Users</Tab>
              </TabList>
              <TabIndicator
                mt="-1.5px"
                height="2px"
                bg="blue.500"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel>
                  <External />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        ) : (
          <>
            <Tabs position="relative" variant="unstyled">
              <TabList>
                <Tab>Internal Users</Tab>
                <Tab>External Users</Tab>
              </TabList>
              <TabIndicator
                mt="-1.5px"
                height="2px"
                bg="blue.500"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel>
                  <UserAvailable/>
                </TabPanel>
                <TabPanel>
                  {/* <External /> */}
                  <ExternalNew/>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        )}


      </Box>
    </Box>
  )
}

export default UserConfig