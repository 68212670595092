import React, { useState, useEffect } from 'react'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Container,
  Box,
  RadioGroup,
  HStack,
  Radio,
  Text,
  Heading,
  Center,
  Stack,
  useToast,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Flex,
  InputGroup,
  InputLeftAddon,
  Button,
  Select,
  Spinner,
  Textarea,
} from '@chakra-ui/react'
import axios from '../../api/axios';
import { BsChevronRight, BsPerson } from 'react-icons/bs';
import { NavLink, useNavigate } from 'react-router-dom'
import { MdSend } from 'react-icons/md';


const NEWCREATE_EMPL_URL = '/employee/brand-ambassador/v1/create-brand-ambassador'
const GETSHOPS_URL = '/employee/shop/v1/get-shops'
const GETUSERID_URL = '/employee/profile/v1/get-employee/'



const employee = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  nationalIdNumber: "",
  address: "",

  // availability: "",
  // employeeZone: "",


}

function UserExtForm() {

    const [state, setState] = useState(employee);

    const { firstName, lastName, phoneNumber, email, nationalIdNumber, address } = state
  
      const [shopId, setShopId] = useState('')
      const [selectshop, setSelectShop] = useState([])
        const[keyAccountRepAccountId, setKeyaccountRepaccountId] = useState("")
        const [companyName, setCompanyName] = useState("")
    const [employeeError, setEmployeeError] = useState('')
    const [phoneError, setPhoneError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [fnameError, setFNameError] = useState('')
    const [lnameError, setLNameError] = useState('')
    const [roleerror, setRoleError] = useState('')
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const toast = useToast();
    const navigate = useNavigate();
  
    useEffect(() => {
      const token = localStorage.getItem("token");
      axios.get(GETSHOPS_URL, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then((response) => {
        setSelectShop(response.data.body);
      });
    }, []);

    useEffect(() => {
        const token = localStorage.getItem("token");
        axios.get(GETUSERID_URL + `${user.id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).then((response) => {
          setKeyaccountRepaccountId(response.data.body.employeeAccount.id);
          console.log(response.data.body.employeeAccount.id)
        });
      }, []);
  
   
  
    const handleemployee = () => {
      // e.preventDefault();
      setErrorMessage('')
      const res = validate();
      if (!res) {
        // console.log('validation failed')
        toast({
          title: 'Failed',
          description: "Please enter details",
          status: 'error',
          duration: 3000,
  
        });
        return;
      }
      const newData = {
        option: selectedOption,
      };
      // setRoles([...roles, selectedOption]);
      setLoading(true);
      const token = localStorage.getItem("token");
      axios.post(NEWCREATE_EMPL_URL, {
        firstName,
        lastName,
        phoneNumber,
        email,
        nationalIdNumber,
        address,
        companyName,
        zone: "1",
        shopId,
        roles: [selectedOption],
        keyAccountRepAccountId,
  
      }, {
        headers: {
          "Content-type": "application/json",
          'Authorization': `Bearer ${token}`
        }
  
      })
  
  
        .then(response => {
          if (response.data.success) {
            let user = localStorage.getItem('user')
            user = JSON.parse(user);
            console.log(user.roles[0].name)
         
            if (user.roles[0].name === 'ADMIN'){
              navigate('/admin/users')
            }else if (user.roles[0].name === 'SUPER_ADMIN'){
              navigate('/admin/users')
          }else if (user.roles[0].name === 'CLERK'){
            navigate('/admin/external-users')
          }else{
              navigate('/admin/operator')
            }
            setLoading(false)
            toast({
              title: 'User created',
              description: response.data.message,
              status: 'success',
              duration: 6000,
  
            });
          }
  
          else {
            setLoading(false)
            toast({
              title: 'Failed',
              description: response.data.message,
              status: 'error',
              duration: 3000,
  
            });
          }
        })
        .catch(error => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            toast({
              title: "Error",
              description: `Server responded with ${error.response.status}`,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            setLoading(false)
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              title: "Network Error",
              description: "Please Check your Internet Connection",
              status: "error",
              duration: 8000,
              isClosable: true,
            });
            setLoading(false)
          } else {
            // Something happened in setting up the request that triggered an Error
            toast({
              title: "Network Error",
              description: `Error: ${error.message}`,
              status: "error",
              duration: 8000,
              isClosable: true,
            });
  
          }
        })
    }
  
    useEffect(() => {
  
    }, [loading])
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setState({ ...state, [name]: value });
    };
  
    const [roles, setRoles] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");
  
    const options = [
      // { label: 'Operator', value: "OPERATOR" },
      { label: 'Brand Ambassador', value: "BRAND_AMBASSADOR",roles: ["ADMIN"] },
      { label: 'Brand Ambassador', value: "BRAND_AMBASSADOR",roles: ["SUPER_ADMIN"] },
    //   { label: 'Clerk', value: "CLERK",roles: ["SUPER_ADMIN"] },
    //   { label: 'Admin', value: "ADMIN",roles: ["ADMIN"] },
    //   { label: 'Admin', value: "ADMIN",roles: ["SUPER_ADMIN"] },

    ];
   
    let user = localStorage.getItem('user')
    user = JSON.parse(user);
  
  const userRoles = user.roles[0].name
  console.log(userRoles);
  
  const filteredOptions = options.filter(item => userRoles === item.roles[0]);
  console.log(filteredOptions)
  
  
    const validate = () => {
      const EMAIL_REGEX = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
      const PHONE_REGEX = /^(71)/;
  
      if (!firstName) {
        setFNameError('First name is required');
        return false;
      }
  
      if (!lastName) {
        setLNameError('Lastname is required');
        return false;
      }
      if (phoneNumber.length < 9) {
        setPhoneError('phone number invalid');
        return false;
      }
      if (phoneNumber.length > 10) {
        setPhoneError('phone number invalid');
        return false;
      }
      if (!PHONE_REGEX.test(phoneNumber)) {
        setPhoneError('please enter staff (980) netone number');
        return false;
      }
  
      if (!roles) {
        setRoleError('Please select roles');
        return false;
      }
     
     
      return true
  
    }

  return (
    <Container maxW={'7xl'}>
      <Flex justifyContent="flex-end">
        <Breadcrumb fontSize={'xs'} fontWeight={'semibold'} spacing='8px' separator={<BsChevronRight color='gray.500' />}>
          <BreadcrumbItem>
            <NavLink>
              <BreadcrumbLink >  Home</BreadcrumbLink>
            </NavLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href='#'>Create Account</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>
      <Center>

        <Box
          //  maxW='sm' borderWidth='2px' borderRadius='lg'
          borderRadius='lg' shadow='md' borderWidth='2px' bg='white'
          style={{
            width: "50%",
            justifyContent: "space-around",
            boxShadow: 50,
            marginTop: "20px",
            marginBottom: "20px",
            // display: "flex",
            //     flexDirection: "column",
            //     justifyContent: "space-evenly",

          }}

        >

          <Stack spacing={1} marginLeft='10' marginRight='10' marginBottom='2'  >
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: '1xl', sm: '2xl', lg: '3xl' }}
              marginBottom='2'
              marginTop='5'
            >
              <Text as={'span'}>
                {' '}
                <Text
                  as={'span'}
                  color="black"
                  position={'relative'}
                >
                  Create BA
                  <Text as={'span'} position={'relative'}>
                    {' '}Account
                  </Text>
                </Text>
              </Text>
            </Heading>

            {user.title === "KEY ACCOUNT REP"  ? (
                <>
            <HStack mb={4} spacing={4}>

            <FormControl isRequired>
              <FormLabel htmlFor='firstName'>First name</FormLabel>
              <Input
                id='firstName'
                placeholder='First name'
                type='text'
                name='firstName'
                value={firstName}
                onChange={handleInputChange}
                onFocus={() => { setFNameError('') }} borderColor={fnameError ? 'red.400' : 'gray.300'}
              />
              <Text color={'red'}>{fnameError}</Text>
            </FormControl>

            <FormControl isRequired>
              <FormLabel htmlFor='lastName'>Last name</FormLabel>
              <Input
                id='lastName'
                placeholder='Last name'
                type='text'
                name='lastName'
                value={lastName}
                onFocus={() => { setLNameError('') }} borderColor={lnameError ? 'red.400' : 'gray.300'}
                onChange={handleInputChange}
                
                />
              <Text color={'red'}>{lnameError}</Text>
            </FormControl>
                </HStack>
              <HStack spacing={4}>
            <FormControl mt={2} isRequired>
              <FormLabel htmlFor='phoneNumber'>Mobile Number</FormLabel>
              <InputGroup>
                <InputLeftAddon children='+263' />
                <Input id='phoneNumber'
                  type='number'
                  placeholder='712000000'
                  _placeholder={{ color: 'gray.500' }}
                  name='phoneNumber'
                  value={phoneNumber}
                  onFocus={() => { setPhoneError('') }} borderColor={phoneError ? 'red.400' : 'gray.300'}
                  onChange={handleInputChange}

                  />
              </InputGroup>
              <Text color={'red'}>{phoneError}</Text>
            </FormControl>

            <FormControl isRequired>
              <FormLabel mt={2} htmlFor='email'>Email: </FormLabel>
              <Input

                id='email'
                placeholder='example@email.com'
                name='email'
                type='email'
                value={email}
                onChange={handleInputChange}
                onFocus={() => { setEmailError('') }} borderColor={emailError ? 'red.400' : 'gray.300'}
              />
              <Text color={'red'}>{emailError}</Text>
            </FormControl>
                  </HStack>
                  <HStack spacing={4}>
                  <FormControl isRequired>
                  <FormLabel htmlFor='nationalIdNumber'>National ID:</FormLabel>
                  <FormHelperText>eg. 00-0000000A00</FormHelperText>
                  <Input
                    marginBottom={2}
                    id='nationalIdNumber'
                    placeholder='00-00000000A00'
                    type='text'
                    name='nationalIdNumber'
                    value={nationalIdNumber}
                    // onFocus={() => { setNatIdError('') }} borderColor={natIdError ? 'red.400' : 'gray.300'}
                    onChange={handleInputChange}
                    textTransform='uppercase'
                  />
                  {/* <Text color={'red'}>{natIdError}</Text> */}
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor='address'>Address</FormLabel>
                  <Textarea
                    marginBottom={2}
                    id='address'
                    placeholder='Address'
                    type='text'
                    name='address'
                    value={address}
                    // onFocus={() => { setAddressError('') }} borderColor={addressError ? 'red.400' : 'gray.300'}
                    onChange={handleInputChange}
                  />
                  {/* <Text color={'red'}>{addressError}</Text> */}
                </FormControl>

         </HStack>
           {/* <FormControl mt={2} mb={2}>
         <FormLabel>Shop</FormLabel>
         <Select 
                    maxW={'260px'}
                   _hover={{
                     borderRadius: 'orange',
                   }} 
                   placeholder='Select Shop' name='regionId' onChange={(e)=> setShopId(e.target.value)}>
           {selectshop.length === 0 && <Spinner />}
           {selectshop.map((shopes) => (
             <option key={shopes.id} value={shopes.id}>
               {shopes.name}
             </option>
           ))}
             
           </Select>
           </FormControl> */}

            <FormControl as='fieldset'>
              <FormLabel as='legend' marginTop={2} marginBottom={2}>Roles</FormLabel>
              <RadioGroup onChange={(e) => setSelectedOption(e)} value={selectedOption} onFocus={() => { setRoleError('') }} borderColor={roleerror ? 'red.400' : 'gray.300'} >
                <HStack spacing='24px' >
                  {filteredOptions.map((option) => (
                    <Radio key={option.value} value={option.value} name='roles'>
                      {option.label}
                    </Radio>
                  ))}
                </HStack>
              </RadioGroup>
              <FormHelperText>Select User Role</FormHelperText>
              <Text color={'red'}>{roleerror}</Text>
            </FormControl>

            {
              errorMessage ? <Text color={'red'}>{errorMessage}</Text> : <></>
            }
            </>
            ) : (

                <>
                <HStack mb={4} spacing={4}>
    
                <FormControl isRequired>
                  <FormLabel htmlFor='firstName'>First name</FormLabel>
                  <Input
                    id='firstName'
                    placeholder='First name'
                    type='text'
                    name='firstName'
                    value={firstName}
                    onChange={handleInputChange}
                    onFocus={() => { setFNameError('') }} borderColor={fnameError ? 'red.400' : 'gray.300'}
                  />
                  <Text color={'red'}>{fnameError}</Text>
                </FormControl>
    
                <FormControl isRequired>
                  <FormLabel htmlFor='lastName'>Last name</FormLabel>
                  <Input
                    id='lastName'
                    placeholder='Last name'
                    type='text'
                    name='lastName'
                    value={lastName}
                    onFocus={() => { setLNameError('') }} borderColor={lnameError ? 'red.400' : 'gray.300'}
                    onChange={handleInputChange}
                    
                    />
                  <Text color={'red'}>{lnameError}</Text>
                </FormControl>
                    </HStack>
                  <HStack spacing={4}>
    
                <FormControl mt={2} isRequired>
                  <FormLabel htmlFor='phoneNumber'>Mobile Number</FormLabel>
                  <InputGroup>
                    <InputLeftAddon children='+263' />
                    <Input id='phoneNumber'
                      type='number'
                      placeholder='712000000'
                      _placeholder={{ color: 'gray.500' }}
                      name='phoneNumber'
                      value={phoneNumber}
                      onFocus={() => { setPhoneError('') }} borderColor={phoneError ? 'red.400' : 'gray.300'}
                      onChange={handleInputChange}
    
                      />
                  </InputGroup>
                  <Text color={'red'}>{phoneError}</Text>
                </FormControl>
    
                <FormControl isRequired>
                  <FormLabel mt={2} htmlFor='email'>Email: </FormLabel>
                  <Input
    
                    id='email'
                    placeholder='example@email.com'
                    name='email'
                    type='email'
                    value={email}
                    onChange={handleInputChange}
                    onFocus={() => { setEmailError('') }} borderColor={emailError ? 'red.400' : 'gray.300'}
                  />
                  <Text color={'red'}>{emailError}</Text>
                </FormControl>
                      </HStack>
                      <HStack spacing={4}>
                      <FormControl isRequired>
                      <FormLabel htmlFor='nationalIdNumber'>National ID:</FormLabel>
                      <FormHelperText>eg. 00-0000000A00</FormHelperText>
                      <Input
                        marginBottom={2}
                        id='nationalIdNumber'
                        placeholder='00-00000000A00'
                        type='text'
                        name='nationalIdNumber'
                        value={nationalIdNumber}
                        // onFocus={() => { setNatIdError('') }} borderColor={natIdError ? 'red.400' : 'gray.300'}
                        onChange={handleInputChange}
                        textTransform='uppercase'
                      />
                      {/* <Text color={'red'}>{natIdError}</Text> */}
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel htmlFor='address'>Address</FormLabel>
                      <Textarea
                        marginBottom={2}
                        id='address'
                        placeholder='Address'
                        type='text'
                        name='address'
                        value={address}
                        // onFocus={() => { setAddressError('') }} borderColor={addressError ? 'red.400' : 'gray.300'}
                        onChange={handleInputChange}
                      />
                      {/* <Text color={'red'}>{addressError}</Text> */}
                    </FormControl>
    
             </HStack>
               <FormControl mt={2} mb={2}>
             <FormLabel>Shop</FormLabel>
             <Select 
                        maxW={'260px'}
                       _hover={{
                         borderRadius: 'orange',
                       }} 
                       placeholder='Select Shop' name='regionId' onChange={(e)=> setShopId(e.target.value)}>
               {selectshop.length === 0 && <Spinner />}
               {selectshop.map((shopes) => (
                 <option key={shopes.id} value={shopes.id}>
                   {shopes.name}
                 </option>
               ))}
                 
               </Select>
               </FormControl>
    
                <FormControl as='fieldset'>
                  <FormLabel as='legend' marginTop={2} marginBottom={2}>Roles</FormLabel>
                  <RadioGroup onChange={(e) => setSelectedOption(e)} value={selectedOption} onFocus={() => { setRoleError('') }} borderColor={roleerror ? 'red.400' : 'gray.300'} >
                    <HStack spacing='24px' >
                      {filteredOptions.map((option) => (
                        <Radio key={option.value} value={option.value} name='roles'>
                          {option.label}
                        </Radio>
                      ))}
                    </HStack>
                  </RadioGroup>
                  <FormHelperText>Select User Role</FormHelperText>
                  <Text color={'red'}>{roleerror}</Text>
                </FormControl>
    
                {
                  errorMessage ? <Text color={'red'}>{errorMessage}</Text> : <></>
                }
                </>
            )}
          </Stack>
          <Button 
            onClick={handleemployee}
            isLoading={loading}
            loadingText='Loading'
            spinnerPlacement='end'
            borderRadius='md' bg='orange.400' color='white'
            px={4} h={8} marginTop='2' marginBottom='5' marginLeft='9'
            rightIcon={<MdSend/>}
          >
            Submit
          </Button>
        </Box>
      </Center>

    </Container>
  )
}

export default UserExtForm