import React, { useState, useEffect } from 'react'
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Stack,
    Box,
    chakra,
    Wrap,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
    Flex,
    Text,
    Center,
    useDisclosure,
    Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Select,
  HStack,
  Spinner,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  } from '@chakra-ui/react'
  import { FiUserPlus } from 'react-icons/fi'
  import { BsBank, BsChevronRight, BsPerson } from 'react-icons/bs';
  import { NavLink, useNavigate } from 'react-router-dom'
  import axios from '../../../api/axios.js'
import CountdownTimer from '../../login/CountdownTimer.js';
  // import CountdownTimer from '../../CountdownTimer'

  const BUNDLE_URL = 'mobile-api/config/bundle'
  const GETBUNDLE_URL = 'mobile-api/config/bundles'
  const GETTYPES_URL = 'mobile-api/config/bundle-types'
  const GETCURRENCY_URL = 'mobile-api/config/currencies'
  const DELETEBUNDLE_URL = '/mobile-api/config/bundle/'

  const initiate_Bundles ={
    displayName: "",
    bundleAmount: "",
    price: "",
    bundleShortCode: "",
    bundleName:"",
  
    
    
  }
  const timeOptions = [
    { value: 'minutes', label: 'Minutes' },
    { value: 'hours', label: 'Hours' },
    { value: 'days', label: 'Days' },
    { value: 'weeks', label: 'Weeks' },
    { value: 'months', label: 'Months' },
  ];


function Bundles() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const initialRef = React.useRef(null);

    const { isVhura, onVhura, onVhara } = useDisclosure()
    const cancelRef = React.useRef()

    const [bundledata, setBundleData] = useState(initiate_Bundles)
    const {displayName, bundleAmount, price,bundleShortCode,bundleName} = bundledata

    const [bundleTypeId, setBundleTypeId] = useState('')
    const [currencyId, setCurrencyId] = useState('')

    const toast = useToast();
    const navigate = useNavigate();

    const [ loading, setLoading] = useState(false);
    const [nameError, setNameError] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setBundleData({ ...bundledata, [name]: value });
  };

  const [selectBundleT, setSelectBundleT] = useState([]);
  const [selectCurrency, setSelectCurrency] = useState([])

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios.get(GETTYPES_URL, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((response) => {
      setSelectBundleT(response.data.body);
    });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios.get(GETCURRENCY_URL, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((response) => {
      setSelectCurrency(response.data.body);

    });
  }, []);

    const handlesubmit = () => {
        setErrorMessage('')
    const res = validate();
        if (!res) {
      toast({
        title: 'Failed',
        description: "Form not complete",
        status: 'error',
        duration: 3000,

      });
      return;
    }
        setLoading(true)
        const maseconds = handleConvert();
        const token = localStorage.getItem("token");
        axios.post(BUNDLE_URL, {
          displayName,
          bundleAmount,
          price,
          bundleTypeId,
          currencyId,
          expiresAfter: maseconds,
          bundleShortCode,
          bundleName,
          active: true,
          
        },{
          headers: { 
            "Content-type": "application/json",
            'Authorization': `Bearer ${token}`
          }
          
        })
        .then(response => {
            if (response.data.success) {
                navigate('/admin/config')
                getBundleType();
                onClose();
                toast({
                  title: 'Success',
                  description: response.data.message,
                  status: 'success',
                  duration: 6000,
                  
                });
                setLoading(false)
              }
            
            else {
              setLoading(false)
              toast({
                title: 'Failed',
                description: response.message,
                status: 'error',
                duration: 3000,
               
              });
            }
        })
        .catch(error => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              toast({
                title: "Error",
                description: `Server responded with ${error.response.status}`,
                status: "error",
                duration: 5000,
                isClosable: true,
              });
              setLoading(false)
            } else if (error.request) {
              // The request was made but no response was received
              toast({
                title: "Network Error",
                description: "No response from server",
                status: "error",
                duration: 8000,
                isClosable: true,
              });
              setLoading(false)
            } else {
              // Something happened in setting up the request that triggered an Error
              toast({
                title: "Network Error",
                description: `Error: ${error.message}`,
                status: "error",
                duration: 8000,
                isClosable: true,
              });
             
            }
          })
    }
    useEffect(() => {

    }, [loading])

    const validate =() => {
        if (!displayName) {
            setNameError('Enter Name')
            return false;
          }
          return true;
    }

    const [bundleT, setBundleT] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(75);
    const [totalPages, setTotalPages] = useState(1);

    const getBundleType = async () => {
        setLoading(true);
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`${GETBUNDLE_URL}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
  
        if(response.status === 200) {
          setBundleT(response.data.body)
          setLoading(false);
        }
      } catch (error) {

      }
    }
  

    useEffect(() => {
        getBundleType();
      
      }, [])
      
  
      useEffect(() => {
        setTotalPages(Math.ceil(bundleT.length / itemsPerPage));
      }, [bundleT]);
      
      const paginateData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return bundleT.slice(startIndex, endIndex);
      };
      
      const handlePageChange = (page) => {
        setCurrentPage(page);
      }
      
      const paginatedData = paginateData(bundleT, currentPage, itemsPerPage);


 
      const [expiresAfter, setExpiresAfter] = useState("");
      const [selectedOption, setSelectedOption] = useState(timeOptions[0].value);
      // const [result, setResult] = useState(null);

      const handleConvert = () => {
        let seconds = 0;
        switch (selectedOption) {
          case 'minutes':
            seconds = expiresAfter * 60;
            break;
          case 'hours':
            seconds = expiresAfter * 60 * 60;
            break;
          case 'days':
            seconds = expiresAfter * 60 * 60 * 24;
            break;
          case 'weeks':
            seconds = expiresAfter * 60 * 60 * 24 * 7;
            break;
          case 'months':
            seconds = expiresAfter * 60 * 60 * 24 * 30; // Assumes a month is 30 days
            break;
          default:
            seconds = 0;
        }
        // setResult(seconds);
        return seconds;
      };

      const handleDelete = async (id) => {
        setLoading(true)
        const token = localStorage.getItem("token");
        try {
          
          const response = await axios.delete(DELETEBUNDLE_URL + `${id}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          if(response.data.success) {
            getBundleType()
            toast({
              title: 'Success',
              description: "Deleted",
              status: 'success',
              duration: 6000,
              
            });
            setLoading(false)
          
          }else{
            toast({
              title: 'Failed',
              description: "Failed to Delete",
              status: 'error',
              duration: 6000,
              
            });
            setLoading(false)
          }
        } catch (error) {
          
        }
      };
    
      function convertToDecimal(price) {
        return parseFloat(price);
      }


  return (
    <Box maxW="4xl" >
       {/* <chakra.h1
        textAlign={'left'}
        fontSize={'1xl'}
        py={5}
        fontWeight={'bold'}>
   Bank List
      </chakra.h1> */}
      <Stack direction='row' spacing={4}>
  <Button onClick={onOpen}  leftIcon={<BsBank/>} colorScheme='orange' variant='solid'>
    Add Bundle
  </Button>
  <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent mt={2}>
          <ModalHeader>Create Bundle:</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
          

            <FormControl mt={2}>
              <FormLabel>Display name:</FormLabel>
              <Input type='text' value={displayName}  name='displayName' placeholder='Display Name' onChange={handleInputChange}  onFocus={()=> {setNameError('')}} borderColor={nameError ? 'red.400' : 'gray.300'} />
              <Text color={'red'}>{nameError}</Text>
            </FormControl>
            <FormControl mt={2}>
              <FormLabel>Bundle Amount:</FormLabel>
              <Input type='text' value={bundleAmount} name='bundleAmount'  placeholder='Bundle Amount' onChange={handleInputChange}  textTransform='uppercase' />
              <Text color={'red'}>{nameError}</Text>
            </FormControl>

            <FormControl mt={2}>
            <FormLabel>Bundle Type</FormLabel>
            <Select 
                      _hover={{
                        borderRadius: 'orange',
                      }} 
                      placeholder='Select bundle type' name='bundleTypeId' onChange={(e)=> setBundleTypeId(e.target.value)}>
              {selectBundleT.length === 0 && <Spinner />}
              {selectBundleT.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
                
              </Select>
              </FormControl>
          
          <FormControl mt={2}>
          <FormLabel>Currency</FormLabel>
               <Select 
                      _hover={{
                        borderRadius: 'orange',
                      }} 
                      placeholder='Select Currency' name='currencyId' onChange={(e)=> setCurrencyId(e.target.value)}>
                 {selectCurrency.length === 0 && <Spinner />}
              {selectCurrency.map((currency) => (
                <option key={currency.id} value={currency.id}>
                  {currency.name}
                </option>
              ))} 
              </Select>
          </FormControl>

                  <FormControl mt={2}>
                          <FormLabel>Bundle Name:</FormLabel>
                          <Input type='text' value={bundleName}  name="bundleName" placeholder='Bundle Name' onChange={handleInputChange}  onFocus={()=> {setNameError('')}} borderColor={nameError ? 'red.400' : 'gray.300'} />
                  </FormControl>
                      
                  <HStack spacing={100} mt={2}>
                        <FormControl mt={2}>
                          <FormLabel>Valid For:</FormLabel>
                          <Input type='number' w={'150px'} name='expiresAfter' 
                           placeholder='#' value={expiresAfter}  
                           onChange={(e)=>setExpiresAfter(e.target.value)} 
                            onFocus={()=> {setNameError('')}} borderColor={nameError ? 'red.400' : 'gray.300'} />
                        </FormControl>

                      <FormControl>
                        <FormLabel>Duration:</FormLabel>
                          <Select
                          placeholder="Select option"
                          value={selectedOption}
                          
                          onChange={(e) => setSelectedOption(e.target.value)}
                          w={'150px'} >
                          {timeOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                  </HStack>
                      {/* <Button onClick={handleConvert}>Convert</Button> */}
                      {/* {result && <p>{`${expiresAfter} ${selectedOption} = ${result} seconds`}</p>} */}
                        {/* <FormControl mt={2}>
                          <FormLabel>Bundle Name:</FormLabel>
                          <Input type='text' value={bundleName}  name="bundleName" placeholder='Bundle Name' onChange={handleInputChange}  onFocus={()=> {setNameError('')}} borderColor={nameError ? 'red.400' : 'gray.300'} />
                        </FormControl> */}
                    <HStack spacing={100}>
                        <FormControl  mt={2}>
                          <FormLabel>Short Code:</FormLabel>
                          <Input type='text' value={bundleShortCode} name="bundleShortCode" w={'150px'}  placeholder='#' onChange={handleInputChange}  onFocus={()=> {setNameError('')}} borderColor={nameError ? 'red.400' : 'gray.300'} />
                        </FormControl>
                        <FormControl  mt={2}>
                          <FormLabel>Price($):</FormLabel>
                          <Input type='number' value={price} name="price" w={'150px'}  placeholder='$' onChange={handleInputChange}  onFocus={()=> {setNameError('')}} borderColor={nameError ? 'red.400' : 'gray.300'} />
                        </FormControl>
                    </HStack>
                        
                  
            {
              errorMessage ? <Text color={'red'}>{errorMessage}</Text> : <></>
            }
          </ModalBody>

          <ModalFooter>
            <Button 
           onClick={handlesubmit} 
           isLoading={loading}
           loadingText='Loading'
           spinnerPlacement='start'
           colorScheme='orange' mr={3}>
             Save
           </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  </Stack>
    <Stack p="3" bg='white' boxShadow="lg" m="4" borderRadius="sm" width={'1100px'}>
    
    <TableContainer>
  <Center>

  <Table variant='striped' size={'md'} >
    <TableCaption> 
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
    
    <Button
    colorScheme={'orange'}
    m={1}
    key={page}
    onClick={() => handlePageChange(page)}
    color={page === currentPage ? 'black' : 'white'}
    cursor="pointer"

    >
            {page}
          </Button>
        ))}
        </TableCaption>
    <Thead>
      <Tr>
        <Th>#</Th>
        <Th>Name</Th>
        <Th>Bundle Type</Th>
        <Th>Expiry(Days)</Th>
        <Th>Currency</Th>
        <Th>Price($)</Th>
        <Th>Action</Th>
      </Tr>
    </Thead>
    <Tbody>
    {paginatedData.map((data,index) =>{
      return(
      <Tr key={index}>
      <Td>{index +1}</Td>
      <Td>{data.displayName == null ? '': data.displayName}</Td>
      <Td>{data.type.name == null ? '': data.type.name}</Td>
      <Td>{data.expiresAfter == null ? '': data.expiresAfter / (24 * 3600)} Day</Td>
    
      <Td>{data.currency.name == null ? '': data.currency.name}</Td>
      <Td>{data.price == null ? '': convertToDecimal(data.price)}</Td>
      <Td>
        <Wrap spacing={2} direction='row' align='center'>
          {/* <Button  colorScheme='blue' size='xs'>View</Button> */}
          {/* <Button onClick={onOpen}  colorScheme='red' size='xs'>Delete</Button> */}
          <Button onClick={() => handleDelete(data.id)}  colorScheme='red' size='xs'>Remove</Button>
          {/* <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
              >
              <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Delete Bundle</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
           Are you sure you want proceed?
          </AlertDialogBody>
          <AlertDialogFooter>
          <Button colorScheme={'gray'} ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Button 
          onClick={() => handleDelete(data.id)}
            isLoading={loading}
            loadingText='Loading'
            spinnerPlacement='start'
            colorScheme='red' ml={3}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
                  </AlertDialog> */}
        </Wrap>
      </Td>
    </Tr>
    )
  })}
    </Tbody>
    

    <Tfoot>
  
    </Tfoot>
    
  </Table>
  </Center>
  <Flex justifyContent={'flex-end'} mr={10} >

 
        </Flex>
</TableContainer>

    </Stack>
    </Box>
  )
}

export default Bundles